/* eslint-disable react-hooks/exhaustive-deps */
import StatsCard from "components/StatsCard";
import React, { useState } from "react";
import "./styles.scss";
import workflow from "assests/icons/buyer/workflow.svg";
import AddIcon from "assests/icons/buyer/add-icon.svg";
import SearchIcon from "components/SearchBar";
import Dropdown from "components/Dropdown";
import { AppDispatch, RootState } from "store/store";
import DataTable from "components/DataTable";
import { Workflowdatas, WorkflowColumn } from "./data";
import CreateWorkFlow from "components/ModalBuyer/CreateWorkFlow";
import { useDispatch, useSelector } from "react-redux";
import useSearch from "hooks/useSearch";
import { deleteWorkFlow_two, getApprovals_two } from "store/slice/BuyerSlice/ApprovalSlice/ApprovalSlice";
import { useEffect } from "react";
import { toastPopUp } from "utils/utils";
import Pagination from "components/Pagination";
import { useHistory } from "react-router-dom";
import { checkPermission } from "utils/utils";

const ApprovalWorkFlow = () => {

    //State
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [singleWorkFlow, setSingleWorkFlow] = useState<any>(null);
    const history = useHistory()

    //Redux
    const dispatch = useDispatch<AppDispatch>();
    const {approvalItems, loading } = useSelector((state: RootState) => state.approvals);
    const [modal, setModal] = useState<boolean>(false);
    const closeModal = () => {
        setModal((prev) => !prev);
        setSingleWorkFlow(null)
    };

    const { role_access } = useSelector((state: RootState) => state.user?.user);


  
    //Hook
    const { search, inputRef, handleChange, searched } = useSearch(getApprovals_two, perPage);

    //Show Number for PerPage Request
    const handleNewPage = (pageNo: number, page: number = 1) => {
        setPerPage(pageNo);
        setPage(page);
        dispatch(getApprovals_two(page, pageNo, search));
    };

       //Pagination
  //---------------------------------------------
  const handlePageChange = React.useCallback(
    (page: number, perPage: number) => {
      setPage(page);
      dispatch(getApprovals_two(page, perPage, search));
    },
    [search]
  );

  const paginate = React.useCallback(
    (pageNo: number, perPage: number) => {
      setPage(pageNo);
      dispatch(getApprovals_two(pageNo, perPage, search));
    },
    [search]
  );

     // setpage to the first page when a searched executed
    useEffect(() => {
        if (searched) setPage(1);
    }, [searched]);

    useEffect(() => {
        dispatch(getApprovals_two(page,perPage));
      }, []);

      //Edit CompanyData
      const editWorkFlow_two = (id: string, proccess_name: string,department_id:string,department_description:string,location_id:string,company_id:string,purchasing_group_id:string) => {
    
        setSingleWorkFlow({ id, proccess_name,department_id,location_id,company_id,department_description,purchasing_group_id });
        setModal((prev) => !prev);
      };

    //   deleteCompany

    const deleteWork2 = (name: string, id: string) => {
        const payload = { approval_workflow_id: id };
        toastPopUp(
          () => {
            dispatch(
              deleteWorkFlow_two(payload, () => {
                dispatch(getApprovals_two(page,perPage));
              })
            );
          },
          "Delete",
          "Cancel",
          "Delete the entity",
          name,
          "red"
        );
      };

      const showProcess = (id,name) => {
        localStorage.setItem("name",name );
        history.push({ pathname: `/buyer/settings/approval/approvalsteps/${id}`});
      };

    const approvals =
    approvalItems &&
    approvalItems?.data?.map((approval) => {
        
      return {
        code: approval.id,
        name: approval.process_name && approval.process_name.replace("_"," "),
        legal: approval.company_description,
        department: approval.department_description ?approval.department_description:'N/A' ,
        location: approval.location_description ? approval.location_description : 'N/A',
        level: approval.workflow_approval_steps && approval.workflow_approval_steps.length,
        viewSteps:()=>showProcess(approval.id, approval.company_description,),
        edit: () => checkPermission(role_access, "SETUP_INDEX") && editWorkFlow_two(
           approval.id,
           approval.process_name,
           approval.department_id,
           approval.department_description,
           approval.location_id,
           approval.company_id,
           approval.purchasing_group_id
           ),
        deleteWork: () => checkPermission(role_access, "SETUP_INDEX") && deleteWork2(approval.process_name, approval.id)
      };
    });
    
    
    // onClick={showProcess}
    return (
        <div id="settings-approval">
            {modal && <CreateWorkFlow closeModal={closeModal} singleWorkFlow={singleWorkFlow} />}
            <div className="container">
                <h3 className="page-title">Workflow</h3>
                <div className="row mt-3 mb-5">
                    <StatsCard
                        statsNumber={approvalItems?.count ?? 0}
                        text="Workflows"
                        classes="stats-icon"
                        image={workflow}
                        iconBackgroundColor="rgba(45, 156, 219, 0.1)"
                    />
                    <StatsCard image={AddIcon} classes="middle-img" centerText="Create Workflow" onClick={closeModal} />
                </div>

                <div className="d-flex  mb-3">
                <SearchIcon
                    placeholder="Search for ID Workflow"
                    boxClasses=""
                    textRef={inputRef}
                    value={search}
                    onChange={handleChange}
                />
                  <Dropdown perPage={perPage} action={handleNewPage} />
                </div>
                <DataTable columns={WorkflowColumn} data={approvals}  loading={loading} />
                {
                  approvalItems?.count > 10 ?
                  <Pagination
                    page={page}
                    lastPage={approvalItems?.pagination?.lastPage}
                    paginate={paginate}
                    total={approvalItems?.pagination?.total}
                    handlePageChange={handlePageChange}
                    perPage={perPage}
                  />
                   : null
                }
                
              
            </div>
        </div>
    );
};

export default ApprovalWorkFlow;

import { Link } from "react-router-dom";

export const reportCol = [
    {
        name: "Level",
        index: "vendor_code",
        id: 1
    },
    {
        name: "Total Vendors",
        index: "vendor_description",
        id: 2
    },
    // {
    //     name: "Vendor",
    //     index: "total_purchase_orders",
    //     id: 3
    // },
    // {
    //     name: "Total Invoice",
    //     index: "total_value",
    //     id: 4
    // }

];


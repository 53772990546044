import StatsCard from "components/StatsCard";
import Icon from "assests/icons/statsCard/Vector (4).png";
import greenCheck from "assests/icons/buyer/green-check.svg";
import arrowdown from "assests/icons/Vector (2).svg";
import membersGreen from "assests/icons/buyer/members-green.svg";
import membersBlue from "assests/icons/buyer/members-blue.svg";
import purpleClock from "assests/icons/buyer/purple-clock1.svg";
import { AppDispatch, RootState } from "store/store";
import mail1 from "assests/icons/buyer/mail-1.svg";
import SearchBar from "../../../../components/SearchBar";
import Dropdown from "components/Dropdown";
import DataTable from "components/DataTable";
import { tableCol, tableData } from "./data";
import { toastr } from "react-redux-toastr";
import exportIcon from "assests/icons/buyer/export-21.svg";
import "./styles.scss";
import React, { useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import DropdownMenu from "components/DropdownMenu";
import { useDispatch, useSelector } from "react-redux";
import useSearch from "hooks/useSearch";
import { useEffect } from "react";
import SearchIcon from "../../../../components/SearchBar";
import { award, disqualify, getAnalysis, getChart, getChartCom, getEachRfp, getOneRfp, invite } from "store/slice/BuyerSlice/EvaluationSlice/EvaluationSlice";
import Pagination from "components/Pagination";
import SelectDropdown from "components/SelectDropdown";
import Checkbox from "./checkbox";
import { Bar } from "react-chartjs-2";
import { dataTool } from "echarts";
import CreateDoc from "components/ModalBuyer/CreateDoc";
import AddIcon from "assests/icons/buyer/add-icon.svg";
import { kFormatter } from "utils/utils";

const Evaluation_5 = () => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  // const { idEach } = useParams<{ idEach?: any }>();
  const [seen, setSeen] = useState(true);
  const [seen2, setSeen2] = useState(true);
  const [companyList, setCompanyList] = useState<string[]>([]);
  const [companyScore, setCompanyScore] = useState<number[]>([]);
  const [companyListCom, setCompanyListCom] = useState<string[]>([]);
  const [companyScoreCom, setCompanyScoreCom] = useState<number[]>([]);
  const [companyPrice, setCompanyPrice] = useState<number[]>([]);
  const [analyze, setAnalyze] = useState(false);
  const [status, setStatus] = useState(null);
  const [isPrice, setIsPrice] = useState(false);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const idEach = urlParams.get("id");
  const rfpType = urlParams.get("rfpType");
  const [modal, setModal] = useState<boolean>(false);

  const handleAnalyze = () => {
    setAnalyze(!analyze);
  };
  const { eachRfp, inviteLoading, eachLoading, oneRfp, awardLoading, disqualifyLoading, oneLoading, chartCommercial, chartTechnical, analysis } = useSelector((state: RootState) => state.rfpData);
  console.log(analysis);
  useEffect(() => {
    const filt = chartTechnical && chartTechnical?.data?.filter((val, i) => i <= 5);
    if (filt) {
      if (seen) {
        const testArray: string[] = [];
        const testScore: number[] = [];
        filt.forEach((item) => testArray.push(item.vendor_description.split(" ")[0]));
        filt.forEach((item) => testScore.push(item.total_vendor_evaluated_score));
        setCompanyList([...companyList, ...testArray]);
        setCompanyScore([...companyScore, ...testScore]);
        setSeen(false);
      }
    }
  }, [chartTechnical]);

  useEffect(() => {
    const filt2 =
      chartCommercial &&
      chartCommercial?.data
        ?.filter((val, i) => i <= 5)
        .filter((item) => {
          return item.rfp_vendor_status !== "-2" && item.rfp_vendor_status !== "3";
        });

    if (filt2) {
      if (seen2) {
        const testArrayCom: string[] = [];
        const testScoreCom: number[] = [];
        const testPrice: number[] = [];

        filt2.forEach((item) => testArrayCom.push(item.vendor_description.split(" ")[0]));
        filt2.forEach((item) => testScoreCom.push(item.total_vendor_evaluated_score));
        filt2.forEach((item) => testPrice.push(item.vendor_price));
        setCompanyListCom([...companyListCom, ...testArrayCom]);
        setCompanyScoreCom([...companyScoreCom, ...testScoreCom]);
        setCompanyPrice([...companyPrice, ...testPrice]);
        setSeen2(false);
      }
    }
  }, [chartCommercial]);

  const handleFilter = (isPrice) => {
    if (isPrice) {
      setIsPrice(true);
    } else {
      setIsPrice(false);
    }
  };

  const options = [
    {
      value: "technical",
      optionName: "Technical Requirements"
    },
    {
      value: "commercial",
      optionName: "Commercial Requirements"
    }
  ];

  const optionsChart = [
    {
      value: "scores",
      optionName: "scores"
    },
    {
      value: "price",
      optionName: "price"
    }
  ];

  const data = [
    {
      question: "How do you intend to deliver the project?",
      scores: [30, 20, 40, 10, 50, 30]
    },
    {
      question: "How will training be cordinated",
      scores: [30, 20, 40, 10, 50, 30]
    },
    {
      question: "Are you CSIA certified",
      scores: [3, 2, 4, 30, 50, 30]
    },
    {
      question: "Technical Prosposal",
      scores: [30, 20, 40, 10, 50, 30]
    }
  ];

  const commercialData = [
    {
      question: "Price",
      scores: [30, 20, 40, 10, 50, 30]
    },
    {
      question: "Payment Terms",
      scores: []
    }
  ];

  const TableRow = ({ question, question2, scores, price }) => {
    return (
      <tr>
        <td>&gt;&gt; {question}</td>
        <td className="empty"></td>
        {scores?.map((el, i) => (
          <td key={el + i}>
            {el} <br /> {question2.includes("price") ? price.length && `(${price[i]})` : null}
          </td>
        ))}
      </tr>
    );
  };

  const [selectedCompany, setSelectedCompany] = useState({ companies: "" });

  // const companies = [
  //   "Satkle Solutions",
  //   "Novel Grey",
  //   "Absolute",
  //   "SARS",
  //   "Potate",
  //   "Goat Crop",
  // ];
  const closeModal = () => {
    setModal((prev) => !prev);
  };
  const handleInput = (e) => {
    const { name, value } = e.target;
    setSelectedCompany({ ...selectedCompany, [name]: value });
  };

  const awardHandle = () => {
    if (status === "4") {
      toastr.error("Action is not allowed");
    } else {
      if (selectedCompany.companies === "") {
        toastr.error("Select a company");
      } else {
        const payload = {
          vendor_id: selectedCompany.companies,
          rfp_id: idEach
        };

        dispatch(
          award(payload, () => {
            dispatch(getAnalysis({ id: idEach }));
          })
        );
      }
    }
  };

  const disqualifyHandle = () => {
    if (status === "4") {
      toastr.error("Action is not allowed");
    } else {
      if (selectedCompany.companies === "") {
        toastr.error("Select a company");
      } else {
        const payload = {
          vendor_id: selectedCompany.companies,
          rfp_id: idEach
        };

        dispatch(
          disqualify(payload, () => {
            dispatch(getAnalysis({ id: idEach }));
          })
        );
      }
    }
  };

  const inviteHandle = () => {
    if (status === "4") {
      toastr.error("Action is not allowed");
    } else {
      if (selectedCompany.companies === "") {
        toastr.error("Select a company");
      } else {
        const payload = {
          vendor_id: selectedCompany.companies,
          rfp_id: idEach
        };
        dispatch(
          invite(payload, () => {
            dispatch(getAnalysis({ id: idEach }));
          })
        );
      }
    }
  };

  //Redux
  const dispatch = useDispatch<AppDispatch>();

  const { search, inputRef, handleChange, searched } = useSearch(getEachRfp, perPage, idEach);

  //Show Number for PerPage Request
  const handleNewPage = (pageNo: number, page: number = 1) => {
    setPerPage(pageNo);
    setPage(page);
    dispatch(getEachRfp({ page, length: pageNo, id: idEach, category: "Commercial" }));
  };
  const history = useHistory();

  //Pagination
  //---------------------------------------------
  const handlePageChange = React.useCallback((page: number, perPage: number) => {
    setPage(page);
    dispatch(getEachRfp({ page, length: perPage, id: idEach, category: "Commercial" }));
  }, []);

  const paginate = React.useCallback((pageNo: number, perPage: number) => {
    setPage(pageNo);
    dispatch(getEachRfp({ page, length: perPage, id: idEach, category: "Commercial" }));
  }, []);

  // barchart data
  const TechnicalCapacityData = {
    labels: companyList && companyList,
    datasets: [
      {
        label: "Technical Evaluation",
        backgroundColor: "#A6CEE3",
        data: companyScore && companyScore
      }
    ]
  };

  const CoomercialCapacityData = {
    labels: companyListCom && companyListCom,
    datasets: [
      {
        label: "Commercial Requirement",
        backgroundColor: "#A6CEE3",
        data: isPrice ? companyScoreCom && companyScoreCom : companyPrice && companyPrice
      }
    ]
  };

  //   setpage to the first page when a searched executed
  useEffect(() => {
    if (searched) setPage(1);
  }, [searched]);

  useEffect(() => {
    setStatus(oneRfp?.data?.[0] && oneRfp?.data?.[0]?.rfp_status);
  }, [oneRfp]);

  useEffect(() => {
    dispatch(getEachRfp({ page, length: perPage, id: idEach, category: "Commercial" }));
    dispatch(getOneRfp({ id: idEach }));
    dispatch(getAnalysis({ id: idEach }));
    dispatch(getChart({ id: idEach }));
    dispatch(getChartCom({ id: idEach }));
  }, []);

  // const review = (id: String, vendor_id: string) => {
  //   history.push(`/buyer/rfx-evaluation/evaluation-4/?id=${id}&vendor_id=${vendor_id}&category=Commercial&status=${oneRfp?.data?.[0] && oneRfp?.data?.[0]?.rfp_status}`);
  // };

  const review = (e, item) => {
    history.push(
      `/buyer/rfx-evaluation/evaluation-4/?id=${item.rfp_id}&vendor_id=${item.vendor_id}&vendor_name=${item.vendor_name}&category=Commercial&status=${
        oneRfp?.data?.[0] && oneRfp?.data?.[0]?.rfp_status
      }`
    );
  };

  const rfpList =
    eachRfp &&
    eachRfp?.data?.map((item) => {
      return {
        // review: () => review(item.rfp_id, item.vendor_id),
        rfp_id: item.rfp_id,
        vendor_id: item.vendor_id,
        date: item.created_at,
        rfp_vendor_status: item.rfp_vendor_status,
        quote_id: item.submission_id && `QT - ${item.submission_id.id}`,
        vendor_name: item.vendor_description,
        category: "Commercial"
      };
    });

  const questionFilterTech =
    analysis &&
    analysis?.data?.analysis?.filter((item) => {
      return item.category === "Technical";
    });

  const questionFilterCom =
    analysis &&
    analysis?.data?.analysis?.filter((item) => {
      return item.category === "Commercial";
    });

  const questionTech =
    questionFilterTech &&
    questionFilterTech?.map((item) => {
      return {
        question: item.field_label,
        question2: item.field_name,
        scores:
          item.evaluated_scores &&
          item.evaluated_scores.map((el) => {
            return el ? parseFloat(el).toFixed(2) : 0;
          })
      };
    });

  const [totalArray, setTotalArray] = useState<any>([]);

  // useEffect(()=>{
  //   let onceSeen = true
  //   let totalArr:any = []
  //   if (onceSeen  && questionTech) {
  //       for (let i = 0; i < questionTech[0].scores.length; i++) {
  //         let totalScore = questionTech.map(el => {
  //           if(el.scores[i] !== undefined){
  //           return parseFloat(el.scores[i]);
  //           }
  //         }).reduce((a,b) => a + b);
  //         totalArr.push(totalScore);
  //       }
  //       console.log(totalArr,'total arrray');

  //   }
  //   onceSeen=false
  //   setTotalArray(totalArr)

  // },[questionTech])

  const [onceSeen, setOnceSeen] = useState(true);
  useEffect(() => {
    let totalArr: any = [];
    if (questionTech) {
      if (onceSeen) {
        for (let i = 0; i < questionTech[0].scores.length; i++) {
          let totalScore = questionTech
            .map((el) => {
              if (el.scores[i] !== undefined) {
                return parseFloat(el.scores[i]);
              }
            })
            .reduce((a, b) => a + b);
          totalArr.push(totalScore);
        }
        setTotalArray(totalArr ? totalArr : []);
        setOnceSeen(false);
      }
    }
  }, [questionTech]);

  const questionCom =
    questionFilterCom &&
    questionFilterCom?.map((item) => {
      return {
        question: item.field_label,
        question2: item.field_name,
        price:
          item.vendor_response &&
          item.vendor_response.map((item) => {
            return item ? kFormatter(item, "") : 0;
          }),
        scores:
          item.evaluated_scores &&
          item.evaluated_scores.map((el) => {
            return el ? parseFloat(el).toFixed(2) : 0;
          })
      };
    });

  console.log(questionCom);

  return (
    <div id="evaluation-3">
      {modal && <CreateDoc closeModal={closeModal} />}

      <div className="container">
        <h3 className="header">
          Sourcing Project: {oneRfp?.data?.[0] && oneRfp?.data?.[0]?.requisition_description} TENDER FOR {oneRfp?.data?.[0] && oneRfp?.data?.[0]?.sourcing_team_description}
        </h3>
        <div className="stats-card mt-4 row">
          <StatsCard text="Invited Vendors" classes="stats-icon-blue" image={membersBlue} statsNumber={oneRfp?.data?.[0] && oneRfp?.data?.[0]?.summary?.total_invited_vendors} />
          <StatsCard text="Submitted Quotes" classes="stats-icon-orange" image={mail1} statsNumber={oneRfp?.data?.[0] && oneRfp?.data?.[0]?.summary?.total_responses} />
          {/* <StatsCard text="Selection Duration" classes="stats-icon-purple" image={purpleClock} statsNumber={oneRfp?.data?.[0] && oneRfp?.data?.[0]?.summary?.total_invited_vendors} />
           */}
          <StatsCard text="Qualified Vendors" classes="stats-icon-green" image={membersGreen} statsNumber={oneRfp?.data?.[0] && oneRfp?.data?.[0]?.summary?.total_qualified_vendors} />
          <StatsCard image={AddIcon} classes="middle-img" centerText="Upload Document" onClick={closeModal} />
        </div>
        <div className="charts">
          {rfpType === "rfq" ? null : (
            <div className="chart">
              <Bar
                data={TechnicalCapacityData}
                width={100}
                height={50}
                options={{
                  title: {
                    display: true,
                    text: "Technical Capacity",
                    fontSize: 40
                  },
                  maintainAspectRatio: false,

                  legend: {
                    display: true,
                    position: "right"
                  }
                }}
              />
            </div>
          )}

          <div className="chart">
            <Bar
              data={CoomercialCapacityData}
              width={100}
              height={50}
              options={{
                title: {
                  display: true,
                  text: "Technical Capacity",
                  fontSize: 20
                },
                maintainAspectRatio: false,
                legend: {
                  display: true,
                  position: "right"
                }
              }}
            />
          </div>
        </div>

        <div className="analyzeDiv">
          <SelectDropdown
            additionalClass="additionalClass"
            name="Filter"
            label="Filter"
            placeholder="Filter"
            options={optionsChart}
            onChange={(e) => {
              if (e.target.value === "scores") {
                handleFilter(true);
              } else {
                handleFilter(false);
              }
            }}
          />
          <button onClick={handleAnalyze} className="analyze">
            Analyze
          </button>
        </div>
        <div className="evalaution mt-5">
          <h3 className="title font-weight-bold">Supplier Quotes</h3>
          <div className="search-field mt-3">
            <SearchIcon placeholder="Search" boxClasses="" textRef={inputRef} value={search} onChange={handleChange} />
            <Dropdown perPage={10} action={(num) => setPerPage(num)} />
          </div>
          <div className="px-3 d-flex row mt-4 justify-content-between">
            {/* <DropdownMenu options={options}>
                                <span>Technical Requirements</span> <img src={arrowdown} alt="alt" className="pl-3" />
                            </DropdownMenu> */}

            <SelectDropdown
              additionalClass="additionalClass"
              name="Category"
              label="Category"
              placeholder="Category"
              options={options}
              onChange={(e) => {
                if (e.target.value === "commercial") {
                  history.push(`/buyer/rfx-evaluation/evaluation/commercial?id=${idEach}&rfpType=${"rfp"}`);
                } else {
                  history.push(`/buyer/rfx-evaluation/evaluation/technical?id=${idEach}&rfpType=${"rfp"}`);
                }
              }}
            />

            <div className="upload">
              <button type="button" id="upload" onClick={() => {}}>
                <img src={exportIcon} alt="alt" className="pr-3" /> <Link to="./buyer/invoice/:id">View Document</Link>
              </button>
            </div>
          </div>

          <div className="mt-3">
            {analyze ? (
              <div className="page-wrap">
                <div className="table-wrap">
                  <table>
                    <thead>
                      <tr className="table-heading">
                        <th>Component Description</th>
                        <th></th>
                        {/* replace this array with the company's array from backend */}
                        {analysis?.data &&
                          analysis?.data?.vendors.length &&
                          analysis?.data?.vendors.map((item) => {
                            return (
                              <th key={item.vendor_id}>
                                <Checkbox value={item.vendor_id} handleInput={handleInput} label={item.vendor_description} name="companies" />
                              </th>
                            );
                          })}
                      </tr>
                      <tr>
                        <th>Technical Requirements </th>
                        <th className="empty"></th>

                        {/* replace his numbers array with the total scores from backend */}
                        {totalArray.map((el, i) => (
                          <th key={el + i}>{parseFloat(el).toFixed(2)}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>{questionTech && questionTech.map(({ question, scores, question2 }, i) => <TableRow question={question} question2={question2} scores={scores} price={[]} key={i} />)}</tbody>
                    <tfoot>
                      <tr>
                        <th>Commercial Requirement</th>
                        <th className="empty"></th>

                        {/* replace this array with backend */}
                        {/* {companies.map((el, i) => (
                <th key={el + i}></th>
              ))} */}
                      </tr>

                      {questionCom && questionCom.map(({ question, scores, price, question2 }, i) => <TableRow price={price} question={question} question2={question2} scores={scores} key={i} />)}
                    </tfoot>
                  </table>
                  <div className="btn-wrap">
                    <button disabled={status === "4" ? true : false} onClick={inviteHandle} className="btn">
                      {inviteLoading ? <div className="spinner-border spinner-border-primary spinner-border-sm mr-2"></div> : "Invite For Negotiations"}
                    </button>

                    <button disabled={status === "4" ? true : false} className="btn" onClick={disqualifyHandle}>
                      {disqualifyLoading ? <div className="spinner-border spinner-border-primary spinner-border-sm mr-2"></div> : "Disqualify"}
                    </button>

                    <button disabled={status === "4" ? true : false} className="btn" onClick={awardHandle}>
                      {awardLoading ? <div className="spinner-border spinner-border-primary spinner-border-sm mr-2"></div> : "Award"}
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <DataTable columns={tableCol} data={rfpList} loading={eachLoading} onClick={review} />
            )}
          </div>
          {eachRfp?.count > 10 ? (
            <Pagination page={page} lastPage={eachRfp?.pagination?.lastPage} paginate={paginate} total={eachRfp?.pagination?.total} handlePageChange={handlePageChange} perPage={perPage} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Evaluation_5;

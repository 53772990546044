import { Link } from "react-router-dom";

import greenCheck from "assests/icons/buyer/green-check.svg";
import rectangle from "assests/icons/buyer/rectangle-buyer.svg";

export const tableCol = [
    {
        name: "Project ID",
        index: "project_id",
        id: 1
    },
    {
        name: "Description",
        index: "description",
        id: 2
    },
    {
        name: "Submitted Quotes",
        index: "Submitted_quotes",
        id: 3
    },
    {
        name: "Invited Vendors",
        index: "invited_vendors",
        id: 4
    },
    // {
    //     name: "Action",
    //     id: 7,
    //     render: (item) => (
        
    //             <button
    //                 type="button"
    //                 onClick={item.status}
    //                 style={{
    //                     padding: "8px 16px",
    //                     background: "rgba(0, 70, 176, 0.15)",
    //                     borderRadius: "10px",
    //                     color: "rgba(47, 128, 237, 1)"
    //                 }}
    //             >
    //                 View
    //             </button>
        
    //     )
    // }
];

export const tableData = [
    {
        project_id: "2nd Sept. 2019",
        description: "Brent Fawaz",
        category: "78600090",
        invited_vendors: "123333",
        status: "View",
        id: 1
    },
    {
        project_id: "24th May 2019",
        description: "Chandler Ross",
        category: "78600091",
        invited_vendors: "123333",
        status: "View",
        id: 0
    }
];

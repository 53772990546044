/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import StatsCard from "components/StatsCard";
import people from "assests/icons/buyer/Purchase Orders/Group (5).svg";
import clock from "assests/icons/buyer/Purchase Orders/clock 1.svg";
import addcircle from "assests/icons/buyer/Purchase Orders/add-circle 1 (1).png";
import blueCheck from "assests/icons/buyer/blueCheck.svg";
import SearchBar from "../../../components/SearchBar";
import Dropdown from "components/Dropdown";
import DataTable from "components/DataTable";
import { tableCol, tableData } from "./data";
import filterIcon from "assests/icons/buyer/filter-icon.svg";

import "./styles.scss";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDocuments } from "store/slice/BuyerSlice/DocumentSlice/DocumentSlice";
import { RootState } from "store/store";
import { formatCurrency } from "utils/utils";
import CreatePO from "components/ModalBuyer/CreatePO/Index";
import Pagination from "components/Pagination";
import useSearch from "hooks/useSearch";
import { getPOSummaries } from "store/slice/SummarySlice/SummarySlice";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import FilterCard from "components/FilterCard";

const Document = () => {
  const [pageNum, setPageNumber] = useState(10);
  const [openPO, setOpenPO] = useState(false);
  const [page, setPage] = React.useState<number>(1);
  const [length, setlength] = React.useState<number>(10);
  const [showFilter, setShowFilter] = useState(false);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const vendor_id = urlParams.get("vendor_id");
  //   helpers
  const dispatch = useDispatch();
  const history = useHistory();

  //   States
  const { documents } = useSelector((state: RootState) => state.document);
  const { POSummaries } = useSelector((state: RootState) => state.summaries) as any;
  // console.log(POSummaries, "poooooo");
  const { search, inputRef, handleChange, searched } = useSearch(getDocuments, length);
  const { register, handleSubmit, reset } = useForm({
    mode: "onChange",
    defaultValues: {
      date_to: "",
      date_from: "",
      open: ""
      // to: '',
    }
  });

  useEffect(() => {
    dispatch(getDocuments(page, length, undefined, vendor_id));
    dispatch(getPOSummaries(vendor_id));
  }, [vendor_id]);
  const toggleModal = () => {
    setOpenPO(!openPO);
  };

  const tableData =
    documents &&
    documents?.data?.map((data: any) => {
      const { purchase_order_status } = data;
      // let status;
      // if (purchase_order_status === "-2") {
      //   status = "draft";
      // } else if (purchase_order_status === "-1") {
      //   status = "Pending Approval";
      // } else {
      //   status = "Approved";
      // }
      let status;
      if (data?.purchase_order_status === "-2") {
        status = "In Draft";
      }
      if (data?.purchase_order_status === "-1") {
        status = "Pending";
      }
      if (data?.purchase_order_status === "1") {
        status = "Approved";
      }
      if (data?.purchase_order_status === "2") {
        status = "Accepted";
      }
      if (data?.purchase_order_status === "3") {
        status = "Out for Delivery";
      }
      if (data?.purchase_order_status === "4") {
        status = "Partially Delivered";
      }
      if (data?.purchase_order_status === "5") {
        status = "Delivered";
      }
      return {
        created_at: data?.purchase_order_date,
        // created_at: data?.created_at,
        purchase_no: data?.purchase_order_code,
        description: data?.vendor_description,
        delivery_loca: `${data?.street_number} ${data?.street_name}`,
        company_description: data?.company_description,
        price: formatCurrency(data?.total_value),
        currency: data?.currency_description,
        status,
        id: data?.id,
        supplier: data?.vendor_description
      };
    });

  //--------------------------------------------
  //Pagination
  //---------------------------------------------
  const handlePageChange = React.useCallback(
    (page: number, length: number) => {
      setPage(page);
      dispatch(getDocuments(page, length, search, vendor_id));
    },
    [search]
  );

  const paginate = React.useCallback(
    (pageNo: number, length: number) => {
      setPage(pageNo);
      dispatch(getDocuments(pageNo, length, vendor_id));
    },
    [search]
  );

  //------------------------------------------------------------
  //Show Number for length Function
  //------------------------------------------------------------
  const handleNewPage = React.useCallback(
    (pageNo: number, page = 1) => {
      setlength(pageNo);
      setPage(1);
      dispatch(getDocuments(page, pageNo, search, "company"));
    },
    [search]
  );

  const onSubmit = (vals) => {
    console.log(vals);
    dispatch(getDocuments(page, length, search, vendor_id, vals.date_from, undefined, undefined, vals.date_to, undefined, undefined, undefined, undefined, vals.open));
  };

  const handleClear = () => {
    dispatch(getDocuments(page, length, undefined, vendor_id));
    reset();
  };

  //   console.log(tableData);

  return (
    <div id="document">
      {openPO && <CreatePO closeModal={toggleModal} />}
      <div className="container">
        <h3 className="header">Purchase Orders</h3>
        <div className="stats-card mt-4 row">
          <StatsCard text="Total Purchase Order" classes="stats-icon-blue" image={people} statsNumber={POSummaries?.overall?.total_purchase_orders} />
          <StatsCard text="Open PO’s" classes="stats-icon-blue" image={blueCheck} statsNumber={POSummaries?.open?.total_purchase_orders} />
          <StatsCard text="Open PO  Value" classes="stats-icon-blue" image={clock} statsNumber={POSummaries?.open?.total_purchase_order_value} />
          <StatsCard centerText="Create Purchase Order" classes="middle-img" image={addcircle} onClick={toggleModal} />
        </div>
        <div className="document mt-5">
          <h3 className="title font-weight-bold" style={{ fontSize: "18px" }}>
            Purchase Order
          </h3>
          {showFilter && (
            <FilterCard handleSubmit={handleSubmit(onSubmit)} clear={handleClear}>
              <div className="row">
                <div className="col col-4">
                  <label style={{ display: "block" }}>Start Date</label>
                  <input
                    type="date"
                    name="date_from"
                    className="mt-3"
                    placeholder="Start Date"
                    style={{
                      width: "100%",
                      height: "3rem",
                      padding: "5px",
                      borderRadius: "10px",
                      border: "1px solid #dfe2e6"
                    }}
                    ref={register({ required: false })}
                  />
                </div>
                <div className="col col-4">
                  <label style={{ display: "block" }}>End Date</label>
                  <input
                    type="date"
                    name="date_to"
                    className="mt-3"
                    placeholder="End Date"
                    style={{
                      width: "100%",
                      height: "3rem",
                      padding: "5px",
                      borderRadius: "10px",
                      border: "1px solid #dfe2e6"
                    }}
                    ref={register({ required: false })}
                  />
                </div>
                <div className="col col-4">
                  <label style={{ display: "block" }}> PO Status </label>
                  <select
                    style={{
                      width: "100%",
                      height: "3rem",
                      padding: "5px",
                      borderRadius: "10px",
                      border: "1px solid #dfe2e6"
                    }}
                    name="open"
                    className="mt-3"
                    ref={register({
                      required: false
                    })}
                  >
                    <option value="">Select</option>
                    <option value="Yes">Open</option>
                    <option value="No">Close</option>
                  </select>
                </div>
              </div>
            </FilterCard>
          )}
          <div className="search-field mt-3">
            <SearchBar placeholder="Search for Purchase Order or Vendor name" textRef={inputRef} value={search} onChange={handleChange} />
            {/* <div className="btn-custom mr-2">
              <button type="button" id="btn-custom" className="d-flex align-items-center" onClick={() => {}}>
                <img src={Icon} alt="icon" className="mr-2" />
                <Link to="./buyer/invoice/:id">Status</Link>
              </button>
            </div> */}
            {/* <div className="btn-custom mr-5">
              <button type="button" id="btn-custom" className="d-flex align-items-center" onClick={() => {}}>
                <img src={greenCheck} alt="icon" className="mr-2" width="25" />
                <Link to="./buyer/invoice/:id">Status</Link>
              </button>
            </div> */}
            {/* <div className="upload mr-5">
              <button type="button" id="upload" onClick={() => {}}>
                <Link to="./buyer/invoice/:id">Status</Link>
              </button>
            </div> */}
            <Dropdown perPage={10} action={(num) => setPageNumber(num)} />
            <div>
              <button
                style={{
                  padding: "5px 10px",
                  borderRadius: "10px",
                  background: "#FFFFFF",
                  color: "#737A91",
                  border: "1px solid #DFE2E6",
                  fontSize: "18px",
                  marginLeft: "1rem"
                }}
                onClick={() => setShowFilter(!showFilter)}
              >
                <img src={filterIcon} alt="icon" className="icon-image" />
                <span
                  style={{
                    paddingLeft: "1rem"
                  }}
                >
                  {!showFilter ? "Filters" : "Hide Filters"}
                </span>
              </button>
            </div>
          </div>
          <div className="mt-3">
            <DataTable columns={tableCol} data={tableData} onClick={(e, item) => history.push({ pathname: `/buyer/purchase-orders/${item?.id}` })} />
            <Pagination page={page} lastPage={documents?.pagination?.pageCount} paginate={paginate} total={documents?.pagination?.total} handlePageChange={handlePageChange} perPage={length} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Document;

import React, { useEffect } from "react";
import Modal from "components/Modal";
import ArrowDropdown from "components/ArrowDropdown";
import Textbox from "components/Textbox";
import Button from "components/Button";
import useArrowDropdown from "hooks/useArrowDropdown";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getCompaniesEntityList } from "store/slice/CompaniesSlice/CompaniesSlice";
import { AppDispatch, RootState } from "store/store";
import SelectDropdown from "components/SelectDropdown";
import { createDepartment, editDepartment, getDepartments } from "store/slice/BuyerSlice/DepartmentSlice/DepartmentSlice";
import { addMember, createSource, editSource, getSource } from "store/slice/BuyerSlice/TeamSlice/TeamSlice";
import { getUsers } from "store/slice/UserMgtSlice/UserMgtSlice";
import { getMaterialCategories } from "store/slice/BuyerSlice/ProductSlice/ProductSlice";
import { createBusiness, getBusiness, getBusinessCat } from "store/slice/BuyerSlice/BusinessSlice/BusinessSlice";
import { Controller } from "react-hook-form";
import API from "utils/httpsRequest";
import AsyncSelect from "react-select/async";

type Props = {
  closeModal: () => void;
  singleBusinessCategory?: any;
};
const CreateOrEditBusinessGroup = ({ closeModal, singleBusinessCategory = null }: Props) => {
  //State
  const [error, setError] = React.useState("");
  const [businessSel, setBusinessSel] = React.useState("");
  const [selBusinessVal, setSelBusinessVal] = React.useState("");
  const [catSel, setCatSel] = React.useState("");
  const [selCatVal, setSelCatVal] = React.useState("");

  const { user } = useSelector((state: RootState) => state.user);
  const { token } = user;

  const handleInputChangeBusiness = (value) => {
    setBusinessSel(value);
  };

  const onChangeBusiness = (value) => {
    setSelBusinessVal(value);
  };

  const handleInputChangeCat = (value) => {
    setCatSel(value);
  };

  const onChangeCat = (value) => {
    setSelCatVal(value);
  };



  type FormData = {
    business_category_id: string;
    material_category_id: string;
  };

  //Setting field on edit mode
  // useEffect(() => {
  //   if (singleBusinessCategory !== null) {
  //     setValue("sourcing_team_description", singleBusinessCategory?.sourcing_team_description);
  //   }
  // }, [singleBusinessCategory]);

  //Redux Store
  const dispatch = useDispatch<AppDispatch>();




  //Hooks
  const { register, handleSubmit, errors,control, setValue } = useForm<FormData>({ mode: "onBlur" });
  const { open, selectedOption, handleDropdownChange, handleAction, keyOption, ref } = useArrowDropdown();
  const { materialCategory } = useSelector((state: RootState) => state.product);
  const { businessCat, loading,isLoading } = useSelector((state: RootState) => state.business);


  const onFormSubmit = (vals: FormData) => {

    const payload = {
   
      business_category_id: selBusinessVal?.["value"],
      material_category_id: selCatVal?.["value"]
    };
    dispatch(
      createBusiness(payload, () => {
        closeModal();
        dispatch(getBusiness(1, 10));

      })
    );
  };



  useEffect(() => {
    dispatch(getMaterialCategories(1, 1000000000));
    dispatch(getBusinessCat(1,100000000))
  }, []);

  const businessCategoryOptions2 = () => {
    return API.get(`/business_categories/get?search=${businessSel}&length=1000`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    }).then((res) => {
      return res.data.data.map((item) => ({
        label: item.business_category_description,
        value: item.id
      }));
    });
  };

  const materialCategoryOptions2 = () => {
    return API.get(`/material_categories/get?search=${catSel}&length=1000`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    }).then((res) => {
      return res.data.data.map((item) => ({
        label: item.material_category_description,
        value: item.id
      }));
    });
  };


  const materialCategoryOptions =
    materialCategory &&
    materialCategory.data.map((_data: { id: string; material_category_description: string }) => {
      return {
        value: _data?.id,
        optionName: _data?.material_category_description
      };
    });

    const businessCategoryOptions =
    businessCat &&
    businessCat.data.map((_data: { id: string; business_category_description: string }) => {
      return {
        value: _data?.id,
        optionName: _data?.business_category_description
      };
    });



  return (
    <Modal modalText={singleBusinessCategory === null ? "Create" : "Edit"} onClose={closeModal} modallength="modal-length">
      <div id="buyer-create-location">
        <p className="">Create Material Group Business</p>

        <form className="mt-2" onSubmit={handleSubmit(onFormSubmit)}>
          {/* <SelectDropdown
            boxClasses="mt-3 mb-4"
            name="business_category_id"
            label="Business Category"
            placeholder="Business Category"
            options={businessCategoryOptions}
            customRef={register({ required: true })}
            error={errors.business_category_id && "This field is required"}
          /> */}

<Controller
            control={control}
            defaultValue
            boxClasses="mt-3"
            name="business_category_id"
            render={({ ref }) => (
              <AsyncSelect
                cacheOptions
                defaultOptions
                placeholder="Business Category"
                value={selBusinessVal}
                loadOptions={businessCategoryOptions2}
                onInputChange={handleInputChangeBusiness}
                onChange={onChangeBusiness}
              />
            )}
          />

          <br/>

<Controller
            control={control}
            defaultValue
            boxClasses="mt-3"
            name="material_category_id"
            render={({ ref }) => (
              <AsyncSelect
                cacheOptions
                defaultOptions
                placeholder="Material Category"
                value={selCatVal}
                loadOptions={materialCategoryOptions2}
                onInputChange={handleInputChangeCat}
                onChange={onChangeCat}
              />
            )}
          />

          {/* <SelectDropdown
            boxClasses="mt-3 mb-4"
            name="material_category_id"
            label="Material Category"
            placeholder="Material Category"
            options={materialCategoryOptions}
            customRef={register({ required: true })}
            error={errors.material_category_id && "This field is required"}
          /> */}

          <Button label="Create" btnType="btn-primary" boxClasses="mt-5 mb-5" btnStyle="100%" loading={isLoading} />
        </form>
      </div>
    </Modal>
  );
};

export default CreateOrEditBusinessGroup;

import Textbox from "components/Textbox";

export const vendorCol = [
  {
    name: "",
    index: '',
    id: 1,
  },
  {
    name: 'Vendor',
    index: 'vendor',
    id: 2,
  },
  {
    name: 'Phone',
    index: 'phone',
    id: 3,
  },
  {
    name: 'Email',
    index: 'email',
    id: 4,
  }
]

export const tableCol = [
  {
    name: "Created  On",
    index: "created_at",
    id: 1
  },
  {
    name: "Requisition Number",
    index: "requisition_code",
    id: 2
  },
  {
    name: "Title",
    index: "requisition_title",
    id: 3
  },
  {
    name: "Delivery Location",
    index: "location_description",
    id: 4
  },
  {
    name: "Requisition Type",
    index: "requisition_type",
    id: 5
  },
  {
    name: "Approved By",
    index: "approved_by",
    id: 6
  },
  {
    name: "Status",
    id: 7,
    render: (item) => {
      if (item.requisition_status === '1') {
        return (
          <button
            style={{
              background: 'rgba(0, 176, 7, 0.15)',
              borderRadius: '10px',
              color: 'rgb(56, 124, 87)',
              padding: "8px 16px",
            }}
          >
            Approved
          </button>
        )
      }
      if (item.requisition_status === '-1') {
        return (
          <button
            style={{
              padding: "8px 16px",
              background: "rgba(239, 196, 66, 0.15)",
              borderRadius: "10px",
              color: "rgb(47, 128, 237)"
            }}
          >
            Pending
          </button>
        )
      }
      if (item.requisition_status === '-2') {
        return (
          <button
            style={{
              padding: "8px 16px",
              background: "rgba(245, 150, 37, 0.15)",
              borderRadius: "10px",
              color: "rgb(245, 150, 37)"
            }}
          >
            Draft
          </button>
        )
      }
      if (item.requisition_status === '2') {
        return (
          <button
            style={{
              padding: "8px 16px",
              background: "rgba(245, 150, 37, 0.15)",
              borderRadius: "10px",
              color: "rgb(245, 150, 37)"
            }}
          >
            Generated
          </button>
        )
      }
      if (item.requisition_status === '3') {
        return (
          <button
            style={{
              background: 'rgba(0, 176, 7, 0.15)',
              borderRadius: '10px',
              color: 'rgb(56, 124, 87)',
              padding: "8px 16px",
            }}
          >
            Completed
          </button>
        )
      }
    }
  }
];

export const materialCol = [
  {
    name: "Image",
    index: "image",
    id: 1
  },
  {
    name: "Sku",
    index: "sku",
    id: 2
  },
  {
    name: "Description",
    index: "description",
    id: 3
  },
  {
    name: "Price",
    index: "price",
    id: 4
  },
  {
    name: "Supplier",
    index: "supplier",
    id: 5
  },
  {
    name: "Category",
    index: "category",
    id: 6
  },
  {
    name: "Quantity",
    id: 7,
    render: (item) => {
      let quantity_required = "";
      return (
        <Textbox
          type="text"
          name="quantity"
          onChange={(e) => {
            quantity_required = e.target.value;
            item.quantity_required = quantity_required;
          }}
        />
      );
    }
  },
  {
    name: "Action",
    id: 8,
    render: (item) => {
      item.estimated_unit_price = item.price.replace(/ NGN/g, "");
      return (
        <button
          style={{
            color: "#FFF",
            background: "#00B0A5",
            padding: "5px 15px",
            minWidth: "5rem",
            borderRadius: "10px"
          }}
          onClick={() => {
            item.addItem(item);
          }}
        >
          Add
        </button>
      );
    }
  }
];

export const materialColNonCat = [
  {
    name: "Image",
    index: "image",
    id: 1
  },
  {
    name: "Sku",
    index: "sku",
    id: 2
  },
  {
    name: "Description",
    index: "description",
    id: 3
  },
  {
    name: "Category",
    index: "category",
    id: 4
  },
  {
    name: "Quantity",
    id: 5,
    render: (item) => {
      let quantity_required = "";
      return (
        <Textbox
          type="text"
          name="quantity"
          defaultValue="1"
          onChange={(e) => {
            quantity_required = e.target.value;
            item.quantity_required = quantity_required;
          }}
        />
      );
    }
  },
  {
    name: "Action",
    id: 6,
    render: (item) => {
      item.estimated_unit_price = item.price.replace(/ NGN/g, "");
      return (
        <button
          style={{
            color: "#FFF",
            background: "#00B0A5",
            padding: "5px 15px",
            minWidth: "5rem",
            borderRadius: "10px"
          }}
          onClick={() => {
            item.addItem(item);
          }}
        >
          Add
        </button>
      );
    }
  }
];

export const tableData = [
  {
    created_on: "2nd Sept. 2019",
    requisition_number: "78600090",
    description: "Brent Fawaz",
    delivery_location: "HQ",
    category_type: "Service",
    approved_by: "Service",
    status: "Pending Approval",
    display: "Display",
    id: 1
  },
  {
    created_on: "24th May 2019",
    requisition_number: "78600091",
    description: "Chandler Ross",
    delivery_location: "HQ",
    category_type: "Material",
    approved_by: "Material",
    status: "Approved",
    display: "Display",
    id: 2
  },
  {
    created_on: "5th Jun. 2019",
    requisition_number: "78600091",
    description: "Monica Williams",
    delivery_location: "",
    category_type: "Material",
    approved_by: "Material",
    status: "Rejected",
    display: "Display",
    id: 3
  },
  {
    created_on: "7th July 2020",
    requisition_number: "8970",
    description: "Asa Okoro",
    delivery_location: "738932",
    category_type: "Material",
    approved_by: "Material",
    status: "Material",
    display: "",
    id: 4
  },
  {
    created_on: "19th Aug. 2020",
    requisition_number: "",
    description: "Jamal Abdul",
    delivery_location: "11233123",
    category_type: "General Contractor",
    approved_by: "General ",
    status: "General ",
    display: "",
    id: 5
  },
  {
    created_on: "22nd Feb. 2020",
    requisition_number: "",
    description: "Sani Abubakar",
    delivery_location: "43637",
    category_type: "IT Services",
    approved_by: "IT Services",
    status: "IT Services",
    display: "",
    id: 6
  }
];

/* eslint-disable react-hooks/exhaustive-deps */
import StatsCard from "components/StatsCard";
import Icon from "assests/icons/statsCard/Vector (4).png";
import SearchBar from "../../../../components/SearchBar";
import Select from "react-select";
import Dropdown from "components/Dropdown";
import DataTable from "components/DataTable";
import { reportCol } from "./data";
import "./styles.scss";
import React, { useEffect, useState } from "react";
import { AppDispatch, RootState } from "store/store";
import { useDispatch, useSelector } from "react-redux";
import useSearch from "hooks/useSearch";
import CreateOrEditReport from "components/ModalBuyer/CreateOrEditReport";
import Pagination from "components/Pagination";
import { getAllReport } from "store/slice/BuyerSlice/ReportSlice/RepotSlice";
import lineImg from "../../../../assests/icons/line-chart.png";
import dateImg from "../../../../assests/icons/calendar.png";
import { Controller, useForm } from "react-hook-form";
import AsyncSelect from "react-select/async";
import API from "utils/httpsRequest";
import { useHistory } from "react-router";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import { kFormatter } from "utils/utils";
import moment from "moment";
import { Pie, Doughnut, Line, Bar } from "react-chartjs-2";

import { getCompanySummary, getDeptSummary, getInvoiceAgingSummary, getInvoiceCompanySummary, getVendorSummary } from "store/slice/SummarySlice/SummarySlice";

const ReportInvoice = () => {
  const [singleReport, setSingleReport] = useState<any>(null);
  const { user } = useSelector((state: RootState) => state.user);
  const { token } = user;
  const dispatch = useDispatch<AppDispatch>();
  const { reports, loading } = useSelector((state: RootState) => state.report);
  const { locations } = useSelector((state: RootState) => state.location);
  const [modal, setModal] = useState<boolean>(false);

  const [vendorSel, setVendorSel] = React.useState("");
  const [selVendorVal, setSelVendorVal] = React.useState("");
  const [vendor, setVendor] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [invoiceSel, setInvoiceSel] = React.useState("");
  const [selInvoiceVal, setSelInvoiceVal] = React.useState("");
  const [invoice, setInvoice] = useState("");
  const [invoiceName, setInvoiceName] = useState("");
  const [statusSel, setStatusSel] = React.useState("");
  const [selStatusVal, setSelStatusVal] = React.useState("");
  const [status, setStatus] = useState("");
  const [statusName, setStatusName] = useState("");
  const [age, setAge] = useState(null);
  const [ageName, setAgeName] = useState(null);
  const [sumInvoice, setSumInvoice] = useState(0);
  const [sumUnpaid, setSumUnpaid] = useState(0);
  const [sumMoney, setSumMoney] = useState(0);
  const [firstFive, setFirstFive] = useState<any>([]);
 
  const [companySel, setCompanySel] = React.useState("");
  const [selCompanyVal, setSelCompanyVal] = React.useState("");
  const [company, setCompany] = useState( user.company_id?user.company_id:"10002");
  const [companyName, setCompanyName] = useState(user.company_description?user.company_description:"GZ Industries Ltd - Nigeria");
  // const [defaultLegal, setDefaultLegal] = useState([{

  //     label: "GZ Industries Ltd - Nigeria",
  //     value: "10002"

  // }]);
  const [newLocationOption, setNewLocationOption] = useState([]);

  const [seen, setSeen] = useState(true);


  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const deptId = urlParams.get("deptId");
  const deptName = urlParams.get("deptName");


    function getItemAge(status) {
    if (status === "aging_30_days") return "Aging 30 Days";
    if (status === "aging_60_days") return "Aging 60 Days";
    if (status === "aging_90_days") return "Aging 90 Days";
    if (status === "aging_above_90_days") return "Above 90 Days";
   
  }


  // from slice

  const { invoiceSummmary, isLoadingDept, isLoadingAging, agingBucket } = useSelector((state: RootState) => state.summaries);

  // setting summary place
  useEffect(() => {
  
    invoiceSummmary && invoiceSummmary.data.length ? setSumInvoice(invoiceSummmary.data[0].total_invoices) : setSumInvoice(0);
    invoiceSummmary && invoiceSummmary.data.length ? setSumUnpaid(invoiceSummmary.data[0].total_vendors) : setSumUnpaid(0);
    invoiceSummmary && invoiceSummmary.data.length ? setSumMoney(invoiceSummmary.data[0].total_invoice_value) : setSumMoney(0);
  }, [invoiceSummmary]);



  const [labelList, setLabelList] = useState<string[]>([]);
  const [labelList9, setLabelList9] = useState<any[]>([]);
  // const [onceSeen, setOnceSeen] = useState(true);
  const [pointScore, setPointScore] = useState<number[]>([]);
  let onceSeen = true;

const [tableData,setTableData] = useState<any[]>([])
  useEffect(() => {

     setLabelList(agingBucket ?  Object.entries(agingBucket.data).length ?  Object.keys(agingBucket.data):[]:[])

     
     let tArr =  labelList ?labelList.length?labelList.map((item)=>{
       return getItemAge(item)
     })  :[]:[]

     setLabelList9(tArr?tArr:[])
     

     let totalArr = agingBucket  &&  Object.entries(agingBucket.data).length && Object.values(agingBucket.data).map((item:any)=>{
       return item.total_vendors
     })

     
     setPointScore(totalArr?totalArr:[])

     let ageBucket = agingBucket && Object.entries(agingBucket.data)

     let totalArr2 = ageBucket && ageBucket.map((item)=>{
       return(
         {
           days:getItemAge(item[0]),
           inv:item[1].total_invoices,
           value: item[1].total_invoice_value,
           vendor: item[1].total_vendors
         }
       )
     })

     setTableData(totalArr2?totalArr2:[])



  }, [agingBucket]);

  const [dateRange, setDateRange] = useState([
    {
      // ate_from=&date_to=
      startDate: new Date(2021, 1, 1),
      endDate: new Date(),
      key: "selection"
    }
  ]);

  const [dateRange2, setDateRange2] = useState([
    {
      // ate_from=&date_to=
      startDate: null,
      endDate: null,
      key: "selection"
    }
  ]);

  const [newDate, setNewDate] = useState([{}]);
  const [newDate2, setNewDate2] = useState([{}]);

  useEffect(() => {
    setNewDate([
      {
        startDate: dateRange[0].startDate,
        endDate: dateRange[0].endDate
      }
    ]);
  }, [dateRange]);

  useEffect(() => {
    setNewDate2([
      {
        startDate: dateRange[0].startDate,
        endDate: dateRange[0].endDate
      }
    ]);
  }, [dateRange2]);

  // get summary for company
  // useEffect(() => {
  //   /purchase_orders/summary_by_company?company_id
  // }, [company]);

  const [showRange, setShowRange] = useState(false);
  const [showRange2, setShowRange2] = useState(false);

  const handleRangeShow = () => {
    setShowRange(!showRange);
  };
  const handleRangeShow2 = () => {
    setShowRange2(!showRange2);
  };

  const history = useHistory();

  const closeModal = () => {
    setModal((prev) => !prev);
    setSingleReport(null);
  };

  const handleInputChangeVendor = (value) => {
    setVendorSel(value);
  };

  const onChangeVendor = (value) => {
    setVendor(value.value);
    setVendorName(value.label);
    setSelVendorVal(value);
  };

  const handleInputChangeInvoice = (value) => {
    setInvoiceSel(value);
  };

  const onChangeInvoice = (value) => {
    setInvoice(value.value);
    setInvoiceName(value.label);
    setSelInvoiceVal(value);
  };

  const handleInputChangeStatus = (value) => {
    setStatusSel(value);
  };

  const onChangeStatus = (value) => {
    setStatus(value.value);
    setStatusName(value.label);
    setSelStatusVal(value);
  };

  const handleAge = (value) => {
    setAge(value);
    setAgeName(value);
  };

  const handleInputChangeCompany = (value) => {
    setCompanySel(value);
  };

  const onChangeCompany = (value) => {
    setCompany(value.value);
    setCompanyName(value.label);
    setSelCompanyVal(value);
  };

  const vendorOptions = () => {
    return API.get(`/vendors/get?search=${vendorSel}&length=1000`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    }).then((res) => {
      return res.data.data.map((item) => ({
        label: item.vendor_description,
        value: item.id
      }));
    });
  };

  const invoiceOptions = () => {
    return API.get(`/purchase_orders/get?search=${invoiceSel}&length=1000`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    }).then((res) => {
      return res.data.data.map((item) => ({
        label: item.email,
        value: item.id
      }));
    });
  };

  const statusOptions = [
    {
      label: "paid",
      value: "2"
    },
    {
      label: "Unpaid",
      value: "1"
    }
  ];

  const { register, control, handleSubmit, errors, setValue } = useForm<FormData>({
    mode: "onChange"
  });



  const lineData = {
    labels: labelList9.length && labelList9,
    datasets: [
      {
        label: "Vendor by Aging Bucket Chart",
        backgroundColor: ["#05445E", "#00B01C", "#A6CEE3", "#189AB4", "#75E6DA", "#05445E"],
        borderColor: ["#05445E", "#00B01C", "#A6CEE3", "#189AB4", "#75E6DA", "#05445E"],
        fill: true,
        data: pointScore.length && pointScore
      }
    ]
  };

  // get department Summary
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  //Pagination
  //---------------------------------------------
  const handlePageChange = React.useCallback((page: number, perPage: number) => {
    setPage(page);
    dispatch(
      getInvoiceAgingSummary({
        company_id: company,
        date_from: moment(dateRange[0].startDate).format("YYYY-MM-D"),
        date_to: moment(dateRange[0].endDate).format("YYYY-MM-DD"),
        length: perPage,
        page: page,
        vendor: vendor,
        invoice: invoice,
        status: status
      })
    );
  }, []);

  const paginate = React.useCallback((pageNo: number, perPage: number) => {
    setPage(pageNo);
    dispatch(
      getInvoiceAgingSummary({
        company_id: company,
        date_from: moment(dateRange[0].startDate).format("YYYY-MM-D"),
        date_to: moment(dateRange[0].endDate).format("YYYY-MM-DD"),
        length: perPage,
        page: page,
        vendor: vendor,
        invoice: invoice,
        status: status
      })
    );
  }, []);

  useEffect(() => {
    dispatch(
      getInvoiceAgingSummary({
        company_id: company,
        date_from: moment(dateRange[0].startDate).format("YYYY-MM-D"),
        date_to: moment(dateRange[0].endDate).format("YYYY-MM-DD"),
        length: perPage,
        page: page,
        vendor: vendor,
        invoice: invoice,
        status: status
      })
    );
  },[company, vendor, newDate, status, invoice]);

  useEffect(() => {
    dispatch(
      getInvoiceCompanySummary({
        company_id: company,
        date_from: moment(dateRange[0].startDate).format("YYYY-MM-D"),
        date_to: moment(dateRange[0].endDate).format("YYYY-MM-DD"),
        invoice_date_from:dateRange2[0].startDate? moment(dateRange2[0].startDate).format("YYYY-MM-D"):"",
        invoice_date_to: dateRange2[0].endDate? moment(dateRange2[0].endDate).format("YYYY-MM-DD"):"",
        length: perPage,
        page: page,
        vendor: vendor,
        invoice: invoice,
        status: status
      })
    );
  }, [company, vendor, newDate,newDate2, status, invoice]);

 

  // removeFilter

  const removeFilter = (nameFilt) => {
    if (nameFilt === "date") {
      setDateRange([
        {
          // ate_from=&date_to=
          startDate: new Date(2021, 1, 1),
          endDate: new Date(),
          key: "selection"
        }
      ]);
    }
    if (nameFilt === "date2") {
      setDateRange2([
        {
          // ate_from=&date_to=
          startDate: null,
          endDate: null,
          key: "selection"
        }
      ]);
    }
    if (nameFilt === "legal") {
      setCompany("");
      setCompanyName("");
    }
    if (nameFilt === "vendor") {
      setVendor("");
      setVendorName("");
    }
    if (nameFilt === "invoice") {
      setInvoice("");
      setInvoiceName("");
    }
    if (nameFilt === "age") {
      setAgeName(null);
      setAge(null);
    }
    if (nameFilt === "status") {
      setStatus("");
      setStatusName("");
    }
  };
  const companiesOptions = () => {
    return API.get(`/companies/get?search=${companySel}&length=1000`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    }).then((res) => {
      return res.data.data.map((item) => ({
        label: item.company_description,
        value: item.id
      }));
    });
  };

  return (
    <div id="report">
      {modal && <CreateOrEditReport closeModal={closeModal} />}

      <div className="container">
        <div className="filterContainer">
          <div className="titleSide">
            <div className="left">
              <img src={lineImg} alt="line icon" />
              <p>Invoice Report </p>
            </div>
          </div>

          <div className="filters">
            <div className="dateFilter each">
              <div onClick={handleRangeShow} className="dateBox">
                <p className="date-range">
                  {` ${moment(dateRange[0].startDate).format("MM/DD/YYYY")} - ${moment(dateRange[0].endDate).format("MM/DD/YYYY")}`}

                  {/* moment(dateRange[0].startDate).format('MM/DD/YYYY') */}
                </p>
                <img src={dateImg} alt="date icon" />
              </div>

              {showRange && (
                <div className="rangeDiv">
                  <DateRangePicker
                    onChange={(item) => setDateRange([item.selection])}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={2}
                    ranges={dateRange}
                    direction="horizontal"
                    preventSnapRefocus={true}
                    calendarFocus="backwards"
                  />
                  <div onClick={handleRangeShow} className="applyBtn">
                    <button type="button">Apply</button>
                  </div>
                </div>
              )}
            </div>

            <div className="CompanyFilter each">
              <Controller
                control={control}
                defaultValue
                boxClasses="mt-3"
                name="company_id"
                render={({ ref }) => (
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    placeholder="Legal Entity"
                    value={selCompanyVal}
                    loadOptions={companiesOptions}
                    onInputChange={handleInputChangeCompany}
                    onChange={onChangeCompany}
                  />
                )}
              />
            </div>

            <div className="CompanyFilter each">
              <Controller
                control={control}
                defaultValue
                boxClasses="mt-3"
                name="vendor_id"
                render={({ ref }) => (
                  <AsyncSelect cacheOptions defaultOptions placeholder="Vendor" value={selVendorVal} loadOptions={vendorOptions} onInputChange={handleInputChangeVendor} onChange={onChangeVendor} />
                )}
              />
            </div>

            <div className="locationFilter each">
              <Select
                classNamePrefix="react-select"
                className="react-select"
                closeOnSelect={false}
                id="status"
                printOptions="on-focus"
                placeholder="Status"
                onChange={onChangeStatus}
                options={statusOptions}
                // value={value}
                // ref={ref}
              />
            </div>

            <div className="dateFilter each">
              <div onClick={handleRangeShow2} className="dateBox">
                <p className="date-range">
                  {
                    dateRange2[0].startDate ?  ` ${moment(dateRange2[0].startDate).format("MM/DD/YYYY")} - ${moment(dateRange2[0].endDate).format("MM/DD/YYYY")}` : "Invoice Due Date"
                  }
                </p>
                <img src={dateImg} alt="date icon" />
              </div>

              {showRange2 && (
                <div className="rangeDiv">
                  <DateRangePicker
                    onChange={(item) => setDateRange2([item.selection])}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={2}
                    ranges={dateRange}
                    direction="horizontal"
                    preventSnapRefocus={true}
                    calendarFocus="backwards"
                  />
                  <div onClick={handleRangeShow2} className="applyBtn">
                    <button type="button">Apply</button>
                  </div>
                </div>
              )}
            </div>

            <div className="groupFilter each">
              <Controller
                control={control}
                defaultValue
                boxClasses="mt-3"
                name="invoice"
                render={({ ref }) => (
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    placeholder="Invoice"
                    value={selInvoiceVal}
                    loadOptions={invoiceOptions}
                    onInputChange={handleInputChangeInvoice}
                    onChange={onChangeInvoice}
                  />
                )}
              />
            </div>
          </div>

          <div className="showing">
            <p className="text">Showing:</p>
            <div className="coverDisplay">
              <div className="displayDate">
                <p>{` ${moment(dateRange[0].startDate).format("MM/DD/YYYY")} - ${moment(dateRange[0].endDate).format("MM/DD/YYYY")}`}</p>
                <p
                  onClick={() => {
                    removeFilter("date");
                  }}
                  className="cancel comp"
                >
                  x
                </p>
              </div>

              {companyName && (
                <div className="displayDate">
                  <p className="Company">{companyName}</p>
                  {/* <p onClick={()=>{removeFilter("legal")}} className="cancel comp">x</p> */}
                </div>
              )}

              {vendorName && (
                <div className="displayDate">
                  <p className="location">{vendorName}</p>
                  <p
                    onClick={() => {
                      removeFilter("vendor");
                    }}
                    className="cancel comp"
                  >
                    x
                  </p>
                </div>
              )}

              {invoiceName && (
                <div className="displayDate">
                  <p className="invoice">{invoiceName}</p>
                  <p
                    onClick={() => {
                      removeFilter("invoice");
                    }}
                    className="cancel comp"
                  >
                    x
                  </p>
                </div>
              )}

              {statusName && (
                <div className="displayDate">
                  <p className="status">{statusName}</p>
                  <p
                    onClick={() => {
                      removeFilter("status");
                    }}
                    className="cancel comp"
                  >
                    x
                  </p>
                </div>
              )}
              {ageName && (
                <div className="displayDate">
                  <p className="status">{age}</p>
                  <p
                    onClick={() => {
                      removeFilter("age");
                    }}
                    className="cancel comp"
                  >
                    x
                  </p>
                </div>
              )}
              { dateRange2[0].startDate && (
                <div className="displayDate">
                  {` ${moment(dateRange2[0].startDate).format("MM/DD/YYYY")} - ${moment(dateRange2[0].endDate).format("MM/DD/YYYY")}`}
                  <p
                    onClick={() => {
                      removeFilter("date2");
                    }}
                    className="cancel comp"
                  >
                    x
                  </p>
                </div>
              )}
            </div>
          </div>

          <div className="summary">
            <p className="topic">Orders</p>
            <div className="summaryCover">
              <div className="spent">
                <div className="moneySide">
                  {/* <p className="currency">$</p> */}
                  <p className="amountMoney">{kFormatter(sumMoney, "")} </p>
                  <p className="currencyType"></p>
                </div>
                <p className="text">Total Amount</p>
              </div>

              <div className="spent mid">
                <div className="deptSide">
                  <p className="out">{kFormatter(sumInvoice, "")}</p>
                  {/* <p className="total">/14</p> */}
                </div>
                <p className="text">Total Invoice</p>
              </div>

              <div className="spent">
                <p className="purchase">{kFormatter(sumUnpaid, "")}</p>
                <p className="text">Total Vendor</p>
              </div>
            </div>
          </div>
        </div>
        <div className="chatBox">
          {/* <div className="title">Monthly Spend Report</div> */}
          <div className="chartFlex">
            <div style={{ width: "100%" }} className="pieChart">
              <Bar
                data={lineData}
                width={100}
                height={50}
                options={{
                  // title: {
                  //   display: true,
                  //   text: "Chart 1",
                  //   fontSize: 40
                  // },
                  maintainAspectRatio: false,

                  legend: {
                    display: true,
                    position: "right"
                  }
                }}
              />
            </div>
          </div>
        </div>

        <div className="document mt-5">
          <div className="mt-3">
            <DataTable columns={reportCol} data={tableData} loading={isLoadingAging} />
            {agingBucket?.pagination?.total > 10 ? (
              <Pagination page={page} lastPage={agingBucket?.pagination?.lastPage} paginate={paginate} total={agingBucket?.pagination?.total} handlePageChange={handlePageChange} perPage={perPage} />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportInvoice;

import React, { useState, useEffect, useRef } from "react";
import "./styles.scss";
import { ReactComponent as Bell } from "assests/icons/bell.svg";
import { ReactComponent as Avatar } from "assests/icons/silhouette.svg";
import { ReactComponent as ArrowDown } from "assests/icons/dropdown.svg";
import mark from "assests/icons/mark.svg";
import handImg from "assests/icons/handImg.svg";
import silh from "assests/icons/silh.png";
import bag from "assests/icons/bag.svg";
import clock from "assests/icons/clock.svg";
import bidImg from "assests/icons/bidImg.svg";
import { ReactComponent as ArrowBackDown } from "assests/icons/arrow-back-circle.svg";
import Hamburger from "assests/icons/hamburger.svg";
import DropdownMenu from "components/DropdownMenu";
import { useDispatch, useSelector } from "react-redux";
import { getProfile, logout } from "store/slice/UserSlice/UserSlice";
import { RootState } from "store/store";
import { useHistory, useLocation, Link } from "react-router-dom";
import { getUserNotification } from "store/slice/NotificationSlice/NotificationSlice";
import moment from "moment";

type NavbarProps = {
  handleMenu: () => void;
  match?: any;
};

const Navbar = ({ handleMenu, match }: NavbarProps) => {
  //state
  const [pageName, setPageName] = useState("Dashboard");
  const [pathLength, setpathLength] = useState<any>();
  const [path, setPath] = useState("");
  const { pathname } = useLocation();
  const [tabPath, setTabPath] = useState("");
  const [notification, setNotification] = useState(false);
  const [listCompany, setListCompany] = useState(false);

  // handle notification

  const handleNotification = () => {
    setNotification(!notification);
  };

  const handleCompany = () => {
    setListCompany(!listCompany);
  };

  //hook
  const dropdown = useRef<HTMLDivElement | null>(null);
  const dispatch = useDispatch();
  let history = useHistory();

  useEffect(() => {
    const dashboardName = pathname.split("/");
    console.log(dashboardName);

    setPath(`${dashboardName[1]}/${dashboardName[2]}`);
    setpathLength(dashboardName.length);
    setPageName(dashboardName[2]);
  }, [pathname]);

  useEffect(() => {
    switch (pageName) {
      case "company-info":
        setPageName("Company Information");
        return;
      case "bids":
        setPageName("Invitation to BID(s)");
        return;
      case "invoice":
        setPageName("Invoices");
        return;
      case "catalogue":
        setPageName("Supplier Catalogue");
        return;
      case "user-mgt":
        setPageName("User Management");
        return;
      case "settings":
        setPageName("Settings");
        return;
      case "requisition":
        setPageName("Requisition");
        return;
      default:
        break;
    }
  }, [pageName]);

  const innerPagesNavName = (name: string): string => {
    let navbarName: string = "";

    switch (name) {
      case "Invitation to BID(s)":
        navbarName = "bids";
        break;
      case "Supplier Invoices":
        navbarName = "Invoice";
        break;
      case "Company Information":
        navbarName = "Company Docs";
        break;
      default:
        break;
    }

    return navbarName;
  };

  useEffect(() => {
    switch (path) {
      case "settings":
        setTabPath(path);
        setPageName("Settings");
        return;
      default:
        break;
    }
  }, [pageName, path]);

  const { user, profile, portal } = useSelector((state: RootState) => state.user);
  console.log(user);
  const { notifications } = useSelector((state: RootState) => state.userNotify);
  // console.log(user, profile)
  console.log(notifications, "kkkkk");
  const handleGo = () => {
    if (user.user_type === "Vendor") {
      history.push("/supplier/notification");
    } else {
      history.push("/buyer/notification");
    }
  };

  useEffect(() => {
    dispatch(getUserNotification(1, 100));
  }, []);

  const Logout = () => {
    dispatch(logout(portal));
  };

  const Profile = () => {
    history.push("/profile");
  };

  const settings = [
    {
      name: "Profile Settings",
      select: Profile
    },
    {
      name: "Logout",
      select: Logout,
      style: "logout_tag"
    }
  ];

  useEffect(() => {
    if (user.user_id) {
      getProfile(user.user_id);
    }
  }, [profile]);

  const handleBack = () => {
    history.go(-1);
  };

  const dataNotify = [
    {
      update: "70 new employees are shifted",
      timestamp: 1596119688264
    },
    {
      update: "Time to Take a Break, TADA!!!",
      timestamp: 1596119686811
    }
  ];

  const handleMove=(id)=>{
    history.push(`/buyer/notification?id=${id}`)
  }

  return (
    <nav id="navbar" className="d-flex mb-sm-0 mb-lg-2 align-items-sm-center">
      <img src={Hamburger} alt="menu" className="hamburger cursor-pointer" onClick={handleMenu} />
      <h4 className="navbar-text  flex-grow-1  text-center">
        {pathLength > 3 && path !== tabPath ? (
          <div className="backBtn" onClick={handleBack}>
            <ArrowBackDown /> Back to <p className="route_name">{pageName}</p>
            {/* <ArrowBackDown /> Back to <p className="route_name">{innerPagesNavName(pageName)}</p> */}
          </div>
        ) : (
          <p className="route_name">{pageName}</p>
        )}
      </h4>
      <div className="bellDiv">
        <div className="coverBell">
          {notifications ? (
            notifications.count === 0 ? null : (
              <p className="notAmount">
                {notifications && notifications.count}
                <span>{notifications && notifications.count > 9 ? "+" : null}</span>
              </p>
            )
          ) : null}

          <Bell onClick={handleNotification} className="icon mobile-none cursor" />
        </div>

        {notification && (
          <div className="notify">
            <div className="allList">
              <button onClick={handleGo} className="markAll greybtn">
                {notifications && notifications.count === 0 ? null : "View All"}
              </button>
              <div className="eachList">
                {/* <div className="topSide">
                  <p className="day">Today</p>
                </div> */}

                <div className="taskLists">
                  {notifications ? (
                    notifications?.data?.length ? (
                      notifications?.data?.map((item) => {
                        return (
                          <div onClick={()=>{handleMove(item.id)}} key={item.id} className="eachTask">
                            <div className="leftSide">
                              <div className="cicle"></div>
                              <div className="imageCover">
                                <img src={bag} alt="bag icon" />
                              </div>
                              <p className="taskName">
                                <span className="number"></span>
                                {item.notification_type}
                              </p>
                            </div>
                            <div className="rightSide">
                              {/* <button className="view greybtn">view</button> */}
                              <div className="time">
                                <img src={clock} alt="clock icon" />
                                <p>{moment(item.created_at, "YYYYMMDD").fromNow()}</p>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="d-flex justify-content-center py-5">
                        <h2 style={{ fontSize: "1.3rem" }}>No notification yet</h2>
                      </div>
                    )
                  ) : (
                    <div className="d-flex justify-content-center py-5">
                      <h2 style={{ fontSize: "1.3rem" }}>Not notification yet</h2>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="profile-tab">
        <div className="d-flex user-profile flex-row align-items-center justify-content-between cursor-pointer">
          <img src={user.profile_image_url ? user.profile_image_url : silh} alt="" className="avatar mobile-none" />
          <p className="cursor-pointer">
            {user?.othernames} {"  "}
            {user?.lastname} {user?.user_type === "Vendor" ? null : <span>| {user?.company_description ? user?.company_description : "All Companies"}</span>}
          </p>
        </div>
      </div>

      <DropdownMenu options={settings} boxClasses={"nav-dropdown"}>
        <ArrowDown className="icon-dropdown ml-sm-2 ml-lg-3 mb-sm-0  mb-lg-1" />
      </DropdownMenu>
    </nav>
  );
};

export default Navbar;

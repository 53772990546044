import React, { useEffect, useState, useRef } from "react";
import "./styles.scss";

type Props = {
  label?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  value?: string;
  placeholder?: string;
  boxClasses?: string;
  type?: string;
  error?: string;
  iconUrl?: string;
  onIconClick?: string;
  disabled?: boolean;
  min?: string;
  customRef?: string | ((instance: HTMLInputElement | null) => void);
  fileNameRef?: string | ((instance: HTMLInputElement | null) => void);
  inputClasses?: string;
  handleFile?: (file: File, fileName: string) => void;
  fileName?: string;
  acceptFiles?: string;
};

const FileUploaderBox = ({
  label,
  onChange,
  name,
  value,
  placeholder,
  boxClasses,
  type,
  error,
  disabled,
  min,
  customRef,
  inputClasses,
  handleFile,
  fileName,
  acceptFiles = ".pdf, .doc, .pages"
}: Props) => {
  //State
  const [file, setFile] = useState<File>();
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleTriggerImgSelection = (e) => {
    e.preventDefault();
    fileInputRef.current!.click();
  };
  const handleSelectImage = (e: React.ChangeEvent<HTMLInputElement>, fileName) => {
    if (!e.target.files) return;
    const file = e.target.files[0]!;
    setFile(file);
    handleFile && handleFile(file, fileName);
  };

  //edge case
  const mergeRefsFn = (...refs) => {
    const filteredRefs = refs.filter(Boolean);
    if (!filteredRefs.length) return null;
    if (filteredRefs.length === 1) return filteredRefs[0];
    return (inst) => {
      for (const ref of filteredRefs) {
        if (typeof ref === "function") {
          ref(inst);
        } else if (ref) {
          ref.current = inst;
        }
      }
    };
  };
  return (
    <div className={`${boxClasses} fileUploadBox`}>
      <fieldset className={`${error && "error"} ${disabled && "disabled-input"}`}>
        {label && (
          <legend id="label-legend" className={` pl-2 pr-2 ${error && "label-error"}`}>
            {label}
          </legend>
        )}

        <div className="d-flex justify-content-between align-items-center">
          <span className="info">{file ? file.name : placeholder}</span>
          <input name={fileName} type="file" style={{ display: "none" }} ref={mergeRefsFn(fileInputRef, customRef)} accept={acceptFiles} onChange={(e) => handleSelectImage(e, fileName)} />
          <button className="btn btn-browse" onClick={handleTriggerImgSelection}>
            Browse
          </button>
        </div>
      </fieldset>
      {fileName !== "" && <a className={`${!error ? "d-block" : "d-none"} a-tag mt-1`} id={`${fileName}_`} href="www.facebook.com" target="_blank">
          
      </a>}
      <p className={`${error ? "d-block" : "d-none"} input-error mt-1`}>{error}</p>
    </div>
  );
};

export default FileUploaderBox;

/* eslint-disable react-hooks/exhaustive-deps */
import StatsCard from "components/StatsCard";
import React, { useState } from "react";
import "./styles.scss";
import workflow from "assests/icons/buyer/workflow.svg";
import AddIcon from "assests/icons/buyer/add-icon.svg";
import SearchIcon from "components/SearchBar";
import Dropdown from "components/Dropdown";
import { AppDispatch, RootState } from "store/store";
import DataTable from "components/DataTable";
import { fielddatas, fieldColumn } from "./data";
import CreateOnBoard from "components/ModalBuyer/CreateOnBoard";
import { useDispatch, useSelector } from "react-redux";
import useSearch from "hooks/useSearch";
import { deleteField, editField, getFields } from "store/slice/BuyerSlice/onBoardSlice/onBoardSlice";
import { useEffect } from "react";
import { toastPopUp } from "utils/utils";
import Pagination from "components/Pagination";
import { getAllCompaniesData } from "store/slice/BuyerSlice/CompanyDataSlice/CompanyDataSlice";
import { useParams } from "react-router-dom";
import { checkPermission } from "utils/utils";

const OnboardingEach = () => {
  //State
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [singleField, setSingleField] = useState<any>(null);
  const { idOn } = useParams<{ idOn?: any }>();
  const { role_access } = useSelector((state: RootState) => state.user?.user);

  //Redux
  const dispatch = useDispatch<AppDispatch>();
  const { fields, loading } = useSelector((state: RootState) => state.field);

  const [modal, setModal] = useState<boolean>(false);
  const closeModal = () => {
    setModal((prev) => !prev);
  };

  //Hook
  // const { search, inputRef, handleChange, searched } = useSearch(getFields, perPage, idOn);

  //Show Number for PerPage Request
  const handleNewPage = (pageNo: number, page: number = 1) => {
    setPerPage(pageNo);
    setPage(page);
    dispatch(getFields({ page, length: pageNo, id: idOn }));
  };

  //Pagination
  //---------------------------------------------
  const handlePageChange = React.useCallback((page: number, perPage: number) => {
    setPage(page);
    dispatch(getFields({ page, length: perPage, id: idOn }));
  }, []);

  const paginate = React.useCallback((pageNo: number, perPage: number) => {
    setPage(pageNo);
    dispatch(getFields({ page, length: perPage, id: idOn }));
  }, []);

  // setpage to the first page when a searched executed
  // useEffect(() => {
  //     if (searched) setPage(1);
  // }, [searched]);

  useEffect(() => {
    dispatch(getFields({ page, length: perPage, id: idOn }));
  }, []);

  //   deleteFiel

  const deleteField2 = (name: string, id: string) => {
    const payload = { vendor_field_id: id };
    toastPopUp(
      () => {
        dispatch(
          deleteField(payload, () => {
            dispatch(getFields({ page, length: perPage, id: idOn }));
          })
        );
      },
      "Delete",
      "Cancel",
      "Delete the entity",
      name,
      "red"
    );
  };

  const fieldList =
    fields &&
    fields?.data?.map((field) => {
      return {
        set_id: field.id,
        field_name: field.field_name,
        assigned_entity: field.company_id,
        category: field.category,
        date: field.created_at,
        // edit: () => editField(
        //    field.id,
        //    field.process_name,
        //    field.department_id,
        //    field.location_id,
        //    field.company_code),
        deleteField: () => checkPermission(role_access, "SETUP_INDEX") && deleteField2(field.company_description, field.id)
      };
    });

  return (
    <div id="settings-approval">
      {modal && <CreateOnBoard closeModal={closeModal} idOn={idOn} singleField={singleField} />}
      <div className="container mt-3">
        <h3 className="page-title">On-Boarding Field Set For {window.localStorage.getItem("name")}</h3>
        <div className="row mt-3 mb-5">
          <StatsCard statsNumber={fields?.count ?? 0} text="Fields" classes="stats-icon" image={AddIcon} iconBackgroundColor="rgba(45, 156, 219, 0.1)" />
          <StatsCard image={AddIcon} classes="middle-img" centerText="Create Field Set" onClick={closeModal} />
        </div>

        <div className="d-flex  mb-3">
          {/* <SearchIcon
                    placeholder="Search for field"
                    boxClasses=""
                    textRef={inputRef}
                    value={search}
                    onChange={handleChange}
                /> */}
          <Dropdown perPage={perPage} action={handleNewPage} />
        </div>
        <DataTable columns={fieldColumn} data={fieldList} loading={loading} />
        {fields?.count > 10 ? (
          <Pagination page={page} lastPage={fields?.pagination?.lastPage} paginate={paginate} total={fields?.pagination?.total} handlePageChange={handlePageChange} perPage={perPage} />
        ) : null}
      </div>
    </div>
  );
};

export default OnboardingEach;

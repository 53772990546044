import StatsCard from "components/StatsCard";
import React, { useState } from "react";
import "../styles.scss";
import Analytics from "assests/icons/buyer/analytics.svg";
import AddIcon from "assests/icons/buyer/add-icon.svg";
import SearchIcon from "components/SearchBar";
import Dropdown from "components/Dropdown";
import DataTable from "components/DataTable";
import { rateColumn } from "../data";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { useEffect } from "react";
import { toastPopUp } from "utils/utils";
import useSearch from "hooks/useSearch";
import Pagination from "components/Pagination";
import CreateNotify from "components/ModalBuyer/CreateNotify";
import { getRate } from "store/slice/BuyerSlice/VendorRate/VendorRateSlice";
import { useHistory } from "react-router";

const VendorRating = () => {
  //State
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [modal, setModal] = useState<boolean>(false);
  const history = useHistory();
  //Redux
  const dispatch = useDispatch<AppDispatch>();
  const { rate, loading } = useSelector((state: RootState) => state.rate);

  //Hook
  const { search, inputRef, handleChange, searched } = useSearch(getRate, perPage);

  useEffect(() => {
    dispatch(getRate(page, perPage));
  }, []);

  // setpage to the first page when a searched executed
  useEffect(() => {
    if (searched) setPage(1);
  }, [searched]);

  const handleView = (e, item) => {
    history.push(`/buyer/vendor-rating/view?id=${item.id}&name=${item.name}&rate=${item.rating ? item.rating : 0}`);
  };
  // new page
  const handleNewPage = (pageNo: number, page: number = 1) => {
    setPerPage(pageNo);
    setPage(1);
    dispatch(getRate(page, pageNo, search));
  };

  //Modal Handler
  const closeModal = () => {
    setModal((prev) => !prev);
  };

  //Pagination
  //---------------------------------------------
  const handlePageChange = React.useCallback(
    (page: number, perPage: number) => {
      setPage(page);
      dispatch(getRate(page, perPage, search));
    },
    [search]
  );

  const paginate = React.useCallback(
    (pageNo: number, perPage: number) => {
      setPage(pageNo);
      dispatch(getRate(pageNo, perPage, search));
    },
    [search]
  );

  const rateList =
    rate &&
    rate?.data?.map((item) => {
      return {
        id: item.id,
        vendor_id: item.id,
        company: item.company_description,
        name: `${item.vendor_description}`,
        rating: item.vendor_rating,
        delivery: item.total_deliveries
      };
    });

  return (
    <div id="settings-material-data">
      {modal}
      <div className="container mt-3">
        <div className="row mt-3 mb-5">
          <StatsCard statsNumber={rate?.count ?? 0} text="Vendor(s)" classes="stats-icon" image={Analytics} iconBackgroundColor="rgba(45, 156, 219, 0.1)" />
        </div>

        <div className="d-flex  mb-3">
          <SearchIcon placeholder="Search" boxClasses="" textRef={inputRef} value={search} onChange={handleChange} />
          <Dropdown perPage={10} action={handleNewPage} />
        </div>
        <DataTable columns={rateColumn} data={rateList} onClick={handleView} loading={loading} />
        <Pagination page={page} lastPage={rate?.pagination?.lastPage} paginate={paginate} total={rate?.pagination?.total} handlePageChange={handlePageChange} perPage={perPage} />
      </div>
    </div>
  );
};

export default VendorRating;

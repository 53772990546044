import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, NavLink, Switch, Redirect, useHistory } from "react-router-dom";
import Login from "pages/Auth/SupplierAuth/Login";
import BuyerLogin from "pages/Auth/BuyerAuth/Login";
import ForgotPassword from "pages/Auth/SupplierAuth/ForgotPassword";
import BuyerForgotPassword from "pages/Auth/BuyerAuth/ForgotPassword";
import SetPassword from "pages/Auth/SupplierAuth/SetPassword";
import Navbar from "components/NavbarHeader";
import Sidebar from "components/Sidebar";
import Dashboard from "pages/SupplierDashboard/Dashboard";
import ScrollToTop from "components/ScrollToTop";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import Activity from "pages/Auth/SupplierAuth/Activity/Activity";
import { AuthContactInformation } from "pages/SupplierDashboard/Registration/ContactInformation";
import { AuthLegalInformation } from "pages/SupplierDashboard/Registration/LegalInformation";
import { AuthBankInformation } from "pages/SupplierDashboard/Registration/BankInformation";
import ComplianceInformation from "pages/SupplierDashboard/Registration/ComplianceInformation";
import Contracts from "pages/SupplierDashboard/Contracts";
import SingleContract from "pages/SupplierDashboard/Contracts/SingleContract";
import SingleInvoice from "pages/SupplierDashboard/Invoice/SingleInvoice";
import Bids from "pages/SupplierDashboard/Bids";
import Catalogue from "pages/SupplierDashboard/Catalogue";
import UserMenu from "pages/SupplierDashboard/UserMenu";
import Invoice from "pages/SupplierDashboard/Invoice";
import Bid from "pages/SupplierDashboard/Bids/Bid";
import CompanyDocs from "pages/SupplierDashboard/CompanyDocs";
import SingleCompanyDoc from "pages/SupplierDashboard/CompanyDocs/SingleCompanyDocs";
import SingleCompanyDocOnboard from "pages/SupplierDashboard/CompanyDocs/OnBoarding/ContactInformation/index";
import SingleCompanyDocOnboardForms from "pages/SupplierDashboard/CompanyDocs/OnBoarding/LegalInformation/index";
import SingleCompanyDocOnboardFormsBank from "pages/SupplierDashboard/CompanyDocs/OnBoarding/BankInformation/index";
import BuyerSidebar from "components/SidebarBuyer";
import Report from "pages/BuyerDashboard/Report";
import Inventory from "pages/BuyerDashboard/Inventory";
import BuyerInvoice from "pages/BuyerDashboard/BuyerInvoice";
import BuyerSingleInvoice from "pages/BuyerDashboard/BuyerInvoice/SingleInvoice";
import Document from "pages/BuyerDashboard/Document";
import DocumentSingle from "pages/BuyerDashboard/Document/SingleDocument/index";
import RFxEvaluation from "pages/BuyerDashboard/Evaluation";
import Evaluation_3 from "pages/BuyerDashboard/Evaluation/Evaluation_3";
import BuyerSettings from "pages/BuyerDashboard/Settings";
import Evaluation_4 from "pages/BuyerDashboard/Evaluation/Evaluation_4";
import Requisition from "pages/BuyerDashboard/Requisition";
import SingleRequisition from "pages/BuyerDashboard/Requisition/SingleRequisition";
import BuyerUserMgt from "pages/BuyerDashboard/UserMgt";
import BuyerApproval from "pages/BuyerDashboard/Approval";
import BuyerApprovalTabs from "pages/BuyerDashboard/Approval/ApprovalTabs";
import BuyerApprovalRequisition from "pages/BuyerDashboard/Approval/Requisition";
import BuyerApprovalRfp from "pages/BuyerDashboard/Approval/RFP";
import BuyerApprovalInvoice from "pages/BuyerDashboard/Approval/Invoice";
import BuyerSuppliers from "pages/BuyerDashboard/Supplier";
import SingleSupplierTransaction from "pages/BuyerDashboard/Supplier/SingleSupplierTransaction";
import SingleSupplierDetails from "pages/BuyerDashboard/Supplier/SingleSupplierDetails";
import MasterData from "pages/BuyerDashboard/Supplier/MasterData";
import SourcingMain from "pages/BuyerDashboard/SourcingMain";
import SourcingGeneral from "pages/BuyerDashboard/SourcingMain/SourcingGeneral";
import ozoicon from "assests/images/ozoicon.svg";
import ForgotPasswordSet from "pages/Auth/BuyerAuth/setPassword";
import Waybill from "pages/SupplierDashboard/Waybill";
import BuyerDashboard from "pages/BuyerDashboard/Dashboard";
import { checkPermission } from "utils/utils";
import { getPermissions } from "store/slice/Roleslice/RoleSlice";
import Evaluation_5 from "pages/BuyerDashboard/Evaluation/Evaluation_5";
import KpiSetup from "pages/BuyerDashboard/Settings/Sourcing/components/KpiSetup";
import GoodsReceipt from "pages/BuyerDashboard/GoodsReceipt";
import SingleGoodsReceipt from "pages/BuyerDashboard/GoodsReceipt/SingleDocument";
import SpendReport from "pages/BuyerDashboard/Report/report_2";
import InnerReport from "pages/BuyerDashboard/Report/report_6";
import ReportPR from "pages/BuyerDashboard/Report/report_PR";
import ReportSupplier from "pages/BuyerDashboard/Report/report_V";
import Budget from "pages/BuyerDashboard/Budget";
import SingleBudget from "pages/BuyerDashboard/Budget/SingleBudget/SingleBudget";
import ReportInvoice from "pages/BuyerDashboard/Report/report_invoice";
import ReportBbbe from "pages/BuyerDashboard/Report/report_bbbe";
import NotificationData from "pages/BuyerDashboard/BuyerNotification";
import NotificationDataSupplier from "pages/SupplierDashboard/SuppplierNotification";
import VendorRating from "pages/BuyerDashboard/VendorRating/components/VendorRating";
import ViewRating from "pages/BuyerDashboard/VendorRating/components/ViewRating";
import NewPassword from "pages/BuyerDashboard/resetPassword/newPassword";

const SupplierPrivateRoutes = (props: any) => {
  const [menu, setMenu] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getPermissions("vendor"));
  }, []);

  const handleMenu = (): void => {
    setMenu(!menu);
  };
  const { role_access } = useSelector((state: RootState) => state.user?.user);
  const { permissions } = useSelector((state: RootState) => state.roles);
  const userPermissions = role_access.map((perm) => perm.permission_name);
  // console.log(role_access, "access");
  // console.log(permissions, "access");

  // console.log(role_access);
  // console.log(permissions);
  // console.log(userPermissions);

  return (
    <div className="app-wrapper">
      <div className="main-body">
        <Sidebar menu={menu} handleMenu={handleMenu} setMenu={setMenu} />
        <div style={{ width: "100%", overflowX: "hidden" }}>
          <Navbar handleMenu={handleMenu} />
          <div className="right-col">
            <ScrollToTop />
            <Switch>
              <Route path="/supplier/dashboard" component={Dashboard} />
              <Route exact path="/supplier/newPassword" component={NewPassword} />

              <Route exact path="/supplier/contracts">
                {userPermissions?.includes("PURCHASE_ORDERS_INDEX") ? <Contracts /> : <Redirect to="/supplier/dashboard" />}
              </Route>
              <Route exact path="/supplier/contracts/:id">
                {userPermissions?.includes("PURCHASE_ORDERS_INDEX") ? <SingleContract /> : <Redirect to="/supplier/dashboard" />}
              </Route>
              <Route exact path="/supplier/invoice/:id">
                {userPermissions?.includes("INVOICE_MANAGEMENT") ? <SingleInvoice /> : <Redirect to="/supplier/dashboard" />}
              </Route>
              <Route exact path="/supplier/bids">
                {userPermissions?.includes("RFPS_INDEX") ? <Bids /> : <Redirect to="/supplier/dashboard" />}
              </Route>
              <Route path="/supplier/bids/:bidNo">{userPermissions?.includes("RFPS_INDEX") ? <Bid /> : <Redirect to="/supplier/dashboard" />}</Route>
              <Route exact path="/supplier/catalogue" component={Catalogue} />
              <Route exact path="/supplier/invoice">
                {userPermissions?.includes("INVOICE_MANAGEMENT") ? <Invoice /> : <Redirect to="/supplier/dashboard" />}
              </Route>
              <Route exact path="/supplier/user-mgt">
                {userPermissions?.includes("USERS_INDEX") ? <UserMenu /> : <Redirect to="/supplier/dashboard" />}
              </Route>
              <Route exact path="/supplier/company-info">
                {userPermissions?.includes("RFPS_INDEX") ? <CompanyDocs /> : <Redirect to="/supplier/dashboard" />}
              </Route>
              <Route exact path="/supplier/company-info/company">
                {userPermissions?.includes("RFPS_INDEX") ? <SingleCompanyDoc /> : <Redirect to="/supplier/dashboard" />}
              </Route>
              <Route exact path="/supplier/company-info/contact-info">
                {userPermissions?.includes("REPLACE_EXPIRING_DATA") ? <SingleCompanyDocOnboard /> : <Redirect to="/supplier/dashboard" />}
              </Route>
              <Route exact path="/supplier/company-info/forms">
                {userPermissions?.includes("REPLACE_EXPIRING_DATA") ? <SingleCompanyDocOnboardForms /> : <Redirect to="/supplier/dashboard" />}
              </Route>
              <Route exact path="/supplier/company-info/company/bank-info">
                {userPermissions?.includes("REPLACE_EXPIRING_DATA") ? <SingleCompanyDocOnboardFormsBank /> : <Redirect to="/supplier/dashboard" />}
              </Route>
              <Route exact path="/supplier/user-menu">
                {userPermissions?.includes("USERS_INDEX") ? <UserMenu /> : <Redirect to="/supplier/dashboard" />}
              </Route>
              <Route exact path="/supplier/waybill" render={() => (userPermissions?.includes("WAYBILL_INDEX") ? <Waybill /> : <Redirect to="/supplier/dashboard" />)} />
              <Redirect to="/supplier/dashboard" />
            </Switch>
          </div>
        </div>
        <img src={ozoicon} className="btmImg" alt="bottom icom" />
      </div>
    </div>
  );
};

const BuyerPrivateRoutes = (props: any) => {
  const [menu, setMenu] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPermissions("company"));
  }, []);

  const { role_access } = useSelector((state: RootState) => state.user?.user);
  const { permissions } = useSelector((state: RootState) => state.roles);
  const userPermissions = role_access.map((perm) => perm.permission_name);
  const handleMenu = (): void => {
    setMenu(!menu);
  };
  // console.log(role_access, "access");
  // console.log(userPermissions);
  // console.log(permissions?.data);

  return (
    <div className="app-wrapper">
      <div className="main-body">
        <BuyerSidebar menu={menu} handleMenu={handleMenu} setMenu={setMenu} />
        <div style={{ width: "100%", overflowX: "hidden", flex: 1 }}>
          <Navbar handleMenu={handleMenu} />
          <div className="right-col">
            <ScrollToTop />
            <Switch>
              <Route exact path="/buyer/inventory" component={Inventory} />
              <Route exact path="/buyer/report" component={Report} />
              <Route exact path="/buyer/report/purchase_order" component={SpendReport} />
              <Route exact path="/buyer/report/inner" component={InnerReport} />
              <Route exact path="/buyer/report/purchase_requisition" component={ReportPR} />
              <Route exact path="/buyer/report/supplier" component={ReportSupplier} />
              <Route exact path="/buyer/report/invoice" component={ReportInvoice} />
              <Route exact path="/buyer/report/bbbe" component={ReportBbbe} />

              <Route exact path="/buyer/newPassword" component={NewPassword} />

              <Route exact path="/buyer/invoice" render={() => (userPermissions.includes("INVOICE_MANAGEMENT") ? <BuyerInvoice /> : <Redirect to="/buyer/dashboard" />)} />
              <Route exact path="/buyer/dashboard" component={BuyerDashboard} />
              <Route
                exact
                path="/buyer/suppliers"
                // component={checkPermission(role_access, "VENDORS_INDEX") ? BuyerSuppliers : <Redirect to="/buyer/dashboard" />}
                render={() => (checkPermission(role_access, "VENDORS_INDEX") ? <BuyerSuppliers /> : <Redirect to="/buyer/dashboard" />)}
              />
              <Route
                exact
                path="/buyer/suppliers/master-data"
                // component={checkPermission(role_access, "VENDORS_INDEX") ? MasterData : <Redirect to="/buyer/dashboard" />}
                render={() => (checkPermission(role_access, "VENDORS_INDEX") ? <MasterData /> : <Redirect to="/buyer/dashboard" />)}
              />
              <Route exact path="/buyer/suppliers/:id" component={SingleSupplierTransaction} />
              <Route exact path="/buyer/suppliers/details/:id" component={SingleSupplierDetails} />
              <Route exact path="/buyer/invoice/:id" render={() => (userPermissions.includes("INVOICE_MANAGEMENT") ? <BuyerSingleInvoice /> : <Redirect to="/buyer/dashboard" />)} />
              <Route
                exact
                path="/buyer/purchase-orders"
                // component={checkPermission(role_access, "PURCHASE_ORDERS_INDEX") ? Document : <Redirect to="/buyer/dashboard" />}
                render={() => (userPermissions.includes("PURCHASE_ORDERS_INDEX") ? <Document /> : <Redirect to="/buyer/dashboard" />)}
              />
              <Route
                exact
                path="/buyer/goods-receipt"
                // component={checkPermission(role_access, "PURCHASE_ORDERS_INDEX") ? Document : <Redirect to="/buyer/dashboard" />}
                render={() => (userPermissions.includes("PURCHASE_ORDERS_INDEX") ? <GoodsReceipt /> : <Redirect to="/buyer/dashboard" />)}
                // component={GoodsReceipt}
              />
              <Route
                exact
                path="/buyer/goods-receipt/:id"
                // component={checkPermission(role_access, "PURCHASE_ORDERS_INDEX") ? Document : <Redirect to="/buyer/dashboard" />}
                render={() => (userPermissions.includes("PURCHASE_ORDERS_INDEX") ? <SingleGoodsReceipt /> : <Redirect to="/buyer/dashboard" />)}
              />
              <Route
                exact
                path="/buyer/purchase-orders/:id"
                // component={checkPermission(role_access, "PURCHASE_ORDERS_INDEX") ? DocumentSingle : <Redirect to="/buyer/dashboard" />}
                render={() => (userPermissions.includes("PURCHASE_ORDERS_INDEX") ? <DocumentSingle /> : <Redirect to="/buyer/dashboard" />)}
              />
              <Route exact path="/buyer/rfx-evaluation" render={() => (userPermissions.includes("RFPS_INDEX") ? <RFxEvaluation /> : <Redirect to="/buyer/dashboard" />)} />

              <Route
                exact
                path="/buyer/rfx-evaluation/evaluation/commercial"
                // component={Evaluation_5}
                render={() => (userPermissions.includes("RFPS_INDEX") ? <Evaluation_5 /> : <Redirect to="/buyer/dashboard" />)}
              />

              <Route
                exact
                path="/buyer/vendor-rating"
                // component={Evaluation_5}
                render={() => (userPermissions.includes("VENDOR_EVALUATIONS") ? <VendorRating /> : <Redirect to="/buyer/dashboard" />)}
              />

              <Route
                exact
                path="/buyer/vendor-rating/view"
                // component={Evaluation_5}
                render={() => (userPermissions.includes("VENDOR_EVALUATIONS") ? <ViewRating /> : <Redirect to="/buyer/dashboard" />)}
              />

              <Route
                exact
                path="/buyer/rfx-evaluation/evaluation/technical"
                // component={Evaluation_3}
                render={() => (userPermissions.includes("RFPS_INDEX") ? <Evaluation_3 /> : <Redirect to="/buyer/dashboard" />)}
              />
              <Route
                exact
                path="/buyer/rfx-evaluation/evaluation/commercial"
                // component={Evaluation_5}
                render={() => (userPermissions.includes("RFPS_INDEX") ? <Evaluation_5 /> : <Redirect to="/buyer/dashboard" />)}
              />

              <Route
                exact
                path="/buyer/rfx-evaluation/evaluation-4/"
                // component={Evaluation_4}
                render={() => (userPermissions.includes("RFPS_INDEX") ? <Evaluation_4 /> : <Redirect to="/buyer/dashboard" />)}
              />
              <Route exact path="/buyer/budget" render={() => (userPermissions.includes("BUDGET_MANAGEMENT") ? <Budget /> : <Redirect to="/buyer/dashboard" />)} />
              <Route exact path="/buyer/budget/:id" render={() => (userPermissions.includes("BUDGET_MANAGEMENT") ? <SingleBudget /> : <Redirect to="/buyer/dashboard" />)} />

              <Route
                exact
                path="/buyer/requisition"
                // component={checkPermission(role_access, "REQUISITIONS_VIEW") ? Requisition : <Redirect to="/buyer/dashboard" />}
                render={() => (userPermissions.includes("REQUISITIONS_VIEW") ? <Requisition /> : <Redirect to="/buyer/dashboard" />)}
              />
              <Route
                exact
                path="/buyer/requisition/:id"
                // component={SingleRequisition}
                render={() => (userPermissions.includes("REQUISITIONS_VIEW") ? <SingleRequisition /> : <Redirect to="/buyer/dashboard" />)}
              />
              <Route
                path="/buyer/settings"
                // component={checkPermission(role_access, "SETUP_INDEX") ? BuyerSettings : <Redirect to="/buyer/dashboard" />}
                render={() => (checkPermission(role_access, "SETUP_INDEX") ? <BuyerSettings /> : <Redirect to="/buyer/dashboard" />)}
              />
              <Route
                path="/buyer/user-mgt"
                // component={checkPermission(role_access, "USER_ROLES_INDEX") || checkPermission(role_access, "USERS_INDEX") ? BuyerUserMgt : <Redirect to="/buyer/dashboard" />}
                render={() => (checkPermission(role_access, "USER_ROLES_INDEX") || checkPermission(role_access, "USERS_INDEX") ? <BuyerUserMgt /> : <Redirect to="/buyer/dashboard" />)}
              />
              <Route exact path="/buyer/approvals" component={BuyerApproval} />
              <Route exact path="/buyer/approvals/:id" component={BuyerApprovalTabs} />
              <Route exact path="/buyer/approvals/requisition/:id" component={BuyerApprovalRequisition} />
              <Route exact path="/buyer/approvals/invoice/:id" component={BuyerApprovalInvoice} />
              <Route exact path="/buyer/approvals/rfp/:id" component={BuyerApprovalRfp} />
              <Route exact path="/buyer/notification" component={NotificationData} />

              <Route
                exact
                path="/buyer/sourcing"
                // component={checkPermission(role_access, "RFPS_INDEX") ? SourcingMain : <Redirect to="/buyer/dashboard" />}
                render={() => (checkPermission(role_access, "RFPS_INDEX") ? <SourcingMain /> : <Redirect to="/buyer/dashboard" />)}
              />
              <Route
                exact
                path="/buyer/sourcing/projects/:id"
                component={SourcingGeneral}
                // render={() => (checkPermission(role_access, "RFPS_INDEX") ? <SourcingGeneral /> : <Redirect to="/buyer/dashboard" />)}
              />
              <Redirect to="/buyer/dashboard" />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
};

const PublicRoutes = () => {
  return (
    <>
      <ScrollToTop />
      <Switch>
        <Route path="/supplier/login" exact component={Login} />
        <Route path="/" exact component={Activity} />
        <Route path="/supplier/forgot-password" exact component={ForgotPassword} />
        <Route path="/set-password" exact component={ForgotPasswordSet} />
        <Route path="/supplier/set-password/:term" exact component={SetPassword} />
        <Route path="/supplier/registration/contact-information" component={AuthContactInformation} />
        <Route path="/supplier/registration/legal-information/:id" component={AuthLegalInformation} />
        <Route path="/supplier/registration/bank-information/:id" component={AuthBankInformation} />
        <Route path="/supplier/registration/compliance-information/:id" component={ComplianceInformation} />
        <Route path="/buyer/login" exact component={BuyerLogin} />
        <Route path="/buyer/forgot-password" exact component={BuyerForgotPassword} />
        <Redirect to="/" />
      </Switch>
    </>
  );
};

type Props = {};

const Root: React.FunctionComponent<Props> = () => {
  const { userToken, user, authorized, portal } = useSelector((state: RootState) => state.user);
  const [userOn, setuserOn] = useState(true);
  const [userId] = useState("Supplier");

  // const renderRoutes = () => {
  //   if (userOn) {
  //     return <SupplierPrivateRoutes />;
  //   } else {
  //     return <BuyerPrivateRoutes />;
  //   }
  // };
  const renderRoutes = () => {
    if (userToken && user !== null && authorized && portal === "Vendor") {
      return <SupplierPrivateRoutes />;
    } else if (userToken && user !== null && authorized && portal === "Buyer") {
      return <BuyerPrivateRoutes />;
    } else {
      return <PublicRoutes />;
    }
  };
  return <Router>{renderRoutes()}</Router>;
};

export default Root;

import React, { useEffect, useState } from "react";
import Modal from "components/Modal";
import Textbox from "components/Textbox";
import Button from "components/Button";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import FileUploaderBox from "components/FileUploaderBox";
import SelectDropdown from "components/SelectDropdown";
import API from "utils/httpsRequest";
import { getCompaniesEntityList } from "store/slice/CompaniesSlice/CompaniesSlice";
import { createCompanyData, editCompanyData, getAllCompaniesData, uploadFile } from "store/slice/BuyerSlice/CompanyDataSlice/CompanyDataSlice";
import { getAllCountries } from "store/slice/CountriesSlice/CountriesSlice";
import { getAllcurrencies } from "store/slice/CurrencySlice/CurrencySlice";
import AsyncSelect from "react-select/async";

type Props = {
  closeModal: () => void;
  singleCompanyData?: any;
};
const CreateOREditLegalEntity = ({ closeModal, singleCompanyData = null }: Props) => {
  const { register, handleSubmit, errors,control, setValue } = useForm<FormData>({
    mode: "onChange"
  });

  //State
  const [files, setFiles] = useState<any>([]);
  const [uploadBool, setUploadBool] = useState(false);
  const [countrySel, setCountrySel] = React.useState("");
  const [selCountryVal, setSelCountryVal] = React.useState("");
  const [currencySel, setCurrencySel] = React.useState("");
  const [selCurrencyVal, setSelCurrencyVal] = React.useState("");

  const checkUpload = () => {
    setUploadBool(!uploadBool);
  };

  type FormData = {
    company_description: string;
    company_code: string;
    default_currency_id: string;
    email: string;
    phoneNo: string;
    company_id: string;
    country_id: string;
  };

  //Redux Store
  const dispatch = useDispatch<AppDispatch>();
  const { companies } = useSelector((state: RootState) => state.companies);
  const { isLoading } = useSelector((state: RootState) => state.companiesData);
  const { countries } = useSelector((state: RootState) => state.country);
  const { currencies } = useSelector((state: RootState) => state.currency);
  const { s3url } = useSelector((state: RootState) => state.companiesData);
  const { user } = useSelector((state: RootState) => state.user)
  const { token } = user;


  const defaultOptions = (id) => {
    return API.get(`/countries/get/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      return res.data.data.map((item) => (
        {
          label: item.country_description,
          value: item.id
        }
      ))
    })
  }

  useEffect(() => {
    if (singleCompanyData !== null) {
      setValue("company_code", singleCompanyData?.code);
      setValue("company_description", singleCompanyData?.desc);

       defaultOptions(singleCompanyData?.country)
      

    }
  }, [singleCompanyData]);

  const handleInputChangeCountry = value => {
    setCountrySel(value)
  }
  const onChangeCountry = value => {
    setSelCountryVal(value);
  }

  const handleInputChangeCurrency = value => {
    setCurrencySel(value)
  }

  const onChangeCurrency = value => {
    setSelCurrencyVal(value);
  }

  //Request
  useEffect(() => {
    dispatch(getAllCountries());
    dispatch(getAllcurrencies());
  }, []);

  const onFormSubmit = (vals) => {
    if (singleCompanyData) {
     
      const editPayload = {
        id: singleCompanyData?.id,
        company_description: vals.company_description,
        company_code: vals.company_code,
        country_id:  selCountryVal?.['value'],
      };

      dispatch(
        editCompanyData(editPayload, () => {
          closeModal();
          dispatch(getAllCompaniesData());
        })
      );
      return;
    }

  
   

    const { company_logo_url, ...rest } = vals;
    const payload = {
      ...rest,
      company_logo_url: s3url,
      country_id:  selCountryVal?.['value'],
      default_currency_id: selCurrencyVal?.['value']
    };

    dispatch(
      createCompanyData(payload, () => {
        closeModal();
        dispatch(getAllCompaniesData());
      })
    );
  };

  const handleFile = (e, field_name: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(e);
    reader.onloadend = () => {
      // use a regex to remove data url part
      let read: any = reader.result;
      const payload = {
        base64_data: read.replace("data:", "").replace(/^.+,/, ""),
        file_extension: e.name.split(".").pop()
      };
      dispatch(uploadFile(payload));

      setFiles(payload);
    };
  };

  // Getting Countries
  const countriesOptions2 =
    countries &&
    countries.map((item) => {
      return {
        label: item.country_description,
        value: item.id
      };
    });
 

    // const selectedCountry = countriesOptions2 && countriesOptions2.filter((item)=>{
    //   return item.value === singleCompanyData.country
    // })

    // console.log(selectedCountry)

    const countriesOptions = () => {
      return API.get(`/countries/get?page=1&length=1000&search=${countrySel}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }).then((res) => {
        return res.data.data.map((item) => (
          {
            label: item.country_description,
            value: item.id
          }
        ))
      })
    }

  
  // Getting Currency
  const currencyOptions =
    currencies &&
    currencies.map((_data: { currency_description: string; id: string }) => {
      return {
        value: _data.id,
        optionName: _data.currency_description
      };
    });

    
    const currencyOptions2 = () => {
      return API.get(`/currencies/get?page=1&length=10&search=${currencySel}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }).then((res) => {
      
        return res.data.data.map((item) => (
          {
            label: item.currency_description,
            value: item.id
          }
        ))
      })
    }

  return (
    <Modal modalText={singleCompanyData === null ? "Create" : "Edit"} isUpload={true} onClose={closeModal} modallength="modal-length" checkUpload={checkUpload} uploadBool={uploadBool}>
      {uploadBool ? (
        <div id="buyer-create-location">
          <p className="">Download and fill the entity template. adhere to file structure before uploading the Legal Entity(s)</p>
          <form className="mt-2">
            <p className="template">xxxx Teemplate sourcing team</p>

            <FileUploaderBox
              fileName=""
              boxClasses="mt-3 mb-5"
              handleFile={handleFile}
              acceptFiles={".jpg, .jpeg, .png"}
              customRef={register({ required: true })}
              error={errors["logo"] && "This field is required"}
            />

            <Button label={singleCompanyData === null ? "Create Legal Entity" : "Edit Legal Entity"} btnType="btn-primary" boxClasses="mt-5 mb-5" btnStyle="100%" loading={isLoading} />
          </form>
        </div>
      
      ) : (
        <div id="buyer-legal-entity">
          <p className="">
            {singleCompanyData === null ? "  Complete this form with accurate data to create a new Entity in GZI." : "  Complete this form with accurate data to update this Entity in GZI."}
          </p>

          <form className="mt-2" onSubmit={handleSubmit(onFormSubmit)}>
            <Textbox
              type="text"
              name="company_description"
              label="Entity Name"
              placeholder="Entity Name"
              error={errors.company_description && "This field is required"}
              customRef={register({
                required: true
              })}
              boxClasses="mb-3"
            />
            <Textbox
              type="text"
              name="company_code"
              label="ERP ID"
              placeholder="ERP ID"
              boxClasses="mb-3"
              error={errors.company_code && "This field is required"}
              customRef={register({
                required: true
              })}
            />
            {/* <SelectDropdown
              boxClasses="mt-3"
              name="country_id"
              label="Country"
              placeholder={singleCompanyData===null?null:"Country"}
              options={countriesOptions}
              customRef={register({ required: true })}
              error={errors.country_id && "This field is required"}
            /> */}

           <Controller
            control={control}
            defaultValue
            boxClasses="mt-3"
            name="country_id"
            render={({ ref }) => (
              <AsyncSelect
                cacheOptions
                defaultOptions
                placeholder="Country"
                value={selCountryVal}
                loadOptions={countriesOptions}
                onInputChange={handleInputChangeCountry}
                onChange={onChangeCountry}
              />
            )} />
            <br />

<Controller
            control={control}
            defaultValue
            boxClasses="mt-3"
            name="default_currency_id"
            render={({ ref }) => (
              <AsyncSelect
                cacheOptions
                defaultOptions
                placeholder="Currency"
                value={selCurrencyVal}
                loadOptions={currencyOptions2}
                onInputChange={handleInputChangeCurrency}
                onChange={onChangeCurrency}
              />
            )} />

            {singleCompanyData === null && (
              <>
                {/* <SelectDropdown
                  boxClasses="mt-3"
                  name="default_currency_id"
                  label="Currency"
                  placeholder="Currency"
                  options={currencyOptions}
                  customRef={register({ required: true })}
                  error={errors.country_id && "This field is required"}
                /> */}
                <br/>

          

                <FileUploaderBox
                  placeholder="Company Logo"
                  fileName="company_logo_url"
                  boxClasses="mt-3 mb-2"
                  label="Company Logo"
                  handleFile={handleFile}
                  acceptFiles={".jpg, .jpeg, .png"}
                  customRef={register({ required: true })}
                  error={errors["logo"] && "This field is required"}
                />
              </>
            )}

            <Button label={singleCompanyData === null ? "Create Legal Entity" : "Edit Legal Entity"} btnType="btn-primary" boxClasses="mt-5 mb-5" btnStyle="100%" loading={isLoading} />
          </form>
        </div>
      )}
    </Modal>
  );
};

export default CreateOREditLegalEntity;

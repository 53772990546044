import React, { useState } from "react";
import Modal from "components/Modal";
import ArrowDropdown from "components/ArrowDropdown";
import Textbox from "components/Textbox";
import Button from "components/Button";
import useArrowDropdown from "hooks/useArrowDropdown";
import { useForm } from "react-hook-form";
// import { getRoles } from "store/slice/RoleSlice/RoleSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { createUser, editUser, getUsers } from "store/slice/UserMgtSlice/UserMgtSlice";
import { CreateUserPayload } from "store/slice/UserMgtSlice/interface";
import "./styles.scss";
import { getSingleUser } from "store/slice/UserMgtSlice/UserMgtSlice";
import SelectDropdown from "components/SelectDropdown";
import { getCompaniesEntityList } from "store/slice/CompaniesSlice/CompaniesSlice";
import { getRoles } from "store/slice/Roleslice/RoleSlice";
import SearchBar from "components/SearchBar";
import { getDepartments } from "store/slice/BuyerSlice/DepartmentSlice/DepartmentSlice";
import { useEffect } from "react";
import useSearch from "hooks/useSearch";
import { generateWaybillInvoice, getWayBill, getWayBillCan } from "store/slice/SupplierSlices/Waybill/WayBillSlice";
import Pagination from "components/Pagination";
import { createInvoiceNonPO } from "store/slice/BuyerSlice/Invoice/InvoiceSlice";
import { useHistory } from "react-router";
import { toastr } from "react-redux-toastr";
import axios from "axios";
import { AppDispatch, AppThunk } from "store/store";
import { getAllcurrencies } from "store/slice/CurrencySlice/CurrencySlice";
import { formatCurrency } from "utils/utils";
import { getTaxSupplier } from "store/slice/SupplierSlices/TaxSlice/TaxSlice";

type Props = {
  closeModal: () => void;
  //   modalData?: { userId: string; viewStatus: boolean; editStatus: boolean };
};
const CreateNonPO2 = ({ closeModal }: Props) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const days = Array.from(Array(30).keys());
  const [dueDate, setDueDate] = React.useState("");
  const [isCreate, setIsCreate] = useState(false);
  const { user } = useSelector((state: RootState) => state.user);
  const d = new Date();
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const history = useHistory();

  const { token, vendor_id } = user;

  const [error, setError] = React.useState("");
  const { register, handleSubmit, errors, setValue, watch } = useForm<FormData>({
    mode: "onChange"
  });
  const [add, setAdd] = useState<any>([]);

  const handleAdd = (e, id) => {
    if (e) {
      setAdd([...add, id]);
    } else {
      setAdd(
        add.filter((item) => {
          return item !== id;
        })
      );
    }
    // if (add.includes(id)) {
    //   setAdd(
    //     add.filter((item) => {
    //       return item !== id;
    //     })
    //   );
    //   toastr.success("", `${id} has been removed`);
    // } else {
    //   setAdd([...add, id]);
    //   toastr.success("", `${id} has been added`);
    // }
    // if (add.includes(id)) {
    //   setAdd([...add, id]);
    // } else {
    //   setAdd(
    //     add.filter((item) => {
    //       return item !== id;
    //     })
    //   );
    // }
  };

  //Pagination
  //---------------------------------------------
  const handlePageChange = React.useCallback((page: number, perPage: number) => {
    setPage(page);
    dispatch(getWayBillCan(page, perPage, search, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, true));
  }, []);

  const paginate = React.useCallback((pageNo: number, perPage: number) => {
    setPage(pageNo);
    dispatch(getWayBillCan(pageNo, perPage, search, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, true));
  }, []);

  const values = watch();
  const { search, inputRef, handleChange, searched } = useSearch(getWayBillCan, 1);
  //Redux Store
  const dispatch = useDispatch();
  // const { roles } = useSelector((state: RootState) => state.roles);
  //Request
  useEffect(() => {
    dispatch(getAllcurrencies());
  }, []);

  const { waybillCanInvoice, isLoadingCan } = useSelector((state: RootState) => state.waybill);
  const { currencies } = useSelector((state: RootState) => state.currency);
  const { tax } = useSelector((state: RootState) => state.taxSupply);

  const taxOptions =
    tax &&
    tax.data.map((_data: { tax_code: string; tax_code_description: string }) => {
      return {
        value: _data.tax_code,
        optionName: _data.tax_code === "V0" ? "No Tax" : _data.tax_code_description
      };
    });
  useEffect(() => {
    dispatch(getWayBillCan(page, perPage));
    dispatch(getTaxSupplier(1, 1000));
  }, []);

  type FormData = {
    days: any;
    dates: any;
    tax_code: any;
    // currency:any;
  };

  const onFormSubmit = (vals: FormData) => {
    const data = {
      waybill_ids: add,
      service_contract_code: `TINV-${d.getMilliseconds()}`,
      invoice_due_date: dueDate,
      payment_terms: vals.days,
      currency_id: waybillCanInvoice.data.length && waybillCanInvoice.data[0].currency_id,
      total_tax_amount: "",
      supplier_invoice_number: `TINV-${d.getMilliseconds()}`,
      // currency_id: currencyVal?.value,
      vendor_id,
      tax_code: vals.tax_code
    };

    // dispatch(
    //   createInvoiceNonPO(data, () => {
    //     closeModal();
    //     dispatch(getInvoices());
    //     invoiceId &&  history.push(`/supplier/invoice/${invoiceId}`)
    //   })
    // );

    const createInvoiceNonPO =
      (data?: object, cb?: () => void): AppThunk =>
      async (dispatch: AppDispatch, getState) => {
        setIsCreate(true);
        const { userToken } = getState().user;
        try {
          const response = await axios.post(`${BASE_URL}/invoices/create_waybill_invoice`, data, {
            headers: {
              Authorization: `Bearer ${userToken}`,
              "Content-Type": "application/json"
            }
          });
          if ([200, 201].includes(response.status)) {
            // dispatch(createInvoiceNonPOSuccess(response.data.data.id));
            setIsCreate(false);

            toastr.success(response.data.message);
            history.push(`/supplier/invoice/${response.data.data.id}`);
            cb && cb();
          }
        } catch (err) {
          setIsCreate(false);
          if (err.response) {
            toastr.error(err?.response?.data?.message);
          } else {
            toastr.error("No Network Conection");
          }
        }
      };

    if (data.waybill_ids.length) {
      dispatch(createInvoiceNonPO(data));
    } else {
      toastr.error("Select a waybill");
    }
  };

  return (
    <div className="po-modal">
      <Modal modalText={"Select"} onClose={closeModal}>
        <div id="create-po-modal">
          <h5 className="mb-4 page-title">Select</h5>
          <div className="d-flex justify-content-between mb-2">
            <div className="search-cont">
              <SearchBar placeholder="Search" inputWidth="100%" textRef={inputRef} value={search} onChange={handleChange} />
            </div>
          </div>
          <form className="mt-2" onSubmit={handleSubmit(onFormSubmit)}>
            <div className="terms-cont2">
              <div className="firstT">
                <Textbox
                  type="date"
                  value={dueDate}
                  name="dates"
                  label="Select Due Date"
                  boxClasses="mt-3"
                  placeholder="Select Due Date"
                  onChange={(e) => setDueDate(e.target.value)}
                  min={new Date().toISOString().slice(0, 10)}
                  customRef={register({ required: true })}
                  error={errors.dates && "This field is required"}
                />
              </div>
              <div className="firstT">
                <SelectDropdown
                  boxClasses="mt-3"
                  name="days"
                  label="Payment Terms"
                  placeholder="Payment Terms"
                  options={days.map((item) => ({ optionName: item + 1, value: item + 1 }))}
                  customRef={register({ required: true })}
                  error={errors.days && "This field is required"}
                />
              </div>
              <div className="firstT">
                <SelectDropdown
                  boxClasses="mt-3"
                  name="tax_code"
                  label="Tax Code"
                  placeholder="Tax Code"
                  options={taxOptions}
                  customRef={register({ required: true })}
                  error={errors.days && "This field is required"}
                />
              </div>
              {/* <div className="firstT">
                <SelectDropdown
                    boxClasses="mt-3"
                    name="currency"
                    label="Currency"
                    placeholder="Currency"
                    options={currencyOptions}
                    customRef={register({ required: true })}
                    error={errors.days && "This field is required"}
                  />
              </div> */}
            </div>
            <table>
              <thead>
                <tr>
                  <th>Select</th>
                  <th>Loading Date</th>
                  <th>Description</th>
                  <th>Currency</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {waybillCanInvoice &&
                  waybillCanInvoice.data.map((item) => {
                    return (
                      <tr key={item.id} className="border-container mb-3">
                        <td className="first-td">
                          <input
                            className=""
                            onChange={(e: any) => {
                              handleAdd(e.target.checked, item.id);
                            }}
                            type="checkbox"
                            // checked={add.includes(item.id)}
                            id={item.id}
                          />
                        </td>
                        <td>{item.loading_date}</td>
                        <td>
                          {item.truck_number}- {item.delivery_number}
                        </td>
                        <td>{item.currency_description}</td>
                        <td className="last-td">{item.amount ? formatCurrency(item.amount) : null}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>

            {isLoadingCan && (
              <div className="justify-content-center w-100 d-flex pb-5 pt-5">
                <div className="spinner-border spinner-border-primary spinner-border-lg justify-content-center mr-2"></div>
              </div>
            )}
            {waybillCanInvoice && waybillCanInvoice.data && waybillCanInvoice.data.length === 0 && !isLoadingCan && (
              <div className="d-flex justify-content-center py-5">
                <h2 style={{ fontSize: "1.3rem" }}>Not found</h2>
              </div>
            )}

            {waybillCanInvoice?.count > 10 ? (
              <Pagination
                page={page}
                lastPage={waybillCanInvoice?.pagination?.lastPage}
                paginate={paginate}
                total={waybillCanInvoice?.pagination?.total}
                handlePageChange={handlePageChange}
                perPage={perPage}
              />
            ) : null}

            <Button loading={isCreate} label="Generate Invoice" btnType="btn-primary" boxClasses="mt-4 mb-2" xtraClass="btm-btn" btnStyle="fit-content" />
          </form>
          <p className=" btm-btn" onClick={closeModal}>
            Cancel
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default CreateNonPO2;

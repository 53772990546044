import StatsCard from "components/StatsCard";
import Icon from "assests/icons/statsCard/Vector (4).png";
import greenCheck from "assests/icons/buyer/green-check.svg";
import arrowdown from "assests/icons/Vector (2).svg";
import membersGreen from "assests/icons/buyer/members-green.svg";
import membersBlue from "assests/icons/buyer/members-blue.svg";
import purpleClock from "assests/icons/buyer/purple-clock1.svg";
import AddIcon from "assests/icons/buyer/add-icon.svg";
import { AppDispatch, RootState } from "store/store";
import mail1 from "assests/icons/buyer/mail-1.svg";
import SearchBar from "../../../../components/SearchBar";
import Dropdown from "components/Dropdown";
import DataTable from "components/DataTable";
import { tableCol, tableData } from "./data";
import exportIcon from "assests/icons/buyer/export-21.svg";
import "./styles.scss";
import React, { useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import DropdownMenu from "components/DropdownMenu";
import { useDispatch, useSelector } from "react-redux";

import useSearch from "hooks/useSearch";
import { useEffect } from "react";
import SearchIcon from "../../../../components/SearchBar";
import { getChart, getEachRfp, getOneRfp } from "store/slice/BuyerSlice/EvaluationSlice/EvaluationSlice";
import Pagination from "components/Pagination";
import SelectDropdown from "components/SelectDropdown";

import * as chartjs from "chart.js";
import { Bar } from "react-chartjs-2";
import CreateDoc from "components/ModalBuyer/CreateDoc";
// import 'chartjs-plugin-annotation';

const Evaluation_3 = () => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  // const { idEach } = useParams<{ idEach?: any }>();
  const [companyList, setCompanyList] = useState<string[]>([]);
  const [seen, setSeen] = useState(true);
  const [companyScore, setCompanyScore] = useState<number[]>([]);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const idEach = urlParams.get("id");
  const [modal, setModal] = useState<boolean>(false);

  const closeModal = () => {
    setModal((prev) => !prev);
  };

  const { eachRfp, eachLoading, oneRfp, oneLoading, chartTechnical } = useSelector((state: RootState) => state.rfpData);

  useEffect(() => {
    const filt =
      chartTechnical &&
      chartTechnical?.data
        ?.filter((val, i) => i <= 5)
        ?.filter((item) => {
          return item.rfp_vendor_status !== "-2" && item.rfp_vendor_status !== "3";
        });

    if (filt) {
      if (seen) {
        const testArray: string[] = [];
        const testScore: number[] = [];
        filt.forEach((item) => testArray.push(item.vendor_description.split(" ")[0]));
        filt.forEach((item) => testScore.push(item.total_vendor_evaluated_score));
        setCompanyList([...companyList, ...testArray]);
        setCompanyScore([...companyScore, ...testScore]);
        setSeen(false);
      }
    }
  }, [chartTechnical]);

  const options = [
    {
      value: "technical",
      optionName: "Technical Requirements"
    },
    {
      value: "commercial",
      optionName: "Commercial Requirements"
    }
  ];

  //Redux
  const dispatch = useDispatch<AppDispatch>();

  const { search, inputRef, handleChange, searched } = useSearch(getEachRfp, perPage, idEach);

  //Show Number for PerPage Request
  const handleNewPage = (pageNo: number, page: number = 1) => {
    setPerPage(pageNo);
    setPage(page);
    dispatch(getEachRfp({ page, length: pageNo, id: idEach, category: "Technical" }));
  };
  const history = useHistory();

  // chart data

  //Pagination
  //---------------------------------------------
  const handlePageChange = React.useCallback((page: number, perPage: number) => {
    setPage(page);
    dispatch(getEachRfp({ page, length: perPage, id: idEach, category: "Technical" }));
  }, []);

  const paginate = React.useCallback((pageNo: number, perPage: number) => {
    setPage(pageNo);
    dispatch(getEachRfp({ page, length: perPage, id: idEach, category: "Technical" }));
  }, []);

  // barchart data
  const TechnicalCapacityData = {
    labels: companyList && companyList,
    datasets: [
      {
        label: "Technical Evaluation",
        backgroundColor: "#A6CEE3",
        data: companyScore && companyScore
      }
    ]
  };

  //   setpage to the first page when a searched executed
  useEffect(() => {
    if (searched) setPage(1);
  }, [searched]);

  useEffect(() => {
    dispatch(getEachRfp({ page, length: perPage, id: idEach, category: "Technical" }));
    dispatch(getOneRfp({ id: idEach }));
    dispatch(getChart({ id: idEach }));
  }, []);

  const review = (e, item) => {
    history.push(
      `/buyer/rfx-evaluation/evaluation-4/?id=${item.rfp_id}&vendor_id=${item.vendor_id}&vendor_name=${item.vendor_name}&category=Technical&status=${
        oneRfp?.data?.[0] && oneRfp?.data?.[0]?.rfp_status
      }`
    );
  };

  const rfpList =
    eachRfp &&
    eachRfp?.data?.map((item) => {
      return {
        // review: () => review(item.rfp_id, item.vendor_id),
        rfp_id: item.rfp_id,
        vendor_id: item.vendor_id,
        date: item.created_at,
        rfp_vendor_status: item.rfp_vendor_status,
        quote_id: item.submission_id && `QT - ${item.submission_id.id}`,
        vendor_name: item.vendor_description,
        category: "Technical"
      };
    });

  return (
    <div id="evaluation-3">
      {modal && <CreateDoc rfp_id={idEach} closeModal={closeModal} />}

      <div className="container">
        <h3 className="header">
          Sourcing Project: {oneRfp?.data?.[0] && oneRfp?.data?.[0]?.requisition_description} TENDER FOR {oneRfp?.data?.[0] && oneRfp?.data?.[0]?.sourcing_team_description}
        </h3>
        <div className="stats-card mt-4 row">
          <StatsCard text="Invited Vendors" classes="stats-icon-blue" image={membersBlue} statsNumber={oneRfp?.data?.[0] && oneRfp?.data?.[0]?.summary?.total_invited_vendors} />
          <StatsCard text="Submitted Quotes" classes="stats-icon-orange" image={mail1} statsNumber={oneRfp?.data?.[0] && oneRfp?.data?.[0]?.summary?.total_responses} />
          {/* <StatsCard text="Selection Duration" classes="stats-icon-purple" image={purpleClock} statsNumber={oneRfp?.data?.[0] && oneRfp?.data?.[0]?.summary?.total_invited_vendors} />
           */}
          <StatsCard text="Qualified Vendors" classes="stats-icon-green" image={membersGreen} statsNumber={oneRfp?.data?.[0] && oneRfp?.data?.[0]?.summary?.total_qualified_vendors} />
          <StatsCard image={AddIcon} classes="middle-img" centerText="Upload Document" onClick={closeModal} />
        </div>
        <div className="charts">
          <div className="chart">
            <Bar
              data={TechnicalCapacityData}
              width={100}
              height={50}
              options={{
                title: {
                  display: true,
                  text: "Technical Capacity",
                  fontSize: 40
                },
                maintainAspectRatio: false,
                legend: {
                  display: true,
                  position: "right"
                },
                plugins: {
                  annotation: {
                    annotations: [
                      {
                        type: "line",
                        mode: "horizontal",
                        // scaleID: 'y-axis-0',
                        value: 5,
                        borderColor: "tomato",
                        borderWidth: 1
                      }
                    ],
                    drawTime: "afterDraw" // (default)
                  }
                }
              }}
            />
          </div>
          <div className="chart">
            {/* <Bar
              data={CoomercialCapacityData}
              width={100}
              height={50}
              options={{
                title: {
                  display: true,
                  text: "Technical Capacity",
                  fontSize: 20
                },
                maintainAspectRatio: false,
                legend: {
                  display: true,
                  position: "right"
                }
              }}
            /> */}
          </div>
        </div>
        <div className="evalaution mt-5">
          <h3 className="title font-weight-bold">Supplier Quotes</h3>
          <div className="search-field mt-3">
            <SearchIcon placeholder="Search" boxClasses="" textRef={inputRef} value={search} onChange={handleChange} />
            <Dropdown perPage={10} action={(num) => setPerPage(num)} />
          </div>
          <div className="px-3 d-flex row mt-4 justify-content-between">
            {/* <DropdownMenu options={options}>
                                <span>Technical Requirements</span> <img src={arrowdown} alt="alt" className="pl-3" />
                            </DropdownMenu> */}

            <SelectDropdown
              additionalClass="additionalClass"
              name="Category"
              label="Category"
              placeholder="Category"
              options={options}
              onChange={(e) => {
                if (e.target.value === "commercial") {
                  history.push(`/buyer/rfx-evaluation/evaluation/commercial?id=${idEach}&rfpType=${"rfp"}`);
                } else {
                  history.push(`/buyer/rfx-evaluation/evaluation/technical?id=${idEach}&rfpType=${"rfp"}`);
                }
              }}
            />

            <div className="upload">
              <button type="button" id="upload" onClick={() => {}}>
                <img src={exportIcon} alt="alt" className="pr-3" /> <Link to="./buyer/invoice/:id">View Document</Link>
              </button>
            </div>
          </div>

          <div className="mt-3">
            <DataTable columns={tableCol} data={rfpList} loading={eachLoading} onClick={review} />
          </div>
          {eachRfp?.count > 10 ? (
            <Pagination page={page} lastPage={eachRfp?.pagination?.lastPage} paginate={paginate} total={eachRfp?.pagination?.total} handlePageChange={handlePageChange} perPage={perPage} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Evaluation_3;

/* eslint-disable react-hooks/exhaustive-deps */
import StatsCard from "components/StatsCard";
import React, { useEffect, useState } from "react";
import "./styles.scss";
import BusinessTrade from "assests/icons/buyer/business-and-trade.svg";
import AddIcon from "assests/icons/buyer/add-icon.svg";
import SearchIcon from "components/SearchBar";
import Dropdown from "components/Dropdown";
import DataTable from "components/DataTable";
import { useDispatch, useSelector } from "react-redux";
import { companyColumn, companydatas } from "./data";
import CreateOREditLegalEntity from "components/ModalBuyer/CreateOrEditLegalEntity";
import { AppDispatch, RootState } from "store/store";
import { toastPopUp } from "utils/utils";
import useSearch from "hooks/useSearch";
import Pagination from "components/Pagination";
import { checkPermission } from "utils/utils";
import moment from "moment";
import { deleteNotification, getUserNotification, readNotification } from "store/slice/NotificationSlice/NotificationSlice";

const NotificationData = () => {
  //State
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const { role_access } = useSelector((state: RootState) => state.user?.user);

  //Redux
  const dispatch = useDispatch<AppDispatch>();
  const { notifications, loading } = useSelector((state: RootState) => state.userNotify);

  //State
  const [modal, setModal] = useState<boolean>(false);
  const closeModal = () => {
    setModal((prev) => !prev);
  };

  //Hook
  // const { search, inputRef, handleChange, searched } = useSearch(getUserNotification, perPage);

  //Show Number for PerPage Request
  const handleNewPage = (pageNo: number, page: number = 1) => {
    setPerPage(pageNo);
    setPage(page);
    dispatch(getUserNotification(page, pageNo));
  };

  // setpage to the first page when a searched executed
  // useEffect(() => {
  //   if (searched) setPage(1);
  // }, [searched]);

  useEffect(() => {
    dispatch(getUserNotification(page, perPage));
  }, []);

  //Edit CompanyData
  const markRead = (id: string) => {
    const payload = { user_notification_id: id };
    dispatch(
      readNotification(payload, () => {
        getUserNotification(page, perPage);
      })
    );

    dispatch(getUserNotification(page, perPage));
  };

  //Pagination
  //---------------------------------------------
  const handlePageChange = React.useCallback((page: number, perPage: number) => {
    setPage(page);
    dispatch(getUserNotification(page, perPage));
  }, []);

  const paginate = React.useCallback((pageNo: number, perPage: number) => {
    setPage(pageNo);
    dispatch(getUserNotification(pageNo, perPage));
  }, []);
  const deleteCompany = (name: string, id: string) => {
    const payload = { user_notification_id: id };

    toastPopUp(
      () => {
        dispatch(
          deleteNotification(payload, () => {
            dispatch(getUserNotification(page, perPage));
          })
        );
      },
      "Delete",
      "Cancel",
      "Delete this notification",
      name,
      "red"
    );
  };

  const notificationList =
    notifications &&
    notifications?.data?.map((item) => {
      return {
        type: item.notification_type,
        time: moment(item.created_at, "YYYYMMDD").fromNow(),
        content: item.content,
        edit: () => checkPermission(role_access, "SETUP_INDEX") && markRead(item.id),
        deleteNotification: () => checkPermission(role_access, "SETUP_INDEX") && deleteCompany(item.location_description, item.id)
      };
    });

  return (
    <div id="settings-company">
      {/* {modal && <CreateOREditLegalEntity closeModal={closeModal} />} */}

      <div className="container">
        <h3 className="page-title">Notifications</h3>
        <div className="row mt-3 mb-5">
          <StatsCard statsNumber={notifications ? notifications.count : 0} text="Notifications" classes="stats-icon" image={BusinessTrade} iconBackgroundColor="rgba(45, 156, 219, 0.1)" />
        </div>

        <div className="d-flex  mb-3">
          {/* <SearchIcon placeholder="Search" boxClasses="" textRef={inputRef} value={search} onChange={handleChange} /> */}
          <Dropdown perPage={perPage} action={handleNewPage} />
        </div>
        <DataTable columns={companyColumn} data={notificationList} loading={loading} />
        {notificationList?.count > 10 ? (
          <Pagination
            page={page}
            lastPage={notificationList?.pagination?.lastPage}
            paginate={paginate}
            total={notificationList?.pagination?.total}
            handlePageChange={handlePageChange}
            perPage={perPage}
          />
        ) : null}
      </div>
    </div>
  );
};

export default NotificationData;

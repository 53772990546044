import React from "react";
import StatsCard from "components/StatsCard";
import Icon from "assests/icons/statsCard/Vector (4).png";
import greenCheck from "assests/icons/buyer/green-check.svg";
import clock from "assests/icons/buyer/clock.svg";
import bill from "assests/icons/buyer/bill.svg";
import SearchBar from "../../../components/SearchBar";
import Dropdown from "components/Dropdown";
import DataTable from "components/DataTable";
import { tableCol } from "./data";
import CreateInvoice from "components/ModalBuyer/CreateInvoice";
import { format } from "date-fns";
import "./styles.scss";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getInvoices, getInvoiceSummary } from "store/slice/BuyerSlice/Invoice/InvoiceSlice";
import { RootState } from "store/store";
import SearchIcon from "../../../components/SearchBar";
import useSearch from "hooks/useSearch";
import { formatCurrency } from "utils/utils";
import Pagination from "components/Pagination";
import filterIcon from "assests/icons/buyer/filter-icon.svg";
import { useHistory } from "react-router-dom";
import FilterCard from "components/FilterCard";
import { useForm } from "react-hook-form";

const BuyerInvoice = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [pageNum, setPageNumber] = React.useState(10);
  const [modals, setModals] = React.useState(false);
  const [perpage, setPerpage] = React.useState<number>(10);
  const [page, setPage] = React.useState<any>(1);
  const [length, setLength] = React.useState<number>(10);
  const [showFilter, setShowFilter] = React.useState(false);

  const { search, inputRef, handleChange, searched } = useSearch(getInvoices, perpage);
  const { register, handleSubmit, reset } = useForm({
    mode: "onChange",
    defaultValues: {
      date_to: "",
      date_from: "",
      open: ""
      // to: '',
    }
  });

  const { invoices, isLoading, summary } = useSelector((state: RootState) => state.invoice);
  //   console.log(invoices);

  const closeModal = () => {
    setModals(!modals);
  };

  const handleNewPage = React.useCallback(
    (pageNo: number, page = 1) => {
      setPerpage(pageNo);
      setPage(1);
      dispatch(getInvoices(page, page, search));
    },
    [search]
  );

  React.useEffect(() => {
    dispatch(getInvoices());
    dispatch(getInvoiceSummary());
  }, []);

//   React.useEffect(() => {
//     console.log(summary);
//   }, [summary]);

  function getItemStatus(status) {
    if (status === "-2") return "Draft";
    if (status === "-1") return "Pending";
    if (status === "1") return "Approved";
    if (status === "2") return "Paid";
    if (status === "3") return "Parked";
  }

  const invoiceList =
    invoices &&
    invoices.data?.map((item: any) => {
      return {
        ...item,
        invoice_date: format(new Date(item.invoice_due_date), "dd.MM.yyyy"),
        vendor_name: item.vendor_description,
        po_number: item.purchase_order_code,
        sap_invoice: item.invoice_code,
        invoice_number: `INV-${item.id}` || "",
        supp_no: item.supplier_invoice_number || "",
        invoice_amount: formatCurrency(item.total_invoice_amount),
        invoice_status: getItemStatus(item.invoice_status)
      };
    });

  //--------------------------------------------
  //Pagination
  //---------------------------------------------
  const handlePageChange = React.useCallback(
    (page: number, length: number) => {
      setPage(page);
      dispatch(getInvoices(page, length, search));
    },
    [search]
  );

  const paginate = React.useCallback(
    (pageNo: number, length: number) => {
      setPage(pageNo);
      dispatch(getInvoices(pageNo, length, search));
    },
    [search]
  );

  const onSubmit = (vals) => {
    // dispatch(getInvoices(page, length, search, undefined, vals.date_from, vals.date_to, vals.purchase_order_code));
    dispatch(getInvoices(page, perpage, search, undefined, vals.date_from, vals.date_to, vals.purchase_order_code, undefined, undefined, vals.invoice_code, vals.supplier_invoice_number));
  };

  const handleClear = () => {
    dispatch(getInvoices());
    reset();
  };

  return (
    <div id="invoice">
      <div className="container">
        {modals && <CreateInvoice closeModal={closeModal} />}
        <h3 className="header">Invoice Stats</h3>
        <div className="stats-card mt-4 row">
          <StatsCard text="Total Invoices" classes="stats-icon-green" image={greenCheck} statsNumber={summary?.overall?.total_invoices || "0"} />
          <StatsCard text="Paid Invoices" classes="stats-icon-orange" image={clock} statsNumber={summary?.paid?.total_invoices || "0"} />
          <StatsCard text="Unpaid Invoices" classes="stats-icon-red" image={bill} statsNumber={summary?.unpaid?.total_invoices || "0"} />
          <StatsCard image={Icon} classes="middle-img" centerText="Create Invoice" onClick={closeModal} />
        </div>
        <div className="document mt-5">
          <h3 className="title font-weight-bold">Invoice List</h3>
          {showFilter && (
            <FilterCard handleSubmit={handleSubmit(onSubmit)} clear={handleClear}>
              <div className="row">
                <div className="col col-4 mb-3">
                  <label style={{ display: "block" }}>Start Date</label>
                  <input
                    type="date"
                    name="date_from"
                    className="mt-3"
                    placeholder="Start Date"
                    style={{
                      width: "100%",
                      height: "3rem",
                      padding: "5px",
                      borderRadius: "10px",
                      border: "1px solid #dfe2e6"
                    }}
                    ref={register({ required: false })}
                  />
                </div>
                <div className="col col-4 mb-3">
                  <label style={{ display: "block" }}>End Date</label>
                  <input
                    type="date"
                    name="date_to"
                    className="mt-3"
                    placeholder="End Date"
                    style={{
                      width: "100%",
                      height: "3rem",
                      padding: "5px",
                      borderRadius: "10px",
                      border: "1px solid #dfe2e6"
                    }}
                    ref={register({ required: false })}
                  />
                </div>
                <div className="col col-4 mb-3">
                  <label style={{ display: "block" }}>Purchase Order Code</label>
                  <input
                    type="text"
                    name="purchase_order_code"
                    className="mt-3"
                    placeholder="Purchase Order Code"
                    style={{
                      width: "100%",
                      height: "3rem",
                      padding: "5px",
                      borderRadius: "10px",
                      border: "1px solid #dfe2e6"
                    }}
                    ref={register({ required: false })}
                  />
                </div>
                <div className="col col-4 mb-3">
                  <label style={{ display: "block" }}>SAP Invoice</label>
                  <input
                    type="text"
                    name="invoice_code"
                    className="mt-3"
                    placeholder="SAP Invoice"
                    style={{
                      width: "100%",
                      height: "3rem",
                      padding: "5px",
                      borderRadius: "10px",
                      border: "1px solid #dfe2e6"
                    }}
                    ref={register({ required: false })}
                  />
                </div>
                <div className="col col-4 mb-3">
                  <label style={{ display: "block" }}>Supplier Invoice Number</label>
                  <input
                    type="text"
                    name="supplier_invoice_number"
                    className="mt-3"
                    placeholder="Supplier Invoice Number"
                    style={{
                      width: "100%",
                      height: "3rem",
                      padding: "5px",
                      borderRadius: "10px",
                      border: "1px solid #dfe2e6"
                    }}
                    ref={register({ required: false })}
                  />
                </div>
              </div>
            </FilterCard>
          )}
          <div className="search-field mt-3">
            <SearchIcon placeholder="Search for Invoice No." boxClasses="mb-3" textRef={inputRef} value={search} onChange={handleChange} />
            <Dropdown perPage={perpage} action={handleNewPage} />
            <div>
              <button
                style={{
                  padding: "5px 10px",
                  borderRadius: "10px",
                  background: "#FFFFFF",
                  color: "#737A91",
                  border: "1px solid #DFE2E6",
                  fontSize: "18px",
                  marginLeft: "1rem"
                }}
                onClick={() => setShowFilter(!showFilter)}
              >
                <img src={filterIcon} alt="icon" className="icon-image" />
                <span
                  style={{
                    paddingLeft: "1rem"
                  }}
                >
                  {!showFilter ? "Filters" : "Hide Filters"}
                </span>
              </button>
            </div>
          </div>
          <div className="mt-3">
            <DataTable columns={tableCol} data={invoiceList} loading={isLoading} onClick={(e, item) => history.push({ pathname: `/buyer/invoice/${item.id}` })} />
            <Pagination page={page} lastPage={invoices?.pagination?.pageCount} paginate={paginate} total={invoices?.pagination?.total} handlePageChange={handlePageChange} perPage={length} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyerInvoice;

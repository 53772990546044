import React from "react";
import Modal from "components/Modal";
import Textbox from "components/Textbox";
import Button from "components/Button";
import useArrowDropdown from "hooks/useArrowDropdown";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import "./styles.scss";
import AsyncSelect from "react-select/async";
import API from "utils/httpsRequest";
import { format } from "date-fns";
import SelectDropdown from "components/SelectDropdown";
import { createInvoice, createInvoiceNonPO, getInvoices } from "store/slice/BuyerSlice/Invoice/InvoiceSlice";
import { useHistory } from "react-router-dom";
import { getTax } from "store/slice/BuyerSlice/TaxSlice/TaxSlice";

type Props = {
  closeModal: () => void;
  modalText?: boolean;
};

const days = Array.from(Array(30).keys());

const CreateInvoice = ({ closeModal, modalText }: Props) => {
  const [error, setError] = React.useState("");
  const [poVal, setPoVal] = React.useState("");
  const [currency, setCurrency] = React.useState("");
  const [vendor, setVendor] = React.useState("");
  const [selPoVal, setSelPoVal] = React.useState("");
  const [currencyVal, setCurrencyVal] = React.useState("") as any;
  const [vendorVal, setVendorVal] = React.useState("") as any;
  const [dueDate, setDueDate] = React.useState("");
  const [isPO, setIsPO] = React.useState(true);
  const [companyId, setCompanyId] = React.useState("");

  const { register, handleSubmit, errors, setValue, control } = useForm({
    mode: "onChange"
  });

  //Redux Store
  const dispatch = useDispatch();
  let history = useHistory();

  const { user } = useSelector((state: RootState) => state.user);

  const { token } = user;
  const { tax } = useSelector((state: RootState) => state.tax);
  const { isLoading } = useSelector((state: RootState) => state.invoice);

  const { open, selectedOption, handleDropdownChange, handleAction, keyOption, ref } = useArrowDropdown();

  console.log(tax);
  const validatePhoneNo = (value: any) => {
    let values = value.replace(/[^0-9]*/g, "");
    if (value.length > 11 || value !== values) return "Should be a phone number";
  };

  const onFormSubmit = (vals) => {
    // console.log(vals);
    if (isPO) {
      const data = {
        purchase_order_id: selPoVal?.["value"],
        invoice_due_date: dueDate,
        payment_terms: vals.days,
        total_tax_amount: vals.tax,
        supplier_invoice_number: vals.supplier_invoice_number
      };
      // console.log(data, "data 1");
      dispatch(
        createInvoice(data, (link) => {
          console.log(link);
          closeModal();
          dispatch(getInvoices());
          // history.push({pathname:`/buyer/invoice/${link}`})
        })
      );
      return;
    } else {
      const data = {
        service_contract_code: vals.service_contract_code,
        invoice_due_date: dueDate,
        payment_terms: vals.days,
        tax_code: vals.tax_code,
        // total_tax_amount: vals.tax,
        supplier_invoice_number: vals.supplier_invoice_number,
        currency_id: currencyVal?.value,
        vendor_id: vendorVal?.value
      };
      // console.log(data);
      dispatch(
        createInvoiceNonPO(data, (link) => {
          closeModal();
          history.push({ pathname: `/buyer/invoice/${link}` });
          dispatch(getInvoices());
          console.log(link);
        })
      );
    }
  };
  // console.log(currencyVal?.value);
  // console.log(currency);

  const handleInputChangePo = (value) => setPoVal(value);
  const handleInputChangeCurrency = (value) => setCurrency(value);
  const handleInputChangeVendor = (value) => setVendor(value);

  const onChangePo = (value) => setSelPoVal(value);
  const onChangeCurrency = (value) => setCurrencyVal(value);
  const onChangeVendor = (value) => {
    setVendorVal(value);
  };

  const loadPo = () => {
    return API.get(`/purchase_orders/get?search=${poVal}&length=10000`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    }).then((res) => {
      return res.data.data.map((item) => ({
        label: `${item.vendor_description} - ${item.purchase_order_code}`,
        value: item.id
      }));
    });
  };

  const laodCurrency = () => {
    return API.get(`/currencies/get?search=${currency}&length=1000`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    }).then((res) => {
      // console.log(res);
      return res.data.data.map((item) => ({
        label: `${item.currency_symbol} - ${item.currency_description}`,
        value: item.id
      }));
    });
  };

  const laodVendors = () => {
    return API.get(`/vendors/get?search=${vendor}&length=10000`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    }).then((res) => {
      console.log(res.data.data);

      return res.data.data.map((item) => ({
        label: `${item.vendor_description}`,
        value: item.id,
        company_id: item.company_id
      }));
    });
  };

  //Remove error message
  React.useEffect(() => {
    if (keyOption) {
      setError("");
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyOption]);

  React.useEffect(() => {
    if (vendorVal.company_id) dispatch(getTax(undefined, 1000, undefined, vendorVal.company_id));
  }, [vendorVal, dispatch]);

  return (
    <Modal modalText={modalText === true ? "Upload an Invoice" : "Create an Invoice"} onClose={closeModal} modallength={!false ? "modal-length" : ""}>
      <div id="createuser-modal">
        {true && true ? <p className="">{modalText ? "Upload a physical Invoice against a reference GZI Purchase Order" : "Generate an Invoice against a GZI Purchase Order."}</p> : null}
        <form className="mt-2" onSubmit={handleSubmit(onFormSubmit)}>
          <div className="d-flex radio-box mt-4" style={{ columnGap: "2rem" }}>
            <div className="">
              <input type="radio" name="certified" id="yes" className="mr-2" checked={isPO} onChange={() => setIsPO(true)} />
              <label className="radio-box_label" htmlFor="yes">
                P.O Invoice
              </label>
            </div>
            <div className="">
              <input type="radio" name="certified" id="no" className="mr-2" checked={!isPO} onChange={() => setIsPO(false)} />
              <label className="radio-box_label" htmlFor="no">
                Non P.O Invoice
              </label>
            </div>
          </div>
          {isPO ? (
            <Controller
              control={control}
              defaultValue
              name="purchase_order_id"
              render={({ ref }) => (
                <AsyncSelect
                  cacheOption
                  defaultOptions
                  placeholder="Select PO Number"
                  value={selPoVal}
                  className="mt-3"
                  loadOptions={loadPo}
                  onInputChange={handleInputChangePo}
                  onChange={onChangePo}
                />
              )}
            />
          ) : (
            <div>
              <Textbox
                type="text"
                name="service_contract_code"
                label="Contract Reference"
                boxClasses="mt-3"
                placeholder="Contract reference"
                customRef={register({ required: true })}
                // onChange={(e) => setDueDate(e.target.value)}
              />

              <Controller
                control={control}
                defaultValue
                name="currency_id"
                render={({ ref }) => (
                  <AsyncSelect
                    cacheOption
                    defaultOptions
                    placeholder="Select Currency"
                    value={currencyVal}
                    className="mt-4"
                    loadOptions={laodCurrency}
                    onInputChange={handleInputChangeCurrency}
                    onChange={onChangeCurrency}
                  />
                )}
              />

              <Controller
                control={control}
                defaultValue
                name="vendor_id"
                render={({ ref }) => (
                  <AsyncSelect
                    cacheOption
                    defaultOptions
                    placeholder="Select Vendor"
                    value={vendorVal}
                    className="mt-3"
                    loadOptions={laodVendors}
                    onInputChange={handleInputChangeVendor}
                    onChange={onChangeVendor}
                  />
                )}
              />
            </div>
          )}
          <Textbox
            type="number"
            name="supplier_invoice_number"
            label="Supplier Invoice Number"
            boxClasses="mt-3"
            placeholder="Supplier Invoice Number"
            customRef={register({ required: true })}
            // onChange={(e) => setDueDate(e.target.value)}
            error={errors.supplier_invoice_number && "This field is required"}
          />

          <Textbox
            type="date"
            value={dueDate}
            label="Select Due Date"
            boxClasses="mt-3"
            placeholder="Select Due Date"
            onChange={(e) => setDueDate(e.target.value)}
            min={new Date().toISOString().slice(0, 10)}
          />

          <SelectDropdown
            boxClasses="mt-3"
            name="days"
            label="Payment Terms"
            placeholder="Payment Terms"
            options={days.map((item) => ({ optionName: item + 1, value: item + 1 }))}
            customRef={register({ required: true })}
            error={errors.days && "This field is required"}
          />

          {/* <Textbox
            type="number"
            name="tax"
            label="Tax Amount"
            boxClasses="mt-3"
            placeholder="Tax Amount"
            customRef={register({ required: true })}
            // onChange={(e) => setDueDate(e.target.value)}
          /> */}
          {!isPO && (
            // <Textbox
            //   type="number"
            //   name="tax"
            //   label="Tax Amount"
            //   boxClasses="mt-3"
            //   placeholder="Tax Amount"
            //   customRef={register({ required: true })}
            //   // onChange={(e) => setDueDate(e.target.value)}
            // />
            <SelectDropdown
              name="tax_code"
              label="Tax"
              boxClasses="mt-3"
              placeholder="Tax Amount"
              customRef={register({ required: true })}
              options={tax?.data.map((item) => ({ optionName: `${item.tax_code} - ${item.tax_code_description}`, value: item.tax_code }))}
              error={errors.tax_code && "This field is required"}
            />
          )}
          <Button disabled={isLoading} label={isLoading ? "Please wait" : "Create"} btnType="btn-primary" boxClasses="mt-4 mb-3" btnStyle="100%" />
          <p onClick={closeModal} style={{ textAlign: "center", cursor: "pointer" }}>
            Cancel
          </p>
        </form>
      </div>
    </Modal>
  );
};

export default CreateInvoice;

/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Star from "components/Star";
import StatsCard from "components/StatsCard";
import SearchIcon from "components/SearchBar";
import membersBlue from "assests/icons/buyer/members-blue.svg";
import Cash from "assests/icons/hand.svg";
import Invoice from "assests/icons/bill.svg";
import Documents from "assests/icons/documents.svg";
import { Bar, Line } from "react-chartjs-2";
import SearchBar from "../../../components/SearchBar";
import { Link } from "react-router-dom";
import Dropdown from "components/Dropdown";
import DataTable from "components/DataTable";
import Pagination from "components/Pagination";
import useSearch from "hooks/useSearch";
import { formatCurrency } from "utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { getDocuments1 } from "store/slice/BuyerSlice/DocumentSlice/DocumentSlice";
import { RootState } from "store/store";
import { tableCol } from "./data";
import { fetchByVendor, fetchMktCatTrend, fetchMktCatTrendByMat, fetchPoSummary, fetchPoTrend, fetchVendorSummary } from "store/slice/SourcingSlice/SourcingSlice";
import styled from "styled-components";
import SelectDropdown from "components/SelectDropdown";
import { format } from "date-fns";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { getInvoiceSummary } from "store/slice/BuyerSlice/Invoice/InvoiceSlice";
import AsyncSelect from "react-select/async";
import filterIcon from "assests/icons/buyer/filter-icon.svg";
import API from "utils/httpsRequest";
import FilterCard from "components/FilterCard";
import { useForm, Controller } from "react-hook-form";
import { getUserNotification } from "store/slice/NotificationSlice/NotificationSlice";

const Box = styled.div`
  border: 1px solid #e8e8e8;
  padding: 1rem;
  margin-top: 3.5rem;
`;

const FilterContainer = styled.div`
  .mySelect__control {
    border-radius: 10px;
    border: 1px solid rgb(223, 226, 230) !important;
  }

  .mySelect__value-container {
    height: 3rem;
  }
`;

declare global {
  interface Window {
    OneSignal: any;
  }
}

const BuyerDashboard = () => {
  const dispatch = useDispatch();

  const [pageNum, setPageNumber] = React.useState(10);
  const [page, setPage] = React.useState<number>(1);
  const [length, setlength] = React.useState<number>(10);
  const [chartFilter, setChartFilter] = React.useState("");
  const [showReset, setShowReset] = React.useState(false);
  const [showLevel, setShowLevel] = React.useState("");
  const [label, setLabel] = React.useState("");
  const [subLabel, setSubLabel] = React.useState("");
  const [showFilter, setShowFilter] = React.useState(false);
  const [deptVal, setDeptVal] = React.useState("");
  const [selDeptVal, setSelDeptVal] = React.useState<any>("");
  const [userVal, setUserVal] = React.useState("");
  const [selUserVal, setSelUserVal] = React.useState<any>("");

  const history = useHistory();

  const { search, inputRef, handleChange } = useSearch(getDocuments1, length);

  const { summary } = useSelector((state: RootState) => state.invoice);
  const { documents, isLoading } = useSelector((state: RootState) => state.document);
  const { po_summary, po_trend, vendor_summary, mkCatTrend } = useSelector((state: RootState) => state.sourcing);
  const { user } = useSelector((state: RootState) => state.user);
  const { token } = user;

  // console.log(user, "user");

  const { register, handleSubmit, reset, control } = useForm({
    mode: "onChange"
    // defaultValues: {
    //   date_from: '',
    //   date_to: '',
    //   from: '',
    //   to: '',
    // }
  });

  const onSubmit = (vals) => {
    const data = {
      ...vals,
      department_id: selDeptVal.value || "",
      vendor_id: selUserVal.value || ""
    };
    console.log(data);
    dispatch(getDocuments1(page, length, search, data.date_from, data.date_to, data.vendor_id, data.department_id));
  };

  const handleClear = () => {
    dispatch(getDocuments1(page, length));
    reset();
    setSelDeptVal("");
    setSelUserVal("");
  };

  const handleInputChangeDept = (value) => setDeptVal(value);

  const onChangeDept = (value) => setSelDeptVal(value);

  const handleInputChangeUsers = (value) => setUserVal(value);

  const onChangeUsers = (value) => setSelUserVal(value);

  const loadDept = () => {
    return API.get(`/departments/get?page=1&length=10&search=${deptVal}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    }).then((res) => {
      return res.data.data.map((item) => ({
        label: `${item.department_description} - ${item.department_code}`,
        value: item.id
      }));
    });
  };

  const loadUsers = () => {
    return API.get(`/vendors/get?page=1&length=10&search=${userVal}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    }).then((res) => {
      return res.data.data.map((item) => ({
        label: item.company_description,
        value: item.id
      }));
    });
  };

  const getData = () => {
    let arr: any[] = [];
    po_trend?.forEach((item) => {
      arr.push(item?.total_purchase_order_value || 0);
    });
    return arr;
  };

  const getLabels = () => {
    let arr: any[] = [];
    po_trend?.forEach((item) => {
      if (chartFilter === "year") arr.push(format(new Date(item?.po_trend_date), "yyyy"));
      else if (chartFilter === "month") arr.push(format(new Date(item?.po_trend_date), "MMM yyyy"));
      else if (chartFilter === "week") arr.push(`Wk ${item.purchase_order_date}`);
      else arr.push(format(new Date(item?.po_trend_date), "do MMM yyyy"));
    });
    return arr;
  };

  function getMkLabels() {
    let arr: any[] = [];
    if (mkCatTrend !== null) {
      const _mkCatTrend = [...mkCatTrend];
      let sortTrend = _mkCatTrend?.sort((x, y) => {
        return y.total_purchase_order_value - x.total_purchase_order_value;
      });
      sortTrend?.forEach((item) => {
        if (showReset) arr.push(item?.vendor_description);
        else arr.push(item?.material_category_description);
      });
    }
    return arr;
  }

  function getMkData() {
    let arr: any[] = [];
    if (mkCatTrend !== null) {
      const _mkCatTrend = [...mkCatTrend];
      let sortTrend = _mkCatTrend?.sort((x, y) => {
        return y.total_purchase_order_value - x.total_purchase_order_value;
      });
      // console.log(sortTrend);
      sortTrend?.forEach((item) => {
        arr.push(item?.total_purchase_order_value);
      });
    }
    return arr;
  }

  const TechnicalCapacityData = {
    labels: getMkLabels(),
    datasets: [
      {
        // label: "Technical Evaluation",
        backgroundColor: "#A6CEE3",
        data: getMkData()
      }
    ]
  };

  const CoomercialCapacityData = {
    labels: getLabels().reverse(),
    datasets: [
      {
        backgroundColor: "rgba(0, 98, 255, 0.1)",
        data: getData().reverse(),
        borderColor: "#0062FF",
        fill: true
      }
    ]
  };

  const handlePageChange = React.useCallback(
    (page: number, length: number) => {
      setPage(page);
      dispatch(getDocuments1(page, length, search));
    },
    [search]
  );

  const paginate = React.useCallback(
    (pageNo: number, length: number) => {
      setPage(pageNo);
      dispatch(getDocuments1(pageNo, length));
    },
    [search]
  );

  const showPos = (e, item) => {
    history.push({ pathname: `/buyer/purchase-orders/${item?.id}` });
  };

  const tableData =
    documents &&
    documents?.data?.map((data: any) => {
      return {
        ...data,
        date: data?.purchase_order_date,
        document_no: data?.purchase_order_code,
        supplier_id: data?.vendor_id,
        supplier_name: data?.vendor_description,
        company_description: data?.company_description,
        amount: formatCurrency(data?.total_value),
        currency: data?.currency_description,
        // status,
        id: data?.id
      };
    });

  React.useEffect(() => {
    dispatch(getDocuments1(page, length));
    dispatch(fetchPoSummary());
    dispatch(fetchMktCatTrend());
    dispatch(fetchVendorSummary());
    dispatch(getInvoiceSummary());
  }, []);

  //   React.useEffect(() => {
  //     /**
  //      * YOUR ONE SIGNAL CONFIGURATION GOES HERE
  //      */
  //     OneSignal.push(function () {
  //       OneSignal.isPushNotificationsEnabled(function (isEnabled) {
  //         if (isEnabled) {
  //           console.log(isEnabled, "isEnabled");
  //           OneSignal.getUserId().then(function (userId) {
  //             console.log("OneSignal User ID:", userId);
  //             axios.post(
  //               "https://api.yaraa.stakle.com/users/create_push_subscription",
  //               { fcm_id: userId },
  //               {
  //                 headers: {
  //                   Authorization: `Bearer ${user?.token}`
  //                 }
  //               }
  //             );
  //             // (Output) OneSignal User ID: 270a35cd-4dda-4b3f-b04e-41d7463a2316
  //             OneSignal.getExternalUserId().then(function (externalUserId) {
  //               console.log("externalUserId: ", externalUserId);
  //               if (externalUserId === null) {
  //                 let externalUserId = user?.email; // You will supply the external user id to the OneSignal SDK

  //                 OneSignal.push(function () {
  //                   OneSignal.setExternalUserId(externalUserId);
  //                 });
  //               }
  //             });
  //           });
  //         }
  //       });
  //     });
  //   }, [user, OneSignal]);

  window.OneSignal = window.OneSignal || [];
  const OneSignal = window.OneSignal;
  const [granted, setGranted] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    setLoaded(true);
    OneSignal.push([
      "init",
      {
        appId: "99a20fc9-e619-4f62-a8aa-369f79ced2b1"
        // Your other init settings
      }
    ]);
  }, []);
  React.useEffect(() => {
    /**
     * YOUR ONE SIGNAL CONFIGURATION GOES HERE
     */
    if (loaded) {
      OneSignal.push(function () {
        OneSignal.isPushNotificationsEnabled(function (isEnabled) {
          console.log(isEnabled);
          // if (isEnabled) {
          //   setGranted(true);
          //   OneSignal.getUserId().then(function (userId) {
          //     console.log("OneSignal User ID:", userId);
          //     // (Output) OneSignal User ID: 270a35cd-4dda-4b3f-b04e-41d7463a2316
          //   });
          // }

          if (isEnabled) {
            setGranted(true);
            OneSignal.getUserId().then(function (userId) {
              console.log("OneSignal User ID:", userId);
              axios.post(
                "https://api.yaraa.stakle.com/users/create_push_subscription",
                { fcm_id: userId },
                {
                  headers: {
                    Authorization: `Bearer ${user?.token}`
                  }
                }
              );
              // (Output) OneSignal User ID: 270a35cd-4dda-4b3f-b04e-41d7463a2316
            });

            OneSignal.getExternalUserId().then(function (externalUserId) {
              console.log("externalUserId: ", externalUserId);
              if (externalUserId === null) {
                let externalUserId = user?.email; // You will supply the external user id to the OneSignal SDK

                OneSignal.push(function () {
                  console.log("setExternal");
                  OneSignal.setExternalUserId(externalUserId);
                });
              }
            });
          }
        });
        OneSignal.push(function () {
          OneSignal.on("notificationDisplay", function (event) {
            console.log("event");
            console.log(event);
            // Call a method on my functional component
            dispatch(getUserNotification(1, 10));
          });

          //This event can be listened to via the `on()` or `once()` listener
        });
      });
    }
  }, [loaded, OneSignal, user]);
  // console.log(granted, "granted");

  React.useEffect(() => {
    if (chartFilter.length === 0) dispatch(fetchPoTrend());
    else dispatch(fetchPoTrend(chartFilter));
  }, [chartFilter]);

  const getElementAtEvent = (element) => {
    if (!element.length) return;

    const { datasetIndex, index } = element[0];
    console.log(TechnicalCapacityData.labels[index]);
    if (showLevel === "") {
      setShowLevel("1");
      setLabel(TechnicalCapacityData.labels[index]);
      // return;
    }
    if (showLevel === "1") {
      setSubLabel(TechnicalCapacityData.labels[index]);
      // return;
    }
    mkCatTrend.forEach((item) => {
      if (item.material_category_description === TechnicalCapacityData.labels[index]) {
        // console.log(item);
        dispatch(fetchByVendor(item.material_category_id));
        setShowReset(true);
      }
    });
  };

  return (
    <div id="dashboard-page">
      <div className="container">
        <div className="quick-stats mb-5">
          <div className="row mt-2">
            <StatsCard statsNumber={vendor_summary?.total_approved_vendors || 0} text="Total Suppliers" classes="stats-icon-orange" image={membersBlue} />

            <StatsCard statsNumber={po_summary?.ytd?.total_purchase_order_value || 0} text="Total Spend(YTD)" classes="stats-icon-orange" image={Cash} />

            <StatsCard statsNumber={po_summary?.open?.total_purchase_orders || 0} text="Open PO's" classes="stats-icon-orange" image={Documents} />

            <StatsCard statsNumber={summary?.overall?.total_invoices || 0} text="Unpaid Invoices" classes="stats-icon-cash" image={Invoice} />
          </div>
        </div>

        <div className="charts row">
          <div className="chart col col-6">
            <h5 style={{ color: "#0062FF", letterSpacing: "0.4px", fontSize: "18px", display: "inline-block" }}>Spend Analysis/Time</h5>
            <div
              style={{
                display: "inline",
                float: "right",
                minWidth: "10rem"
              }}
            >
              <SelectDropdown
                name="requisition_type"
                label="Select Date Filter"
                placeholder="Select Date Filter"
                options={[
                  { optionName: "Day", value: "date" },
                  { optionName: "Week", value: "week" },
                  { optionName: "Month", value: "month" },
                  { optionName: "Year", value: "year" }
                ]}
                value={chartFilter}
                onChange={(e) => setChartFilter(e.target.value)}
              />
            </div>
            <Box>
              <Line
                data={CoomercialCapacityData}
                width={100}
                height={50}
                options={{
                  title: {
                    display: false,
                    text: "Technical Capacity",
                    fontSize: 20
                  },
                  maintainAspectRatio: true,
                  legend: {
                    display: false,
                    position: "right"
                  },
                  plugins: {
                    legend: {
                      display: false
                    }
                  }
                }}
              />
            </Box>
          </div>
          <div className="chart col col-6">
            <h5 style={{ color: "#0062FF", letterSpacing: "0.4px", fontSize: "18px", display: "inline" }}>Spend Analysis/Commodity</h5>

            {showReset && (
              <p
                style={{ float: "right", cursor: "pointer" }}
                onClick={() => {
                  setShowReset(false);
                  dispatch(fetchMktCatTrend());
                  setLabel("");
                  setShowLevel("");
                  setSubLabel("");
                }}
              >
                reset
              </p>
            )}

            {showReset && (
              <>
                <p>Supplier Spend Distribution : {label}</p>
                <p>{subLabel}</p>
              </>
            )}

            <Box>
              <Bar
                data={TechnicalCapacityData}
                width={100}
                height={50}
                getElementAtEvent={getElementAtEvent}
                options={{
                  indexAxis: "y",
                  scales: {
                    yAxes: {
                      ticks: {
                        callback: (context) => `${TechnicalCapacityData?.labels?.[context]?.substring(0, 10)}...`
                      }
                    }
                  },
                  plugins: {
                    legend: {
                      display: false
                    }
                  }
                }}
              />
            </Box>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="document mt-5">
          {showFilter && (
            <FilterContainer>
              <FilterCard handleSubmit={handleSubmit(onSubmit)} clear={handleClear}>
                <div className="row">
                  <div className="col col-4">
                    <label style={{ display: "block" }}>Start Date</label>
                    <input
                      type="date"
                      name="date_from"
                      className="mt-3"
                      placeholder="Start Date"
                      style={{
                        width: "100%",
                        height: "3rem",
                        padding: "5px",
                        borderRadius: "10px",
                        border: "1px solid #dfe2e6"
                      }}
                      ref={register({ required: false })}
                    />
                  </div>
                  <div className="col col-4">
                    <label style={{ display: "block" }}>End Date</label>
                    <input
                      type="date"
                      name="date_to"
                      className="mt-3"
                      placeholder="End Date"
                      style={{
                        width: "100%",
                        height: "3rem",
                        padding: "5px",
                        borderRadius: "10px",
                        border: "1px solid #dfe2e6"
                      }}
                      ref={register({ required: false })}
                    />
                  </div>
                </div>
                <div
                  className="row"
                  style={{
                    marginTop: "2rem"
                  }}
                >
                  <div className="col col-4">
                    <label style={{ display: "block" }}>Department</label>
                    <Controller
                      control={control}
                      defaultValue
                      name="department_id"
                      render={({ ref }) => (
                        <AsyncSelect
                          cacheOption
                          defaultOptions
                          placeholder="Select Department"
                          value={selDeptVal}
                          loadOptions={loadDept}
                          onInputChange={handleInputChangeDept}
                          onChange={onChangeDept}
                          menuPortalTarget={document.body}
                          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                          classNamePrefix="mySelect"
                        />
                      )}
                    />
                  </div>
                  <br />
                  <div className="col col-4">
                    <label style={{ display: "block" }}>Vendor</label>
                    <Controller
                      control={control}
                      defaultValue
                      name="vendor_id"
                      render={({ ref }) => (
                        <AsyncSelect
                          cacheOption
                          defaultOptions
                          placeholder="Select Vendor"
                          value={selUserVal}
                          loadOptions={loadUsers}
                          onInputChange={handleInputChangeUsers}
                          onChange={onChangeUsers}
                          menuPortalTarget={document.body}
                          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                          classNamePrefix="mySelect"
                        />
                      )}
                    />
                  </div>
                </div>
              </FilterCard>
            </FilterContainer>
          )}
          <h3 className="title font-weight-bold" style={{ fontSize: "18px" }}>
            Document Logs
          </h3>
          <div className="search-field mt-3">
            <SearchBar placeholder="Search for Open Documents (PO, PR, INVOICES)" textRef={inputRef} value={search} onChange={handleChange} />
            <Dropdown perPage={10} action={(num) => setPageNumber(num)} />
            <div>
              <button
                style={{
                  padding: "5px 10px",
                  borderRadius: "10px",
                  background: "#FFFFFF",
                  color: "#737A91",
                  border: "1px solid #DFE2E6",
                  fontSize: "18px",
                  marginLeft: "1rem",
                  width: "max-content"
                }}
                onClick={() => setShowFilter(!showFilter)}
              >
                <img src={filterIcon} alt="icon" className="icon-image" />
                <span
                  style={{
                    paddingLeft: "1rem"
                  }}
                >
                  {!showFilter ? "Filters" : "Hide Filters"}
                </span>
              </button>
            </div>
          </div>
          <div className="mt-3">
            <DataTable columns={tableCol} data={tableData} loading={isLoading} onClick={showPos} />
            <Pagination page={page} lastPage={documents?.pagination?.pageCount} paginate={paginate} total={documents?.pagination?.total} handlePageChange={handlePageChange} perPage={length} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyerDashboard;

import { toastr } from "react-redux-toastr";
import axios from "axios";
import { AppThunk, AppDispatch } from "store/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import API from "utils/httpsRequest";
import { RegistrationState, Action, onBoardVendorPayload } from "./interface";
// import { resolve } from "node:dns";

const initialState: RegistrationState = {
  isLoading: false,
  companies: null,
  legal_data_field: null,
  complaince_data_fields: null,
  onboarding_data: null,
  onboarding_draft: null,
  loadingOnboardingCode: false,
  forms: null
};

const registrationSlice = createSlice({
  name: "registration",
  initialState,
  reducers: {
    contactRegistrationLoading: (state) => {
      state.isLoading = true;
    },
    contactRegistrationSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoading = false;
      state.legal_data_field = action.payload.LegalFormData;
      state.complaince_data_fields = action.payload.complainceData;
      state.onboarding_data = action.payload.onboardingData;
      state.forms = action.payload.forms;
      state.loadingOnboardingCode = true;
    },
    updatecontactRegistration: (state, action: PayloadAction<object>) => {
      state.onboarding_data = { ...state.onboarding_data, ...action.payload };
      state.loadingOnboardingCode = false;
    },
    contactRegistrationFail: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
    },
    getCompaniesLoading: (state) => {
      state.isLoading = true;
    },
    getCompaniesSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoading = false;
      state.companies = action.payload;
    },
    getCompaniesFail: (state) => {
      state.isLoading = false;
    },
    submitVendorOnboardingLoading: (state) => {
      state.isLoading = true;
    },
    submitVendorOnboardingSuccess: (state) => {
      state.isLoading = false;
    },
    submitVendorOnboardingFail: (state) => {
      state.isLoading = false;
    },
    saveOnboardingDraftLoading: (state) => {
      state.isLoading = true;
    },
    saveOnboardingDraftSuccess: (state) => {
      state.isLoading = false;
    },
    saveOnboardingDraftFail: (state) => {
      state.isLoading = false;
    },
    getOnboardingDraftLoading: (state) => {
      state.isLoading = true;
    },
    getOnboardingDraftSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoading = false;
      state.onboarding_draft = action.payload;
    },
    getOnboardingDraftFail: (state) => {
      state.isLoading = false;
    },
    uploadingFileLoading: (state) => {
      state.isLoading = true;
    },
    uploadingFileSuccess: (state) => {
      state.isLoading = false;
    },
    uploadingFileFail: (state) => {
      state.isLoading = false;
    }
  }
});

const { actions, reducer } = registrationSlice;

export const {
  contactRegistrationLoading,
  contactRegistrationSuccess,
  contactRegistrationFail,
  getCompaniesLoading,
  getCompaniesSuccess,
  getCompaniesFail,
  submitVendorOnboardingLoading,
  submitVendorOnboardingSuccess,
  submitVendorOnboardingFail,
  updatecontactRegistration,
  saveOnboardingDraftLoading,
  saveOnboardingDraftSuccess,
  saveOnboardingDraftFail,
  getOnboardingDraftLoading,
  getOnboardingDraftSuccess,
  getOnboardingDraftFail,
  uploadingFileLoading,
  uploadingFileSuccess,
  uploadingFileFail
} = actions;

export const addOnboardingCode = (code?: any, cb?: () => void) => (dispatch: AppDispatch) => {
  setTimeout(() => {
    dispatch(updatecontactRegistration(code));
    cb && cb();
  }, 1000);
};

export const contactRegistration =
  (payload: any, history, cb: () => void): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(contactRegistrationLoading());
    try {
      const response = await API.post("/vendors/signup", payload);
      if ([200, 201].includes(response.status)) {
        toastr.success("", response.data.message);
        console.log(response);

        //Getting Out the Dynamic fields JSON
        let legalForms = [] as any;
        let complianceForm = [] as any;
        response.data.data?.vendor_fields.forEach((element: { category: string }) => {
          if (element.category === "Legal Information") {
            legalForms.push(element);
          }
          if (element.category === "Compliance Information") {
            complianceForm.push(element);
          }
        });
        dispatch(
          contactRegistrationSuccess({
            LegalFormData: legalForms,
            complainceData: complianceForm,
            onboardingData: response.data.data,
            forms: response.data.data?.vendor_fields
          })
        );
        history.push({
          pathname: `/supplier/registration/legal-information/${response.data.data?.vendor_id}`,
          // state: { userID: response.data.data }
        });
        cb();
      }
    } catch (err) {
      dispatch(contactRegistrationFail(err.response.data?.message));
      toastr.error(err.response.data?.message);
    }
  };

export const getCompaniesList = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(getCompaniesLoading());
  try {
    const response = await API.get("/companies/list");
    if ([200, 201].includes(response.status)) {
      dispatch(getCompaniesSuccess(response.data.data));
    }
  } catch (error) {
    dispatch(getCompaniesFail());
  }
};

export const saveOnboardingDraft =
  (payload, cb: (code: string) => void): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(saveOnboardingDraftLoading());

    try {
      const response = await API.post("prometheus/save_draft", payload);
      if ([200, 201].includes(response.status)) {
        // toastr.success("", response.data.message);
        dispatch(saveOnboardingDraftSuccess());
        cb && cb(response.data.temp_code);
      }
    } catch (error) {
      toastr.error(error.response.data?.message);
      dispatch(saveOnboardingDraftFail());
    }
  };

export const uploadFile = (payload: object) => (dispatch: AppDispatch) => {
  dispatch(uploadingFileLoading());
  return new Promise((resolve, reject) => {
    API.post("/vendors/onboard_file_uploads", payload)
      .then((response) => {
        dispatch(uploadingFileSuccess());
        toastr.success(response.data?.status);
        // console.log(response);
        return resolve(response.data.s3url);
      })
      .catch((error) => {
        toastr.error(error.response.data?.message);
        dispatch(uploadingFileFail());
        // return reject(error.response.data?.message);
      });
  });
};

export const getOnboardingDraft =
  (temp_code: string, history): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(getOnboardingDraftLoading());

    try {
      const response = await API.get(`/prometheus/get_draft?temp_code=${temp_code}`);
      if ([200, 201].includes(response.status)) {
        dispatch(getOnboardingDraftSuccess(response.data.data));

        //Updating the dynamic fields if the user had selected an entity before saving as draft
        if (response.data.data?.onboarding_data) {
          dispatch(
            contactRegistrationSuccess({
              LegalFormData: response.data.data?.legal_data_field,
              complainceData: response.data.data?.complaince_data_fields,
              onboardingData: response.data.data?.onboarding_data
            })
          );
        }

        //extracting the last saved URL from the response payload to route the
        //user to the last form to continue the onboarding process from where
        //he/she stopped
        history.push({ pathname: response.data?.data?.lastUrl, state: response?.data?.data });
      }
    } catch (error) {
      // toastr.error(error.response.data?.message);
      dispatch(getOnboardingDraftFail());
    }
  };

export const vendorOnBoardSubmission =
  (payload: any, cb: () => void): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(submitVendorOnboardingLoading());
    try {
      const response = await API.post("/vendors/onboard", payload);
      if ([200, 201].includes(response.status)) {
        toastr.success("", response.data.message);
        dispatch(submitVendorOnboardingSuccess());
        cb();
      }
    } catch (error) {
      toastr.error(error.response.data?.message);
      dispatch(submitVendorOnboardingFail());
    }
  };
export default reducer;

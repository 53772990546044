import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getBid } from "store/slice/SupplierSlices/BidSlice/BidsSlice";
import { AppDispatch, RootState } from "store/store";
import "./styles.scss";

const Overview = () => {
  //Redux Store
  const { bid } = useSelector((state: RootState) => state.bids);

  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  //   const { bidNo } = useParams() as any;

  //   React.useEffect(() => {
  //     if (bidNo) {
  //       //   const { state } = location as any;
  //       dispatch(getBid(bidNo));
  //     } else history.push("/supplier/bids");
  //   }, []);

  return (
    <div className="route-transition">
      <div className="bid-overview-page">
        <div className="col-lg-8 col-md-12 pl-0 d-block">
          <p className="my-4">{bid && bid[0]?.rfp_title}</p>
          <p className="my-4">{bid && bid[0]?.rfp_description}</p>
          {/* <p>
            catching fire. Sometimes you need to take a site offline for routine maintenance or upgrades. While a no-downtime update is ideal, it isn’t always possible. The advantage is, with planned
            downtime you can typically schedule it for non-peak hours, when as few people as possible will be effected. But more than ever audiences and co-workers are spread across geographies{" "}
          </p> */}
        </div>
        <div className="d-block">
          Additional Info (if any)
          <hr />
          <div className="d-flex">
            {bid && bid[0]?.document_url && (
              <p>
                {/* Click for more Info:{" "} */}
                <span>
                  <a href={`${bid && bid[0]?.document_url}`} target="_blank" rel="noreferrer" style={{ color: "#3d59b7" }}>
                    {/* {bid && bid[0]?.document_url} */}
                    See more details
                  </a>
                </span>
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;

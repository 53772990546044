import axios from "axios";
import { AppThunk, AppDispatch } from "store/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { approvalState, Action } from "./interface";
import { toastr } from "react-redux-toastr";
import API from "utils/httpsRequest";

const initialState: approvalState = {
  loading: false,
  isLoading:false,
  approvalItems: null,
  successMessage: "",
  errorMessage: "",
  deleting: false
};

const approvalSlice = createSlice({
  name: "approval",
  initialState,
  reducers: {
    getAllApprovalsLoading: (state) => {
      state.loading = true;
    },
    getAllApprovalSuccess: (state, action: PayloadAction<Action>) => {
      state.loading = false;
      state.approvalItems = action.payload;
    },
    getAllApprovalFail: (state) => {
      state.loading = false;
    },
    approveItemLoading: (state) => {
      state.loading = true;
    },
    approveItemSuccess: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.successMessage = action.payload;
    },
    approveItemFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.errorMessage = action.payload;
    },
    rejectItemLoading: (state) => {
      state.loading = true;
    },
    rejectItemSuccess: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.successMessage = action.payload;
    },
    rejectItemFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.errorMessage = action.payload;
    },
    createWorkFlowLoading: (state) => {
      state.isLoading = true;
    },
    createtWorkFlowSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoading = false;
      state.approvalItems = action.payload;
    },
    createWorkFlowFail: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },
    editWorkFlowLoading: (state) => {
      state.isLoading = true;
    },
    editWorkFlowSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoading = false;
      state.approvalItems = action.payload;
    },
    editWorkFlowFail: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },
    deleteWorkFlowLoading: (state) => {
      state.deleting = true;
    },
    deleteWorkFlowSuccess: (state, action: PayloadAction<Action>) => {
      state.deleting = false;
      state.approvalItems = action.payload;
    },
    deleteWorkFlowFail: (state, action: PayloadAction<string>) => {
      state.deleting = false;
      state.errorMessage = action.payload;
    }
  }
});

const { actions, reducer } = approvalSlice;
export const {
  getAllApprovalsLoading,
  getAllApprovalSuccess,
  getAllApprovalFail,
  approveItemFail,
  approveItemLoading,
  approveItemSuccess,
  createWorkFlowLoading,
  createtWorkFlowSuccess,
  createWorkFlowFail,
  editWorkFlowLoading,
  editWorkFlowSuccess,
  editWorkFlowFail,
  deleteWorkFlowLoading,
  deleteWorkFlowSuccess,
  deleteWorkFlowFail,
  rejectItemFail,
  rejectItemLoading,
  rejectItemSuccess
} = actions;

export const getApprovals_two =
  (page?: number, length?: number, search?: string, activity_name?: string, approval_status?: string): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getAllApprovalsLoading());
    try {
      const response = await API.get("approval_workflows/get", {
        params: {
          activity_name,
          length,
          search,
          approval_status,
          page
        },
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getAllApprovalSuccess(response.data));
      }
    } catch (error) {
      dispatch(getAllApprovalFail());
    }
  };
export const createWorkFlow_two =
  (data: any, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(createWorkFlowLoading());
    try {
      const response = await API.post(`/approval_workflows/create`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(createtWorkFlowSuccess(response.data.message));
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error) {
   
     
      if (error.response) {
        dispatch(createWorkFlowFail(error.response.data?.message));
      toastr.error(error.response.data.message);
      }
      else{
        dispatch(createWorkFlowFail("No network connecton"));
        toastr.error("No network connecton");
      }
      
      setTimeout(() => {
        dispatch(createWorkFlowFail(""));
      }, 3000);
    }
  };

export const editWorkFlow_two =
  (data: any, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(editWorkFlowLoading());
    try {
      const response = await API.post(`approval_workflows/edit`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(editWorkFlowSuccess(response.data.message));
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error) {
     
      if (error.response) {
        dispatch(editWorkFlowFail(error.response.data.message));
        toastr.error(error.response.data.message);
      }
      else{
        dispatch(editWorkFlowFail("No network connecton"));
        toastr.error("No network connecton");
      }
      setTimeout(() => {
        dispatch(editWorkFlowFail(""));
      }, 3000);
    }
  };

export const deleteWorkFlow_two =
  (data?: any, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(deleteWorkFlowLoading());
    try {
      const response = await API.post(`approval_workflows/delete`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(deleteWorkFlowSuccess(response.data.message));
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error) {
      dispatch(deleteWorkFlowFail(error.response.data.message));
      toastr.error(error.response.data.message);
      setTimeout(() => {
        dispatch(deleteWorkFlowFail(""));
      }, 3000);
    }
  };

export const getApprovals =
  (page?: number, length?: number, search?: string, activity_name?: string, approval_status?: string): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getAllApprovalsLoading());
    try {
      const response = await API.get("/approval_queues/get", {
        params: {
          activity_name,
          length,
          search,
          approval_status,
          page
        },
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getAllApprovalSuccess(response.data));
      }
    } catch (error) {
      dispatch(getAllApprovalFail());
    }
  };

export const approveItem =
  (data?: object, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(approveItemLoading());
    try {
      // params: {},
      const response = await API.post(`/approval_queues/approve`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(approveItemSuccess(response.data));
        cb && cb();
      }
    } catch (error) {
      dispatch(approveItemFail(error.response.data));
    }
  };

export const rejectItem =
  (data?: object, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(rejectItemLoading());
    try {
      // params: {},
      const response = await API.post(`/approval_queues/reject`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(rejectItemSuccess(response.data));
        cb && cb();
      }
    } catch (error) {
      dispatch(rejectItemFail(error.response.data));
    }
  };

export const createWorkFlow =
  (data: any, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(createWorkFlowLoading());
    try {
      const response = await API.post(`/approval_queues/create`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(createtWorkFlowSuccess(response.data.message));
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error) {
    

      if (error.response) {
        dispatch(createWorkFlowFail(error.response.data?.message));
      toastr.error(error.response.data.message);
      }
      else{
        dispatch(createWorkFlowFail("No network connecton"));
        toastr.error("No network connecton");
      }
      setTimeout(() => {
        dispatch(createWorkFlowFail(""));
      }, 3000);
      // setTimeout(() => {
      //   dispatch(createWorkFlowFail(""));
      // }, 3000);
    }
  };

export const editWorkFlow =
  (data: any, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(editWorkFlowLoading());
    try {
      const response = await API.post(`/approval_queues/edit`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(editWorkFlowSuccess(response.data.message));
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error) {  
      if (error.response) {
        dispatch(editWorkFlowFail(error.response.data.message));
        toastr.error(error.response.data.message);
      }
      else{
        dispatch(editWorkFlowFail(error.response.data.message));
        toastr.error("No network connecton");
      }
      setTimeout(() => {
        dispatch(createWorkFlowFail(""));
      }, 3000);
    }
  };

export const deleteWorkFlow =
  (data?: any, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(deleteWorkFlowLoading());
    try {
      const response = await API.post(`/approval_queues/delete`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(deleteWorkFlowSuccess(response.data.message));
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error) {
      dispatch(deleteWorkFlowFail(error.response.data.message));
      toastr.error(error.response.data.message);
      setTimeout(() => {
        dispatch(deleteWorkFlowFail(""));
      }, 3000);
    }
  };

export default reducer;

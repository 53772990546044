import React from "react";
import Tabs from "components/Tabs";
import Overview from "./Overview";
import CommercialRequirements from "pages/BuyerDashboard/SourcingMain/SourcingGeneral/CommercialRequirements";
import TechnicalRequirements from "./TechnicalRequirements";
import InvitedVendors from "./InvitedVendors";
import { useDispatch, useSelector } from "react-redux";
import { getRfpFields, getSingleRfp, publishRfp } from "store/slice/SourcingSlice/SourcingSlice";
import { RootState } from "store/store";

const SourcingGeneral = ({ match }) => {
  const menus = ["Overview", "Technical Requirements", "Commercial Requirements", "Invited Vendors"];

  const [menu, setMenu] = React.useState(menus[0]);

  const { id } = match.params;

  const dispatch = useDispatch();

  const overViewRef = React.useRef<any>();

  const { rfp, isLoading } = useSelector((state: RootState) => state.sourcing);

  const selectMenu = (name) => {
    setMenu(name);
    if (rfp.rfp_status === '-2') {
      if (menu === 'Overview') {
        saveToDraft();
      }
    } else return;
  };

  const saveToDraft = () => {
    console.log('localItems', JSON.parse(localStorage.getItem('tempData')!))
    if (!overViewRef.current) return;
    overViewRef.current.getAlert();
  }

  const handlePublish = () => {
    const data = { rfp_id: id }
    dispatch(publishRfp(data, () => handleSuc()));
  }

  const handleSuc = () => {
    dispatch(getRfpFields(id));
    dispatch(getSingleRfp(id));
  }

  React.useEffect(() => {
    dispatch(getRfpFields(id));
    dispatch(getSingleRfp(id));
  }, [id])

  return (
    <div id="sourcing-general">
      <div className="container">
        {/* <h4 className="page-title mr-2" style={{ color: '#2f80ed', display: 'inline' }}>
          {rfp?.requisition_description}
        </h4> */}
        <div className="tab-container" style={{ position: 'relative' }}>
          <Tabs menus={menus} onSelect={selectMenu} />
          <div className="tabs-page pb-5">
            <div className="route-transition">
              {menu === "Overview" && <Overview ref={overViewRef} parentCallback id={id} />}
              {menu === "Technical Requirements" && <TechnicalRequirements id={id} />}
              {menu === "Commercial Requirements" && <CommercialRequirements id={id} />}
              {menu === "Invited Vendors" && <InvitedVendors id={id} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SourcingGeneral;

import React, { useState, useLayoutEffect, useRef, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { routes } from "./data";
import "./styles.scss";
import Button from "components/Button";
import CloseMenu from "assests/icons/close-menu.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { showPayFarmerModal } from "store/slice/UISlice/UISlice";
import { useLocation } from "react-router-dom";
import { ReactComponent as Dispatch } from "assests/icons/sidebar/dispatch.svg";
import { ReactComponent as User } from "../../assests/icons/pers2.svg";
import { ReactComponent as People } from "assests/icons/sidebar/people.svg";
import { ReactComponent as Inventory } from "../../assests/icons/docs.svg";
import { ReactComponent as Settings } from "../../assests/icons/catalog3.svg";
import { ReactComponent as Chart } from "assests/icons/sidebar/ios-pie.svg";
import { ReactComponent as Arrow } from "../../assests/icons/auction3.svg";
import { ReactComponent as Contracts } from "assests/icons/sidebar/contracts.svg";
import { ReactComponent as Calculator } from "../../assests/icons/bill5.svg";
import { ReactComponent as Bus } from "../../assests/icons/waybill.svg";
import HelpIcon from "../../assests/icons/icons8-help-24.png";

import GZILogo from "components/GZILogo";
import { checkPermission } from "utils/utils";

export interface SidebarProps {
  menu: boolean;
  handleMenu: () => void;
  setMenu: any;
}

const Sidebar: React.FunctionComponent<SidebarProps> = ({ menu, handleMenu, setMenu }) => {
  const dropdown = useRef<HTMLDivElement | null>(null);
  const { pathname } = useLocation();
  const { role_access } = useSelector((state: RootState) => state.user?.user);

  //Closes the sideBar on route changes on mobile view
  React.useEffect(() => {
    if (menu) {
      setMenu(false);
    }
  }, [pathname]);

  //closes the sidebar
  useEffect(() => {
    const hide = (e: any): void => {
      if (!dropdown!.current?.contains(e.target)) {
        setMenu(false);
      }
    };

    window.addEventListener("mousedown", hide);
    return () => {
      window.removeEventListener("mousedown", hide);
    };
  }, []);

  const dispatch = useDispatch();
  const userPermissions = role_access.map((perm) => perm.permission_name);
  // console.log(role_access);
  // console.log(userPermissions);

  return (
    <div className={`side-bar ${menu ? "show-left" : "hide-left"}`} ref={dropdown}>
      <div className="side-bar-navigation">
        <div className="brand-logo">
          <GZILogo small />
          {/* <img src={CloseMenu} alt="menu" className="close-menu cursor-pointer" onClick={handleMenu} /> */}
        </div>
        <div className="mt-4 mb-2">
          <NavLink activeClassName="active" className="d-flex align-items-center nav-link mb-3" to="/supplier/dashboard">
            <Chart className="icon" />
            <h6 className="ml-3 route-name">Dashboard</h6>
          </NavLink>

          {userPermissions?.includes("PURCHASE_ORDERS_INDEX") && (
            <NavLink activeClassName="active" className="d-flex align-items-center nav-link mb-3" to="/supplier/contracts">
              <Contracts className="icon" />
              <h6 className="ml-3 route-name">Contracts</h6>
            </NavLink>
          )}

          {userPermissions?.includes("RFPS_INDEX") && (
            <NavLink activeClassName="active" className="d-flex align-items-center nav-link mb-3" to="/supplier/bids">
              <Arrow className="icon" />
              <h6 className="ml-3 route-name">Bid(s)</h6>
            </NavLink>
          )}
          <NavLink activeClassName="active" className="d-flex align-items-center nav-link mb-3" to="/supplier/company-info">
            <Inventory className="icon" />
            <h6 className="ml-3 route-name">Company Docs</h6>
          </NavLink>

          {userPermissions?.includes("INVOICE_MANAGEMENT") && (
            <NavLink activeClassName="active" className="d-flex align-items-center nav-link mb-3" to="/supplier/invoice">
              <Calculator className="icon" />
              <h6 className="ml-3 route-name">Invoice</h6>
            </NavLink>
          )}
          <NavLink activeClassName="active" className="d-flex align-items-center nav-link  mb-3" to="/supplier/catalogue">
            <Settings className="icon" />
            <h6 className="ml-3 route-name">Catalogue</h6>
          </NavLink>
          {userPermissions?.includes("USER_ROLES_INDEX") && (
            <NavLink activeClassName="active" className="d-flex align-items-center nav-link mb-3" to="/supplier/user-mgt">
              <User className="icon" />
              <h6 className="ml-3 route-name">User Mgt</h6>
            </NavLink>
          )}
          {userPermissions?.includes("WAYBILL_INDEX") && (
            <NavLink activeClassName="active" className="d-flex align-items-center nav-link mb-3" to="/supplier/waybill">
              <Bus className="icon" />
              <h6 className="ml-3 route-name">Waybill</h6>
            </NavLink>
          )}

          <a className="d-flex align-items-center nav-link" target="_blank" href="http://supplier-docs.gzi.yaraa.io/support/home">
            <img src={HelpIcon} alt="icon" />
            <h6 className="ml-3 route-name">Training Doc</h6>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

import { toastr } from "react-redux-toastr";
import axios from "axios";
import { AppThunk, AppDispatch } from "store/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { SummarySatate, Action } from "./interface";
import API from "utils/httpsRequest";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const initialState: SummarySatate = {
  isLoading: false,
  isLodingCompany: false,
  isLodingCompanyPr: false,
  isLoadingVendor: false,
  isLoadingDept: false,
  isLoadingPrUser: false,
  isLoadingCountry: false,
  isLoadingCategory: false,
  isLoadingExpiry: false,
  isLoadingInvoice: false,
  isLoadingAging: false,
  isLoadingbbb: false,
  isLoadingLevel: false,
  isLoadingPreq: false,
  Preq: null,
  isLoadingPrDept: false,
  PrDept: null,
  summaries: null,
  POSummaries: null,
  companySummary: null,
  deptSummary: null,
  vendorSummary: null,
  prCompanySummary: null,
  prUserSummary: null,
  companyVendor: null,
  countryVendor: null,
  categoryVendor: null,
  expiryVendor: null,
  invoiceSummmary: null,
  agingBucket: null,
  bbbCompany: null,
  levelSummary: null,
  success: "",
  errorMessage: ""
};

const summarySlice = createSlice({
  name: "summary",
  initialState,
  reducers: {
    getPrdeptLoading: (state) => {
      state.isLoadingPrDept = true;
    },
    getPrdeptSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoadingPrDept = false;
      state.PrDept = action.payload;
    },
    getPrdeptFail: (state, action: PayloadAction<string>) => {
      state.isLoadingPrDept = false;
    },
    getPreqLoading: (state) => {
      state.isLoadingPreq = true;
    },
    getPreqSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoadingPreq = false;
      state.Preq = action.payload;
    },
    getPreqFail: (state, action: PayloadAction<string>) => {
      state.isLoadingPreq = false;
    },
    getBbbSummariesLoading: (state) => {
      state.isLoadingbbb = true;
    },
    getBbbSummariesSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoadingbbb = false;
      state.bbbCompany = action.payload;
    },
    getBbbSummariesFail: (state, action: PayloadAction<string>) => {
      state.isLoadingbbb = false;
    },
    getLevelSummariesLoading: (state) => {
      state.isLoadingLevel = true;
    },
    getLevelSummariesSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoadingLevel = false;
      state.levelSummary = action.payload;
    },
    getLevelSummariesFail: (state, action: PayloadAction<string>) => {
      state.isLoadingLevel = false;
    },
    getInvoiceSummariesLoading: (state) => {
      state.isLoadingInvoice = true;
    },
    getInvoiceSummariesSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoadingInvoice = false;
      state.invoiceSummmary = action.payload;
    },
    getInvoiceSummariesFail: (state, action: PayloadAction<string>) => {
      state.isLoadingInvoice = false;
    },
    getAgingSummariesLoading: (state) => {
      state.isLoadingAging = true;
    },
    getAgingSummariesSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoadingAging = false;
      state.agingBucket = action.payload;
    },
    getAgingSummariesFail: (state, action: PayloadAction<string>) => {
      state.isLoadingAging = false;
    },
    getSummariesLoading: (state) => {
      state.isLoading = true;
    },
    getSummariesSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoading = false;
      state.summaries = action.payload;
    },
    getSummariesFail: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
    },
    getVendorSummariesLoading: (state) => {
      state.isLoadingVendor = true;
    },
    getVendorSummariesSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoadingVendor = false;
      state.vendorSummary = action.payload;
    },
    getVendorSummariesFail: (state, action: PayloadAction<string>) => {
      state.isLoadingVendor = false;
    },
    getCompanySummariesLoading: (state) => {
      state.isLodingCompany = true;
    },
    getCompanySummariesSuccess: (state, action: PayloadAction<Action>) => {
      state.isLodingCompany = false;
      state.companySummary = action.payload;
    },
    getCompanySummariesFail: (state, action: PayloadAction<string>) => {
      state.isLodingCompany = false;
    },
    getCompanyPrLoading: (state) => {
      state.isLodingCompanyPr = true;
    },
    getCompanyPrSuccess: (state, action: PayloadAction<Action>) => {
      state.isLodingCompanyPr = false;
      state.prCompanySummary = action.payload;
    },
    getCompanyPrFail: (state, action: PayloadAction<string>) => {
      state.isLodingCompanyPr = false;
    },
    getUserPrLoading: (state) => {
      state.isLoadingPrUser = true;
    },
    getUserPrSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoadingPrUser = false;
      state.prUserSummary = action.payload;
    },
    getUserPrFail: (state, action: PayloadAction<string>) => {
      state.isLoadingPrUser = false;
    },
    getDeptSummariesLoading: (state) => {
      state.isLoadingDept = true;
    },
    getDeptSummariesSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoadingDept = false;
      state.deptSummary = action.payload;
    },
    getDeptSummariesFail: (state, action: PayloadAction<string>) => {
      state.isLoadingDept = false;
    },
    getPOSummariesLoading: (state) => {
      state.isLoading = true;
    },
    getPOSummariesSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoading = false;
      state.POSummaries = action.payload;
    },
    getPOSummariesFail: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
    },
    getCompanyVendorLoading: (state) => {
      // state.isLodingCompany = true;
    },
    getCompanyVendorSuccess: (state, action: PayloadAction<Action>) => {
      // state.isLodingCompany = false;
      state.companyVendor = action.payload;
    },
    getCompanyVendorFail: (state, action: PayloadAction<string>) => {
      // state.isLodingCompany = false;
    },
    getCountryVendorLoading: (state) => {
      state.isLoadingCountry = true;
    },
    getCountryVendorSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoadingCountry = false;
      state.countryVendor = action.payload;
    },
    getCountryVendorFail: (state, action: PayloadAction<string>) => {
      state.isLoadingCountry = false;
    },
    getCategoryVendorLoading: (state) => {
      state.isLoadingCategory = true;
    },
    getCategoryVendorSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoadingCategory = false;
      state.categoryVendor = action.payload;
    },
    getCategoryVendorFail: (state, action: PayloadAction<string>) => {
      state.isLoadingCategory = false;
    },
    getExpiryVendorLoading: (state) => {
      state.isLoadingExpiry = true;
    },
    getExpiryVendorSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoadingExpiry = false;
      state.expiryVendor = action.payload;
    },
    getExpiryVendorFail: (state, action: PayloadAction<string>) => {
      state.isLoadingExpiry = false;
    }
  }
});

const { actions, reducer } = summarySlice;

export const {
  getSummariesFail,
  getSummariesLoading,
  getSummariesSuccess,
  getPOSummariesFail,
  getPOSummariesLoading,
  getPOSummariesSuccess,
  getCompanySummariesFail,
  getCompanySummariesLoading,
  getCompanySummariesSuccess,
  getDeptSummariesFail,
  getDeptSummariesLoading,
  getDeptSummariesSuccess,
  getVendorSummariesFail,
  getVendorSummariesLoading,
  getVendorSummariesSuccess,
  getCompanyPrFail,
  getCompanyPrLoading,
  getCompanyPrSuccess,
  getUserPrFail,
  getUserPrLoading,
  getUserPrSuccess,
  getCompanyVendorFail,
  getCompanyVendorLoading,
  getCompanyVendorSuccess,
  getCategoryVendorFail,
  getCategoryVendorLoading,
  getCategoryVendorSuccess,
  getCountryVendorFail,
  getCountryVendorLoading,
  getCountryVendorSuccess,
  getExpiryVendorFail,
  getExpiryVendorLoading,
  getExpiryVendorSuccess,
  getAgingSummariesFail,
  getAgingSummariesLoading,
  getAgingSummariesSuccess,
  getInvoiceSummariesFail,
  getInvoiceSummariesLoading,
  getInvoiceSummariesSuccess,
  getBbbSummariesFail,
  getBbbSummariesLoading,
  getBbbSummariesSuccess,
  getLevelSummariesFail,
  getLevelSummariesLoading,
  getLevelSummariesSuccess,
  getPreqFail,
  getPreqLoading,
  getPreqSuccess,
  getPrdeptFail,
  getPrdeptLoading,
  getPrdeptSuccess
} = actions;

// company summary

type getCompany = {
  company_id?: any;
  department_id?: any;
  material_category_id?: string;
  location_id?: string;
  date_from?: any;
  date_to?: any;
  length?: number;
  page?: number;
  currency_id?: string;
  purchase?: any;
  vendor?: any;
  purchase_code?: any;
  userId?: any;
  status?: any;
};

export const getCompanySummary =
  ({ company_id, department_id, location_id, material_category_id, date_from, date_to, length, currency_id }: getCompany): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getCompanySummariesLoading());
    try {
      const response = await API.get(`/purchase_orders/summary_by_company`, {
        params: {
          company_id,
          department_id,
          location_id,
          material_category_id,
          date_from,
          date_to,
          length,
          currency_id
        },
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getCompanySummariesSuccess(response.data));
      }
    } catch (error) {
      dispatch(getCompanySummariesFail("Fail"));
    }
  };

export const getDeptSummary =
  ({ company_id, department_id, location_id, material_category_id, date_from, date_to, length, page, currency_id, purchase, vendor }: getCompany): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getDeptSummariesLoading());
    try {
      const response = await API.get(`/purchase_orders/summary_by_department`, {
        params: {
          company_id,
          department_id,
          location_id,
          material_category_id,
          date_from,
          date_to,
          length,
          page,
          currency_id,
          purchase_order_id: purchase,
          vendor_id: vendor
        },
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getDeptSummariesSuccess(response.data));
      }
    } catch (error) {
      dispatch(getDeptSummariesFail("Fail"));
    }
  };

export const getPRCompanySummary =
  ({ company_id, department_id, location_id, material_category_id, date_from, date_to, length, page, userId, status }: getCompany): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getCompanyPrLoading());
    try {
      const response = await API.get(`/requisitions/summary_by_company`, {
        params: {
          company_id,
          department_id,
          location_id,
          material_category_id,
          date_from,
          date_to,
          length,
          page,
          created_by: userId,
          requisition_status: status
        },
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getCompanyPrSuccess(response.data));
      }
    } catch (error) {
      dispatch(getCompanyPrFail("Fail"));
    }
  };

export const getPRUserSummary =
  ({ company_id, department_id, location_id, material_category_id, date_from, date_to, length, page, userId, status }: getCompany): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getUserPrLoading());
    try {
      const response = await API.get(`/requisitions/summary_by_user`, {
        params: {
          company_id,
          department_id,
          location_id,
          material_category_id,
          date_from,
          date_to,
          length,
          page,
          created_by: userId,
          requisition_status: status
        },
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getUserPrSuccess(response.data));
      }
    } catch (error) {
      dispatch(getUserPrFail("Fail"));
    }
  };

export const getPRDeptSummary =
  ({ company_id, department_id, location_id, material_category_id, date_from, date_to, length, page, userId, status }: getCompany): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getPrdeptLoading());
    try {
      const response = await API.get(`/requisitions/summary_by_department`, {
        params: {
          company_id,
          department_id,
          location_id,
          material_category_id,
          date_from,
          date_to,
          length,
          page,
          created_by: userId,
          requisition_status: status
        },
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getPrdeptSuccess(response.data));
      }
    } catch (error) {
      dispatch(getPrdeptFail("Fail"));
    }
  };

export const getPReqSummary =
  ({ department_id, date_from, date_to, length, page, userId, status }: getCompany): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getPreqLoading());
    try {
      const response = await API.get(`/requisitions/get_with_lead_time`, {
        params: {
          department_id,
          date_from,
          date_to,
          length,
          page,
          created_by: userId,
          requisition_status: status
        },
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getPreqSuccess(response.data));
      }
    } catch (error) {
      dispatch(getPreqFail("Fail"));
    }
  };

export const getVendorSummary =
  ({ company_id, department_id, location_id, material_category_id, date_from, date_to, length, page, currency_id, purchase, vendor, purchase_code }: getCompany): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getVendorSummariesLoading());
    try {
      const response = await API.get(`/purchase_orders/get_trend_per_vendor`, {
        params: {
          company_id,
          department_id,
          location_id,
          material_category_id,
          date_from,
          date_to,
          length,
          page,
          currency_id,
          purchase_order_id: purchase,
          vendor_id: vendor,
          purchase_order_code: purchase_code
        },
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getVendorSummariesSuccess(response.data));
      }
    } catch (error) {
      dispatch(getVendorSummariesFail("Fail"));
    }
  };

export const getSummaries =
  (vendor_type?: string, company_id?: string, date_from?: string, date_to?: string, vendor_classification?: string): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getSummariesLoading());
    try {
      const response = await axios.get(`${BASE_URL}/vendors/summary`, {
        params: {
          vendor_classification,
          vendor_type,
          company_id,
          date_from,
          date_to
        },
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json"
        }
      });
      if (response.status) {
        dispatch(getSummariesSuccess(response.data.data));
      }
    } catch (error) {
      dispatch(getSummariesFail("Fail"));
    }
  };

export const getPOSummaries =
  (
    vendor_id?: any,
    department_id?: string,
    company_id?: string,
    purchase_order_id?: string,
    date_from?: string,
    date_to?: string,
    purchase_order_code?: string,
    requisition_id?: string,
    rfp_id?: string
  ): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getPOSummariesLoading());
    try {
      const response = await axios.get(`${BASE_URL}/purchase_orders/summary`, {
        params: {
          company_id,
          date_from,
          date_to,
          department_id,
          purchase_order_code,
          purchase_order_id,
          vendor_id,
          requisition_id,
          rfp_id
        },
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json"
        }
      });
      if (response.status) {
        dispatch(getPOSummariesSuccess(response.data.data));
      }
    } catch (error) {
      dispatch(getPOSummariesFail("Fail"));
    }
  };

export const getPOSummariesParam =
  (
    vendor_id?: string,
    department_id?: string,
    purchase_order_status?: string,
    cb?: (data) => void,
    company_id?: string,
    date_from?: string,
    date_to?: string,
    purchase_order_code?: string,
    purchase_order_id?: string,
    requisition_id?: string,
    rfp_id?: string
  ): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getPOSummariesLoading());
    try {
      const response = await axios.get(`${BASE_URL}/purchase_orders/summary`, {
        params: {
          company_id,
          date_from,
          date_to,
          department_id,
          purchase_order_code,
          purchase_order_id,
          vendor_id,
          requisition_id,
          rfp_id,
          purchase_order_status
        },
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json"
        }
      });
      if (response.status) {
        // dispatch(getPOSummariesSuccess(response.data.data));
        cb && cb(response.data.data);
      }
    } catch (error) {
      // dispatch(getPOSummariesFail("Fail"));
    }
  };

// report vendor

type getVendorCompany = {
  company_id?: string;
  category?: string;
  classification?: string;
  date_from?: any;
  date_to?: any;
  length?: number;
  page?: number;
  data_level?: any;
};

export const getCompanyVendorSummary =
  ({ company_id, category, classification, date_from, date_to, length, page, data_level }: getVendorCompany): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getCompanyVendorLoading());
    try {
      const response = await API.get(`/vendors/summary_by_company`, {
        params: {
          company_id,
          vendor_category: category,
          vendor_classification: classification,
          date_from,
          date_to,
          length,
          page,
          data_level
        },
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getCompanyVendorSuccess(response.data));
      }
    } catch (error) {
      dispatch(getCompanyVendorFail("Fail"));
    }
  };

export const getCountryVendorSummary =
  ({ company_id, category, classification, date_from, date_to, length, page, data_level }: getVendorCompany): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getCountryVendorLoading());
    try {
      const response = await API.get(`/vendors/summary_by_country`, {
        params: {
          company_id,
          vendor_category: category,
          vendor_classification: classification,
          date_from,
          date_to,
          length,
          page,
          data_level
        },
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getCountryVendorSuccess(response.data));
      }
    } catch (error) {
      dispatch(getCountryVendorFail("Fail"));
    }
  };

export const getCategoryVendorSummary =
  ({ company_id, category, classification, date_from, date_to, length, page, data_level }: getVendorCompany): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getCategoryVendorLoading());
    try {
      const response = await API.get(`/vendors/summary_by_category`, {
        params: {
          company_id,
          vendor_category: category,
          vendor_classification: classification,
          date_from,
          date_to,
          length,
          page,
          data_level
        },
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getCategoryVendorSuccess(response.data));
      }
    } catch (error) {
      dispatch(getCategoryVendorFail("Fail"));
    }
  };

export const getExpiryVendorSummary =
  ({ company_id, category, classification, date_from, date_to, length, page, data_level }: getVendorCompany): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getExpiryVendorLoading());
    try {
      const response = await API.get(`/vendors/get_expiring_document_summary?`, {
        params: {
          company_id,
          vendor_category: category,
          vendor_classification: classification,
          date_from,
          date_to,
          length,
          page,
          data_level
        },
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getExpiryVendorSuccess(response.data));
      }
    } catch (error) {
      dispatch(getExpiryVendorFail("Fail"));
    }
  };

// invoice report

type getInvoiceCompany = {
  company_id?: string;
  vendor?: string;
  age?: any;
  invoice?: any;
  status?: string;
  date_from?: any;
  date_to?: any;
  invoice_date_from?: any;
  invoice_date_to?: any;
  length?: number;
  page?: number;
};

export const getInvoiceCompanySummary =
  ({ company_id, vendor, age, invoice, status, date_from, date_to, invoice_date_from, invoice_date_to, length, page }: getInvoiceCompany): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getInvoiceSummariesLoading());
    try {
      const response = await API.get(`/invoices/summary_by_company`, {
        params: {
          company_id,
          vendor_id: vendor,
          age,
          invoice_code: invoice,
          invoice_due_status: status,
          date_from,
          date_to,
          invoice_date_from,
          invoice_date_to,
          length,
          page
        },
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getInvoiceSummariesSuccess(response.data));
      }
    } catch (error) {
      dispatch(getInvoiceSummariesFail("Fail"));
    }
  };

export const getInvoiceAgingSummary =
  ({ company_id, vendor, age, invoice, status, date_from, date_to, length, page }: getInvoiceCompany): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getAgingSummariesLoading());
    try {
      const response = await API.get(`/invoices/aging_summary`, {
        params: {
          company_id,
          vendor_id: vendor,
          age,
          invoice,
          status,
          date_from,
          date_to,
          length,
          page
        },
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getAgingSummariesSuccess(response.data));
      }
    } catch (error) {
      dispatch(getAgingSummariesFail("Fail"));
    }
  };

// bbb Report
type getBbbCompany = {
  company_id?: string;
  vendor?: string;
  level?: any;
  date_from?: any;
  date_to?: any;
  length?: number;
  page?: number;
};

export const getBbbCompanySummary =
  ({ company_id, vendor, level, date_from, date_to, length, page }: getBbbCompany): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getBbbSummariesLoading());
    try {
      const response = await API.get(`/vendors/get_dynamic_summary/34`, {
        params: {
          company_id: 10001,
          vendor_id: vendor,
          data_value: level,
          date_from,
          date_to,
          length,
          page
        },
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getBbbSummariesSuccess(response.data));
      }
    } catch (error) {
      dispatch(getBbbSummariesFail("Fail"));
    }
  };

export const getLevelSummary =
  ({ company_id, vendor, level, date_from, date_to, length, page }: getBbbCompany): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getLevelSummariesLoading());
    try {
      const response = await API.get(`/vendors/get_dynamic_summary/34`, {
        params: {
          company_id: 10001,
          vendor_id: vendor,
          data_value: level,
          date_from,
          date_to,
          length,
          page
        },
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getLevelSummariesSuccess(response.data));
      }
    } catch (error) {
      dispatch(getLevelSummariesFail("Fail"));
    }
  };

export default reducer;

import React, { useEffect, useState } from "react";
import blueCircle from "assests/icons/Ellipse 48.svg";
import lightBluCircle from "assests/icons/Ellipse 49.svg";
import redArrowDown from "assests/icons/Vector (13).svg";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { getSingleVendor } from "store/slice/BuyerSlice/VendorSlice/VendorSlice";
import "./styles.scss";
import DoughnutChart from "components/Charts/DonutChart";
import { useParams } from "react-router-dom";
import { getRequisition } from "store/slice/RequisitionSlice/RequisitionSlice";
import { getInvoice, setCostBudget } from "store/slice/BuyerSlice/Invoice/InvoiceSlice";
import RejectOnboarding from "components/ModalBuyer/RejectOnboarding";
import { approveItem, rejectItem } from "store/slice/BuyerSlice/ApprovalSlice/ApprovalSlice";
import { formatCurrency, toastPopUp } from "utils/utils";
import { useHistory, useLocation } from "react-router-dom";
import SelectDropdown from "components/SelectDropdown";
import { getAllCostElement, getCostElementList, setCostElement } from "store/slice/CostElementSlice/CostElementSlice";
import API from "utils/httpsRequest";
import { Controller, useForm } from "react-hook-form";
import AsyncSelect from "react-select/async";
import { getAllBudgets } from "store/slice/BuyerSlice/Budget/BudgetSlice";

const ApproveInvoice = () => {
  const history = useHistory();
  const location = useLocation();
  const [rejectModal, setRejectModal] = React.useState<boolean>(false);
  const chartData = [
    { value: 1048, name: "Budget" },
    { value: 735, name: "Commitment" }
  ];
  const dispatch = useDispatch<AppDispatch>();
  let { id }: any = useParams();
  const { invoice, isLoading } = useSelector((state: RootState) => state.invoice) as any;
  const { listCostElement, loading } = useSelector((state: RootState) => state.costElement) as any;
  const [invoiceStat, setInvoiceStat] = useState("");
  const data = invoice;
  const { user } = useSelector((state: RootState) => state.user);
  const { token } = user;
  const { register, handleSubmit, errors, setValue, control } = useForm({
    mode: "onChange"
  });

  const [poVal, setPoVal] = React.useState("");
  const [currency, setCurrency] = React.useState("");
  const [vendor, setVendor] = React.useState("");
  const [selPoVal, setSelPoVal] = React.useState("") as any;
  const [currencyVal, setCurrencyVal] = React.useState("") as any;
  const [vendorVal, setVendorVal] = React.useState("") as any;

  useEffect(() => {
    if (data) {
      switch (data?.invoice_status) {
        case "-1":
          setInvoiceStat("Pending Approval");
          break;
        case "-2":
          setInvoiceStat("In Draft");
          break;
        case "2":
          setInvoiceStat("paid");
          break;
        case "1":
          setInvoiceStat("Approved");
          break;
        case "3":
          setInvoiceStat("Parked");
          break;
        default:
          break;
      }
    }
  }, [data]);
  console.log(listCostElement);
  // const reqData = singleRequisition && singleRequisition[0];
  // console.log(reqData)

  useEffect(() => {
    dispatch(getInvoice(id));
    dispatch(getCostElementList());
    // dispatch(getAllBudgets());
  }, []);

  useEffect(() => {
    if (invoice) {
      if (invoice.budget_id !== null) {
        setSelPoVal({ label: invoice?.budget_description, value: invoice?.budget_id });
      }
      if (invoice.cost_center !== null) {
        setCurrencyVal({ label: invoice?.department_description, value: invoice?.cost_center });
      }
    }
  }, [invoice]);
  // console.log(selPoVal);
  const color = ["#A6CEE3", "#1F78B4"];

  const approve = () => {
    toastPopUp(
      () => {
        dispatch(
          approveItem(
            {
              approval_queue_id: location.state,
              note: ""
            },
            () => history.push("/buyer/approvals")
          )
        );
      },
      "Approve",
      "Cancel",
      "Approve Invoice",
      "",
      "#737a91"
    );
  };

  const reject = (vals) => {
    // if (window.confirm("Confirm Approve")) {
    dispatch(
      rejectItem(
        {
          approval_queue_id: location.state,
          note: vals
        },
        () => history.push("/buyer/approvals")
      )
    );
    // }
  };

  const closeReject = () => {
    setRejectModal(false);
  };

  const subTotalGR =
    // invoice?.gr_items.length > 0 &&
    invoice?.gr_items?.reduce((acc, current) => {
      return acc + Number(current.item_invoice_amount);
    }, 0);
  const totalGr =
    // invoice?.gr_items.length > 0 &&
    invoice?.gr_items?.reduce((acc, current) => {
      return acc + Number(current.gr_quantity);
    }, 0);
  const totalGrQty =
    // invoice?.gr_items.length > 0 &&
    invoice?.gr_items?.reduce((acc, current) => {
      return acc + Number(current.purchase_order_quantity);
    }, 0);
  const subTotal =
    // invoice?.gr_items.length > 0 &&
    invoice?.invoice_items?.reduce((acc, current) => {
      return acc + Number(current.item_invoice_amount);
    }, 0);

  const handleInputChangePo = (value) => setPoVal(value);
  const handleInputChangeCurrency = (value) => setCurrency(value);
  const handleInputChangeVendor = (value) => setVendor(value);

  const onChangePo = (value) => {
    setSelPoVal(value);
    dispatch(
      setCostBudget({
        invoice_id: invoice.id,
        budget_id: value.value
      })
    );
  };
  const onChangeCurrency = (value) => {
    setCurrencyVal(value);
    dispatch(
      setCostBudget({
        invoice_id: invoice.id,
        cost_center: value.value
      })
    );
  };
  const onChangeVendor = (value, i) => {
    setVendorVal(value);
    dispatch(
      setCostElement(
        {
          invoice_item_id: i,
          cost_element: value.value
        },
        () => dispatch(getInvoice(id))
      )
    );
  };

  const loadPo = () => {
    return API.get(`/budgets/get?search=${poVal}&length=1000`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    }).then((res) => {
      return res.data.data.map((item) => ({
        label: `${item.budget_description}`,
        value: item.id
      }));
    });
  };

  const laodCurrency = () => {
    return API.get(`/departments/get?search=${currency}&length=1000&company_id=${invoice?.company_id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    }).then((res) => {
      // console.log(res);
      return res.data.data.map((item) => ({
        label: `${item.department_description}`,
        value: item.id
      }));
    });
  };

  const laodVendors = () => {
    return API.get(`/cost_elements/get?search=${vendor}&length=1000&company_id=${invoice?.company_id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    }).then((res) => {
      return res.data.data.map((item) => ({
        label: `${item.cost_element_description}`,
        value: item.cost_element_number
      }));
    });
  };

  return (
    <div className="approve-requisition">
      {/* <h5 className='mb-5 page-title'>{singleRequisition?.requisition_description}</h5>
      <div className="row mb-4">
        <div className="col-md-8 col-lg-8 col-xs-12">
          <DoughnutChart data={data} name="donut-1" color={color} />
        </div>
        <div className="col-md col-lg col-xs-12">
          <p>Budget Details</p>
          <div className="d-flex ">
            <img src={blueCircle} alt="" />
            <p>
              Budget: <span>120000</span>
            </p>
          </div>
          <div className="d-flex ">
            <img src={blueCircle} alt="" />
            <p>
              Budget: <span>120000</span>
            </p>
          </div>
          <div className="d-flex ">
            <img src={blueCircle} alt="" />
            <p>
              Budget: <span>120000</span>
            </p>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-8">
          <div className="row mb-4">
            <div className="col-md col-lg col-xs-12">
              <p className="page-title">Requisition</p>
              <p>{singleRequisition?.requisition_type}</p>
            </div>
            <div className="col-md col-lg col-xs-12">
              <p className="page-title">Expected delivery date</p>
              <p>{singleRequisition?.delivery_date}</p>
            </div>
            <div className="col-md col-lg col-xs-12">
              <p className="page-title">Requester</p>
              <p>{singleRequisition?.delivery_date}</p>
            </div>
          </div>
          <div className="row d-block" style={{ paddingLeft: "inherit" }}>
            <p className="page-title">Desription</p>
            <p>{singleRequisition?.requisition_description}</p>
          </div>
        </div>
        <div className="col-md">
          <textarea rows={4} className="text-area mt-4" placeholder="Enter description" name="note" ref={register({ required: true })} />
          <p>
            To: <span className="page-title">Requisitioner</span>
          </p>
        </div>
      </div> */}

      {rejectModal && <RejectOnboarding closeModal={closeReject} reject={reject} />}
      <div className="d-flex justify-content-between align-items-baseline">
        <h5 className="mb-5 page-title">INV-{data?.id}</h5>
        <div className="d-flex justify-content-center align-items-center mb-3" style={{ columnGap: "2rem" }}>
          <button
            type="button"
            // disabled={checkFiles?.includes("-1")}
            style={{
              padding: "8px 30px",
              background: " #F85A5A",
              borderRadius: "10px",
              color: "white"
            }}
            onClick={() => setRejectModal(true)}
          >
            {isLoading ? <div className="spinner-border spinner-border-primary spinner-border-sm mr-2"></div> : "Reject"}
          </button>
          <button
            type="submit"
            // disabled={checkFiles?.includes("-1") || vendor === ""}
            style={{
              padding: "8px 30px",
              background: "#00B0A5",
              borderRadius: "10px",
              color: "white"
            }}
            onClick={approve}
          >
            {isLoading ? <div className="spinner-border spinner-border-primary spinner-border-sm mr-2"></div> : "Approve"}
          </button>
        </div>
      </div>

      <div className="row">
        <div className="col-md-4 col-lg-4 col-xs-12">
          <div className="mb-3">
            <p className="page-title d-block">Invoice Date</p>
            {/* <p>{data?.requisitions?.location_description}</p> */}
            <p>{data?.invoice_due_date}</p>
          </div>
          <div className="mb-3">
            <p className="page-title">Created On</p>
            <p>{data?.created_at}</p>
            {/* <p>{format(new Date(data?.purchase_order_date), "dd.MM.yyyy")}</p> */}
          </div>
          <div className="mb-3">
            <p className="page-title">Supplier Invoice No.</p>
            <p>{data?.supplier_invoice_number}</p>
          </div>
          <div className="mb-3">
            <p className="page-title">Budget Component</p>
            {/* <p>Supply Chain</p> */}
            {/* <SelectDropdown
              // boxClasses="mt-3"
              name="name"
              label="Buget Component"
              placeholder="Buget Component"
              options={[]}
              // customRef={register({
              //   required: { value: requiredForm, message: "This field is required" }
              // })}
              // error={errors[field.field_name] && errors[field.field_name].message}
            /> */}
            <Controller
              control={control}
              defaultValue={selPoVal}
              name="budget_id"
              render={({ ref }) => (
                <AsyncSelect cacheOption defaultOptions placeholder="Select Budget" value={selPoVal} className="mt-3" loadOptions={loadPo} onInputChange={handleInputChangePo} onChange={onChangePo} />
              )}
            />
          </div>
        </div>
        <div className="col-md-4 col-lg-4 col-xs-12">
          <div className="mb-3">
            <p className="page-title">Requester</p>
            {data?.vendor_description}
            {/* <p>{format(new Date(data?.delivery_date), "dd.MM.yyyy")}</p> */}
          </div>
          <div className="mb-3">
            <p className="page-title">Legal Entity</p>
            <p>{data?.company_description}</p>
            {/* <p>{format(new Date(data?.delivery_date), "dd.MM.yyyy")}</p> */}
          </div>
          <div className="mb-3">
            <p className="page-title d-block">Currency</p>
            {/* <p>{data?.requisitions?.location_description}</p> */}
            <p>{data?.currency_description}</p>
          </div>
          {invoice && invoice && (
            <div className="mb-3">
              <p className="page-title">Select Cost Center</p>
              <Controller
                control={control}
                defaultValue
                name="cost_center"
                render={({ ref }) => (
                  <AsyncSelect
                    cacheOption
                    defaultOptions
                    placeholder="Select Cost Center"
                    value={currencyVal}
                    className="mt-3"
                    loadOptions={laodCurrency}
                    onInputChange={handleInputChangeCurrency}
                    onChange={onChangeCurrency}
                  />
                )}
              />
            </div>
          )}
        </div>
        <div className="col-md-4 col-lg-4 col-xs-12">
          <DoughnutChart data={chartData} color={color} name="" />
        </div>
        <div className="col-md-4 col-lg-4 col-xs-12">
          <div className="mb-3">
            <p className="page-title mb-2">Status</p>
            {/* <p>{data?.requisitions?.location_description}</p> */}
            <span
              style={{
                padding: "8px 16px",
                background: invoiceStat === "Pending" ? "rgba(245, 150, 37, 0.15)" : "rgba(0, 176, 7, 0.15)",
                borderRadius: "10px",
                color: invoiceStat === "Pending" ? "rgba(245, 150, 37, 1)" : "#387C57"
              }}
            >
              {invoiceStat}
            </span>
          </div>
        </div>
        {invoice?.invoice_document_url && (
          <div className="col-md-4 col-lg-4 col-xs-12">
            <div className="mb-3">
              <p className="page-title mb-2">Invoice File</p>
              {/* <p>{data?.requisitions?.location_description}</p> */}
              <a href={`${invoice?.invoice_document_url}`} target="_blank" rel="noreferrer">
                View Invoice File
              </a>
            </div>
          </div>
        )}
      </div>

      <div className="row mb-3">
        <div className="col-md-10">
          <table>
            <thead>
              <tr>
                <th>PO Match</th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {/* {data?.materials?.map((mat, i) => ( */}
              <tr className="border-container mb-3">
                {/* <td className="first-td">Purchase Quantity: {data?.gr_items?.length > 0 ? data?.gr_items[0]?.purchase_order_quantity : "N/A"} </td> */}
                <td className="first-td">Purchase Quantity: {data?.gr_items?.length > 0 ? totalGrQty : "N/A"} </td>
                <td>Delivered Quantity {data?.gr_items?.length > 0 ? totalGr : "N/A"}</td>
                <td>Status: {data?.gr_items?.length > 0 && data?.gr_items[0]?.purchase_order_quantity === totalGr ? "Delivered" : "Pending"}</td>
                <td></td>
                <td></td>
                <td></td>
                <td className="last-td">{/* {formatCurrency(mat?.total_amount)} {mat?.currency_description} */}</td>
              </tr>
              {/* ))} */}
            </tbody>
          </table>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-md-10">
          <table>
            <thead>
              <tr>
                <th>Tax validation</th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {/* {data?.materials?.map((mat, i) => ( */}
              <tr className="border-container mb-3">
                <td className="first-td">Tax Amount: {formatCurrency(data?.total_tax_amount)} </td>
                <td>Expected Amount: {formatCurrency(data?.expected_tax_amount)}</td>
                <td>Status: {data?.expected_tax_amount === data?.total_tax_amount && "Match"} </td>
                <td></td>
                <td></td>
                <td></td>
                <td className="last-td">{/* {formatCurrency(mat?.total_amount)} {mat?.currency_description} */}</td>
              </tr>
              {/* ))} */}
            </tbody>
          </table>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-md-10">
          <table>
            <thead>
              <tr>
                <th>Item</th>
                <th>Mat. No</th>
                <th>Product Description</th>
                <th>Quantity</th>
                <th>UoM</th>
                <th>Net Amount</th>
                {invoice?.gr_items.length < 1 && <th style={{ width: "20%" }}>Cost Element</th>}
              </tr>
            </thead>
            {invoice?.gr_items.length > 0 ? (
              <tbody>
                {data?.gr_items?.map((mat, i) => {
                  // const elementName = listCostElement?.data.find((item) => item.cost_element_number === mat?.cost_element);
                  // let value = "" as any;
                  // if (elementName) {
                  //   value = {
                  //     label: elementName.cost_element_description,
                  //     value: elementName.cost_element_number
                  //   };
                  // }
                  // console.log(value);
                  return (
                    <tr key={i} className="border-container mb-3">
                      <td className="first-td">{i + 1}</td>
                      <td>{mat?.purchase_order_material_id}</td>
                      <td>{mat?.material_description}</td>
                      <td>{mat?.gr_quantity}</td>
                      <td>{mat?.uom_description}</td>
                      {/* <td></td> */}
                      <td className="last-td">
                        {formatCurrency(mat?.gr_amount)}
                        {/* <Controller
                          control={control}
                          defaultValue
                          name="cost_element"
                          render={({ ref }) => (
                            <AsyncSelect
                              cacheOption
                              defaultOptions
                              placeholder="Cost Element"
                              value={value}
                              className=""
                              loadOptions={laodVendors}
                              onInputChange={handleInputChangeVendor}
                              onChange={(e) => onChangeVendor(e, mat?.id)}
                            />
                          )}
                        /> */}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            ) : (
              <tbody>
                {data?.invoice_items?.map((mat, i) => {
                  const elementName = listCostElement?.data.find((item) => item.cost_element_number === mat?.cost_element);
                  let value = "" as any;
                  if (elementName) {
                    value = {
                      label: elementName.cost_element_description,
                      value: elementName.cost_element_number
                    };
                  }
                  // console.log(value);
                  return (
                    <tr key={i} className="border-container mb-3">
                      <td className="first-td">{i + 1}</td>
                      <td>{mat?.purchase_order_material_id}</td>
                      <td>{mat?.line_item_description}</td>
                      <td>{mat?.line_item_quantity}</td>
                      <td>{mat?.uom_description}</td>
                      <td>{formatCurrency(mat?.item_invoice_amount)}</td>
                      <td className="last-td">
                        <Controller
                          control={control}
                          defaultValue
                          name="cost_element"
                          render={({ ref }) => (
                            <AsyncSelect
                              cacheOption
                              defaultOptions
                              placeholder="Cost Element"
                              value={value}
                              className=""
                              loadOptions={laodVendors}
                              onInputChange={handleInputChangeVendor}
                              onChange={(e) => onChangeVendor(e, mat?.id)}
                            />
                          )}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
          {/* <div className="d-flex" style={{ width: "fit-content", float: "right" }}>
            <div className="d-block">
              <p className="page-title">Tax: </p>
              <p className="page-title">Total Amount:</p>
            </div>
            <div className="d-block">
              <p className="page-title">&nbsp;</p>
            </div>
          </div> */}
          <div className="total-tax" style={{ float: "right" }}>
            <p className="tax-total">
              <span>Sub-Total</span>
              <span>Tax</span>
              <span>Total</span>
            </p>

            <p className="prices">
              <span>{formatCurrency(subTotalGR || subTotal)}</span>
              <span>{formatCurrency(Number(invoice?.total_tax_amount))}</span>
              <span>{formatCurrency(Number(invoice?.total_invoice_amount) + Number(invoice?.total_tax_amount))}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApproveInvoice;

import React, { useCallback, useEffect, useState } from "react";
// import RegistrationLayout from "../RegistrationLayout";
import "./styles.scss";
import Textbox from "components/Textbox";
import { useForm } from "react-hook-form";
import Button from "components/Button";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { AppDispatch, RootState } from "store/store";
import { getCompaniesList, saveOnboardingDraft } from "store/slice/SupplierSlices/RegistrationSlice/RegistrationSlice";
import SelectLegalEntity from "components/ModalSupplier/CreateEntity";
import SelectDropdown from "components/SelectDropdown";
import { getAllBusinessCategory } from "store/slice/BusinessCategorySlice/BusinessCategorySlice";
import { getAllCountries } from "store/slice/CountriesSlice/CountriesSlice";
import { getAllStates } from "store/slice/CountryStatesSlice/StateSlice";
import { getAllCities } from "store/slice/CitySlice/CitySlice";
import TempCode from "components/ModalSupplier/TempOnBoardingCode/TempCodeModal";
import ArrowDropdown from "components/ArrowDropdown";
import useArrowDropdown from "hooks/useArrowDropdown";
import axios from "axios";
import API from "utils/httpsRequest";
import { getSingleVendor } from "store/slice/BuyerSlice/VendorSlice/VendorSlice";

type Props = {
  showButtons?: boolean;
};
const ContactInformation: React.FunctionComponent<Props> = ({ showButtons = true }) => {
  //State
  const [modal, setModal] = useState<boolean>(false);
  const [tempCode_modal, setTempCodeModal] = useState<boolean>(false);
  const [tempCode, setTempCode] = useState<undefined | string>();
  const [modalData, setModalData] = useState({});
  const [requiredForm, setRequiredForm] = useState<boolean>(true);
  const [submitBtn, setSubmitBtn] = useState<boolean>(false);
  const [alive, setAlive] = useState<boolean>(false);
  const [filtered, setFiltered] = useState<any>([]);
  const [businessList, setbusinessList] = useState<any>([]);
  const [businessFieldError, setBusinessFieldError] = useState("");
  const [placeholder, setPlaceholder] = useState("Select other Business Categories");
  //Helpers
  const { register, handleSubmit, errors, setValue, setError, watch } = useForm({
    mode: "onBlur"
  });
  const values = watch();

  //Redux Hooks
  const dispatch = useDispatch<AppDispatch>();
  const { onboarding_draft, isLoading } = useSelector((state: RootState) => state.vendorRegistration);
  const { allBusinessCategory } = useSelector((state: RootState) => state.businessCategory);
  const { countries } = useSelector((state: RootState) => state.country);
  const { states } = useSelector((state: RootState) => state.states);
  const { cities } = useSelector((state: RootState) => state.city);
  const { singleVendor } = useSelector((state: RootState) => state.vendorDetails);
  const { user } = useSelector((state: RootState) => state.user);

  const vendorData = singleVendor?.data[0];

  //Hooks
  let history = useHistory();
  const location = useLocation();
  const { ...rest } = useArrowDropdown(businessList, setbusinessList, filtered);
  const data = singleVendor?.data[0];
  const enteredValues = localStorage.getItem("ContactInfo") || "";
  const localVals = (enteredValues && JSON.parse(enteredValues)) || [];
  console.log(localVals);
  // console.log(enteredValues);
  // console.log(JSON.parse(enteredValues));

  useEffect(() => {
    dispatch(getCompaniesList());
    dispatch(getAllBusinessCategory("Parent"));
    dispatch(getAllCountries());
    dispatch(getSingleVendor(user?.vendor_id));

    dispatch(getAllCountries());
    if (location && location.state) {
      const { state } = location as any;
      dispatch(getAllCountries());
      dispatch(getAllStates(undefined, 1000, undefined, state?.countryID, undefined, undefined, state?.state_name));
      dispatch(getAllCities(undefined, 1000, state?.countryID, state?.stateId, undefined, undefined, undefined, state?.city_id));
    }
    // dispatch(getAllStates(undefined, 1000));
    // dispatch(getAllCities(undefined, 1000));
    // if (enteredValues && JSON.parse(enteredValues)) {
    //   console.log('enter')
    //   console.log(JSON.parse(enteredValues))
    //   for (const [key, value] of Object.entries(JSON.parse(enteredValues))) {
    //     setValue(key, value);
    //   }
    // }
  }, []);

  useEffect(() => {
    if (vendorData && enteredValues === "") {
      console.log("here");
      for (const [key, value] of Object.entries(vendorData?.contact_user)) {
        setValue(key, value);
      }
      setValue("country_description", vendorData.country_description);
      setValue("business_category_id", vendorData.business_category_id);
      setValue("street_name", vendorData.street_name);
      setValue("street_number", vendorData.street_number);
      setValue("vendor_description", vendorData.vendor_description);
      setValue("state_name", vendorData.state_name);
      setValue("city_id", vendorData.city_id);
    }
    if (enteredValues && JSON.parse(enteredValues)) {
      console.log("enter");
      console.log(JSON.parse(enteredValues));
      for (const [key, value] of Object.entries(JSON.parse(enteredValues))) {
        setValue(key, value);
      }
    }
  }, [vendorData]);

  useEffect(() => {
    API.get(`/business_categories/get?length=1000&business_category_type=Sub&parent_business_category_id=${values.business_category_id}`)
      .then((response) => {
        const selectedOptions = vendorData?.categories.map((item) => item.business_category_id);
        let options: any[] = response.data.data?.reduce((accumulator, current) => {
          if (values.business_category_id !== current.id && enteredValues && JSON.parse(enteredValues)) {
            const selectedOptions2 = localVals?.business_categories.map((item) => item);
            const payload = {
              id: current.id,
              label: current.business_category_description,
              name: current.business_category_description,
              isChecked: selectedOptions?.includes(current.id) || selectedOptions2?.includes(current.id) ? true : false
            };
            accumulator.push(payload);
          } else if (values.business_category_id !== current.id && localVals.length < 1) {
            const payload = {
              id: current.id,
              label: current.business_category_description,
              name: current.business_category_description,
              isChecked: selectedOptions?.includes(current.id) ? true : false
            };
            accumulator.push(payload);
          }

          return accumulator;
        }, []);

        setFiltered(options);
        setbusinessList(options);
      })
      .catch((error) => {
        console.log(error);
      });
    // .finally(() => {});
  }, [values.business_category_id]);

  // console.log(values.business_category_id);

  // useEffect(() => {
  //   //Getting Bussiness Categories
  //   // const businessCategoryOptions: any[] =
  //   //   allBusinessCategory &&
  //   //   allBusinessCategory.map((_data: { id: string; business_category_description: string }) => {
  //   //     if (values.business_category_id !== _data.id) {
  //   //       return {
  //   //         id: _data.id,
  //   //         label: _data.business_category_description,
  //   //         name: _data.business_category_description,
  //   //         isChecked: false
  //   //       };
  //   //     }
  //   //   });

  //   let options: any[] = allBusinessCategory?.reduce((accumulator, current) => {
  //     if (values.business_category_id !== current.id) {
  //       const payload = {
  //         id: current.id,
  //         label: current.business_category_description,
  //         name: current.business_category_description,
  //         isChecked: false
  //       };
  //       accumulator.push(payload);
  //     }

  //     return accumulator;
  //   }, []);

  //   setFiltered(options);
  //   setbusinessList(options);
  // }, [allBusinessCategory, values.business_category_id]);

  //Getting Bussiness Categories
  const businessCategoryOption =
    allBusinessCategory &&
    allBusinessCategory.map((_data: { id: string; business_category_description: string }) => {
      return {
        value: _data.id,
        optionName: _data.business_category_description
      };
    });

  //Getting Countries
  const countriesOptions =
    countries &&
    countries.map((_data: { country_description: string }) => {
      return {
        value: _data.country_description,
        optionName: _data.country_description
      };
    });

  //Getting States
  const stateOptions = [] as any;
  states &&
    states.forEach((element: { country_description: string; state_name: string }) => {
      // if (element.country_description === values.country) {
      stateOptions.push({
        value: element.state_name,
        optionName: element.state_name
      });
      // }
    });

  //Getting Cities
  const cityOptions = [] as any;
  cities &&
    cities.forEach((element: { state_name: string; id: string; city_name: string; country_description: string }) => {
      // if (element.state_name === values.state && element.country_description === values.country) {
      cityOptions.push({
        value: element.id,
        optionName: element.city_name
      });
      // }
    });
  type FormData = {
    othernames: string;
    lastname: string;
    city_id: string;
    phone_number: string;
    email: string;
    vendor_description: string;
    country: string;
    state: string;
    position: string;
    street_number: string;
    street_name: string;
    business_category_id: string;
  };

  //modal toggler
  const closeModal = () => {
    setModal(!modal);
  };

  //modal toggler
  const displayTempCodeModal = () => {
    setTempCodeModal(!tempCode_modal);
    history.replace("/");
  };

  //checking multi-select field
  const checkBusinessList = filtered?.reduce((acc, current) => {
    if (current?.isChecked) {
      acc.push(current?.id);
    }
    return acc;
  }, []);

  const onSubmit = (vals: FormData) => {
    // if (checkBusinessList < 1 && requiredForm) {
    //   setBusinessFieldError("Please select at least one cateogry");
    //   return;
    // }

    if (!requiredForm) {
      const { email, lastname, othernames } = vals;
      if ([email, lastname, othernames].includes("")) {
        ["email", "lastname", "othernames"].forEach((element) => setError(element, { type: "required", message: "This is required to save as draft" }));
        return;
      }

      const payload = {
        // email,
        // lastname,
        // othernames,
        ...vals
        //lastUrl key is saved to store the last route when he/she used the saved draft implemention,
        // lastUrl: window.location.pathname
      };
      // dispatch(
      //   saveOnboardingDraft(payload, (code) => {
      //     setTempCodeModal(true);
      //     setTempCode(code);
      //   })
      // );

      return;
    }

    // setModal(!modal);
    const payload = {
      ...vals,
      business_categories: checkBusinessList
    };
    localStorage.setItem("ContactInfo", JSON.stringify(payload));
    // history.push("supplier/company-info/forms");
    history.push({
      pathname: "/supplier/company-info/forms"
    });
    // setModalData(payload);
  };

  //Clear Error Message
  useEffect(() => {
    if (checkBusinessList?.length >= 1) {
      setBusinessFieldError("");
      setPlaceholder(`${checkBusinessList?.length} items selected`);
    } else setPlaceholder("Select other Business Categories");
  }, [businessList, checkBusinessList]);

  const saveFormDraft = (): void => {
    setRequiredForm(false);
    setSubmitBtn(true);
  };

  return (
    <>
      {/* {tempCode_modal && <TempCode closeModal={displayTempCodeModal} code={tempCode} />}
      {modal && <SelectLegalEntity data={modalData} closeModal={closeModal} />} */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <Textbox
              label="FirstName"
              name="lastname"
              customRef={register({
                required: { value: requiredForm, message: "This field is required" }
              })}
              placeholder="FirstName"
              boxClasses="mt-4"
              error={errors.lastname && errors.lastname.message}
            />
            <Textbox
              label="LastNames"
              name="othernames"
              customRef={register({
                required: { value: requiredForm, message: "This field is required" }
              })}
              placeholder="LastNames"
              boxClasses="mt-4"
              error={errors.othernames && errors.othernames.message}
            />
            <Textbox
              label="Email Address"
              name="email"
              type="email"
              customRef={register({
                required: { value: requiredForm, message: "This field is required" }
              })}
              placeholder="Email Address"
              boxClasses="mt-4"
              readOnly
              error={errors.email && errors.email.message}
            />
            <Textbox
              label="Phone Number"
              type="text"
              name="phone_number"
              customRef={register({
                required: { value: requiredForm, message: "This field is required" }
              })}
              placeholder="Phone Number"
              boxClasses="mt-4"
              error={errors.phone_number && errors.phone_number.message}
            />
            <Textbox
              label="Company Name"
              name="vendor_description"
              customRef={register({
                required: { value: requiredForm, message: "This field is required" }
              })}
              placeholder="Company Name"
              readOnly
              boxClasses="mt-3"
              error={errors.vendor_description && errors.vendor_description.message}
            />
            <Textbox
              label="Position In Company"
              name="position"
              customRef={register({
                required: { value: requiredForm, message: "This field is required" }
              })}
              placeholder="Position In Company"
              boxClasses="mt-3"
              error={errors.position && errors.position.message}
            />
            <Textbox
              label="House Number"
              name="street_number"
              customRef={register({
                required: { value: requiredForm, message: "This field is required" }
              })}
              type="number"
              placeholder="House Number"
              boxClasses="mt-4"
              error={errors.street_number && errors.street_number.message}
            />
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <Textbox
              label="Street"
              name="street_name"
              customRef={register({
                required: { value: requiredForm, message: "This field is required" }
              })}
              placeholder="Street"
              boxClasses="mt-4"
              error={errors.street_name && errors.street_name.message}
            />

            <SelectDropdown
              boxClasses="mt-4"
              name="country_description"
              label="Country"
              placeholder="Country"
              options={countriesOptions}
              customRef={register({
                required: { value: requiredForm, message: "This field is required" }
              })}
              error={errors.country && errors.country.message}
              onChange={(e) => {
                const countryID = countries.find((country) => country.country_description === e.target.value);
                dispatch(getAllStates(undefined, 1000, undefined, countryID.id));
              }}
            />

            <SelectDropdown
              boxClasses="mt-3"
              name="state_name"
              label="State"
              placeholder="State"
              options={stateOptions}
              customRef={register({
                required: { value: requiredForm, message: "This field is required" }
              })}
              onChange={(e) => {
                const countryID = countries.find((country) => country.country_description === values.country);
                const stateID = states.find((state) => state.state_name === e.target.value);
                dispatch(getAllCities(undefined, 1000, countryID?.id, stateID?.id));
              }}
              error={errors.state && errors.state.message}
            />
            <SelectDropdown
              boxClasses="mt-3"
              name="city_id"
              label="City"
              placeholder="City"
              options={cityOptions}
              customRef={register({
                required: { value: requiredForm, message: "This field is required" }
              })}
              error={errors.city_id && errors.city_id.message}
            />

            <SelectDropdown
              boxClasses="mt-3"
              name="business_category_id"
              label="Business Category"
              placeholder="Business Category"
              options={businessCategoryOption}
              customRef={register({
                required: { value: requiredForm, message: "This field is required" }
              })}
              error={errors.business_category_id && errors.business_category_id.message}
            />
            <ArrowDropdown text={placeholder} label="Business Categories" error={businessFieldError && businessFieldError} options={businessList} boxClasses="mt-3" {...rest} search={true} />
          </div>
        </div>
        {showButtons && (
          <div className="d-flex justify-content-center align-items-center mt-5">
            {/* <Button type="submit" label="Save Draft" btnType="btn-skeleton" xtraClass="w-100 px-4" boxClasses="w-7 mr-3" onClick={saveFormDraft} loading={isLoading} /> */}
            <Button disabled={submitBtn} type="submit" label="Next" btnType="btn-temporary" xtraClass="w-100 px-5" boxClasses="w-7" />
          </div>
        )}
      </form>
    </>
  );
};

export default ContactInformation;

export const AuthContactInformation = () => {
  return (
    <div className="supplier-contact-information">
      {/* <h4 className="text-center">Supplier Registration</h4>
        <div className="form-container mt-3 pb-5"> */}
      {/* <h4 className="temp_no">Temp No : FDWE2314-098UEIJM</h4> */}
      {/* <h6 className="form-header mt-1">Contact Information</h6> */}
      <ContactInformation showButtons={true} />
      {/* </div> */}
    </div>
  );
};

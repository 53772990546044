import React from 'react';
import styled from "styled-components";
import Icon from "assests/icons/statsCard/Vector (4).png";
import { useDispatch, useSelector } from 'react-redux';
import { getSingleRfp, getVendors, publishRfp, removeVendorFromRfp, updateRFISourcing } from 'store/slice/SourcingSlice/SourcingSlice';
import { RootState } from 'store/store';
import InviteVendors from 'components/ModalBuyer/InviteVendor';
import { format } from 'date-fns';
import Pagination from "components/Pagination";

import "styles/variables.scss";
import useSearch from 'hooks/useSearch';

const Wrapper = styled.div`
  margin-top: 3rem;

  div.spinner-border {
    display: inline-block;
    width: 5rem;
    height: 5rem;
    vertical-align: text-bottom;
    border: 0.8em solid rgba(47, 128, 237, 0.2);
    border-right-color: transparent;
    border-radius: 50%;
    animation: 1.9s linear infinite spinner-border;
  }

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 15px;
    td {
      text-align: center;
      padding: 5px;
      border-bottom: solid 1px #dfe2e6;
      border-top: solid 1px #dfe2e6;
    }
    th {
      padding: 5px;
      text-align: center;
      color: #3d59b7;
      line-height: 36px;
      font-weight: 700;
      font-family: "Averia-bold";
      background-color: rgba(119, 157, 207, 0.1);
    }
    .first-td {
      border-left: solid 1px #dfe2e6;
      border-radius: 10px 0px 0px 10px;
    }
    .last-td {
      border-right: solid 1px #dfe2e6;
      border-radius: 0px 10px 10px 0px;
    }
    // tr {
    //   .border-container {
    //     border-bottom: solid 1px red;
    //     border-left: solid 1px red;
    //     border-radius: 5px 0px 0px 5px;
    //     border-top: solid 1px red;
    //   }
    // }
  }
`;

const InvitedVendors = ({ id }) => {
    const dispatch = useDispatch();

    const [showModal, setShowModal] = React.useState(false);
    const [page, setPage] = React.useState<number>(1);
    const [perpage, setPerpage] = React.useState<number>(10);

    const { invitedVendors, rfp, isLoading } = useSelector((state: RootState) => state.sourcing)

    const { search, inputRef, handleChange, searched } = useSearch(getVendors, id, perpage);

    const paginate = React.useCallback(
        (pageNo: number, length: number) => {
            setPage(pageNo);
            dispatch(getVendors(id, pageNo, length, search));
        },
        [search]
    );

    const handlePageChange = React.useCallback(
        (page: number, length: number) => {
            setPage(page);
            dispatch(getVendors(id, page, length, search));
        },
        [search]
    );

    const handleDel = (id) => {
        const data = {
            vendor_id: id,
            rfp_id: rfp.id,
        }
        dispatch(removeVendorFromRfp(data, () => handleSuc()));
    }

    const handleSuc = () => {
        dispatch(getVendors(id));
    }

    React.useEffect(() => {
        dispatch(getVendors(id))
    }, [id])

    React.useEffect(() => {
        console.log(invitedVendors);
    }, [invitedVendors])

    React.useEffect(() => {
        if (!showModal) dispatch(getVendors(id));
    }, [showModal]);

    const saveToDraft = () => {
        console.log('localItems', JSON.parse(localStorage.getItem('tempData')!))
        const _tempData = JSON.parse(localStorage.getItem('tempData')!)
        const data = {
            ..._tempData,
            id: rfp.id
        }
        console.log(data);
        dispatch(updateRFISourcing(data, () => dispatch(getSingleRfp(id))))
    }

    const handlePublish = () => {
        const data = { rfp_id: id }
        dispatch(publishRfp(data, () => dispatch(getSingleRfp(id))));
    }

    return (
        <Wrapper className="route-transition">
            {showModal && <InviteVendors closeModal={() => setShowModal(false)} />}
            {rfp?.rfp_status === '-2' && (
                <div>
                    <p onClick={() => setShowModal(true)} style={{ fontWeight: 'bold', fontSize: '18px', cursor: 'pointer' }}>Invite Vendors &nbsp;<img src={Icon} alt="Invite Vendors" /></p>
                    {rfp?.rfp_status === '-2' && !isLoading && (
                        <div style={{ display: 'inline-flex', float: 'right', position: 'relative', bottom: '3rem' }}>
                            <button style={{
                                padding: "5px 10px",
                                borderRadius: "10px",
                                background: "#FFFFFF",
                                color: "#737A91",
                                border: '1px solid #DFE2E6',
                                marginRight: '1rem',
                            }}
                                onClick={() => saveToDraft()}
                            >
                                Save to Draft
                            </button>

                            <button style={{
                                padding: "8px 30px",
                                background: "#00B0A5",
                                borderRadius: "10px",
                                color: "white",
                            }}
                                onClick={() => handlePublish()}
                            >
                                {isLoading ? <div className="spinner-border spinner-border-primary spinner-border-sm mr-2"></div> : "Publish"}
                            </button>
                        </div>
                    )}
                </div>
            )}

            {isLoading ? (
                <div className="justify-content-center w-100 d-flex pb-5 pt-5">
                    <div className="spinner-border spinner-border-primary spinner-border-lg justify-content-center mr-2"></div>
                </div>
            ) : (
                <div className="row mb-3">
                    <div className="col-md-12">
                        <table>
                            <thead>
                                <tr>
                                    <th style={{ color: '#000000' }}>Item</th>
                                    <th style={{ padding: '1rem', color: '#000000' }}>Vendor ID</th>
                                    <th style={{ padding: '1rem', color: '#000000' }}>Vendor Description</th>
                                    <th style={{ padding: '1rem', color: '#000000' }}>Email</th>
                                    <th style={{ padding: '1rem', color: '#000000' }}>Date Invited</th>
                                    {rfp?.rfp_status === '-2' && (
                                        <th style={{ padding: '1rem', color: '#000000' }}>Action</th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {invitedVendors?.data?.map((item, i) => (
                                    <tr key={i} className="table-data-row">
                                        <td style={{ color: '#000000' }}>{i + 1}</td>
                                        <td style={{ padding: '1rem', color: '#000000' }}>{item.vendor_id} </td>
                                        <td style={{ padding: '1rem', color: '#000000' }}>{item?.vendor_description}</td>
                                        <td style={{ padding: '1rem', color: '#000000' }}>{item?.email}</td>
                                        <td style={{ padding: '1rem', color: '#000000' }}>{format(new Date(item.created_at), 'yyyy-MM-dd')}</td>
                                        {rfp?.rfp_status === '-2' && (
                                            <td style={{ padding: '1rem', color: '#000000' }}>
                                                <button style={{
                                                    backgroundColor: 'rgba(0, 113, 176, 0.15)',
                                                    color: '#2F80ED',
                                                    padding: "5px 15px",
                                                    borderRadius: '10px'
                                                }}
                                                    onClick={() => handleDel(item.vendor_id)}
                                                >Remove</button>
                                            </td>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div style={{ width: '100%' }}>
                            <Pagination
                                page={page}
                                lastPage={invitedVendors?.pagination?.lastPage}
                                paginate={paginate}
                                total={invitedVendors?.pagination?.total}
                                handlePageChange={handlePageChange}
                                perPage={perpage} />
                        </div>
                    </div>
                </div>
            )}

        </Wrapper >
    )
}

export default InvitedVendors;
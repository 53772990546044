import React, { useEffect, useState } from "react";
import Modal from "components/Modal";
import Textbox from "components/Textbox";
import Button from "components/Button";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import "./styles.scss";
import { useForm } from "react-hook-form";
import { doverifyVendorFile, getSingleVendor } from "store/slice/BuyerSlice/VendorSlice/VendorSlice";

type Props = {
  closeModal: () => void;
  docName?: string;
  data_id?: string;
  id?: string;
};
const VerifyFile = ({ closeModal, docName = "", data_id = "", id = "" }: Props) => {
  //Redux Store
  const dispatch = useDispatch();
  const { handleSubmit } = useForm<FormData>({
    mode: "onBlur"
  });
  const { loading } = useSelector((state: RootState) => state.vendorDetails);

  const [date, setDate] = useState("");
  const onChange = (e) => {
    setDate(e.target.value);
  };
  const onFormSubmit = () => {
    dispatch(
      doverifyVendorFile({ expires_on: date !== "" ? date : undefined, vendor_data_id: data_id }, () => {
        dispatch(getSingleVendor(id));
        closeModal();
      })
    );
  };
  return (
    <Modal modalText="Verify Document" onClose={closeModal} boxClasses="role-modal-top">
      <div className="verify-file-modal">
        <p className="">Verify Document and append Expiry Date if Required.</p>
        <form className="mt-4" onSubmit={handleSubmit(onFormSubmit)}>
          <p className="mb-3 yaraa-color">Document Name</p>
          <p>{docName}</p>
          <Textbox type="date" name="farmer_id" value={date} label="Expiry Date" boxClasses="mt-3" placeholder="Expiry Date" onChange={onChange} min={new Date().toISOString().slice(0, 10)} />
          <Button label="Verify" btnType="btn-primary" boxClasses="mt-4 mb-5" btnStyle="100%" loading={loading} disabled={loading} />
        </form>
        <p className="role-p" onClick={closeModal}>
          Cancel
        </p>
      </div>
    </Modal>
  );
};

export default VerifyFile;

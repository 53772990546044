/* eslint-disable react-hooks/exhaustive-deps */
import StatsCard from "components/StatsCard";
import Icon from "assests/icons/statsCard/Vector (4).png";
import SearchBar from "../../../../components/SearchBar";
import Dropdown from "components/Dropdown";
import DataTable from "components/DataTable";
import { reportCol } from "./data";
import "./styles.scss";
import React, { useEffect, useState } from "react";
import { AppDispatch, RootState } from "store/store";
import { useDispatch, useSelector } from "react-redux";
import useSearch from "hooks/useSearch";
import CreateOrEditReport from "components/ModalBuyer/CreateOrEditReport";
import Pagination from "components/Pagination";
import { getAllReport } from "store/slice/BuyerSlice/ReportSlice/RepotSlice";
import lineImg from "../../../../assests/icons/line-chart.png";
import dateImg from "../../../../assests/icons/calendar.png";
import SelectDropdown from "components/SelectDropdown";
import { Controller, useForm } from "react-hook-form";
import AsyncSelect from "react-select/async";
import API from "utils/httpsRequest";
import { useHistory } from "react-router";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import { kFormatter } from "utils/utils";
import moment from "moment";
import { Pie, Doughnut, Line, Bar } from "react-chartjs-2";

import { getCompanySummary, getDeptSummary, getVendorSummary } from "store/slice/SummarySlice/SummarySlice";

const InnerReport = () => {
  const [singleReport, setSingleReport] = useState<any>(null);

  const dispatch = useDispatch<AppDispatch>();
  const { reports, loading } = useSelector((state: RootState) => state.report);
  const { locations } = useSelector((state: RootState) => state.location);
  const [modal, setModal] = useState<boolean>(false);

  const [vendorSel, setVendorSel] = React.useState("");
  const [selVendorVal, setSelVendorVal] = React.useState("");
  const [purchaseSel, setPurchaseSel] = React.useState("");
  const [selPurchaseVal, setSelPurchaseVal] = React.useState("");
  const [catSel, setCatSel] = React.useState("");
  const [selCatVal, setSelCatVal] = React.useState("");
  const [vendor, setVendor] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [cat, setCat] = useState("");
  const [catName, setCatName] = useState("");
  const [purchase, setPurchase] = useState("");
  const [purchaseName, setPurchaseName] = useState("");

  const [sumVendor, setSumVendor] = useState(0);
  const [sumItem, setSumItem] = useState(0);
  const [sumMoney, setSumMoney] = useState(0);
  // const [sumIso, setSumIso] = useState(null);
  const [firstFive, setFirstFive] = useState<any>([]);

  // const [defaultLegal, setDefaultLegal] = useState([{

  //     label: "GZ Industries Ltd - Nigeria",
  //     value: "10002"

  // }]);
  const [newVendorOption, setNewVendorOption] = useState([]);

  const [seen, setSeen] = useState(true);
  const { user } = useSelector((state: RootState) => state.user);
  const { token } = user;

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const deptId = urlParams.get("deptId");
  const deptName = urlParams.get("deptName");
  const company = urlParams.get("company");

  // from slice

  const { deptSummary, isLoadingDept, isLoadingVendor, vendorSummary } = useSelector((state: RootState) => state.summaries);

  // setting summary place
  useEffect(() => {
    deptSummary && deptSummary.data.length ? setSumVendor(deptSummary.data[0].total_vendors) : setSumVendor(0);
    deptSummary && deptSummary.data.length ? setSumItem(deptSummary.data[0].total_purchase_order_items) : setSumItem(0);
    // deptSummary && deptSummary.data.length ? setSumIso(companySummary.data[0].currency_iso) : setSumIso(null);
    deptSummary && deptSummary.data.length ? setSumMoney(deptSummary.data[0].total_purchase_order_value) : setSumMoney(0);
  }, [deptSummary]);

  // setting pie chat data

  const [labelList, setLabelList] = useState<string[]>([]);
  // const [onceSeen, setOnceSeen] = useState(true);
  const [pointScore, setPointScore] = useState<number[]>([]);
  let onceSeen = true;

  useEffect(() => {
    const filt =
      vendorSummary &&
      vendorSummary?.data
        ?.filter((val, i) => i <= 5)
        ?.filter((item) => {
          return item;
        });

    if (filt) {
      if (filt.length) {
        setFirstFive(filt);
        onceSeen = true;
        if (onceSeen) {
          let testArray: string[] = [];
          let testScore: number[] = [];
          // item.department_description.split(" ")[0]
          filt.forEach((item) => testArray.push(item.vendor_description));
          filt.forEach((item) => testScore.push(item.total_purchase_order_value));
          setLabelList([...testArray]);
          setPointScore([...testScore]);

          onceSeen = false;
        }
      } else {
        setLabelList([]);
        setPointScore([]);
      }
    }
  }, [vendorSummary]);

  const [dateRange, setDateRange] = useState([
    {
      // ate_from=&date_to=
      startDate: new Date(2021, 1, 1),
      endDate: new Date(),
      key: "selection"
    }
  ]);

  const [newDate, setNewDate] = useState([{}]);

  useEffect(() => {
    setNewDate([
      {
        startDate: dateRange[0].startDate,
        endDate: dateRange[0].endDate
      }
    ]);
  }, [dateRange]);

  // get summary for company
  // useEffect(() => {
  //   /purchase_orders/summary_by_company?company_id
  // }, [company]);

  const [showRange, setShowRange] = useState(false);

  const handleRangeShow = () => {
    setShowRange(!showRange);
  };

  const history = useHistory();

  const closeModal = () => {
    setModal((prev) => !prev);
    setSingleReport(null);
  };

  const handleInputChangeVendor = (value) => {
    setVendorSel(value);
  };

  const onChangeVendor = (value) => {
    setVendor(value.value);
    setVendorName(value.label);
    setSelVendorVal(value);
  };

  const handleInputChangePurchase = (value) => {
    setPurchaseSel(value);
  };

  const onChangePurchase = (value) => {
    setPurchase(value.value);
    setPurchaseName(value.label);
    setSelPurchaseVal(value);
  };

  const handleInputChangeCat = (value) => {
    setCatSel(value);
  };

  const onChangeCat = (value) => {
    setCat(value.value);
    setCatName(value.label);
    setSelCatVal(value);
  };

  // const vendorOptions = () => {
  //   return API.get(`/vendors/get?search=${vendorSel}`, {
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //       "Content-Type": "application/json"
  //     }
  //   }).then((res) => {
  //     return res.data.data.map((item) => ({
  //       label: item.vendor_description,
  //       value: item.id
  //     }));

  //   });
  // };
  const vendorOptions = ({ companyId = company }) => {
    return API.get(`/vendors/get?search=${vendorSel}&company_id=${companyId}&length=1000`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    }).then((res) => {
      let guy =
        res.data &&
        res.data.data.map((item) => ({
          label: item.vendor_description,
          value: item.id
        }));

      setNewVendorOption(guy);
      return guy;
    });
  };

  useEffect(() => {
    vendorOptions({ companyId: company });
  }, []);

  const purchaseOptions = () => {
    return API.get(`/purchase_orders/get?search=${purchaseSel}&length=1000`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    }).then((res) => {
      return res.data.data.map((item) => ({
        label: item.purchase_order_code,
        value: item.id
      }));
    });
  };

  const materialCategoryOptions = () => {
    return API.get(`/material_categories/get?search=${catSel}&length=1000`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    }).then((res) => {
      return res.data.data.map((item) => ({
        label: item.material_category_description,
        value: item.id
      }));
    });
  };

  const { register, control, handleSubmit, errors, setValue } = useForm<FormData>({
    mode: "onChange"
  });

  //Hook
  //   const { search, inputRef, handleChange, searched } = useSearch(getAllReport, perPage);

  //   chart info

  // const pieData = {
  //   labels: labelList.length && labelList,
  //   datasets: [
  //     {
  //       label: "Technical Evaluation",
  //       weight: 1,
  //       backgroundColor: ["#05445E", "#00B01C", "#A6CEE3","#189AB4","#75E6DA", "#05445E" ],
  //       data: pointScore.length && pointScore
  //     }
  //   ]
  // };

  const lineData = {
    labels: labelList.length && labelList,
    datasets: [
      {
        label: "Vendor Chart",
        backgroundColor: ["#05445E", "#00B01C", "#A6CEE3", "#189AB4", "#75E6DA", "#05445E"],
        borderColor: ["#05445E", "#00B01C", "#A6CEE3", "#189AB4", "#75E6DA", "#05445E"],
        fill: true,
        data: pointScore.length && pointScore
      }
    ]
  };

  // get department Summary
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  //Show Number for PerPage Request
  const handleNewPage = (pageNo: number, page: number = 1) => {
    setPerPage(pageNo);
    setPage(page);
    dispatch(
      getVendorSummary({
        company_id: "",
        location_id: "",
        date_from: moment(dateRange[0].startDate).format("YYYY-MM-D"),
        date_to: moment(dateRange[0].endDate).format("YYYY-MM-DD"),
        department_id: deptId,
        material_category_id: cat,
        length: perPage,
        page: page,
        currency_id: "",
        purchase: purchase,
        vendor: vendor
      })
    );
  };

  //Pagination
  //---------------------------------------------
  const handlePageChange = React.useCallback((page: number, perPage: number) => {
    setPage(page);
    dispatch(
      getVendorSummary({
        company_id: company,
        location_id: "",
        date_from: moment(dateRange[0].startDate).format("YYYY-MM-D"),
        date_to: moment(dateRange[0].endDate).format("YYYY-MM-DD"),
        department_id: deptId,
        material_category_id: cat,
        length: perPage,
        page: page,
        currency_id: "",
        purchase: purchase,
        vendor: vendor
      })
    );
  }, []);

  const paginate = React.useCallback((pageNo: number, perPage: number) => {
    setPage(pageNo);
    dispatch(
      getVendorSummary({
        company_id: company,
        location_id: "",
        date_from: moment(dateRange[0].startDate).format("YYYY-MM-D"),
        date_to: moment(dateRange[0].endDate).format("YYYY-MM-DD"),
        department_id: deptId,
        material_category_id: cat,
        length: perPage,
        page: page,
        currency_id: "",
        purchase: purchase,
        vendor: vendor
      })
    );
  }, []);

  useEffect(() => {
    dispatch(
      getDeptSummary({
        company_id: company,
        location_id: "",
        date_from: moment(dateRange[0].startDate).format("YYYY-MM-D"),
        date_to: moment(dateRange[0].endDate).format("YYYY-MM-DD"),
        department_id: deptId,
        material_category_id: cat,
        length: perPage,
        page: page,
        currency_id: "",
        purchase: purchase,
        vendor: vendor
      })
    );
  }, [purchase, vendor, newDate, cat]);

  useEffect(() => {
    dispatch(
      getVendorSummary({
        company_id: company,
        location_id: "",
        date_from: moment(dateRange[0].startDate).format("YYYY-MM-D"),
        date_to: moment(dateRange[0].endDate).format("YYYY-MM-DD"),
        department_id: deptId,
        material_category_id: cat,
        length: perPage,
        page: page,
        currency_id: "",
        purchase: purchase,
        vendor: vendor,
        purchase_code: ""
      })
    );
  }, [purchase, vendor, newDate, cat]);

  //   const deleteCompany = (name: string, id: string) => {
  //     const payload = { id: id };

  //     toastPopUp(
  //       () => {
  //         dispatch(
  //           deleteCompanyData(payload, () => {
  //             dispatch(getAllReport(page,perPage));
  //           })
  //         );
  //       },
  //       "Delete",
  //       "Cancel",
  //       "Delete the entity",
  //       name,
  //       "red"
  //     );
  //   };

  // const editCompanyData = (id: string, desc: string, code: string,country:string) => {
  //     setSingleCompanyData({ id, desc, code, country });
  //     setModal((prev) => !prev);
  //   };

  const reportList =
    vendorSummary &&
    vendorSummary?.data?.map((company) => {
      return {
        id: company.vendor_id,
        vendor_code: company.vendor_code,
        vendor_description: company.vendor_description,
        total_purchase_orders: company.total_purchase_orders,
        total_value: `${company.currency_symbol}${kFormatter(company.total_purchase_order_value, "")}`
      };
    });

  const handleView = (e, item) => {
    history.push(`/buyer/purchase-orders?vendor_id=${item.id}`);
  };

  // removeFilter

  const removeFilter = (nameFilt) => {
    if (nameFilt === "date") {
      setDateRange([
        {
          // ate_from=&date_to=
          startDate: new Date(2021, 1, 1),
          endDate: new Date(),
          key: "selection"
        }
      ]);
    }
    if (nameFilt === "vendor") {
      setVendor("");
      setVendorName("");
    }
    if (nameFilt === "purchase") {
      setPurchase("");
      setPurchaseName("");
    }
    if (nameFilt === "cat") {
      setCat("");
      setCatName("");
    }
  };

  return (
    <div id="report">
      {modal && <CreateOrEditReport closeModal={closeModal} />}

      <div className="container">
        <div className="filterContainer">
          <div className="titleSide">
            <div className="left">
              <img src={lineImg} alt="line icon" />
              <p>Purchase Order Report{` > ${deptName}`} </p>
            </div>
          </div>

          <div className="filters">
            <div className="dateFilter each">
              <div onClick={handleRangeShow} className="dateBox">
                <p className="date-range">
                  {` ${moment(dateRange[0].startDate).format("MM/DD/YYYY")} - ${moment(dateRange[0].endDate).format("MM/DD/YYYY")}`}

                  {/* moment(dateRange[0].startDate).format('MM/DD/YYYY') */}
                </p>
                <img src={dateImg} alt="date icon" />
              </div>

              {showRange && (
                <div className="rangeDiv">
                  <DateRangePicker
                    onChange={(item) => setDateRange([item.selection])}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={2}
                    ranges={dateRange}
                    direction="horizontal"
                    preventSnapRefocus={true}
                    calendarFocus="backwards"
                  />
                  <div onClick={handleRangeShow} className="applyBtn">
                    <button type="button">Apply</button>
                  </div>
                </div>
              )}
            </div>

            <div className="CompanyFilter each">
              <Controller
                control={control}
                defaultValue
                boxClasses="mt-3"
                name="vendor_id"
                render={({ ref }) => (
                  <AsyncSelect
                    cacheOptions
                    defaultOptions={newVendorOption}
                    placeholder="Vendor"
                    value={selVendorVal}
                    loadOptions={vendorOptions}
                    onInputChange={handleInputChangeVendor}
                    onChange={onChangeVendor}
                  />
                )}
              />
            </div>

            <div className="locationFilter each">
              <Controller
                control={control}
                defaultValue
                boxClasses="mt-3"
                name="purchase_order"
                render={({ ref }) => (
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    placeholder="Purchase Order"
                    value={selPurchaseVal}
                    loadOptions={purchaseOptions}
                    onInputChange={handleInputChangePurchase}
                    onChange={onChangePurchase}
                  />
                )}
              />
            </div>

            <div className="groupFilter each">
              <Controller
                control={control}
                defaultValue
                boxClasses="mt-3"
                name="material_category_id"
                render={({ ref }) => (
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    placeholder="Material Category"
                    value={selCatVal}
                    loadOptions={materialCategoryOptions}
                    onInputChange={handleInputChangeCat}
                    onChange={onChangeCat}
                  />
                )}
              />
            </div>
          </div>

          <div className="showing">
            <p className="text">Showing:</p>
            <div className="coverDisplay">
              <div className="displayDate">
                <p>{` ${moment(dateRange[0].startDate).format("MM/DD/YYYY")} - ${moment(dateRange[0].endDate).format("MM/DD/YYYY")}`}</p>
                <p
                  onClick={() => {
                    removeFilter("date");
                  }}
                  className="cancel comp"
                >
                  x
                </p>
              </div>
              {vendorName && (
                <div className="displayDate">
                  <p className="location">{vendorName}</p>
                  <p
                    onClick={() => {
                      removeFilter("vendor");
                    }}
                    className="cancel comp"
                  >
                    x
                  </p>
                </div>
              )}

              {purchaseName && (
                <div className="displayDate">
                  <p className="dept">{purchaseName}</p>
                  <p
                    onClick={() => {
                      removeFilter("purchase");
                    }}
                    className="cancel comp"
                  >
                    x
                  </p>
                </div>
              )}

              {catName && (
                <div className="displayDate">
                  <p className="cat">{catName}</p>
                  <p
                    onClick={() => {
                      removeFilter("cat");
                    }}
                    className="cancel comp"
                  >
                    x
                  </p>
                </div>
              )}
            </div>
          </div>

          <div className="summary">
            <p className="topic">Orders</p>
            <div className="summaryCover">
              <div className="spent">
                <div className="moneySide">
                  {/* <p className="currency">$</p> */}
                  <p className="amountMoney">{kFormatter(sumMoney, "")} </p>
                  <p className="currencyType"></p>
                </div>
                <p className="text">Total Spending</p>
              </div>

              <div className="spent mid">
                <div className="deptSide">
                  <p className="out">{kFormatter(sumVendor, "")}</p>
                  {/* <p className="total">/14</p> */}
                </div>
                <p className="text">Total Vendor</p>
              </div>

              <div className="spent">
                <p className="purchase">{kFormatter(sumItem, "")}</p>
                <p className="text">Total Items Purchased</p>
              </div>
            </div>
          </div>
        </div>
        <div className="chatBox">
          {/* <div className="title">Monthly Spend Report</div> */}
          <div className="chartFlex">
            <div style={{ width: "100%" }} className="pieChart">
              <Bar
                data={lineData}
                width={100}
                height={50}
                options={{
                  // title: {
                  //   display: true,
                  //   text: "Chart 1",
                  //   fontSize: 40
                  // },
                  maintainAspectRatio: false,

                  legend: {
                    display: true,
                    position: "right"
                  }
                }}
              />
            </div>
          </div>
        </div>

        <div className="document mt-5">
          <div className="mt-3">
            <DataTable columns={reportCol} data={reportList} onClick={handleView} loading={isLoadingVendor} />
            {vendorSummary?.pagination?.total > 10 ? (
              <Pagination
                page={page}
                lastPage={vendorSummary?.pagination?.lastPage}
                paginate={paginate}
                total={vendorSummary?.pagination?.total}
                handlePageChange={handlePageChange}
                perPage={perPage}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InnerReport;

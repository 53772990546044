import React, { useEffect, useState } from "react";
import Dropdown from "components/Dropdown";
import DataTable from "components/DataTable";
import { purchaseReqTableCol, purchaseReqTableData } from "../data";
import SearchIcon from "components/SearchBar";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { getRequisitions } from "store/slice/RequisitionSlice/RequisitionSlice";
import useSearch from "hooks/useSearch";
import { format } from "date-fns";
import Pagination from "components/Pagination";
import CreateSourcingProject from "components/ModalBuyer/CreateSourcingProject";
import { useHistory } from "react-router-dom";
import filterIcon from "assests/icons/buyer/filter-icon.svg";
import { useForm, Controller } from "react-hook-form";
import AsyncSelect from "react-select/async";
import API from "utils/httpsRequest";
import FilterCard from "components/FilterCard";
import styled from "styled-components";

const FilterContainer = styled.div`
  .mySelect__control {
    border-radius: 10px;
    border: 1px solid rgb(223, 226, 230) !important;
  }

  .mySelect__value-container {
    height: 3rem;
  }
`;

const PurchaseRequisitions = () => {
  const [page, setPage] = useState<number>(1);
  const [perpage, setPerpage] = useState<number>(10);
  const [openTLmodal, setOpenTLmodal] = useState<Boolean>(false);
  const [item, setItem] = useState({});
  const [showFilter, setShowFilter] = useState(false)
  const [deptVal, setDeptVal] = useState('');
  const [selDeptVal, setSelDeptVal] = useState<any>('');

  const dispatch = useDispatch<AppDispatch>();

  const history = useHistory();

  const { search, inputRef, handleChange, searched } = useSearch(getRequisitions, perpage, '', '1',);

  const { requisitions, isLoading } = useSelector((state: RootState) => state.requisition);

  const { user } = useSelector((state: RootState) => state.user);

  const { token } = user;

  const {
    register,
    handleSubmit,
    reset,
    control,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      start_date: '',
      end_date: '',
      from: '',
      to: '',
    }
  })

  const onSubmit = (vals) => {
    const data = {
      ...vals,
      department_id: selDeptVal.value || '',
    }
    dispatch(getRequisitions(page, perpage, search, data.requisition_status, data.date_from, data.date_to, data.department_id))
  }

  const handleClear = () => {
    dispatch(getRequisitions(page, perpage));
    reset();
    setSelDeptVal('')
  };

  const handleInputChangeDept = value => setDeptVal(value);

  const onChangeDept = value => setSelDeptVal(value);

  const loadDept = () => {
    return API.get(`/departments/get?page=1&length=10&search=${deptVal}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      return res.data.data.map((item) => ({
        label: `${item.department_description} - ${item.department_code}`,
        value: item.id,
      }))
    })
  }

  useEffect(() => {
    dispatch(getRequisitions(page, perpage, '', '1', '', ''));
  }, []);

  React.useEffect(() => {
    if (searched) setPage(1);
  }, [searched]);

  const paginate = React.useCallback(
    (pageNo: number, length: number) => {
      setPage(pageNo);
      dispatch(getRequisitions(pageNo, length, search, '1', '', ''));
    },
    [search]
  );

  const handleNewPage = React.useCallback(
    (pageNo: number, page = 1) => {
      setPerpage(pageNo);
      setPage(1);
      dispatch(getRequisitions(page, pageNo, search, '1', '', ''));
    },
    [search]
  );

  const handlePageChange = React.useCallback(
    (page: number, length: number) => {
      setPage(page);
      dispatch(getRequisitions(page, length, search, '1', '', ''));
    },
    [search]
  );

  React.useEffect(() => {
    if (!openTLmodal) setItem({});
  }, [openTLmodal]);

  const requisitionList =
    requisitions &&
    requisitions?.data?.map((item: any) => {
      return {
        ...item,
        purchase_req: item.requisition_code,
        description: item.requisition_description,
        location: item.location_description,
        department: item.department_description,
        material_type: item.requisition_type,
        approved_by: `${item.approved_by_lastname} ${item.approved_by_othernames}`,
        createClicked: (item) => {
          setItem(item);
          setOpenTLmodal(!openTLmodal);
        },
        viewClicked: (item) => history.push({ pathname: `/buyer/requisition/${item.id}` }),
      };
    });

  return (
    <>
      {openTLmodal && <CreateSourcingProject item={item} closeModal={() => setOpenTLmodal(false)} />}
      <div className="document mt-5">
        {showFilter && (
          <FilterContainer>
            <FilterCard
              handleSubmit={handleSubmit(onSubmit)}
              clear={handleClear}
            >
              <div className="row">
                <div className="col col-4">
                  <label
                    style={{ display: 'block' }}
                  >Start Date</label>
                  <input
                    type="date"
                    name="date_from"
                    className="mt-3"
                    placeholder="Start Date"
                    style={{
                      width: '100%',
                      height: '3rem',
                      padding: '5px',
                      borderRadius: '10px',
                      border: '1px solid #dfe2e6',
                    }}
                    ref={register({ required: false })}
                  />
                </div>
                <div className="col col-4">
                  <label
                    style={{ display: 'block' }}
                  >End Date</label>
                  <input
                    type="date"
                    name="date_to"
                    className="mt-3"
                    placeholder="End Date"
                    style={{
                      width: '100%',
                      height: '3rem',
                      padding: '5px',
                      borderRadius: '10px',
                      border: '1px solid #dfe2e6',
                    }}
                    ref={register({ required: false })}
                  />
                </div>
              </div>
              <div className="row" style={{
                marginTop: '2rem'
              }}>
                <div className="col col-4">
                  <label
                    style={{ display: 'block' }}
                  >Requisition Status</label>
                  <select
                    style={{
                      width: '100%',
                      height: '3rem',
                      padding: '5px',
                      borderRadius: '10px',
                      border: '1px solid #dfe2e6',
                    }}
                    name="requisition_status"
                    ref={register({
                      required: false
                    })}
                  >
                    <option value=''>Select</option>
                    <option value="1">Approved</option>
                    <option value="-1">Pending</option>
                    <option value="-2">Draft</option>
                    <option value="2">Generated</option>
                  </select>
                </div>
                <div className="col col-4">
                  <label
                    style={{ display: 'block' }}
                  >Department</label>
                  <Controller
                    control={control}
                    defaultValue
                    name="department_id"
                    render={({ ref }) => (
                      <AsyncSelect
                        cacheOption
                        defaultOptions
                        placeholder="Select Department"
                        value={selDeptVal}
                        loadOptions={loadDept}
                        onInputChange={handleInputChangeDept}
                        onChange={onChangeDept}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        classNamePrefix="mySelect"
                      />
                    )} />
                </div>
              </div>
            </FilterCard>
          </FilterContainer>
        )}
        <div className="search-field">
          <SearchIcon placeholder="Search for Purchase Requisition" boxClasses="mb-3" textRef={inputRef} value={search} onChange={handleChange} />
          <Dropdown perPage={perpage} action={handleNewPage} />
          <div>
            <button style={{
              padding: "5px 10px",
              borderRadius: "10px",
              background: "#FFFFFF",
              color: "#737A91",
              border: '1px solid #DFE2E6',
              fontSize: '18px',
              marginLeft: '1rem',
              width: 'max-content'
            }}
              onClick={() => setShowFilter(!showFilter)}
            >
              <img src={filterIcon} alt="icon" className="icon-image" />
              <span style={{
                paddingLeft: '1rem',
              }}>
                {!showFilter ? 'Filters' : 'Hide Filters'}
              </span>
            </button>
          </div>
        </div>

        <div className="mt-3">
          <DataTable columns={purchaseReqTableCol} data={requisitionList} loading={isLoading} />
          <Pagination page={page} lastPage={requisitions?.pagination?.lastPage} paginate={paginate} total={requisitions?.pagination?.total} handlePageChange={handlePageChange} perPage={perpage} />
        </div>
      </div>
    </>
  );
};

export default PurchaseRequisitions;

import * as React from "react";
import "./styles.scss";

type Props = {
    columns: any[];
    data: any[];
    loading?: boolean;
    onClick?: (e, item) => void | undefined;
};

const DataTable: React.FunctionComponent<Props> = ({ columns, data, loading = false, onClick }) => {
    return (
        <div className="data-table">
            <table>
                <tbody>
                    <tr className="table-header">
                        {columns && columns.map((column) => <th key={column.id}>{column.name}</th>)}
                    </tr>
                    {data &&
                        !loading &&
                        data.map((item) => (
                            <tr className={`table-data-row ${onClick && "modal_click"}`} key={Math.random() * 1000}>
                                {columns &&
                                    columns.map((column) => (
                                        <td
                                            key={column.id}
                                            onClick={(e) => {
                                                onClick && onClick(e, item);
                                            }}
                                        >
                                            {column.render && column.render(item)}
                                            {item[column.index]}
                                        </td>
                                    ))}
                            </tr>
                        ))}
                </tbody>
            </table>
            {(data === null || data === undefined || loading) && (
                <div className="justify-content-center w-100 d-flex pb-5 pt-5">
                    <div className="spinner-border spinner-border-primary spinner-border-lg justify-content-center mr-2"></div>
                </div>
            )}
            {data && data.length === 0 && !loading && (
                <div className="d-flex justify-content-center py-5">
                    <h2 style={{ fontSize: "1.3rem" }}>Not found</h2>
                </div>
            )}
        </div>
    );
};

export default DataTable;

import React from "react";
import Document from "assests/icons/sidebar/inventory.svg";
import Button from "components/Button/index";
import "./styles.scss";
import { Link } from "react-router-dom";
import Checkmark from "assests/icons/checkmark-circle.svg";
import warning from "assests/icons/supplier/Vector (8).svg";

export const documentsColumns = [
  {
    name: "Document No.",
    index: "doc_no",
    id: 1
  },
  {
    name: "Description",
    index: "description",
    id: 2
  },
  {
    name: "Technical",
    index: "technical",
    id: 3
  },
  {
    name: "Commercial",
    index: "commercial",
    id: 4
  },
  {
    name: "Response",
    index: "response",
    id: 5
  },
  {
    name: "Time Left",
    index: "time",
    id: 6
  },
  {
    name: "Status",
    id: 7,
    render: ({ response }) => <span>{response === "Sent" ? <img src={Checkmark} alt="icon" /> : <img src={warning} alt="icon" />}</span>
  },
  {
    name: "",
    id: 7,
    render: ({ id, response, closed, decline }) => (
      <span className="span-container">
        {(response === "Not Sent" || response === "Pending") && !closed && (
          <p className={response === "Not Sent" ? "edit-n" : "edit"}>
            <Link to={{ pathname: `/supplier/bids/${id}/overview` }}>Review</Link>
          </p>
        )}
        {response === "Pending" && !closed && (
          <p className="delete pl-3" onClick={decline}>
            {/* <Link to="">Decline</Link> */}
            Decline
          </p>
        )}
        {response === "Sent" && !closed && (
          <p className={"edit-n"}>
            <Link to={{ pathname: `/supplier/bids/${id}/overview` }}>View</Link>
          </p>
        )}
        {closed && (
          <p className={"edit-n"} style={{ cursor: "default" }}>
            Closed
          </p>
        )}
      </span>
    )
  }
];

export const documents = [
  {
    doc_no: "45000008910",
    description: "Server Maintainance and installation",
    technical: "Pending",
    commercial: "Pending",
    response: "Pending",
    time: "10:00:59",
    amount: "N4,800,000",
    status: "Delivered",
    id: 1
  },
  {
    doc_no: "45000008233",
    description: "In-house software development",
    technical: "Pending",
    commercial: "Pending",
    response: "Sent",
    time: "10:00:59",
    amount: "N34,120,000",
    status: "In Progress",
    id: 2
  },
  {
    doc_no: "45000008738",
    description: "Computer Sales",
    technical: "Completed",
    commercial: "N5,560,900",
    amount: "N5,560,900",
    response: "Pending",
    status: "PO Received",
    time: "4:00:20",
    id: 3
  },
  {
    doc_no: "45000008910",
    description: "Office Supplies",
    technical: "Pending",
    commercial: "N5,560,900",
    amount: "N78,900",
    response: "Pending",
    time: "2:00:00",
    status: "Out for delivery",
    id: 4
  }
];


import FileUploaderBox from "components/FileUploaderBox";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRfpFields, getSingleRfp, publishRfp, updateRFISourcing, uploadFile, uploadingImageSuccess } from "store/slice/SourcingSlice/SourcingSlice";
import { RootState } from "store/store";
import silh from "assests/icons/silh.png"
import styled from "styled-components";
import { useForm } from "react-hook-form";

const Wrapper = styled.div`
  p.page-title {
    color: #3d59b7;
    line-height: 36px;
    font-weight: 700;
    font-family: "Averia-bold";
  }

  div.spinner-border {
    display: inline-block;
    width: 5rem;
    height: 5rem;
    vertical-align: text-bottom;
    border: 0.8em solid rgba(47, 128, 237, 0.2);
    border-right-color: transparent;
    border-radius: 50%;
    animation: 1.9s linear infinite spinner-border;
  }

  margin-top: 3rem;

  h5.main {
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 1rem;

    span {
      margin-left: 1.5rem;
      color: #1753ac;
    }
  }

  h5.sub {
    padding-top: 1rem;
    color: #4061cb;
  }

  div.requisition-details {
    margin-top: 3rem;

    div.container {
      border: 1px solid #dfe2e6;
      width: 60%;
      border-radius: 6px;
      padding: 1.5rem 1rem;
      margin-top: 1rem;

      div.flex {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;

        div.flex-item {
          margin: 10px;
          width: 15rem;

          h5 {
            color: #1a3baf;
          }
        }
      }

      table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0 15px;
        td {
          text-align: center;
          padding: 5px;
          border-bottom: solid 1px #dfe2e6;
          border-top: solid 1px #dfe2e6;
        }
        th {
          padding: 5px;
          text-align: center;
          color: #3d59b7;
          line-height: 36px;
          font-weight: 700;
          font-family: "Averia-bold";
          background-color: rgba(119, 157, 207, 0.1);
        }
        .first-td {
          border-left: solid 1px #dfe2e6;
          border-radius: 10px 0px 0px 10px;
        }
        .last-td {
          border-right: solid 1px #dfe2e6;
          border-radius: 0px 10px 10px 0px;
        }
        // tr {
        //   .border-container {
        //     border-bottom: solid 1px red;
        //     border-left: solid 1px red;
        //     border-radius: 5px 0px 0px 5px;
        //     border-top: solid 1px red;
        //   }
        // }
      }
    }
  }

  div.additional-information {
    margin-top: 3rem;

    span.width-60 {
      display: block;
      width: 60%;
      margin-top: 2rem;

      span.flex {
        margin-top: 1rem;
        display: block;

        label {
          margin-right: 1rem;
        }

        input {
          border: 1px solid #e8e8e8;
          padding: 15px;
          border-radius: 0.5rem;
          min-width: 25rem;
        }
      }
    }
  }
`;

const Overview = React.forwardRef((props: { id, parentCallback }, ref) => {
  const dispatch = useDispatch();

  const { rfp, isLoading, s3url } = useSelector((state: RootState) => state.sourcing)

  const [files, setFiles] = React.useState<any>([]);
  const [tempData, setTempData] = React.useState({});

  const {
    register,
    handleSubmit,
    formState: { isDirty },
    setValue,
  } = useForm({
    mode: 'onChange',
  });

  React.useImperativeHandle(ref, () => ({
    getAlert() {
      handleSubmit(onSubmit)();
      // const data = {
      //   ...ovData,
      //   id: rfp.id,
      // }
      // console.log(data);
      // dispatch(updateRFISourcing(data, () => dispatch(getSingleRfp(props?.id))))
    }
  }));

  const onSubmit = (vals) => {
    if (vals.document_url.length > 0) vals.document_url = s3url
    else delete vals.document_url
    if (
      vals.rfp_description.length > 0 ||
      vals.in_house_estimate.length > 0 ||
      vals.technical_passing_score.length > 0 || vals.document_url.length > 0) {
      const data = {
        ...vals,
        id: rfp.id
      }
      console.log(data);
      dispatch(updateRFISourcing(data, () => dispatch(getSingleRfp(props?.id))))
    }
  }

  React.useEffect(() => {
    dispatch(getSingleRfp(props?.id))
    dispatch(uploadingImageSuccess(''));
  }, [])

  const handlePublish = () => {
    const data = { rfp_id: props?.['id'] }
    dispatch(publishRfp(data, () => dispatch(getSingleRfp(props?.['id']))));
  }

  const handleFile = (e, field_name: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(e);
    reader.onloadend = () => {
      // use a regex to remove data url part
      let read: any = reader.result;
      const payload = {
        base64_data: read.replace("data:", "").replace(/^.+,/, ""),
        file_extension: e.name.split(".").pop()
      };
      dispatch(uploadFile(payload));
      setFiles(payload);
    };
  };

  const handleRfpSuc = () => {
    dispatch(getSingleRfp(props?.id));
    dispatch(getRfpFields(props?.id));
  }

  React.useEffect(() => {
    // console.log(rfp);
    if (rfp !== undefined || rfp !== null) {
      setValue('in_house_estimate', rfp?.in_house_estimate);
      setValue('technical_passing_score', rfp?.technical_passing_score);
      setValue('rfp_description', rfp?.rfp_description);
      // localStorage.setItem('tempData', JSON.stringify({
      //   in_house_estimate: rfp?.in_house_estimate,
      //   technical_passing_score: rfp?.technical_passing_score,
      //   rfp_description: rfp?.rfp_description
      // }));
      setTempData({
        in_house_estimate: rfp?.in_house_estimate,
        technical_passing_score: rfp?.technical_passing_score,
        rfp_description: rfp?.rfp_description
      })
    }
  }, [rfp])

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noreferrer')
    if (newWindow) newWindow.opener = null
  }

  return (
    <Wrapper className="route-transition">
      {isLoading ? (
        <div className="justify-content-center w-100 d-flex pb-5 pt-5">
          <div className="spinner-border spinner-border-primary spinner-border-lg justify-content-center mr-2"></div>
        </div>
      ) : (
        <>
          {rfp?.rfp_status === '-2' && !isLoading && (
            <div style={{ display: 'inline-flex', float: 'right', position: 'relative', bottom: '1rem' }}>
              <button style={{
                padding: "5px 10px",
                borderRadius: "10px",
                background: "#FFFFFF",
                color: "#737A91",
                border: '1px solid #DFE2E6',
                marginRight: '1rem',
              }}
                // onClick={() => saveToDraft()}
                onClick={() => handleSubmit(onSubmit)()}
              >
                Save to Draft
              </button>

              <button style={{
                padding: "8px 30px",
                background: "#00B0A5",
                borderRadius: "10px",
                color: "white",
              }}
                onClick={handlePublish}
              >
                {isLoading ? <div className="spinner-border spinner-border-primary spinner-border-sm mr-2"></div> : "Publish"}
              </button>
            </div>
          )}
          <h5 className="header main">
            Project Detail <span>{rfp?.rfp_code}</span>
            <button style={{
              padding: '8px 16px',
              borderRadius: '10px',
              marginLeft: '1rem',
              fontSize: '16px',
              background: rfp?.rfp_status === '1' ? 'rgba(0, 176, 7, 0.15)' :
                rfp?.rfp_status === '-1' ? 'rgba(239, 196, 66, 0.15)' :
                  rfp?.rfp_status === '-2' ? 'rgba(245, 150, 37, 0.15)' :
                    rfp?.rfp_status === '2' ? 'rgba(245, 150, 37, 0.15)' : 'rgba(0, 176, 7, 0.15)',
              color: rfp?.rfp_status === '1' ? 'rgb(56, 124, 87)' :
                rfp?.rfp_status === '-1' ? 'rgb(47, 128, 237)' :
                  rfp?.rfp_status === '-2' ? 'rgb(245, 150, 37)' : 'rgb(245, 150, 37)',
            }}>
              {rfp?.rfp_status === '-2' ? 'Draft' :
                rfp?.rfp_status === '-1' ? 'Pending' :
                  rfp?.rfp_status === '1' ? 'Approved' :
                    rfp?.rfp_status === '2' ? 'Closed' :
                      rfp?.rfp_status === '3' ? 'In Selection' :
                        rfp?.rfp_status === '4' ? 'Awarded' : null}
            </button>
          </h5>
          <h5 className="sub">{rfp?.rfp_title}</h5>
          <div className="requisition-details">
            <h5 className="header">Requisition Details</h5>
            <div className="container">

              <div className="row mb-5">
                <div className="col-md-4 col-lg-4 col-xs-12">
                  <div className="mb-3">
                    <p className="page-title">Document Number</p>
                    <p>{rfp?.requisition_code}</p>
                  </div>
                  <div className="mb-3">
                    <p className="page-title">Head Of Department</p>
                    <p>{rfp?.department?.[0]?.hod_lastname || 'n/a'} {rfp?.department?.[0]?.hod_othernames}</p>
                  </div>
                </div>
                <div className="col-md-4 col-lg-4 col-xs-12">
                  <div className="mb-3">
                    <p className="page-title">Approved On</p>
                    <p>{rfp?.approved_at || 'n/a'}</p>
                  </div>
                  <div className="mb-3">
                    <p className="page-title">Requesting Department</p>
                    <p>{rfp?.department_description}</p>
                  </div>
                </div>
                <div className="col-md-4 col-lg-4 col-xs-12">
                  <div className="mb-3">
                    <p className="page-title">Date Of Request</p>
                    <p>{rfp?.created_at}</p>
                  </div>
                  <div className="mb-3">
                    <p className="page-title">Requester</p>
                    <p>{rfp?.requisition_requester?.lastname || 'n/a'}</p>
                  </div>
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-12">
                  <table>
                    <thead>
                      <tr>
                        <th style={{ padding: '1rem' }}>Item</th>
                        <th>Mat. No.</th>
                        <th>Mat. Cat.</th>
                        <th>Product Description</th>
                        <th>Quantity</th>
                        <th>UoM</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rfp?.materials?.map((item, i) => (
                        <tr key={i} className="border-container mb-3">
                          <td style={{ padding: '1rem' }}>{i + 1}</td>
                          <td>{item?.material_code}</td>
                          <td>{item?.material_category_description}</td>
                          <td>{item?.material_description}</td>
                          <td>{item.quantity_required} </td>
                          <td>{item.uom}</td>
                          <td>{item.estimated_unit_price}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

            </div>
          </div>

          <div className="additional-information">
            <h5 className="header main">Additional Information</h5>
            <span className="width-60">
              <span style={{
                marginBottom: "0.5rem", display: "block", color: '#3d59b7',
                lineHeight: '36px',
                fontWeight: 'bold',
                fontFamily: "Averia-bold"
              }}>Requirements Description</span>
              <textarea
                rows={4}
                className="comments text-area mt-4"
                placeholder="Enter Requirements Description"
                name="rfp_description"
                ref={register({ required: false })}
                style={{
                  resize: "none",
                  border: "1px solid #E8E8E8",
                  borderRadius: "1rem",
                  width: "100%",
                  outline: "none",
                  padding: "1rem"
                }}
                onChange={(e) => {
                  setTempData({
                    ...tempData,
                    rfp_description: e.target.value
                  })
                }}
              />

              <span className="flex">
                <label htmlFor="estimate" style={{
                  color: '#3d59b7',
                  lineHeight: '36px',
                  fontWeight: 'bold',
                  fontFamily: "Averia-bold"
                }}>In-house Estimate</label>
                <input
                  type="text"
                  placeholder="Value From Market Research"
                  name="in_house_estimate"
                  ref={register({ required: false })}
                  onChange={(e) => {
                    setTempData({
                      ...tempData,
                      in_house_estimate: e.target.value
                    })
                  }}
                  style={{ display: 'inline-block' }}
                />
              </span>

              <span className="flex">
                <label htmlFor="" style={{
                  color: '#3d59b7',
                  lineHeight: '36px',
                  fontWeight: 'bold',
                  fontFamily: "Averia-bold"
                }}>Qualifying percentage</label>
                <input
                  max="100"
                  name="technical_passing_score"
                  type="number"
                  ref={register({ required: false })}
                  onChange={(e) => {
                    setTempData({
                      ...tempData,
                      technical_passing_score: e.target.value
                    })
                  }}
                  placeholder="Qualifying percentage"
                  style={{ display: 'inline-block' }} />
              </span>

              <span className="flex">
                <label htmlFor="upload" style={{
                  color: '#3d59b7',
                  lineHeight: '36px',
                  fontWeight: 'bold',
                  fontFamily: "Averia-bold"
                }}>Upload Attachment</label>
                <span style={{ display: "inline-flex", position: 'relative', top: '1.5rem' }}>
                  <FileUploaderBox
                    fileName="document_url"
                    placeholder="Browse your Computer"
                    boxClasses="mt-3 mb-2"
                    handleFile={handleFile}
                    acceptFiles={".jpg, .jpeg, .png"}
                    customRef={register({ required: false })}
                  />
                </span>
                {rfp?.document_url?.length > 0 && (
                  <div style={{ paddingTop: '1rem', color: '#4061cb' }}>
                    <span onClick={() => openInNewTab(rfp?.document_url)} style={{ display: "table-cell", cursor: 'pointer' }}>
                      Download Attachment Here
                    </span>
                  </div>
                )}
              </span>
            </span>
          </div>

          <div className="additional-information">
            <h5 className="header main">Tender Team</h5>
            <span className="width-60" style={{ display: 'inline-flex' }}>
              {rfp?.sourcing_team?.[0]?.team_members?.map((item, index) => (
                <div key={index} style={{ margin: '10px', padding: '5px', minWidth: 'fit-content' }}>
                  <img src={silh} alt="person img" style={{ height: '15px' }} /> <span style={{ display: 'inline-block' }}>{item?.lastname} {item?.othernames}</span>
                </div>
              ))}
            </span>
          </div>
        </>
      )}
    </Wrapper>
  );
});

export default Overview;
import { toastr } from "react-redux-toastr";
import { AppThunk, AppDispatch } from "store/store";
import { Action, SupplierUserPayload, SupplierUserState } from "./interface";
import axios from "axios";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import API from "utils/httpsRequest";

const initialState: SupplierUserState = {
  isLoading: false,
  users: null,
  singleUser: null,
  success: "",
  errorMessage: ""
};

const SupplierUserSlice = createSlice({
  name: "supplier_user",
  initialState,
  reducers: {
    createSupplierUserLoading: (state: SupplierUserState) => {
      state.isLoading = true;
    },
    createSupplierUserSuccess: (state: SupplierUserState, action: PayloadAction<string>) => {
      state.isLoading = true;
      state.success = action.payload;
    },
    createSupplierUserFail: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },
    getSupplierUserLoading: (state) => {
      state.isLoading = true;
    },
    getSupplierUserSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoading = false;
      state.singleUser = action.payload;
    },
    getSupplierUserFail: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },
    getSupplierUsersLoading: (state) => {
      state.isLoading = true;
    },
    getSupplierUsersSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoading = false;
      state.users = action.payload;
    },
    getSupplierUsersFail: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },
    editSupplierUserLoading: (state) => {
      state.isLoading = true;
    },
    editSupplierUserSuccess: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.success = action.payload;
    },
    editSupplierUserFail: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    }
  }
});

const { actions, reducer } = SupplierUserSlice;

export const {
  createSupplierUserLoading,
  createSupplierUserSuccess,
  createSupplierUserFail,
  getSupplierUserLoading,
  getSupplierUserSuccess,
  getSupplierUserFail,
  getSupplierUsersLoading,
  getSupplierUsersSuccess,
  getSupplierUsersFail,
  editSupplierUserLoading,
  editSupplierUserSuccess,
  editSupplierUserFail
} = actions;

export const getSupplierUsers =
  (page?: number, length?: number, search?: string, user_type?: string, sortBy?: string, orderBy?: string): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getSupplierUsersLoading());
    try {
      const response = await API.get(`/users/get`, {
        params: {
          page,
          length,
          search,
          user_type,
          sortBy,
          orderBy
        },
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json"
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getSupplierUsersSuccess(response.data));
      }
    } catch (error) {
      dispatch(getSupplierUsersFail("Request failed"));
      toastr.error(error?.response?.data.message);
    }
  };
export const getSupplierUser =
  (user_id: string): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getSupplierUserLoading());
    try {
      const response = await API.get(`/users/get/${user_id}`, {
        headers: { Authorization: `Bearer ${userToken}` }
      });

      dispatch(getSupplierUserSuccess(response.data));
    } catch (error) {
      dispatch(getSupplierUserFail("Request failed"));
    }
  };

export const createSupplierUser =
  (payload: SupplierUserPayload, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    dispatch(createSupplierUserLoading());
    const { userToken } = getState().user;
    try {
      const response = await API.post(`/users/create`, payload, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json"
        }
      });
      dispatch(createSupplierUserSuccess(response.data.message));
      toastr.success("", response.data.message);
      dispatch(getSupplierUsers());
      cb && cb();
    } catch (error) {
      dispatch(createSupplierUserFail(error?.response?.data?.error));
      toastr.error(error?.response?.data.message);
    }
  };

export const editSupplierUser =
  (id: string, data: any, cb: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(editSupplierUserLoading());
    try {
      const response = await API.post(`/users/edit`, data, {
        headers: { Authorization: `Bearer ${userToken}`, "Content-Type": "application/json" }
      });
      dispatch(editSupplierUserSuccess(response.data.message));
      toastr.success("", response.data.message);
      cb();
    } catch (error) {
      dispatch(editSupplierUserFail(error.response.data.message));
      toastr.error(error.response.data.message);
    }
  };

export default reducer;

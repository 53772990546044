import axios from "axios";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch, AppThunk } from "store/store";
import { toastr } from "react-redux-toastr";
import { InvoiceState } from "./interface";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const initialState: InvoiceState = {
  isLoading: false,
  invoices: null,
  invoice: null,
  summary: null
};

const invoiceSlice = createSlice({
  name: "invoice",
  initialState,
  reducers: {
    startRequest: (state) => {
      state.isLoading = true;
    },
    endRequest: (state) => {
      state.isLoading = false;
    },
    getAllInvoices: (state, action) => {
      state.invoices = action.payload;
    },
    getSingleInvoice: (state, action) => {
      state.invoice = action.payload;
    },
    getSummary: (state, action) => {
      state.summary = action.payload;
    }
  }
});

const { actions, reducer } = invoiceSlice;

export const { startRequest, endRequest, getAllInvoices, getSingleInvoice, getSummary } = actions;

export const confirmInvoice =
  (data?: object, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    dispatch(startRequest());
    const { userToken } = getState().user;
    try {
      const response = await axios.post(
        `${BASE_URL}/invoices/confirm`,
        { ...data },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json"
          }
        }
      );
      if ([200, 201].includes(response.status)) {
        dispatch(endRequest());
        toastr.success(response.data.message);
        cb && cb();
      }
    } catch (err) {
      dispatch(endRequest());
      toastr.error(err?.response?.data?.message);
    }
  };

export const createInvoice =
  (data?: object, cb?: (item) => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    dispatch(startRequest());
    const { userToken } = getState().user;
    try {
      const response = await axios.post(
        `${BASE_URL}/invoices/create`,
        { ...data },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json"
          }
        }
      );
      if ([200, 201].includes(response.status)) {
        dispatch(endRequest());
        toastr.success(response.data.message);
        cb && cb(response.data.data.id);
      }
    } catch (err) {
      dispatch(endRequest());
      toastr.error(err?.response?.data?.message);
    }
  };

export const createInvoiceNonPO =
  (data?: object, cb?: (data) => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    dispatch(startRequest());
    const { userToken } = getState().user;
    try {
      const response = await axios.post(`${BASE_URL}/invoices/create_non_po`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json"
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(endRequest());
        toastr.success(response.data.message);
        cb && cb(response.data.data.id);
      }
    } catch (err) {
      dispatch(endRequest());
      toastr.error(err?.response?.data?.message);
    }
  };

export const getInvoiceSummary =
  (vendor_id?: string, purchase_order_id?: string): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    dispatch(startRequest());
    const { userToken } = getState().user;
    try {
      const response = await axios.get(`${BASE_URL}/invoices/summary`, {
        params: {
          vendor_id,
          purchase_order_id
        },
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json"
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(endRequest());
        dispatch(getSummary(response.data.data));
      }
    } catch (err) {
      dispatch(endRequest());
      toastr.error(err?.response?.data?.message);
    }
  };

export const getInvoice =
  (id?: string): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    dispatch(startRequest());
    const { userToken } = getState().user;
    try {
      const response = await axios.get(`${BASE_URL}/invoices/get/${id}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json"
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(endRequest());
        dispatch(getSingleInvoice(response.data.data?.[0]));
      }
    } catch (err) {
      dispatch(endRequest());
      toastr.error(err?.response?.data?.message);
    }
  };

export const getInvoices =
  (
    page?: any,
    length?: any,
    search?: string,
    company_id?: string,
    date_from?: string,
    date_to?: string,
    purchase_order_code?: string,
    purchase_order_id?: string,
    vendor_id?: string,
    invoice_code?: string,
    supplier_invoice_number?: string
  ): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    dispatch(startRequest());
    const { userToken } = getState().user;

    try {
      const response = await axios.get(`${BASE_URL}/invoices/get?`, {
        params: {
          page,
          length,
          search,
          company_id,
          date_from,
          date_to,
          purchase_order_code,
          purchase_order_id,
          vendor_id,
          invoice_code,
          supplier_invoice_number
        },
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json"
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getAllInvoices(response.data));
      }
      dispatch(endRequest());
    } catch (err) {
      dispatch(endRequest());
      toastr.error(err?.response?.data?.message);
    }
  };

export const updateInvoice =
  (data?: object, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    dispatch(startRequest());
    const { userToken } = getState().user;
    try {
      const response = await axios.post(
        `${BASE_URL}/invoices/edit`,
        { ...data },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json"
          }
        }
      );
      if ([200, 201].includes(response.status)) {
        dispatch(endRequest());
        toastr.success(response.data.message);
        cb && cb();
      }
    } catch (err) {
      dispatch(endRequest());
      toastr.error(err?.response?.data?.message);
    }
  };
export const creteLineItems =
  (data?: object, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    dispatch(startRequest());
    const { userToken } = getState().user;
    try {
      const response = await axios.post(
        `${BASE_URL}/invoices/create_non_po_item`,
        { ...data },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json"
          }
        }
      );
      if ([200, 201].includes(response.status)) {
        dispatch(endRequest());
        toastr.success(response.data.message);
        cb && cb();
      }
    } catch (err) {
      dispatch(endRequest());
      toastr.error(err?.response?.data?.message);
    }
  };

export const editLineItems =
  (data?: object, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    dispatch(startRequest());
    const { userToken } = getState().user;
    try {
      const response = await axios.post(
        `${BASE_URL}/invoices/edit_non_po_item`,
        { ...data },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json"
          }
        }
      );
      if ([200, 201].includes(response.status)) {
        dispatch(endRequest());
        toastr.success(response.data.message);
        cb && cb();
      }
    } catch (err) {
      dispatch(endRequest());
      toastr.error(err?.response?.data?.message);
    }
  };

export const uploadLogo =
  (data?: object, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    dispatch(startRequest());
    const { userToken } = getState().user;
    try {
      const response = await axios.post(
        `${BASE_URL}/vendors/update_logo`,
        { ...data },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json"
          }
        }
      );
      if ([200, 201].includes(response.status)) {
        dispatch(endRequest());
        toastr.success(response.data.message);
        cb && cb();
      }
    } catch (err) {
      dispatch(endRequest());
      toastr.error(err?.response?.data?.message);
    }
  };

export const setCostBudget =
  (data?: object, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    dispatch(startRequest());
    const { userToken } = getState().user;
    try {
      const response = await axios.post(
        `${BASE_URL}/invoices/update_cost_center_and_budget`,
        { ...data },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json"
          }
        }
      );
      if ([200, 201].includes(response.status)) {
        dispatch(endRequest());
        toastr.success(response.data.message);
        cb && cb();
      }
    } catch (err) {
      dispatch(endRequest());
      toastr.error(err?.response?.data?.message);
    }
  };

export default reducer;

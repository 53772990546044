export const tableCol = [
    {
        name: "Process",
        index: "process",
        id: 1
    },
    {
        name: "Details",
        index: "details",
        id: 2
    },
    {
        name: "Requested date",
        index: "delivery_date",
        id: 3
    },
    {
        name: "Requester",
        index: "requester",
        id: 4
    },
    // {
    //     name: "Price",
    //     index: "price",
    //     id: 5
    // },
    // {
    //     name: "Budget Utilization",
    //     index: "budget",
    //     id: 6
    // },
];

export const tableData = [
    {
        delivery_date: "2nd Sept. 2019",
        process: "78600090",
        details: "Brent Fawaz",
        requester: "HQ",
        price: "Service",
        budget: "Service",
        id: 1
    },
];

import React, { useEffect, useState, useRef, useCallback } from "react";
import "./styles.scss";
import StatsCard from "components/StatsCard";
import DataTable from "components/DataTable";
import { documentsColumns, documents } from "./data";
import Cash from "assests/icons/hand.svg";
import Contract from "assests/icons/order.svg";
import Truck from "assests/icons/truck2.svg";
import Invoice from "assests/icons/bill.svg";
import CardContainer from "components/CardContainer";
import SearchIcon from "components/SearchBar";
import { useDispatch, useSelector } from "react-redux";
// import { getHouseholdSummary, getAllHousehold } from "store/slice/HouseHoldsSlice/HouseholdSlice";
import { RootState } from "store/store";
import Pagination from "components/Pagination";
import Dropdown from "components/Dropdown";
import useSearch from "hooks/useSearch";
import useArrowDropdown from "hooks/useArrowDropdown";
import { getDocuments } from "store/slice/BuyerSlice/DocumentSlice/DocumentSlice";
import { getPOSummaries, getPOSummariesParam } from "store/slice/SummarySlice/SummarySlice";
// import { getCommunity } from "store/slice/CommunitySlice/communitySlice";
import Star from "components/Star";
import { formatCurrency } from "utils/utils";
import { getInvoiceSummary } from "store/slice/BuyerSlice/Invoice/InvoiceSlice";

type Props = {};

const Contracts: React.FunctionComponent<Props> = () => {
  const [page, setPage] = useState<number>(1);
  const [perpage, setPerpage] = useState<number>(10);
  const [length, setlength] = React.useState<number>(10);
  const [outDel, setOutdel] = React.useState("0");
  const [selectComboxOption, setSelectComboxOption] = React.useState("");
  const dispatch = useDispatch();
  const { dashboardSummary, isLoading } = useSelector((state: RootState) => state.dashboard);
  const { POSummaries } = useSelector((state: RootState) => state.summaries) as any;
  const { documents } = useSelector((state: RootState) => state.document);
  const { summary } = useSelector((state: RootState) => state.invoice) as any;

  //hook
  const { user } = useSelector((state: RootState) => state.user);

  //Hook
  const { search, inputRef, handleChange } = useSearch(getDocuments, length);

  useEffect(() => {
    dispatch(getDocuments(page, length));
    dispatch(getPOSummaries());
    dispatch(getPOSummaries());
    dispatch(
      getPOSummariesParam(undefined, undefined, "3", (data) => {
        setOutdel(data?.overall?.total_purchase_orders);
      })
    );
    dispatch(getInvoiceSummary(user?.vendor_id));
  }, []);

  //--------------------------------------------
  //Pagination
  //---------------------------------------------
  const handlePageChange = React.useCallback(
    (page: number, length: number) => {
      setPage(page);
      dispatch(getDocuments(page, length, search));
    },
    [search]
  );

  const paginate = React.useCallback(
    (pageNo: number, length: number) => {
      setPage(pageNo);
      dispatch(getDocuments(pageNo, length));
    },
    [search]
  );

  //------------------------------------------------------------
  //Show Number for length Function
  //------------------------------------------------------------
  const handleNewPage = React.useCallback(
    (pageNo: number, page = 1) => {
      setlength(pageNo);
      setPage(1);
      dispatch(getDocuments(page, pageNo, search));
    },
    [search]
  );

  const tableData =
    documents &&
    documents?.data?.map((data: any) => {
      const { purchase_order_status } = data;
      let status;
      if (purchase_order_status === "-2") {
        status = "draft";
      } else if (purchase_order_status === "-1") {
        status = "Pending Approval";
      } else {
        status = "Approved";
      }
      return {
        created_at: data?.purchase_order_date,
        // created_at: data?.created_at,
        purchase_no: data?.purchase_order_code,
        description: data?.vendor_description,
        delivery_loca: `${data?.street_number} ${data?.street_name}`,
        company_description: data?.company_description,
        price: formatCurrency(data?.total_value),
        currency: data?.currency_description,
        status,
        id: data?.id
      };
    });

  return (
    <div id="contracts-page">
      <div className="container">
        <div className="d-flex align-items-center mb-3">
          <h4 className="page-title mr-2 ">
            {user?.vendor_description} | {user?.vendor_code}
          </h4>
          <Star stars={3} disabled={true} />
        </div>
        <div className=" row mb-5">
          <StatsCard statsNumber={POSummaries?.overall?.total_purchase_orders} text="Purchase Orders" classes="stats-icon-orange" image={Contract} />

          <StatsCard statsNumber={outDel || 0} text="Out For Delivery" classes="stats-icon-leaf" image={Truck} />

          <StatsCard statsNumber={summary?.overall?.total_invoices || 0} text="Invoice" classes="stats-icon-folder" image={Invoice} />

          <StatsCard statsNumber={POSummaries?.overall?.total_purchase_order_value} text="Total Amount" classes="stats-icon-cash" image={Cash} amount="" />
        </div>

        <h4 className="page-title mb-2">Recent Documents</h4>
        <div className="search-field">
          <SearchIcon placeholder="Search for PO ID, Description, name, or Invoice ID" boxClasses=" mb-4" textRef={inputRef} value={search} onChange={handleChange} />
          <Dropdown perPage={perpage} action={handleNewPage} />
        </div>

        <DataTable columns={documentsColumns} data={tableData} />
        <Pagination page={page} lastPage={documents?.pagination?.pageCount} paginate={paginate} total={documents?.pagination?.total} handlePageChange={handlePageChange} perPage={length} />
      </div>
    </div>
  );
};

export default Contracts;

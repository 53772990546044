/* eslint-disable react-hooks/exhaustive-deps */
import StatsCard from "components/StatsCard";
import Icon from "assests/icons/statsCard/Vector (4).png";
import SearchBar from "../../../../components/SearchBar";
import Select from "react-select";
import Dropdown from "components/Dropdown";
import DataTable from "components/DataTable";
import { reportCol } from "./data";
import "./styles.scss";
import React, { useEffect, useState } from "react";
import { AppDispatch, RootState } from "store/store";
import { useDispatch, useSelector } from "react-redux";
import useSearch from "hooks/useSearch";
import CreateOrEditReport from "components/ModalBuyer/CreateOrEditReport";
import Pagination from "components/Pagination";
import { getAllReport } from "store/slice/BuyerSlice/ReportSlice/RepotSlice";
import lineImg from "../../../../assests/icons/line-chart.png";
import dateImg from "../../../../assests/icons/calendar.png";
import SelectDropdown from "components/SelectDropdown";
import { Controller, useForm } from "react-hook-form";
import AsyncSelect from "react-select/async";
import API from "utils/httpsRequest";
import { useHistory } from "react-router";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import { kFormatter } from "utils/utils";
import moment from "moment";
import { Pie, Doughnut, Line, Bar } from "react-chartjs-2";

import { getBbbCompanySummary, getCompanySummary, getCompanyVendorSummary, getDeptSummary, getLevelSummary, getVendorSummary } from "store/slice/SummarySlice/SummarySlice";

const ReportBbbe = () => {
  const [singleReport, setSingleReport] = useState<any>(null);

  const dispatch = useDispatch<AppDispatch>();
  const { reports, loading } = useSelector((state: RootState) => state.report);
  const { locations } = useSelector((state: RootState) => state.location);
  const [modal, setModal] = useState<boolean>(false);

  const [vendorSel, setVendorSel] = React.useState("");
  const [selVendorVal, setSelVendorVal] = React.useState("");
  const [vendor, setVendor] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [levelSel, setLevelSel] = React.useState("");
  const [selLevelVal, setSelLevelVal] = React.useState("");
  const [level, setLevel] = useState("");
  const [levelName, setLevelName] = useState("");
  const [sumComp, setSumComp] = useState(0);
  const [sumNcomp, setSumNcomp] = useState(0);
  const [sumExp, setSumExp] = useState(0);
  // const [sumIso, setSumIso] = useState(null);
  const [firstFive, setFirstFive] = useState<any>([]);

  // const [defaultLegal, setDefaultLegal] = useState([{

  //     label: "GZ Industries Ltd - Nigeria",
  //     value: "10002"

  // }]);
  const [newLocationOption, setNewLocationOption] = useState([]);


  const [seen, setSeen] = useState(true);
  const { user } = useSelector((state: RootState) => state.user);
  const { token } = user;
  const [comply,setComply ] = useState([])
  const [unComply,setUnComply] = useState([])


  // from slice

  const { bbbCompany, isLoadingDept,isLoadingLevel,levelSummary } = useSelector((state: RootState) => state.summaries);

  const { companyVendor } = useSelector(
    (state: RootState) => state.summaries
  );

  // setting summary place
  useEffect(() => {
  
    companyVendor && companyVendor.data.length ? setSumExp(companyVendor.data[0].total_expiring_documents) : setSumExp(0);
    let newArr = bbbCompany ? bbbCompany.data.length ? bbbCompany.data.filter((item)=>{
      return item.value !== "Non Compliant"
    }) : [] : []
    let newArr2 = bbbCompany ? bbbCompany.data.length ? bbbCompany.data.filter((item)=>{
      return item.value === "Non Compliant"
    }) : [] : []
    newArr.length ? setSumComp(newArr.length) : setSumComp(0);
    newArr2.length ? setSumNcomp(newArr2.length) : setSumNcomp(0);
   



  }, [bbbCompany,companyVendor]);

  

  // setting pie chat data

  const [labelList, setLabelList] = useState<string[]>([]);
  // const [onceSeen, setOnceSeen] = useState(true);
  const [pointScore, setPointScore] = useState<number[]>([]);
  let onceSeen = true;

  useEffect(() => {
   
    const filt =
    levelSummary &&
    levelSummary?.data
        ?.filter((val, i) => i <= 5)
        ?.filter((item) => {
          return item;
        });

   
    if (filt) {
      if (filt.length) {
        setFirstFive(filt);
        onceSeen = true;
        if (onceSeen) {
          let testArray: string[] = [];
          let testScore: number[] = [];
          // item.department_description.split(" ")[0]
          filt.forEach((item) => testArray.push(item.value));
          filt.forEach((item) => testScore.push(item.total_vendors));
          setLabelList([...testArray]);
          setPointScore([...testScore]);

          onceSeen = false;
         
        }
      } else {
        setLabelList([]);
        setPointScore([]);
      }
    }
  }, [levelSummary]);

  const [dateRange, setDateRange] = useState([
    {
      // ate_from=&date_to=
      startDate: new Date(2021, 1, 1),
      endDate: new Date(),
      key: "selection"
    }
  ]);

  const [newDate, setNewDate] = useState([{}]);

  useEffect(() => {
    setNewDate([
      {
        startDate: dateRange[0].startDate,
        endDate: dateRange[0].endDate
      }
    ]);
  }, [dateRange]);


  const [showRange, setShowRange] = useState(false);

  const handleRangeShow = () => {
    setShowRange(!showRange);
  };

  const history = useHistory();

  const closeModal = () => {
    setModal((prev) => !prev);
    setSingleReport(null);
  };

  const handleInputChangeVendor = (value) => {
    setVendorSel(value);
  };

  const onChangeVendor = (value) => {
    setVendor(value.value);
    setVendorName(value.label);
    setSelVendorVal(value);
  };

  const handleInputChangeLevel= (value) => {
    setLevelSel(value);
  };

  const onChangeLevel = (value) => {
    setLevel(value.value);
    setLevelName(value.label);
    setSelLevelVal(value);
  };


  const vendorOptions = () => {
    return API.get(`/vendors/get?search=${vendorSel}&length=1000&company_id=${10001}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    }).then((res) => {
      return res.data.data.map((item) => ({
        label: item.vendor_description,
        value: item.id
      }));
     
    });
  };



  const levelOptions = [
    {
      label: "Level 1",
      value: "Level 1"
    },
    {
      label: "Level 2",
      value: "Level 2"
    },
    {
      label: "Level 3",
      value: "Level 3"
    },
    {
      label: "Level 4",
      value: "Level 4"
    },
    {
      label: "Level 5",
      value: "Level 5"
    },
    {
      label: "Level 6",
      value: "Level 6"
    },
    {
      label: "Level 7",
      value: "Level 7"
    },
    {
      label: "Level 8",
      value: "Level 8"
    },
    {
      label: "Non Compliant",
      value: "Non Compliant"
    }

  ];




  const { register, control, handleSubmit, errors, setValue } = useForm<FormData>({
    mode: "onChange"
  });


  const lineData = {
    labels:  labelList.length && labelList,
    datasets: [
      {
        label: "Vendor by Level Chart",
        backgroundColor:  ["#05445E", "#00B01C", "#A6CEE3","#189AB4","#75E6DA", "#05445E" ],
        borderColor:  ["#05445E", "#00B01C", "#A6CEE3","#189AB4","#75E6DA", "#05445E" ],
        fill: true,
        data: pointScore.length && pointScore
      }
    ]
  };

  // get department Summary
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

   // get company Summary
   useEffect(() => {
    dispatch(
      getCompanyVendorSummary({
        company_id: "10001",
        date_from: moment(dateRange[0].startDate).format("YYYY-MM-D"),
        date_to: moment(dateRange[0].endDate).format("YYYY-MM-DD"),
      })
    );
  }, [newDate]);


  //Pagination
  //---------------------------------------------
  const handlePageChange = React.useCallback((page: number, perPage: number) => {
    setPage(page);
    dispatch(
      getLevelSummary({
        date_from: moment(dateRange[0].startDate).format("YYYY-MM-D"),
        date_to: moment(dateRange[0].endDate).format("YYYY-MM-DD"),
        length: perPage,
        level:level,
        page: page,
        vendor:vendor
      })
    );
  }, []);

  const paginate = React.useCallback((pageNo: number, perPage: number) => {
    setPage(pageNo);
    dispatch(
      getLevelSummary({
        date_from: moment(dateRange[0].startDate).format("YYYY-MM-D"),
        date_to: moment(dateRange[0].endDate).format("YYYY-MM-DD"),
        length: perPage,
        level:level,
        page: page,
        vendor:vendor
      })
    );
  }, []);

  useEffect(() => {
    dispatch(
      getBbbCompanySummary({
        date_from: moment(dateRange[0].startDate).format("YYYY-MM-D"),
        date_to: moment(dateRange[0].endDate).format("YYYY-MM-DD"),
        length: perPage,
        level:level,
        page: page,
        vendor:vendor
      })
    );
  }, [ vendor, newDate, level]);

  useEffect(() => {
    dispatch(
      getLevelSummary({
        date_from: moment(dateRange[0].startDate).format("YYYY-MM-D"),
        date_to: moment(dateRange[0].endDate).format("YYYY-MM-DD"),
        length: perPage,
        level:level,
        page: page,
        vendor:vendor
      })
    );
  }, [ vendor, newDate,level]);

 
  const reportList =
  levelSummary &&
  levelSummary?.data?.map((company) => {
      return {
        id: company.vendor_id,
        vendor_code: company.value,
        vendor_description: company.total_vendors,
       
      };
    });



  // removeFilter

  const removeFilter=(nameFilt)=>{
    if (nameFilt==="date") {
      setDateRange([
        {
          // ate_from=&date_to=
          startDate: new Date(2021, 1, 1),
          endDate: new Date(),
          key: "selection"
        }
      ])
    }
    if (nameFilt === "vendor") {
     setVendor("")
     setVendorName("")
    
    }
    if (nameFilt==="invoice") {
    setLevel("")
    setLevelName("")
    }
  }



  return (
    <div id="report">
      {modal && <CreateOrEditReport closeModal={closeModal} />}

      <div className="container">
        <div className="filterContainer">
          <div className="titleSide">
            <div className="left">
              <img src={lineImg} alt="line icon" />
              <p>Bbbe Report </p>
            </div>
          </div>

          <div className="filters">
            <div className="dateFilter each">
              <div onClick={handleRangeShow} className="dateBox">
                <p className="date-range">
                  {` ${moment(dateRange[0].startDate).format("MM/DD/YYYY")} - ${moment(dateRange[0].endDate).format("MM/DD/YYYY")}`}

                  {/* moment(dateRange[0].startDate).format('MM/DD/YYYY') */}
                </p>
                <img src={dateImg} alt="date icon" />
              </div>

              {showRange && (
                <div className="rangeDiv">
                  <DateRangePicker
                    onChange={(item) => setDateRange([item.selection])}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={2}
                    ranges={dateRange}
                    direction="horizontal"
                    preventSnapRefocus={true}
                    calendarFocus="backwards"
                  />
                    <div onClick={handleRangeShow} className="applyBtn">
                      <button type="button">Apply</button>
                    </div>
                </div>
              )}
            </div>

            <div className="CompanyFilter each">
              <Controller
                control={control}
                defaultValue
                boxClasses="mt-3"
                name="vendor_id"
                render={({ ref }) => (
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    placeholder="Vendor"
                    value={selVendorVal}
                    loadOptions={vendorOptions}
                    onInputChange={handleInputChangeVendor}
                    onChange={onChangeVendor}
                  />
                )}
              />
            </div>

            <div className="groupFilter each">
               <Select
                classNamePrefix="react-select"
                className="react-select"
                closeOnSelect={false}
                id="services"
                printOptions="on-focus"
                placeholder="Level"
                onChange={onChangeLevel}
                options={levelOptions}
                // value={value}
                // ref={ref}
              />
            </div>
          </div>

          <div className="showing">
            <p className="text">Showing:</p>
            <div className="coverDisplay">
              <div className="displayDate">
                <p>{` ${moment(dateRange[0].startDate).format("MM/DD/YYYY")} - ${moment(dateRange[0].endDate).format("MM/DD/YYYY")}`}</p>
                <p onClick={()=>{removeFilter("date")}} className="cancel comp">x</p>
              </div>
              {vendorName && (
                <div className="displayDate">
                  <p className="location">{vendorName}</p>
                  <p onClick={()=>{removeFilter("vendor")}} className="cancel comp">x</p>
                </div>
              )}

              {levelName && (
                <div className="displayDate">
                  <p className="invoice">{levelName}</p>
                  <p onClick={()=>{removeFilter("invoice")}} className="cancel comp">x</p>
                </div>
              )}

            </div>
          </div>

          <div className="summary">
            <p className="topic">Orders</p>
            <div className="summaryCover">
              <div className="spent">
                <div className="moneySide">
                  {/* <p className="currency">$</p> */}
                  <p className="amountMoney">{kFormatter(sumExp, "")} </p>
                  <p className="currencyType"></p>
                </div>
                <p className="text">Total Expiry Documents</p>
              </div>

              <div className="spent mid">
                <div className="deptSide">
                  <p className="out">{kFormatter(sumComp, "")}</p>
                  {/* <p className="total">/14</p> */}
                </div>
                <p className="text">Total Complying Suppliers</p>
              </div>

              <div className="spent">
                <p className="purchase">{kFormatter(sumNcomp, "")}</p>
                <p className="text">Total UnComplying Suppliers</p>
              </div>
            </div>
          </div>
        </div>
        <div className="chatBox">
          <div className="chartFlex">
            <div style={{ width: "100%" }} className="pieChart">

              <Bar
                data={lineData}
                width={100}
                height={50}
                options={{
                  // title: {
                  //   display: true,
                  //   text: "Chart 1",
                  //   fontSize: 40
                  // },
                  maintainAspectRatio: false,

                  legend: {
                    display: true,
                    position: "right"
                  }
                }}
              />

            </div>
          </div>
        </div>
        
       
        <div className="document mt-5">
          <div className="mt-3">
            <DataTable columns={reportCol} data={reportList} loading={isLoadingLevel} />
            {levelSummary?.pagination?.total > 10 ? (
              <Pagination page={page} lastPage={levelSummary?.pagination?.lastPage} paginate={paginate} total={levelSummary?.pagination?.total} handlePageChange={handlePageChange} perPage={perPage} />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportBbbe;

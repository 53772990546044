import FileUploaderBox from "components/FileUploaderBox";
import SelectDropdown from "components/SelectDropdown";
import Textbox from "components/Textbox";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store/store";

type Props = {
  fields: any;
  responses: any;
  responseStat: boolean;
  updateComm: () => void;
};
const CommercialRequirements = ({ fields, responses, responseStat, updateComm }: Props) => {
  const { register, handleSubmit, errors, setValue, watch } = useForm<FormData>({ mode: "onBlur" });
  const values = watch();
  const dispatch = useDispatch<AppDispatch>();

  // const renderOptions = (options: string) => {
  //   const array = JSON.parse(options);
  //   return array.map((_data: { option_value: string; option_title: string }) => {
  //     return {
  //       value: _data.option_value,
  //       option_title: _data.option_title
  //     };
  //   });
  // };

  const renderOptions = (options: string) => {
    const array = JSON.parse(options);
    return array.map((_data: { option_value: string; option_title: string }) => {
      return {
        value: _data.option_value,
        optionName: _data.option_title
      };
    });
  };
  const radioOptions = (options: string, field_name: string, id: string) => {
    const array = JSON.parse(options);
    return array.map((_data: { option_title: string; option_value: string }) => {
      return (
        <div className="">
          <input type="radio" name={field_name} className="mr-2" value={_data?.option_value} onChange={(e) => handleChange(e, id)} ref={register({})} disabled={responseStat} />
          <label className="radio-box_label" htmlFor="yes">
            {_data.option_title}
          </label>
        </div>
      );
    });
  };
  const checkboxOptions = (options: string, field_name: string, id: string) => {
    const array = JSON.parse(options);
    const handleChange = ({ target: { name, value } }, id) => {
      let temp = { ...(data as any) };
      // console.log(data[name]);
      // console.log(data, "data");
      // let dataVal = [] as any;
      // if (data[name]) {
      //   dataVal = temp[name].vendor_response.split(",");
      // }
      // dataVal.push(value);
      // dataVal.reduce((acc, current) => {
      //   if(acc.includes(value)){
      //     acc.filter((val) => val !== value)
      //   }
      // }, [])
      temp[name] = {
        vendor_response: value,
        // vendor_response: dataVal.join(","),
        rfp_field_id: id,
        field_name: name
      };
      setData(temp);
    };
    return array.map((_data: { option_title: string; option_value: string }) => {
      return (
        <div className="">
          <input type="checkbox" name={field_name} className="mr-2" value={_data?.option_value} onChange={(e) => handleChange(e, id)} ref={register({})} disabled={responseStat} />
          <label className="radio-box_label" htmlFor="yes">
            {_data.option_title}
          </label>
        </div>
      );
    });
  };
  // Handle upload for files
  const handleFile = (e, field_id: any, field_name: any) => {
    const reader = new FileReader();
    const { name } = e;
    reader.readAsDataURL(e);
    reader.onloadend = () => {
      // let anchor = document.getElementById(`${field_name}_`) as any;

      let read: any = reader.result;
      const payload = {
        base64_data: read.replace("data:", "").replace(/^.+,/, ""),
        file_extension: e.name.split(".").pop()
      };
      // dispatch(uploadFile(payload)).then((data) => {
      let temp = { ...(data as any) };
      temp[field_name] = {
        vendor_response: read.replace("data:", "").replace(/^.+,/, ""),
        rfp_field_id: field_id,
        field_name: name,
        file_extension: e.name.split(".").pop()
      };
      setData(temp);
      // });
    };
  };
  const [data, setData] = React.useState({});
  const handleChange = ({ target: { name, value } }, id) => {
    let temp = { ...(data as any) };
    temp[name] = {
      vendor_response: value,
      rfp_field_id: id,
      field_name: name
    };
    setData(temp);
  };

  React.useEffect(() => {
    const fieldsData = JSON.parse(localStorage.getItem("Commercial") as any);
    if (fieldsData && fieldsData) {
      fieldsData.map((field) => {
        if (field.vendor_response.includes(`https`)) {
          let anchor = document.getElementById(`${field.field_name}_`) as any;
          if (anchor !== null) {
            if (field?.vendor_response && field?.vendor_response !== null) {
              anchor.text = field?.vendor_response;
              anchor.href = field?.vendor_response;
            } else {
              anchor.text = "";
              anchor.href = "";
            }
          }
        } else {
          setValue(field.field_name, field.vendor_response);
        }
      });
      const dataState = fieldsData.reduce((acc, field) => {
        let name = { ...acc };
        // if (field.category === "Commercial") {
        name[field.field_name] = {
          vendor_response: field.vendor_response,
          rfp_field_id: field.rfp_field_id,
          field_name: field.field_name
        };
        // console.log(name, "this na name");
        // }
        return {
          ...name
        };
      }, {});
      setData(dataState);
    }
  }, [fields]);

  React.useEffect(() => {
    if (responses?.length > 0) {
      responses.map((field) => {
        if (field.field_type !== "File" && field.category === "Commercial") setValue(field.field_name, field.vendor_response);
        if (field.field_type === "File" && field.category === "Commercial") {
          let anchor = document.getElementById(`${field.field_name}_`) as any;
          if (anchor !== null) {
            if (field?.vendor_response && field?.vendor_response !== null) {
              anchor.text = field?.vendor_response;
              anchor.href = field?.vendor_response;
              // localStorage.setItem("Commercial", JSON.stringify({ vendor_response: field.vendor_response, rfp_field_id: field.rfp_field_id, field_name: field.field_name }));
            } else {
              anchor.text = "";
              anchor.href = "";
            }
          }
        }
      });
      const valueData = responses.reduce((acc, current) => {
        if (current.category === "Commercial") {
          const data = {
            vendor_response: current.vendor_response,
            rfp_field_id: current.rfp_field_id,
            field_name: current.field_name
          };
          acc.push(data);
        }
        return acc;
      }, []);
      // localStorage.setItem("Commercial", JSON.stringify(valueData));
      const fieldsData = JSON.parse(localStorage.getItem("Commercial") as any);
      let dataObj = {};
      if (fieldsData && fieldsData !== null) {
        dataObj = fieldsData.reduce((acc, obj) => {
          let name = { ...acc };
          name[obj.field_name] = {
            vendor_response: obj.vendor_response,
            rfp_field_id: obj.rfp_field_id,
            field_name: obj.field_name
          };
          // console.log(name, "this na name");
          return {
            ...name
          };
        }, {});
      }

      const dataState = responses.reduce((acc, field) => {
        if (Object.keys(dataObj).length > 0) acc = dataObj;
        let name = { ...acc };
        if (field.category === "Commercial") {
          name[field.field_name] = {
            vendor_response: field.vendor_response,
            rfp_field_id: field.rfp_field_id,
            field_name: field.field_name
          };
          // console.log(name, "this na name");
        }
        return {
          ...name
        };
      }, {});
      setData(dataState);
    }
  }, [responses]);

  React.useEffect(() => {
    if(Object.keys(data).length > 0){
      updateComm()
    }
  }, [data])

  React.useEffect(() => {
    if (data && data) {
      const valueData = Object.entries(data).map(([key, value]) => {
        return value;
      });
      valueData.map((value: any) => {
        if (value.vendor_response.includes("https:")) {
          let anchor = document.getElementById(`${value.field_name}_`) as any;
          if (anchor !== null) {
            if (value?.vendor_response && value?.vendor_response !== null) {
              anchor.text = value?.vendor_response;
              anchor.href = value?.vendor_response;
              // localStorage.setItem("Commercial", JSON.stringify({ vendor_response: field.vendor_response, rfp_field_id: field.rfp_field_id, field_name: field.field_name }));
            } else {
              anchor.text = "";
              anchor.href = "";
            }
          }
        } else {
          setValue(value.field_name, value.vendor_response);
        }
      });
      if (Object.keys(data).length > 0) {
        localStorage.setItem("Commercial", JSON.stringify(valueData));
      }
    }
  }, [data]);

  return (
    <div className="route-transition">
      <div className="bid-technical-requirment-page">
        <div className="col-lg-10 col-md-12 pl-0">
          <form action="">
            {fields?.map((field, i) => {
              switch (field.type) {
                case "Text": {
                  return (
                    <div className="question-box mb-3" key={i}>
                      <label className="question-label" htmlFor="">
                        {field.label}
                      </label>
                      <textarea
                        name={field.name}
                        id="additionalComments"
                        cols={35}
                        rows={3}
                        className="textarea"
                        placeholder={field.label}
                        ref={register({ required: true })}
                        onChange={(e) => handleChange(e, field.id)}
                        disabled={responseStat}
                      />
                    </div>
                  );
                }
                case "Number": {
                  return (
                    <div className="question-box mb-3" key={i}>
                      <label className="question-label" htmlFor="">
                        {field.label}
                      </label>
                      <Textbox disabled={responseStat} type="number" label={field.label} name={field.name} customRef={register({})} onChange={(e) => handleChange(e, field.id)} placeholder={field.label} boxClasses="mt-3" />
                    </div>
                  );
                }
                case "Select": {
                  return (
                    <div className="question-box" key={i}>
                      {field?.options?.length > 0 && (
                        <label className="question-label" htmlFor="">
                          {field.label}
                        </label>
                      )}
                      <SelectDropdown
                        key={i}
                        boxClasses="mt-3 select"
                        name={field.name}
                        disabled={responseStat}
                        onChange={(e) => handleChange(e, field.id)}
                        //   label={''}
                        //   placeholder="City"
                        options={renderOptions(field.options)}
                        customRef={register({
                          // required: { value: requiredForm, message: "This field is required" }
                        })}
                        error={errors[field.name] && errors[field.name].message}
                      />
                    </div>
                  );
                }
                case "File": {
                  return (
                    <div className="question-box mb-3" key={i}>
                      <label className="question-label" htmlFor="">
                        {field.label}
                      </label>
                      <FileUploaderBox
                        key={i}
                        label={field.label}
                        customRef={register({
                          required: true
                        })}
                        disabled={responseStat}
                        handleFile={(e) => {
                          handleFile(e, field.id, field.name);
                        }}
                        boxClasses="mt-3"
                        fileName={field.name}
                        error={errors[field.name] && "This field is required"}
                      />
                    </div>
                  );
                }
                case "Radio": {
                  return (
                    <div className="question-box" key={i}>
                      {field?.options?.length > 0 && (
                        <label className="question-label" htmlFor="">
                          {field.label}
                        </label>
                      )}
                      {radioOptions(field.options, field.name, field.id)}
                    </div>
                  );
                }
                case "Checkbox": {
                  return (
                    <div className="question-box" key={i}>
                      {field?.options?.length > 0 && (
                        <label className="question-label" htmlFor="">
                          {field.label}
                        </label>
                      )}
                      {checkboxOptions(field.options, field.name, field.id)}
                    </div>
                  );
                }
                case "Date": {
                  return (
                    <div className="question-box" key={i}>
                      {field?.options?.length > 0 && (
                        <label className="question-label" htmlFor="">
                          {field.label}
                        </label>
                      )}
                      {/* {checkboxOptions(field.options)} */}
                      {/* <input
                        type="date"
                        name={field.name}
                        id="yes"
                        className="mr-2"
                        onChange={(e) => handleChange(e, field.id)}
                      /> */}
                      <Textbox
                        type="date"
                        label={field.label}
                        name={field.name}
                        customRef={register({})}
                        onChange={(e) => handleChange(e, field.id)}
                        placeholder={field.label}
                        boxClasses="mt-3"
                        disabled={responseStat}
                        min={new Date().toISOString().slice(0, 10)}
                      />
                    </div>
                  );
                }

                default:
                // return <Textbox label="Company Name" name="farmer_id" customRef={register({})} placeholder="Company Name" boxClasses="mt-3" />;
              }
            })}
            {/* <div className="question-box">
                            <label className="question-label" htmlFor="">
                                Q1: Net Amount/Price to deliver Project
                            </label>
                            <textarea
                                name="additionalComments"
                                id="additionalComments"
                                cols={35}
                                rows={1}
                                className="textarea"
                                placeholder="Add comment"
                            />
                        </div>
                        <div className="question-box">
                            <label className="question-label" htmlFor="">
                                Q2: Currency
                            </label>
                            <textarea
                                name="additionalComments"
                                id="additionalComments"
                                cols={35}
                                rows={2}
                                className="textarea"
                                placeholder="Add comment"
                            />
                        </div>
                        <div className="question-box">
                            <label className="question-label" htmlFor="">
                                Q3: Delivery Timeline
                            </label>
                            <textarea
                                name="additionalComments"
                                id="additionalComments"
                                cols={35}
                                rows={2}
                                className="textarea"
                                placeholder="Add comment"
                            />
                        </div>
                        <div className="question-box">
                            <label className="question-label" htmlFor="">
                                Q4: Attach Commercial Proposal
                            </label>
                            <div className="upload-box">
                                <label className="upload-box_label" htmlFor="">
                                    Attachments:
                                </label>
                                <FileUploaderBox boxClasses="fileuploader" />
                            </div>
                        </div> */}
          </form>
        </div>
      </div>
    </div>
  );
};

export default CommercialRequirements;

import React, { useEffect, useState } from "react";
// import RegistrationLayout from "../RegistrationLayout";
import Textbox from "components/Textbox";
import { useForm } from "react-hook-form";
import Button from "components/Button";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "store/store";
import "../ContactInformation/styles.scss";
import SelectDropdown from "components/SelectDropdown";
import AddOnBoardingCode from "components/ModalSupplier/AddOnBoardingCode";
import { saveOnboardingDraft } from "store/slice/SupplierSlices/RegistrationSlice/RegistrationSlice";
import TempCode from "components/ModalSupplier/TempOnBoardingCode/TempCodeModal";
import FileUploaderBox from "components/FileUploaderBox";
import arrowBack from "assests/icons/arrow-back-circle.svg";
import { getSingleVendor } from "store/slice/BuyerSlice/VendorSlice/VendorSlice";
import { uploadFile } from "store/slice/BuyerSlice/CompanyDataSlice/CompanyDataSlice";

type Props = {
  showButtons?: boolean;
  newData: any;
  tabNames: string[];
  currentForm: string;
  updateTab: (val) => void;
  // updateSetValues: (val) => void;
  goback: () => void;
  // draft?: any;
  savedValues?: any;
  singleVendor?: any;
};
const LegalInformation: React.FunctionComponent<Props> = ({ showButtons = true, newData, tabNames, currentForm, updateTab, goback, savedValues, singleVendor }: Props) => {
  //Helpers
  let history = useHistory();
  let location = useLocation();
  let { id }: any = useParams();
  // let anchors = document.querySelectorAll("a");
  const { state } = location as any;
  const { register, handleSubmit, errors, setValue, watch } = useForm<FormData>({ mode: "onBlur" });
  // const values = watch();

  //Redux Hooks
  const dispatch = useDispatch<AppDispatch>();

  //State
  const [loading, setState] = useState(false);
  const [modal, setModal] = useState<boolean>(true);
  const [backDropCloseModal, setbackDropCloseModal] = useState(false);
  const [requiredForm, setRequiredForm] = useState<boolean>(true);
  const [submitBtn, setSubmitBtn] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const [tempCode_modal, setTempCodeModal] = useState<boolean>(false);
  const [tempCode, setTempCode] = useState<undefined | string>();
  const [files, setFiles] = useState<object[]>([]);

  //Redux Hook
  const { legal_data_field, onboarding_data, loadingOnboardingCode, complaince_data_fields, isLoading, forms } = useSelector((state: RootState) => state.vendorRegistration);

  const isFile = newData.filter((data) => data.field_type === "File");
  const isNotFile = newData.filter((data) => data.field_type !== "File");
  const isNotFileIds = isNotFile.map((item) => item.field_name);
  useEffect(() => {
    //Route Guard
    if (id != onboarding_data?.vendor_id) {
      history.push("/supplier/registration/contact-information");
    }
    console.log(isFile);
    console.log(isNotFile);
    console.log(newData);
    console.log(savedValues);
    // if (savedValues?.length >= 1) {
    //   const isNotFile = savedValues.filter((data) => isNotFileIds.includes(data?.field_name));
    //   if (isFile) {
    //     // const savedFiles = savedValues.filter((item) => Array.isArray(item));
    //     // const fileNames = newData.filter
    //     const fileNames = isFile?.reduce((acc, current) => {
    //       const data = savedValues.find((item) => item?.field_name === current?.field_name);
    //       if (data) {
    //         acc.push(data);
    //       }
    //       return acc;
    //     }, []);
    //     let anchors = document.querySelectorAll("a");
    //     anchors.forEach(function (a) {
    //       const fields = fileNames?.filter((item) => `${item.field_name}_` === a.id);
    //       if (fields[0]?.name === undefined) return;
    //       a.text = fields[0]?.name;
    //       a.href = fields[0]?.value;
    //     });
    //     setFiles(fileNames);
    //   }
    //   //  else if (!isFile) {
    //   const savedFiles = isNotFile.filter((item) => !Array.isArray(item));
    //   savedFiles.map(({ field_name, value }, i) => {
    //     setValue(field_name, value);
    //   });
    //   // }
    // }

    if (isFile) {
      const fileNames = isFile?.reduce((acc, current) => {
        const data = savedValues.find((item) => item?.field_name === current?.field_name);
        if (data) {
          acc.push(data);
        }
        return acc;
      }, []);
      let anchors = document.querySelectorAll("a");
      anchors.forEach(function (a) {
        const fields = isFile?.filter((item) => `${item.field_name}_` === a.id);
        console.log(fields);
        console.log(fields[0]?.category !== undefined);
        // if (fields[0]?.name === undefined) return;
        // a.text = fields[0]?.name;
        console.log("here");
        if (fields[0]?.category === undefined) return;
        console.log("here1");
        a.text = fields[0]?.value;
        a.href = fields[0]?.value;
      });
      setFiles(fileNames);
    }
    //  else if (!isFile) {
    const savedFiles = isNotFile.filter((item) => !Array.isArray(item));
    savedFiles.map(({ field_name, value }, i) => {
      setValue(field_name, value);
    });

    let enterForms;
    tabNames.forEach((item) => {
      if (localStorage.getItem(currentForm) === null) {
        return;
      } else enterForms = JSON.parse(localStorage.getItem(currentForm) || "");
    });
    const fields = [].concat.apply([], enterForms) as any;
    console.log(fields);

    if (fields?.length > 0) {
      const newValues = fields.filter((vals) => isNotFileIds.includes(vals.field_name));
      newValues.forEach(({ field_name, value }, i) => {
        setValue(field_name, value);
      });
    }
    if (fields?.length > 0 && isFile) {
      const newFields = fields?.filter((item: any) => item?.file_extension) as any;
      setFiles(newFields);
      // newFields && newFields?.reduce((acc, curr) => {});
      let anchors = document.querySelectorAll("a");
      anchors.forEach(function (a) {
        const fields = newFields?.find((item: any) => `${item.field_name}_` === a.id);
        if (fields && fields?.name !== undefined) {
          a.text = fields?.name;
          a.href = fields?.value;
        }
        // else {
        //   a.text = "";
        //   a.href = "";
        // }
      });
    }
  }, []);

  const ids = newData.map((name) => name.id);
  const onSubmit = (vals: any) => {
    // const isFile = newData.some((data) => data.field_type === "File");
    const isFile = newData.some((data) => data.field_type === "File");
    if (!requiredForm) {
      //Removing empty fields
      const values = Object.entries(vals).reduce((accumulator: any, [key, value]) => {
        if (value !== "") {
          accumulator[key] = value;
        }
        return accumulator;
      }, {});

      // const { vendor_fields, temp_code, ...rest } = onboarding_data;
      localStorage.removeItem(currentForm);
      const enterForms = [] as any;
      tabNames.forEach((item) => {
        if (localStorage.getItem(item) === null) {
          return;
        } else enterForms.push(JSON.parse(localStorage.getItem(item) || ""));
      });

      let formVals = [] as any;

      // else {
      // formVals = Object.entries(vals).map(([key, value]) => {
      //   if (isNotFileIds.includes(key)) {
      //     const { id } = newData.find((name) => name.field_name === key);
      //     return {
      //       field_name: key,
      //       value: value,
      //       vendor_field_id: id
      //     };
      //   }
      // });

      formVals = Object.entries(vals).reduce((acc, [key, value]) => {
        if (isNotFileIds.includes(key)) {
          const { id } = newData.find((name) => name.field_name === key);
          acc.push({
            field_name: key,
            value: value,
            vendor_field_id: id
          });
        }
        return acc;
      }, [] as Object[]);
      if (isFile) {
        formVals.push(...files);
      }
      // }
      // let myArray = savedValues.filter(function (obj) {
      //   return !ids.includes(obj.vendor_field_id);
      // });

      let myArray = [].concat.apply([], savedValues).filter(function (obj: any) {
        return !ids.includes(obj?.vendor_field_id);
      });

      let merged;
      if (savedValues && savedValues?.length > 0) {
        merged = [].concat.apply([], [...enterForms, myArray, formVals]);
      } else merged = [].concat.apply([], [...enterForms, formVals] as any);

      const payload = {
        //saving the dynamic fields
        // legal_data_field,
        // complaince_data_fields,

        // onboarding_data: { temp_code, ...rest },
        current_form_values: merged,
        form: currentForm,
        forms: state ? state.forms : forms,
        // temp_code,

        //lastUrl key is saved to store the last route when he/she used the saved draft implemention,
        lastUrl: window.location.pathname
      };
      dispatch(
        saveOnboardingDraft(payload, (code) => {
          setTempCodeModal(true);
          setTempCode(code);
        })
      );
      tabNames.forEach((k) => localStorage.removeItem(k));

      return;
      //  history.push("/");
    }

    setState(true);
    // setTimeout(() => {
      setState(false);
      const index = tabNames.findIndex((name, i) => name === currentForm);
      // if (isFile) {
      //   localStorage.setItem(currentForm, JSON.stringify(files));
      // }
      // if (!isFile) {
      const file = newData.filter((data) => data.field_type === "File");

      // const legalfiles = Object.entries(vals).map(([key, value]) => {
      //   const { id } = newData.find((name) => name.field_name === key);
      //   return {
      //     field_name: key,
      //     value: value,
      //     vendor_field_id: id
      //   };
      // });
      const legal_files = Object.entries(vals).reduce((acc, current) => {
        if (file.length > 0) {
          for (const key of file) {
            if (!current.includes(key.field_name) && key.field_type !== "File") {
              const { id } = newData.find((name) => name.field_name === current[0]);
              let newdata = {
                field_name: current[0],
                value: current[1],
                vendor_field_id: id
              };
              acc.push(newdata);
            }
          }
        } else {
          const { id } = newData.find((name) => name.field_name === current[0]);
          let newdata = {
            field_name: current[0],
            value: current[1],
            vendor_field_id: id
          };
          acc.push(newdata);
        }
        return acc;
      }, [] as any);
      const mergedFiles = files.concat(legal_files);
      localStorage.setItem(currentForm, JSON.stringify(mergedFiles));
      // }
      console.log(savedValues);

      let myArray = savedValues.filter(function (obj) {
        return !ids.includes(obj.vendor_field_id);
      });
      updateTab(myArray);

      if (index === tabNames?.length - 1) {
        if (savedValues?.length > 0) {
          history.push({
            pathname: `/supplier/company-info/company/bank-info`,
            state: { legalData: vals, categories: tabNames, savedValues: myArray }
          });
        } else
          history.push({
            pathname: `/supplier/company-info/company/bank-info`,
            state: { legalData: vals, categories: tabNames }
          });
      }
    // }, 2000);
  };

  const index = tabNames.findIndex((name, i) => name === currentForm);

  const renderOptions = (options: any) => {
    const array = JSON.parse(options);
    return array?.map((_data: { optionValue: string; optionName: string }) => {
      return {
        value: _data.optionValue,
        optionName: _data.optionName
      };
    });
  };

  const radioOptions = (options: string, field_name: string, id: string) => {
    const array = JSON.parse(options);
    return array.map((_data: { optionName: string; optionValue: string }) => {
      return (
        <div className="">
          <input
            type="radio"
            name={field_name}
            value={_data.optionValue}
            className="mr-2"
            id={id}
            ref={register({
              required: { value: requiredForm, message: "This field is required" }
            })}
          />
          <label className="radio-box_label" htmlFor="yes">
            {_data.optionName}
          </label>
        </div>
      );
    });
  };

  const closeModal = () => {
    if (!loadingOnboardingCode) {
      setModal(false);
    }
  };

  //modal toggler
  const displayTempCodeModal = () => {
    setTempCodeModal(!tempCode_modal);
    history.replace("/");
  };

  //Function declaration: This disables the next button and makes the form inputs not required
  const saveFormDraft = (): void => {
    setRequiredForm(false);
    setSubmitBtn(true);
  };

  // Handle upload for files
  const handleFile = (e, { field_name, field_id }: any) => {
    setIsUploading(true);
    const reader = new FileReader();
    reader.readAsDataURL(e);
    reader.onloadend = () => {
      // use a regex to remove data url part
      let read: any = reader.result;
      const payload = {
        // field_name,
        base64_data: read.replace("data:", "").replace(/^.+,/, ""),
        file_extension: e.name.split(".").pop()
        // vendor_id: id,
        // onboard_code: onboarding_data?.onboard_code
      };

      let anchor = document.getElementById(`${field_name}_`) as any;

      /**NEW CONCEPT */
      // dispatch(uploadFile(payload)).then((data) => {
      //   setIsUploading(false);
      //   const array = [...files];
      //   const removeObject = array.filter((item: any) => item.field_name !== field_name);
      //   setFiles([...removeObject, { field_name: field_name, value: data, vendor_field_id: field_id, name: e.name, file_extension: e.name.split(".").pop() }]);
      //   localStorage.setItem(currentForm, JSON.stringify([...removeObject, { field_name: field_name, value: data, vendor_field_id: field_id, name: e.name, file_extension: e.name.split(".").pop() }]));
      //   anchor.text = e.name;
      //   anchor.href = data;
      // });
      dispatch(
        uploadFile(payload, (data) => {
          setIsUploading(false);
          const array = [...files];
          const removeObject = array.filter((item: any) => item.field_name !== field_name);
          setFiles([...removeObject, { field_name: field_name, value: data, vendor_field_id: field_id, name: e.name, file_extension: e.name.split(".").pop() }]);
          localStorage.setItem(
            currentForm,
            JSON.stringify([...removeObject, { field_name: field_name, value: data, vendor_field_id: field_id, name: e.name, file_extension: e.name.split(".").pop() }])
          );
          anchor.text = e.name;
          anchor.href = data;
        })
      );
    };
  };

  return (
    <>
      {tempCode_modal && <TempCode closeModal={displayTempCodeModal} code={tempCode} />}
      {/* {loadingOnboardingCode && modal && <AddOnBoardingCode closeModal={closeModal} />} */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-grid">
          {newData.map((field, i) => {
            switch (field.field_type) {
              case "Text": {
                return (
                  <Textbox
                    key={i}
                    label={field.field_label}
                    name={field.field_name}
                    customRef={register({
                      required: { value: requiredForm, message: "This field is required" }
                    })}
                    placeholder={field.field_label}
                    boxClasses="mt-3"
                    error={errors[field.field_name] && errors[field.field_name].message}
                  />
                );
              }
              case "Select": {
                return (
                  <SelectDropdown
                    key={i}
                    boxClasses="mt-3"
                    name={field.field_name}
                    label={field.field_label}
                    placeholder={field.field_label}
                    options={renderOptions(field.field_options)}
                    customRef={register({
                      required: { value: requiredForm, message: "This field is required" }
                    })}
                    error={errors[field.field_name] && errors[field.field_name].message}
                  />
                );
              }
              case "File": {
                return (
                  <FileUploaderBox
                    key={i}
                    label={field.field_label}
                    // customRef={register({
                    //   // required: !files.some((file: any) => file.field_name === field.field_name) && requiredForm
                    // })}
                    handleFile={(e) => handleFile(e, { field_name: field.field_name, field_id: field.id })}
                    boxClasses="mt-3 onboard-file"
                    fileName={field.field_name}
                    error={errors[field.field_name] && "This field is required"}
                    fileNameRef={register({
                      // required: { value: requiredForm, message: "This field is required" }
                    })}
                  />
                );
              }
              case "Number": {
                return (
                  <div className="question-box mb-3" key={i}>
                    <Textbox
                      type="number"
                      label={field.field_label}
                      name={field.field_name}
                      customRef={register({
                        required: { value: requiredForm, message: "This field is required" }
                      })}
                      placeholder={field.field_label}
                      boxClasses="mt-3"
                    />
                  </div>
                );
              }

              case "Date": {
                return (
                  <div className="question-box" key={i}>
                    <Textbox
                      type="date"
                      label={field.field_label}
                      name={field.field_name}
                      customRef={register({
                        required: { value: requiredForm, message: "This field is required" }
                      })}
                      placeholder={field.field_label}
                      boxClasses="mt-3"
                      min={new Date().toISOString().slice(0, 10)}
                    />
                  </div>
                );
              }

              case "Radio": {
                return (
                  <div className="">
                    <div className="question-box" key={i}>
                      {/* {field?.options.length > 0 && ( */}
                      <label className="question-label" htmlFor="">
                        {field.field_label}
                      </label>
                      {/* )} */}
                      {radioOptions(field.field_options, field.field_name, field.id)}
                    </div>
                  </div>
                );
              }

              case "Checkbox": {
                return (
                  <div className="">
                    <input
                      type="checkbox"
                      name={field.name}
                      className="mr-2"
                      ref={register({
                        required: { value: requiredForm, message: "This field is required" }
                      })}
                      // disabled={responseStat}
                    />
                    <label className="radio-box_label" htmlFor="yes">
                      {field.label}
                    </label>
                  </div>
                );
              }

              default:
                return <Textbox label="Company Name" name="farmer_id" customRef={register({})} placeholder="Company Name" boxClasses="mt-3" />;
            }
          })}
        </div>

        {showButtons && (
          <div className="d-flex justify-content-center align-items-center mt-5">
            {/* <Button type="submit" label="Save Draft" btnType="btn-skeleton" xtraClass="w-100" boxClasses="w-7 mr-3" onClick={saveFormDraft} loading={isLoading} /> */}
            {/* {index > 0 && ( */}
            <Button
              type="button"
              label="Back"
              btnType="btn-skeleton"
              xtraClass="w-100 d-flex justify-content-around align-items-center py-2"
              boxClasses="w-7 mr-3"
              onClick={() => {
                if (index > 0) {
                  goback();
                } else {
                  history.push({
                    pathname: "/supplier/company-info/contact-info",
                    state: {
                      stateId: singleVendor?.data[0].state_id,
                      countryID: singleVendor?.data[0].country_id,
                      state_name: singleVendor?.data[0].state_name,
                      city_id: singleVendor?.data[0].city_id
                    }
                  });
                }
              }}
              icon={arrowBack}
            />
            {/* )} */}
            <Button type="submit" label="Next" btnType="btn-temporary" xtraClass="w-100" disabled={submitBtn || isUploading} boxClasses="w-7" loading={loading} />
          </div>
        )}
      </form>
    </>
  );
};

export const AuthLegalInformation = () => {
  // states
  const [tabNames, setTabNames] = useState([] as any);
  const [currentForm, setCurrentForm] = useState("");
  const [savedValues, setSavedValues] = useState([]);

  const { onboarding_data, forms, legal_data_field, loadingOnboardingCode } = useSelector((state: RootState) => state.vendorRegistration);
  const { singleVendor } = useSelector((state: RootState) => state.vendorDetails);
  const { user } = useSelector((state: RootState) => state.user);

  const vendorData = singleVendor?.data[0];

  useEffect(() => {
    dispatch(getSingleVendor(user?.vendor_id));
  }, []);

  // helpers
  const dispatch = useDispatch<AppDispatch>();
  let { id }: any = useParams();
  let history = useHistory();
  const location = useLocation();
  const { state } = location as any;
  let vendorDataFiles = vendorData?.vendor_meta;
  console.log(vendorData);
  console.log(vendorDataFiles);
  console.log(tabNames);
  // let vendorDataFiles = forms;
  // if (state) vendorDataFiles = state.forms;
  // if (vendorDataFiles?.length === 0)
  //   history.push({
  //     pathname: `/supplier/registration/bank-information/${id}`,
  //     state: { legalData: [], categories: [] }
  //   });

  let test = [] as any;
  let newData = [] as any;
  let array = [] as any;
  vendorDataFiles?.forEach((items, i) => {
    // if (items.field_type === "Text") {
    test.push(items);
    if (tabNames.includes(items.category)) {
      return;
    } else {
      if (array.includes(items.category)) {
        return;
      } else array = [...array, items.category];
      setTabNames(array);
    }
    return items;
    // }
  });

  for (let i = 0; i < tabNames?.length; i++) {
    let data = {};
    data = { ...data, header: tabNames[i], array: [] };
    test.forEach((item, i) => {
      if (item.category === data["header"]) {
        data["array"] = [
          ...data["array"],

          // {
          //   field_label: item.field_label,
          //   field_name: item.field_name,
          //   field_type: item.field_type,
          //   field_options: item.field_options,
          //   id: item.id
          // }
          item
        ];
      }
    });
    newData.push(data);
  }

  // update
  useEffect(() => {
    if (tabNames?.length > 0) {
      setCurrentForm(tabNames[0]);
    }
  }, [tabNames]);

  const index = tabNames.findIndex((name, i) => name === currentForm);
  const updateTab = (val: any) => {
    if (index < tabNames?.length - 1) {
      setCurrentForm(tabNames[index + 1]);
    }
    setSavedValues(val);
  };

  // const updateSetValues = (val: any) => {
  //   setSavedValues(val);
  // };

  const goback = () => {
    setCurrentForm(tabNames[index - 1]);
  };

  // useEffect(() => {
  //   if (location?.state) {
  //     const { state } = location as any;
  //     setCurrentForm(state.form);
  //     setSavedValues(state.current_form_values);
  //   }
  // }, []);
  console.log(newData);

  return (
    <div>
      {newData?.map((category, i) => {
        if (category.header === currentForm) {
          return (
            <div className="supplier-contact-information" key={i}>
              {/* <h4 className="text-center">Supplier Registration</h4>
              <div className="form-container mt-3 pb-5">
                <h4 className="temp_no">Temp Code : {onboarding_data?.onboard_code}</h4>
                <h6 className="form-header mt-1">{category?.header}</h6> */}
              <LegalInformation
                showButtons={true}
                newData={category?.array}
                tabNames={tabNames}
                currentForm={currentForm}
                updateTab={updateTab}
                goback={goback}
                savedValues={savedValues}
                singleVendor={singleVendor}
                // updateSetValues={updateSetValues}
              />
              {/* </div> */}
            </div>
          );
        }
      })}
    </div>
  );
};

export default AuthLegalInformation;

import React from "react";
import Root from "Root";
import ReduxToastr from "react-redux-toastr";

type AppProps = {};

const App: React.FunctionComponent<AppProps> = () => {

  return (
    <>
      <Root />;
      <ReduxToastr
        timeOut={4000}
        newestOnTop={false}
        preventDuplicates
        position="top-right"
        getState={(state: { toastr: any }) => state.toastr} // This is the default
        closeOnToastrClick
        className="toastr"
      />
    </>
  );
};

export default App;

import React, { useEffect, useState, useRef, useCallback } from "react";
import "./styles.scss";
import StatsCard from "components/StatsCard";
import DataTable from "components/DataTable";
import { documentsColumns, documents } from "./data";
import Meeting from "assests/icons/auction.svg";
import Arrow from "assests/icons/nego.svg";
import SearchIcon from "components/SearchBar";
import { useDispatch, useSelector } from "react-redux";
// import { getHouseholdSummary, getAllHousehold } from "store/slice/HouseHoldsSlice/HouseholdSlice";
import { RootState } from "store/store";
import Pagination from "components/Pagination";
import Dropdown from "components/Dropdown";
import useSearch from "hooks/useSearch";
import useArrowDropdown from "hooks/useArrowDropdown";
// import { getCommunity } from "store/slice/CommunitySlice/communitySlice";
import Stars from "../../../components/Star/index";
import { declineBid, getBids } from "store/slice/SupplierSlices/BidSlice/BidsSlice";
import { differenceInMinutes, format } from "date-fns";
import Countdown from "react-countdown";
import { checkPermission, toastPopUp } from "utils/utils";

type Props = {};

const Bid: React.FunctionComponent<Props> = () => {
  const [page, setPage] = React.useState<number>(1);
  const [length, setLength] = React.useState<number>(10);
  const [selectComboxOption, setSelectComboxOption] = React.useState("");
  const dispatch = useDispatch();

  //Redux
  // const { summary, households } = useSelector((state: RootState) => state.household);
  const { bids } = useSelector((state: RootState) => state.bids);
  const { user } = useSelector((state: RootState) => state.user);
  const { role_access } = useSelector((state: RootState) => state.user?.user);

  //hook
  // const { open, selectedOption, handleDropdownChange, handleAction } = useArrowDropdown();
  const { open: open2, selectedOption: selectedOption2, handleDropdownChange: handleDropdownChange2, handleAction: handleAction2, ref: dropdownRef2 } = useArrowDropdown();

  //
  const params = [selectedOption2 !== "" && selectedOption2 !== "All" ? selectedOption2 : undefined, selectComboxOption !== "" && selectComboxOption !== "All" ? selectComboxOption : undefined];
  const { search, inputRef, handleChange } = useSearch(getBids, length, undefined, params);

  const tableData =
    bids &&
    bids?.data.map((item: any, i) => {
      const closed = format(new Date(), "yyyy-MM-dd HH:mm:ss") > format(new Date(item?.closing_date), "yyyy-MM-dd HH:mm:ss");
      let response = "";
      const technical = item.rfp_responses.some((item) => item.category === "Technical");
      const commercial = item.rfp_responses.some((item) => item.category === "Commercial");
      switch (item.rfp_vendor_status) {
        case "-1":
          response = "Pending";
          break;
        case "1":
          response = "Not Sent";
          break;
        case "2":
          response = "Sent";
          break;

        default:
          break;
      }
      return {
        doc_no: item.requisition_id,
        description: item.rfp_title,
        time: <Countdown date={item?.closing_date} />,
        status: item.rfp_vendor_status === "1",
        id: item.rfp_id,
        technical: technical ? "Completed" : "Pending",
        commercial: commercial ? "Completed" : "Pending",
        response: response,
        closed,
        decline: () =>decline(item.vendor_id, item.rfp_id)
      };
    });

  const decline = (id, rfp_id) => {
    console.log(id, rfp_id)
    const payload = { rfp_id: rfp_id };
    toastPopUp(
      () => {
        dispatch(
          declineBid(id, payload, () => {
            dispatch(getBids());
          })
        );
      },
      "Decline",
      "Cancel",
      "Decline Invite?",
      "",
      "red"
    );
  };

  //Filter for Payment frequency and Community
  useEffect(() => {
    if ((selectedOption2 !== "" && selectedOption2) || (selectComboxOption !== "" && selectComboxOption)) {
      if (selectedOption2 === "All") {
        // dispatch(
        //   getAllHousehold(
        //     page,
        //     perpage,
        //     search,
        //     undefined,
        //     selectComboxOption !== "" && selectComboxOption !== "All"
        //       ? selectComboxOption
        //       : undefined
        //   )
        // );
        return;
      }
      setPage(1);
      //   dispatch(
      //     getAllHousehold(
      //       1,
      //       perpage,
      //       search,
      //       selectedOption2,
      //       selectComboxOption !== "" && selectComboxOption !== "All" ? selectComboxOption : undefined
      //     )
      //   );
    }
  }, [selectedOption2, selectComboxOption]);

  useEffect(() => {
    dispatch(getBids());
    // dispatch(getAllHousehold(page, perpage));
    // dispatch(getCommunity(1, 1000));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //--------------------------------------------
  //Pagination
  //---------------------------------------------
  const handlePageChange = React.useCallback(
    (page: number, length: number) => {
      setPage(page);
      dispatch(getBids(page, length, search));
    },
    [search]
  );

  const paginate = React.useCallback(
    (pageNo: number, length: number) => {
      setPage(pageNo);
      dispatch(getBids(pageNo, length, search));
    },
    [search]
  );

  //------------------------------------------------------------
  //Show Number for PerPage Function
  //------------------------------------------------------------
  const handleNewPage = React.useCallback(
    (pageNo: number, page = 1) => {
      setLength(pageNo);
      setPage(1);
      dispatch(getBids(page, pageNo, search));
    },
    [search]
  );

  return (
    <div id="bid-page">
      <div className="container">
        <div className="d-flex align-items-center mb-3">
          <h4 className="page-title mr-2 ">
            {user?.vendor_description} | {user?.vendor_code}
          </h4>
          <Stars stars={3} disabled={true} />
        </div>
        <div className=" row mb-5">
          <StatsCard statsNumber={bids?.count} text="Invited Bids" classes="stats-icon-orange" image={Arrow} />

          <StatsCard statsNumber={0} text="Negotiations" classes="stats-icon-leaf" image={Meeting} />
        </div>

        <h4 className="page-title mb-2">Recent Documents</h4>
        <div className="search-field">
          <SearchIcon placeholder="Search for PO ID, Description, name, or Invoice ID" boxClasses=" mb-4" textRef={inputRef} value={search} onChange={handleChange} />
          <Dropdown perPage={length} action={handleNewPage} />
        </div>

        <DataTable columns={documentsColumns} data={tableData} />
        <Pagination
          page={page}
          lastPage={bids?.pagination?.pageCount}
          paginate={paginate}
          total={bids?.pagination?.total}
          handlePageChange={handlePageChange}
          perPage={length}
        />
      </div>
    </div>
  );
};

export default Bid;

import React from "react";
import "./styles.scss";

type Props = {
  label?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (event: React.ChangeEvent<any>) => void;
  name?: string;
  value?: any;
  placeholder?: string;
  boxClasses?: string;
  type?: string;
  error?: string;
  iconUrl?: string;
  onIconClick?: string;
  disabled?: boolean;
  min?: string;
  customRef?: string | ((instance: HTMLInputElement | null) => void);
  inputClasses?: string;
  defaultValue?: string;
  readOnly?: boolean;
};

const Textbox = ({
  label,
  onChange,
  onClick,
  name,
  value,
  placeholder,
  boxClasses,
  type = "text",
  error,
  disabled,
  min,
  customRef,
  inputClasses,
  defaultValue,
  readOnly = false,
  ...rest
}: Props) => {
  return (
    <div className={`${boxClasses} textbox`}>
      <fieldset className={`${error && "error"} ${disabled && "disabled-input"}`}>
        {label && (
          <legend id="label-legend" className={` pl-2 pr-2 ${error && "label-error"}`}>
            {label}
          </legend>
        )}

        <div className="d-flex">
          <input
            autoComplete="off"
            min={min && min}
            onChange={onChange}
            onClick={onClick}
            name={name}
            value={value}
            placeholder={placeholder}
            type={type}
            disabled={disabled && disabled}
            ref={customRef}
            className={inputClasses}
            defaultValue={defaultValue}
            readOnly={readOnly}
            {...rest}
          />
        </div>
      </fieldset>
      <p className={`${error ? "d-block" : "d-none"} input-error mt-1`}>{error}</p>
    </div>
  );
};

export default Textbox;

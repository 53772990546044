/* eslint-disable react-hooks/exhaustive-deps */
import StatsCard from "components/StatsCard";
import Icon from "assests/icons/statsCard/Vector (4).png";
import SearchBar from "../../../../components/SearchBar";
import Dropdown from "components/Dropdown";
import DataTable from "components/DataTable";
import { reportCol, reportData, reportCategory, reportDoc } from "./data";
import Select from "react-select";
import "./styles.scss";
import React, { useEffect, useState } from "react";
import { AppDispatch, RootState } from "store/store";
import { useDispatch, useSelector } from "react-redux";
import useSearch from "hooks/useSearch";
import CreateOrEditReport from "components/ModalBuyer/CreateOrEditReport";
import Pagination from "components/Pagination";
import { getAllReport } from "store/slice/BuyerSlice/ReportSlice/RepotSlice";
import { Pie, Doughnut, Bar } from "react-chartjs-2";
import lineImg from "../../../../assests/icons/line-chart.png";
import dateImg from "../../../../assests/icons/calendar.png";
import SelectDropdown from "components/SelectDropdown";
import { Controller, useForm } from "react-hook-form";
import AsyncSelect from "react-select/async";
import API from "utils/httpsRequest";
import { useHistory } from "react-router";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import { kFormatter } from "utils/utils";
import moment from "moment";
import {
  getCategoryVendorSummary,
  getCompanySummary,
  getCompanyVendorSummary,
  getCountryVendorSummary,
  getDeptSummary,
  getExpiryVendorSummary,
  getPRCompanySummary,
  getPRUserSummary
} from "store/slice/SummarySlice/SummarySlice";

const ReportSupplier = () => {
  const { user } = useSelector((state: RootState) => state.user);
  const { token } = user;

  const [singleReport, setSingleReport] = useState<any>(null);

  const dispatch = useDispatch<AppDispatch>();
  const { reports, loading } = useSelector((state: RootState) => state.report);
  const { locations } = useSelector((state: RootState) => state.location);
  const [modal, setModal] = useState<boolean>(false);

  const [locationSel, setLocationSel] = React.useState("");
  const [selLocationVal, setSelLocationVal] = React.useState("");
  const [categorySel, setCategorySel] = React.useState("");
  const [selCategoryVal, setSelCategoryVal] = React.useState("");
  const [bbbSel, setBbbSel] = React.useState("");
  const [selBbbVal, setSelBbbVal] = React.useState("");
  const [companySel, setCompanySel] = React.useState("");
  const [selCompanyVal, setSelCompanyVal] = React.useState("");
  const [catSel, setCatSel] = React.useState("");
  const [selCatVal, setSelCatVal] = React.useState("");
  const [company, setCompany] = useState(user.company_id ? user.company_id : "10002");
  const [companyName, setCompanyName] = useState(user.company_description ? user.company_description : "GZ Industries Ltd - Nigeria");
  const [location, setLocation] = useState("");
  const [locationName, setLocationName] = useState("");
  const [dept, setDept] = useState("");
  const [deptName, setDeptName] = useState("");
  const [cat, setCat] = useState("");
  const [catName, setCatName] = useState("");
  const [category, setCategory] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [bbb, setBbb] = useState("");
  const [bbbName, setBbbName] = useState("");
  const [sumSup, setSumSup] = useState(0);
  const [sumCat, setSumCat] = useState(0);
  const [sumDoc, setSumDoc] = useState(0);
  const [firstFive, setFirstFive] = useState<any>([]);
  const [firstFive2, setFirstFive2] = useState<any>([]);
  const [firstFive3, setFirstFive3] = useState<any>([]);
  const [isBbb, setIsBbb] = useState(false);
  const [isCountry, setIsCountry] = useState(true);
  const [isCategory, setIsCategory] = useState(false);
  const [isDoc, setIsDoc] = useState(false);

  // const [defaultLegal, setDefaultLegal] = useState([{

  //     label: "GZ Industries Ltd - Nigeria",
  //     value: "10002"

  // }]);
  const [newLocationOption, setNewLocationOption] = useState([]);

  const [newDepartmentOption, setNewDepartmentOption] = useState([]);
  const [seen, setSeen] = useState(true);

  const { companyVendor, countryVendor, categoryVendor, expiryVendor, isLoadingCountry, isLoadingCategory, isLoadingExpiry } = useSelector((state: RootState) => state.summaries);

  // setting summary place
  useEffect(() => {
    companyVendor && companyVendor.data.length ? setSumSup(companyVendor.data[0].total_vendors) : setSumSup(0);
    categoryVendor ? setSumCat(categoryVendor.count) : setSumCat(0);
    companyVendor && companyVendor.data.length ? setSumDoc(companyVendor.data[0].total_expiring_documents) : setSumDoc(0);
  }, [companyVendor]);

  // setting pie chat data country
  const [labelList, setLabelList] = useState<string[]>([]);
  const [pointScore, setPointScore] = useState<number[]>([]);
  let onceSeen = true;

  useEffect(() => {
    const filt =
      countryVendor &&
      countryVendor?.data
        ?.filter((val, i) => i <= 5)
        ?.filter((item) => {
          return item;
        });

    if (filt) {
      if (filt.length) {
        setFirstFive(filt);
        onceSeen = true;
        if (onceSeen) {
          let testArray: string[] = [];
          let testScore: number[] = [];
          // item.department_description.split(" ")[0]
          filt.forEach((item) => testArray.push(item.country_description));
          filt.forEach((item) => testScore.push(item.total_vendors));
          setLabelList([...testArray]);
          setPointScore([...testScore]);

          onceSeen = false;
        }
      } else {
        setLabelList([]);
        setPointScore([]);
      }
    }
  }, [countryVendor]);

  // setting pie chat data category
  const [labelList2, setLabelList2] = useState<string[]>([]);
  const [pointScore2, setPointScore2] = useState<number[]>([]);
  let onceSeen2 = true;

  useEffect(() => {
    const filt =
      categoryVendor &&
      categoryVendor?.data
        ?.filter((val, i) => i <= 5)
        ?.filter((item) => {
          return item;
        });

    if (filt) {
      if (filt.length) {
        setFirstFive2(filt);
        onceSeen2 = true;
        if (onceSeen2) {
          let testArray: string[] = [];
          let testScore: number[] = [];
          // item.department_description.split(" ")[0]
          filt.forEach((item) => testArray.push(item.business_category_description));
          filt.forEach((item) => testScore.push(item.total_vendors));
          setLabelList2([...testArray]);
          setPointScore2([...testScore]);

          onceSeen2 = false;
        }
      } else {
        setLabelList2([]);
        setPointScore2([]);
      }
    }
  }, [categoryVendor]);

  // setting pie chat data expiry
  const [labelList3, setLabelList3] = useState<string[]>([]);
  const [pointScore3, setPointScore3] = useState<number[]>([]);
  let onceSeen3 = true;

  useEffect(() => {
    const filt =
      expiryVendor &&
      expiryVendor?.data
        ?.filter((val, i) => i <= 5)
        ?.filter((item) => {
          return item;
        });

    if (filt) {
      if (filt.length) {
        setFirstFive3(filt);
        onceSeen3 = true;
        if (onceSeen3) {
          let testArray: string[] = [];
          let testScore: number[] = [];
          // item.department_description.split(" ")[0]
          filt.forEach((item) => testArray.push(`${item.field_label}`));
          filt.forEach((item) => testScore.push(item.total_vendors));
          setLabelList3([...testArray]);
          setPointScore3([...testScore]);

          onceSeen3 = false;
        }
      } else {
        setLabelList3([]);
        setPointScore3([]);
      }
    }
  }, [expiryVendor]);

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(2021, 1, 1),
      endDate: new Date(2021, 9, 27),
      key: "selection"
    }
  ]);

  const [newDate, setNewDate] = useState([{}]);

  useEffect(() => {
    setNewDate([
      {
        startDate: dateRange[0].startDate,
        endDate: dateRange[0].endDate
      }
    ]);
  }, [dateRange]);

  const [showRange, setShowRange] = useState(false);

  const handleRangeShow = () => {
    setShowRange(!showRange);
  };

  const history = useHistory();

  const closeModal = () => {
    setModal((prev) => !prev);
    setSingleReport(null);
  };

  const handleInputChangeBbb = (value) => {
    setBbbSel(value);
  };

  const onChangeBbb = (value) => {
    setBbb(value.value);
    setBbbName(value.label);
    setSelBbbVal(value);
  };

  const handleInputChangeCat = (value) => {
    setCatSel(value);
  };

  const handleInputChangeCategory = (value) => {
    setCatSel(value);
  };

  const onChangeCat = (value) => {
    setCat(value.value);
    setCatName(value.label);
    setSelCatVal(value);
  };

  const onChangeCategory = (value) => {
    setCategory(value.value);
    setCategoryName(value.label);
    setSelCategoryVal(value);
  };

  const handleInputChangeCompany = (value) => {
    setCompanySel(value);
  };

  const onChangeCompany = (value) => {
    setCompany(value.value);
    setCompanyName(value.label);
    setSelCompanyVal(value);

    if (value.value === "10001") {
      setIsBbb(true);
    } else {
      setIsBbb(false);
    }
  };

  const removeFilter = (nameFilt) => {
    if (nameFilt === "date") {
      setDateRange([
        {
          // ate_from=&date_to=
          startDate: new Date(2021, 1, 1),
          endDate: new Date(),
          key: "selection"
        }
      ]);
    }
    if (nameFilt === "legal") {
      setCompany("");
      setCompanyName("");
    }
    if (nameFilt === "bbb") {
      setBbb("");
      setBbbName("");
    }
    if (nameFilt === "cat") {
      setCat("");
      setCatName("");
    }
    if (nameFilt === "category") {
      setCategory("");
      setCategoryName("");
    }
  };

  const companiesOptions = () => {
    return API.get(`/companies/get?search=${companySel}&length=1000`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    }).then((res) => {
      return res.data.data.map((item) => ({
        label: item.company_description,
        value: item.id
      }));
    });
  };

  const bbbOptions = [
    {
      label: "Level 1",
      value: "Level 1"
    },
    {
      label: "Level 2",
      value: "Level 2"
    },
    {
      label: "Level 3",
      value: "Level 3"
    },
    {
      label: "Level 4",
      value: "Level 4"
    },
    {
      label: "Level 5",
      value: "Level 5"
    },
    {
      label: "Level 6",
      value: "Level 6"
    },
    {
      label: "Level 7",
      value: "Level 7"
    },
    {
      label: "Level 8",
      value: "Level 8"
    },
    {
      label: "Non Compliant",
      value: "Non Compliant"
    }
  ];

  // const materialCategoryOptions = () => {
  //   return API.get(`/material_categories/get?search=${catSel}`, {
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //       "Content-Type": "application/json"
  //     }
  //   }).then((res) => {
  //     return res.data.data.map((item) => ({
  //       label: item.material_category_description,
  //       value: item.id
  //     }));
  //   });
  // };

  const materialCategoryOptions = [
    {
      label: "Contractor",
      value: "Contractor"
    },
    {
      label: "Supplier",
      value: "Supplier"
    }
  ];

  const categoryOptions = [
    {
      label: "Foreign",
      value: "Foreign"
    },
    {
      label: "Local",
      value: "Local"
    }
  ];

  const { register, control, handleSubmit, errors, setValue } = useForm<FormData>({
    mode: "onChange"
  });

  const barData = {
    labels: labelList.length && labelList,
    datasets: [
      {
        label: "Vendor By Country Chart",
        weight: 1,
        backgroundColor: ["#05445E", "#00B01C", "#A6CEE3", "#189AB4", "#75E6DA", "#05445E"],
        data: pointScore.length && pointScore
      }
    ]
  };

  const barData2 = {
    labels: labelList2.length && labelList2,
    datasets: [
      {
        label: "Vendor By Category Chart",
        weight: 1,
        backgroundColor: ["#05445E", "#00B01C", "#A6CEE3", "#189AB4", "#75E6DA", "#05445E"],
        data: pointScore.length && pointScore
      }
    ]
  };

  const barData3 = {
    labels: labelList3.length && labelList3,
    datasets: [
      {
        label: "Vendor By Expiry Document Chart",
        weight: 1,
        backgroundColor: ["#05445E", "#00B01C", "#A6CEE3", "#189AB4", "#75E6DA", "#05445E"],
        data: pointScore3.length && pointScore3
      }
    ]
  };

  // get company Summary
  useEffect(() => {
    dispatch(
      getCompanyVendorSummary({
        company_id: company,
        date_from: moment(dateRange[0].startDate).format("YYYY-MM-D"),
        date_to: moment(dateRange[0].endDate).format("YYYY-MM-DD"),
        category: cat,
        classification: category,
        data_level: bbb
      })
    );
  }, [company, category, cat, newDate, bbb]);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  //Show Number for PerPage Request
  const handleNewPage = (pageNo: number, page: number = 1) => {
    setPerPage(pageNo);
    setPage(page);
    dispatch(
      getCountryVendorSummary({
        company_id: company,
        date_from: moment(dateRange[0].startDate).format("YYYY-MM-D"),
        date_to: moment(dateRange[0].endDate).format("YYYY-MM-DD"),
        category: cat,
        classification: category,
        length: perPage,
        page: page,
        data_level: bbb
      })
    );
  };

  //Pagination
  //---------------------------------------------
  const handlePageChange = React.useCallback((page: number, perPage: number) => {
    setPage(page);
    dispatch(
      getCountryVendorSummary({
        company_id: company,
        date_from: moment(dateRange[0].startDate).format("YYYY-MM-D"),
        date_to: moment(dateRange[0].endDate).format("YYYY-MM-DD"),
        category: cat,
        classification: category,
        length: perPage,
        page: page,
        data_level: bbb
      })
    );
  }, []);

  const paginate = React.useCallback((pageNo: number, perPage: number) => {
    setPage(pageNo);
    dispatch(
      getCountryVendorSummary({
        company_id: company,
        date_from: moment(dateRange[0].startDate).format("YYYY-MM-D"),
        date_to: moment(dateRange[0].endDate).format("YYYY-MM-DD"),
        category: cat,
        classification: category,
        length: perPage,
        page: page,
        data_level: bbb
      })
    );
  }, []);

  //Show Number for PerPage Request
  const handleNewPage2 = (pageNo: number, page: number = 1) => {
    setPerPage(pageNo);
    setPage(page);
    dispatch(
      getCategoryVendorSummary({
        company_id: company,
        date_from: moment(dateRange[0].startDate).format("YYYY-MM-D"),
        date_to: moment(dateRange[0].endDate).format("YYYY-MM-DD"),
        category: cat,
        classification: category,
        length: perPage,
        page: page,
        data_level: bbb
      })
    );
  };

  //Pagination
  //---------------------------------------------
  const handlePageChange2 = React.useCallback((page: number, perPage: number) => {
    setPage(page);
    dispatch(
      getCategoryVendorSummary({
        company_id: company,
        date_from: moment(dateRange[0].startDate).format("YYYY-MM-D"),
        date_to: moment(dateRange[0].endDate).format("YYYY-MM-DD"),
        category: cat,
        classification: category,
        length: perPage,
        page: page,
        data_level: bbb
      })
    );
  }, []);

  const paginate2 = React.useCallback((pageNo: number, perPage: number) => {
    setPage(pageNo);
    dispatch(
      getCategoryVendorSummary({
        company_id: company,
        date_from: moment(dateRange[0].startDate).format("YYYY-MM-D"),
        date_to: moment(dateRange[0].endDate).format("YYYY-MM-DD"),
        category: cat,
        classification: category,
        length: perPage,
        page: page,
        data_level: bbb
      })
    );
  }, []);

  //Show Number for PerPage Request
  const handleNewPage3 = (pageNo: number, page: number = 1) => {
    setPerPage(pageNo);
    setPage(page);
    dispatch(
      getExpiryVendorSummary({
        company_id: company,
        date_from: moment(dateRange[0].startDate).format("YYYY-MM-D"),
        date_to: moment(dateRange[0].endDate).format("YYYY-MM-DD"),
        category: cat,
        classification: category,
        length: perPage,
        page: page,
        data_level: bbb
      })
    );
  };

  //Pagination
  //---------------------------------------------
  const handlePageChange3 = React.useCallback((page: number, perPage: number) => {
    setPage(page);
    dispatch(
      getExpiryVendorSummary({
        company_id: company,
        date_from: moment(dateRange[0].startDate).format("YYYY-MM-D"),
        date_to: moment(dateRange[0].endDate).format("YYYY-MM-DD"),
        category: cat,
        classification: category,
        length: perPage,
        page: page,
        data_level: bbb
      })
    );
  }, []);

  const paginate3 = React.useCallback((pageNo: number, perPage: number) => {
    setPage(pageNo);
    dispatch(
      getExpiryVendorSummary({
        company_id: company,
        date_from: moment(dateRange[0].startDate).format("YYYY-MM-D"),
        date_to: moment(dateRange[0].endDate).format("YYYY-MM-DD"),
        category: cat,
        classification: category,
        length: perPage,
        page: pageNo,
        data_level: bbb
      })
    );
  }, []);

  // get country Summary
  useEffect(() => {
    dispatch(
      getCountryVendorSummary({
        company_id: company,
        date_from: moment(dateRange[0].startDate).format("YYYY-MM-D"),
        date_to: moment(dateRange[0].endDate).format("YYYY-MM-DD"),
        category: cat,
        classification: category,
        length: perPage,
        page: page,
        data_level: bbb
      })
    );
  }, [company, category, cat, newDate, bbb]);

  // get category Summary
  useEffect(() => {
    dispatch(
      getCategoryVendorSummary({
        company_id: company,
        date_from: moment(dateRange[0].startDate).format("YYYY-MM-D"),
        date_to: moment(dateRange[0].endDate).format("YYYY-MM-DD"),
        category: cat,
        classification: category,
        length: perPage,
        page: page,
        data_level: bbb
      })
    );
  }, [company, category, cat, newDate, bbb]);

  // get expiry Summary
  useEffect(() => {
    dispatch(
      getExpiryVendorSummary({
        company_id: company,
        date_from: moment(dateRange[0].startDate).format("YYYY-MM-D"),
        date_to: moment(dateRange[0].endDate).format("YYYY-MM-DD"),
        category: cat,
        classification: category,
        length: perPage,
        page: page,
        data_level: bbb
      })
    );
  }, [company, category, cat, newDate, bbb]);

  const reportList =
    countryVendor &&
    countryVendor?.data?.map((company) => {
      return {
        country_description: company.country_description,
        total_vendors: company.total_vendors,
        total_companies: company.total_companies
      };
    });

  const reportList2 =
    categoryVendor &&
    categoryVendor?.data?.map((company) => {
      return {
        business_category_id: company.business_category_id,
        business_category_description: company.business_category_description,
        total_vendors: company.total_vendors
      };
    });

  const reportList3 =
    expiryVendor &&
    expiryVendor?.data?.map((company) => {
      return {
        id: company.vendor_field_id,
        field_label: company.field_label,
        total_vendors: company.total_vendors
      };
    });

  const handleView = (e, item) => {
    history.push(`/buyer/report/inner?deptId=${item.id}&deptName=${item.department_description}`);
  };

  const handleApi = (type) => {
    if (type === "country") {
      setIsCountry(true);
      setIsDoc(false);
      setIsCategory(false);
    }
    if (type === "category") {
      setIsCountry(false);
      setIsDoc(false);
      setIsCategory(true);
    }
    if (type === "doc") {
      setIsCountry(false);
      setIsDoc(true);
      setIsCategory(false);
    }
  };

  return (
    <div id="report">
      {modal && <CreateOrEditReport closeModal={closeModal} />}

      <div className="container">
        <div className="filterContainer">
          <div className="titleSide">
            <div className="left">
              <img src={lineImg} alt="line icon" />
              <p>Supplier Report</p>
            </div>
          </div>

          <div className="filters">
            <div className="dateFilter each">
              <div onClick={handleRangeShow} className="dateBox">
                <p className="date-range">
                  {` ${moment(dateRange[0].startDate).format("MM/DD/YYYY")} - ${moment(dateRange[0].endDate).format("MM/DD/YYYY")}`}

                  {/* moment(dateRange[0].startDate).format('MM/DD/YYYY') */}
                </p>
                <img src={dateImg} alt="date icon" />
              </div>

              {showRange && (
                <div className="rangeDiv">
                  <DateRangePicker
                    onChange={(item) => setDateRange([item.selection])}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={2}
                    ranges={dateRange}
                    direction="horizontal"
                    preventSnapRefocus={true}
                    calendarFocus="backwards"
                  />
                  <div onClick={handleRangeShow} className="applyBtn">
                    <button type="button">Apply</button>
                  </div>
                </div>
              )}
            </div>

            <div className="CompanyFilter each">
              <Controller
                control={control}
                defaultValue
                boxClasses="mt-3"
                name="company_id"
                render={({ ref }) => (
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    placeholder="Legal Entity"
                    value={selCompanyVal}
                    loadOptions={companiesOptions}
                    onInputChange={handleInputChangeCompany}
                    onChange={onChangeCompany}
                  />
                )}
              />
            </div>

            <div className="groupFilter each">
              <Select
                classNamePrefix="react-select"
                className="react-select"
                closeOnSelect={false}
                id="services"
                printOptions="on-focus"
                placeholder="Category"
                onChange={onChangeCat}
                options={materialCategoryOptions}
                // value={value}
                // ref={ref}
              />
            </div>

            <div className="groupFilter each">
              <Select
                classNamePrefix="react-select"
                className="react-select"
                closeOnSelect={false}
                id="services"
                printOptions="on-focus"
                placeholder="Classification"
                onChange={onChangeCategory}
                options={categoryOptions}
                // value={value}
                // ref={ref}
              />
            </div>
            {isBbb ? (
              <div className="locationFilter each">
                <Select
                  classNamePrefix="react-select"
                  className="react-select"
                  closeOnSelect={false}
                  id="services"
                  printOptions="on-focus"
                  placeholder="Bbbe"
                  onChange={onChangeBbb}
                  options={bbbOptions}
                  // value={value}
                  // ref={ref}
                />
              </div>
            ) : null}
          </div>

          <div className="showing">
            <p className="text">Showing:</p>
            <div className="coverDisplay">
              <div className="displayDate">
                <p>{` ${moment(dateRange[0].startDate).format("MM/DD/YYYY")} - ${moment(dateRange[0].endDate).format("MM/DD/YYYY")}`}</p>
                <p
                  onClick={() => {
                    removeFilter("date");
                  }}
                  className="cancel comp"
                >
                  x
                </p>
              </div>
              {companyName && (
                <div className="displayDate">
                  <p className="Company">{companyName}</p>
                </div>
              )}

              {bbbName && (
                <div className="displayDate">
                  <p className="dept">{bbbName}</p>
                  <p
                    onClick={() => {
                      removeFilter("bbb");
                    }}
                    className="cancel dept"
                  >
                    x
                  </p>
                </div>
              )}

              {catName && (
                <div className="displayDate">
                  <p className="cat">{catName}</p>
                  <p
                    onClick={() => {
                      removeFilter("cat");
                    }}
                    className="cancel cat"
                  >
                    x
                  </p>
                </div>
              )}

              {categoryName && (
                <div className="displayDate">
                  <p className="dept">{categoryName}</p>
                  <p
                    onClick={() => {
                      removeFilter("category");
                    }}
                    className="cancel dept"
                  >
                    x
                  </p>
                </div>
              )}
            </div>
          </div>

          <div className="summary">
            <p className="topic">Orders</p>
            <div className="summaryCover">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleApi("country");
                }}
                className="spent hoverr"
              >
                <div className="moneySide">
                  {/* <p className="currency">$</p> */}
                  <p className="amountMoney">{kFormatter(sumSup, "")} </p>
                </div>
                <p className="text">Total Suppliers</p>
              </div>

              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleApi("category");
                }}
                className="spent mid hoverr"
              >
                <div className="deptSide">
                  <p className="out">{kFormatter(sumCat, "")}</p>
                  {/* <p className="total">/14</p> */}
                </div>
                <p className="text">Total Category</p>
              </div>

              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleApi("doc");
                }}
                className="spent hoverr"
              >
                <p className="purchase">{kFormatter(sumDoc, "")}</p>
                <p className="text">Total Expiry Documents</p>
              </div>
            </div>
          </div>
        </div>

        <div className="chatBox">
          {isCountry ? (
            <div className="chartFlex">
              {labelList.length && pointScore.length ? (
                <div style={{ width: "100%" }} className="pieChart">
                  <Bar
                    data={barData}
                    options={{
                      title: {
                        display: false,
                        text: "",
                        fontSize: 10
                      },
                      cutout: 100,
                      maintainAspectRatio: false,
                      legend: {
                        display: true,
                        position: "right"
                      }
                    }}
                  />
                </div>
              ) : null}
            </div>
          ) : null}

          {isCategory ? (
            <div className="chartFlex">
              {labelList2.length && pointScore2.length ? (
                <div style={{ width: "100%" }} className="pieChart">
                  <Bar
                    data={barData2}
                    options={{
                      title: {
                        display: false,
                        text: "",
                        fontSize: 10
                      },
                      cutout: 100,
                      maintainAspectRatio: false,
                      legend: {
                        display: true,
                        position: "right"
                      }
                    }}
                  />
                </div>
              ) : null}
            </div>
          ) : null}

          {isDoc ? (
            <div className="chartFlex">
              {labelList3.length && pointScore3.length ? (
                <div style={{ width: "100%" }} className="pieChart">
                  <Bar
                    data={barData3}
                    options={{
                      title: {
                        display: false,
                        text: "",
                        fontSize: 10
                      },
                      cutout: 100,
                      maintainAspectRatio: false,
                      legend: {
                        display: true,
                        position: "right"
                      }
                    }}
                  />
                </div>
              ) : null}
            </div>
          ) : null}
        </div>

        <div className="document mt-5">
          {isCountry ? (
            <div className="mt-3">
              <DataTable columns={reportCol} data={reportList} loading={isLoadingCountry} />
              {countryVendor?.count > 10 ? (
                <Pagination
                  page={page}
                  lastPage={countryVendor?.pagination?.pageCount}
                  paginate={paginate}
                  total={countryVendor?.pagination?.total}
                  handlePageChange={handlePageChange}
                  perPage={perPage}
                />
              ) : null}
            </div>
          ) : null}
          {isCategory ? (
            <div className="mt-3">
              <DataTable columns={reportCategory} data={reportList2} loading={isLoadingCategory} />
              {categoryVendor?.count > 10 ? (
                <Pagination
                  page={page}
                  lastPage={categoryVendor?.pagination?.pageCount}
                  paginate={paginate2}
                  total={categoryVendor?.pagination?.total}
                  handlePageChange={handlePageChange2}
                  perPage={perPage}
                />
              ) : null}
            </div>
          ) : null}
          {isDoc ? (
            <div className="mt-3">
              <DataTable columns={reportDoc} data={reportList3} loading={isLoadingExpiry} />
              {/* {countryVendor?.count > 10 ? (
                <Pagination
                  page={page}
                  lastPage={expiryVendor?.pagination?.lastPage}
                  paginate={paginate3}
                  total={expiryVendor?.pagination?.total}
                  handlePageChange={handlePageChange3}
                  perPage={perPage}
                />
              ) : null} */}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ReportSupplier;

import React, { useEffect, useState } from 'react';
import StatsCard from 'components/StatsCard';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import budgetIcon from 'assests/icons/buyer/budget-icon.svg';
import AddIcon from "assests/icons/buyer/add-icon.svg";
import filterIcon from "assests/icons/buyer/filter-icon.svg";
import './styles.scss';
import SearchIcon from 'components/SearchBar';
import Dropdown from 'components/Dropdown';
import useSearch from 'hooks/useSearch';
import { deleteBudgetHeader, getAllBudgetHeaders, getSummary } from 'store/slice/BuyerSlice/Budget/BudgetSlice';
import { budgetCol } from './data';
import DataTable from 'components/DataTable';
import { format } from "date-fns";
import Pagination from 'components/Pagination';
import CreateBudget from './CreateBudget';
import Textbox from 'components/Textbox';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import FilterCard from 'components/FilterCard';
import SelectDropdown from 'components/SelectDropdown';

const Budget = () => {

    const dispatch = useDispatch<AppDispatch>();
    const history = useHistory();

    const {
        isLoading,
        summary,
        budgetHeaders
    } = useSelector((state: RootState) => state.budget);

    const [page, setPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [showFilter, setShowFilter] = useState(false)
    const [modal, setModal] = useState(false);
    const [modalData, setModalData] = useState({});
    const [modalText, setModalText] = useState('');

    const { search, inputRef, handleChange } = useSearch(getAllBudgetHeaders, perpage);

    const {
        register,
        handleSubmit,
        reset,
    } = useForm({
        mode: "onChange",
        defaultValues: {
            start_date: '',
            end_date: '',
            from: '',
            to: '',
        }
    })

    const onSubmit = (vals) => {
        dispatch(getAllBudgetHeaders(page, perpage, search, vals.start_date, vals.end_date, vals.budget_header_status))
    }

    const handleClear = () => {
        dispatch(getAllBudgetHeaders());
        reset();
    };

    const handleNewPage = React.useCallback(
        (pageNo: number, page = 1) => {
            setPerpage(pageNo);
            setPage(1);
            dispatch(getAllBudgetHeaders(page, pageNo, search));
        },
        [search]
    );

    const paginate = React.useCallback(
        (pageNo: number, length: number) => {
            setPage(pageNo);
            dispatch(getAllBudgetHeaders(pageNo, length, search));
        },
        [search]
    );

    const handlePageChange = React.useCallback(
        (page: number, length: number) => {
            setPage(page);
            dispatch(getAllBudgetHeaders(page, length, search));
        },
        [search]
    );

    const editBudget = (item) => {
        setModalData(item)
        setModalText('Edit');
        setModal(!modal)
    }

    const handleDeleteBudget = (id) => {
        const data = {
            budget_header_id: id,
        }
        dispatch(deleteBudgetHeader(data, () => dispatch(getAllBudgetHeaders())))
    }

    const budgetList =
        budgetHeaders &&
        budgetHeaders?.data?.map((item) => {
            return {
                ...item,
                name: item.budget_name,
                location: item.company_description,
                budget_holder: `${item.budget_holder_lastname} ${item.budget_holder_othernames}`,
                date_created: format(new Date(item.created_at), "dd.MM.yyyy"),
                budget_utilization: item.budget_period,
                available: item.budget_ledger_balance,
                view: () => history.push(`/buyer/budget/${item.id}`),
                edit: () => editBudget(item),
                deleteId: () => handleDeleteBudget(item.id),
            }
        })

    useEffect(() => {
        dispatch(getSummary());
        dispatch(getAllBudgetHeaders());
    }, [])

    useEffect(() => {
        if (!modal) setModalData({})
    }, [modal])

    return (
        <div id="budget">
            {modal && <CreateBudget
                closeModal={() => setModal(!modal)}
                modalData={modalData}
                modalText={modalText}
            />}
            <div className="container">
                <div className="stats-card mt-4 row">
                    <StatsCard
                        text="Available Budgets"
                        classes="stats-icon-blue"
                        image={budgetIcon}
                        statsNumber={summary?.overall?.total_count || 0}
                    />
                    <StatsCard
                        image={AddIcon}
                        classes="middle-img"
                        centerText="Create Budget"
                        onClick={() => {
                            setModal(!modal);
                            setModalText('Create')
                        }}
                    />{" "}
                </div>
                {showFilter && (
                    <FilterCard
                        handleSubmit={handleSubmit(onSubmit)}
                        clear={handleClear}
                    >
                        <div className="row">
                            <div className="col col-4">
                                <label
                                    style={{ display: 'block' }}
                                >Start Date</label>
                                <input
                                    type="date"
                                    name="start_date"
                                    className="mt-3"
                                    placeholder="Start Date"
                                    style={{
                                        width: '100%',
                                        height: '3rem',
                                        padding: '5px',
                                        borderRadius: '10px',
                                        border: '1px solid #dfe2e6',
                                    }}
                                    ref={register({ required: false })}
                                />
                            </div>
                            <div className="col col-4">
                                <label
                                    style={{ display: 'block' }}
                                >End Date</label>
                                <input
                                    type="date"
                                    name="end_date"
                                    className="mt-3"
                                    placeholder="End Date"
                                    style={{
                                        width: '100%',
                                        height: '3rem',
                                        padding: '5px',
                                        borderRadius: '10px',
                                        border: '1px solid #dfe2e6',
                                    }}
                                    ref={register({ required: false })}
                                />
                            </div>
                        </div>
                        <div className="row" style={{
                            marginTop: '2rem'
                        }}>
                            <div className="col col-4">
                                <label
                                    style={{ display: 'block' }}
                                >Budget Header Status</label>
                                <select
                                    style={{
                                        width: '100%',
                                        height: '3rem',
                                        padding: '5px',
                                        borderRadius: '10px',
                                        border: '1px solid #dfe2e6',
                                    }}
                                    name="budget_header_status"
                                    ref={register({
                                        required: false
                                    })}
                                >
                                    <option value=''>Select</option>
                                    <option value="-2">Draft</option>
                                    <option value="-1">Pending Approval</option>
                                    <option value="1">Approved</option>
                                </select>
                            </div>
                        </div>
                    </FilterCard>
                )}
                <div className="document mt-5">
                    <div className="search-field">
                        <SearchIcon
                            placeholder="Search Budgets"
                            boxClasses="mb-3"
                            textRef={inputRef}
                            value={search}
                            onChange={handleChange}
                        />
                        <Dropdown
                            perPage={perpage}
                            action={handleNewPage}
                        />
                        <div>
                            <button style={{
                                padding: "5px 10px",
                                borderRadius: "10px",
                                background: "#FFFFFF",
                                color: "#737A91",
                                border: '1px solid #DFE2E6',
                                fontSize: '18px',
                                marginLeft: '1rem',
                                width: 'max-content'
                            }}
                                onClick={() => setShowFilter(!showFilter)}
                            >
                                <img src={filterIcon} alt="icon" className="icon-image" />
                                <span style={{
                                    paddingLeft: '1rem',
                                }}>
                                    {!showFilter ? 'Filters' : 'Hide Filters'}
                                </span>
                            </button>
                        </div>

                    </div>
                    <div className="mt-3">
                        <DataTable
                            columns={budgetCol}
                            data={budgetList}
                            loading={isLoading}
                        />
                        <Pagination
                            page={page}
                            lastPage={budgetHeaders?.pagination?.lastPage}
                            paginate={paginate}
                            total={budgetHeaders?.pagination?.total}
                            handlePageChange={handlePageChange}
                            perPage={perpage}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Budget;
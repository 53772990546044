import ScoreOrView from "components/ModalBuyer/ScoreOrView";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { award, disqualify, getEachRfp, invite, publish } from "store/slice/BuyerSlice/EvaluationSlice/EvaluationSlice";
import { AppDispatch, RootState } from "store/store";
import { toastr } from "react-redux-toastr";
import doc from "../../../../assests/images/doc.png";
import "./styles.scss";
import { truncateSync } from "fs";

const Evaluation_4 = () => {
  const [pageNum, setPageNumber] = useState(10);
  const [modals, setModals] = useState(false);
  const [whichModal, setWhichModals] = useState(false);
  const [fieldId, setFieldId] = useState("");
  const [fieldQuestion, setFieldQuestion] = useState("");
  const [order, setOrder] = useState("");
  const [vendorScore, SetVendorScore] = useState(null);

  const closeModal = () => {
    setModals(!modals);
  };
  const dispatch = useDispatch<AppDispatch>();
  const { eachRfp, inviteLoading, disqualifyLoading, publishLoading } = useSelector((state: RootState) => state.rfpData);
  const [totalScore, setTotalScore] = useState(0);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const idResponse = urlParams.get("id");
  const vendor_id = urlParams.get("vendor_id");
  const vendor_name = urlParams.get("vendor_name");
  const categoryName = urlParams.get("category");
  const rfpStatus = urlParams.get("status");
  const currentRfp = eachRfp && eachRfp.data;

  useEffect(() => {
    dispatch(getEachRfp({ id: idResponse, vendor_id: vendor_id, categoryName: categoryName }));
  }, []);
  // Create Invoice
  const ScoreReview = (id, question, order) => {
    if (rfpStatus === "4") {
      toastr.error("Action is not allowed");
    } else {
      setFieldId(id);
      setFieldQuestion(question);
      setOrder(order);
      setWhichModals(true);
      closeModal();
    }
  };

  const inviteHandle = () => {
    if (rfpStatus === "4") {
      toastr.error("Action is not allowed");
    } else {
      const payload = {
        vendor_id,
        rfp_id: idResponse
      };
      dispatch(
        invite(payload, () => {
          dispatch(getEachRfp({ id: idResponse, vendor_id: vendor_id, categoryName: categoryName }));
        })
      );
    }
  };

  const publishHandle = () => {
    if (rfpStatus === "4") {
      toastr.error("Action is not allowed");
    } else {
      const payload = {
        vendor_id,
        rfp_id: idResponse
      };
      dispatch(
        publish(payload, () => {
          dispatch(getEachRfp({ id: idResponse, vendor_id: vendor_id, categoryName: categoryName }));
        })
      );
    }
  };

  const disqualifyHandle = () => {
    if (rfpStatus === "4") {
      toastr.error("Action is not allowed");
    } else {
      const payload = {
        vendor_id,
        rfp_id: idResponse
      };
      dispatch(
        disqualify(payload, () => {
          dispatch(getEachRfp({ id: idResponse, vendor_id: vendor_id, categoryName: categoryName }));
        })
      );
    }
  };

  const ViewReview = (vendor_score, question, order) => {
    SetVendorScore(vendor_score);
    setFieldQuestion(question);
    setOrder(order);
    setWhichModals(false);
    closeModal();
  };

  useEffect(() => {
    calcScore();
  });
  const calcScore = () => {
    let start = 0;
    eachRfp?.data?.[0]?.vendor_responses &&
      eachRfp?.data?.[0]?.vendor_responses.map((item) => {
        if (item.response_score) {
          start += parseInt(item.response_score, 10);
        }
      });

    setTotalScore(start);
  };

  return (
    <div id="evaluation_4">
      <div className="container">
        {modals && (
          <ScoreOrView
            modalText={whichModal}
            order={order}
            question={fieldQuestion}
            vendor_id={currentRfp?.[0] && currentRfp?.[0]?.vendor_id}
            rfp_id={currentRfp?.[0] && currentRfp?.[0]?.rfp_id}
            rfp_field_id={fieldId}
            vendor_score={vendorScore}
            closeModal={closeModal}
            categoryName={categoryName}
          />
        )}
        <header className="header-container">
          <h3 className="header">
            QT-{currentRfp?.[0] && currentRfp?.[0]?.rfp_id} - {vendor_name}
          </h3>
          {/* <div className="save-to-draft mt-4">
            <button disabled={rfpStatus === "4"?true:false} type="button" id="upload" onClick={() => {}}>
              Save to Draft
            </button>
          </div>{" "} */}
          <div className="invite-for-negotiations mt-4">
            <button disabled={rfpStatus === "4" ? true : false} type="button" className="invitebtn" id="upload" onClick={inviteHandle}>
              {inviteLoading ? <div className="spinner-border spinner-border-primary spinner-border-sm mr-2"></div> : "Invite For Negotiations"}
            </button>
          </div>
          <div className="disqualify mt-4">
            <button disabled={rfpStatus === "4" ? true : false} type="button" id="upload" onClick={disqualifyHandle}>
              {disqualifyLoading ? <div className="spinner-border spinner-border-primary spinner-border-sm mr-2"></div> : "Disqualify"}
            </button>
          </div>
          <div className="publish mt-4" onClick={publishHandle}>
            <button disabled={rfpStatus === "4" ? true : false} type="button" className={rfpStatus === "4" ? "published publish-btn" : "publish-btn"} id="upload" onClick={() => {}}>
              {publishLoading ? <div className="spinner-border spinner-border-primary spinner-border-sm mr-2"></div> : "Publish"}
            </button>
          </div>
        </header>
        <div className="evaluation mt-5">
          <div className="overall-container">
            <h3 className="">{categoryName} Evaluation</h3>
            <h3>Overall Score : {totalScore}</h3>
          </div>
          <hr />{" "}
          <div className="bid-technical-requirment-page-answers">
            {" "}
            <div className="col-lg-10 col-md-12 pl-0">
              <form action="">
                {eachRfp?.data?.[0]?.vendor_responses &&
                  eachRfp?.data?.[0]?.vendor_responses.map((item) => {
                    return (
                      <div key={item.id} className="question-box">
                        <label className="question-label" htmlFor="">
                          <span className="rating-header">
                            Q{item?.field_order}: {item?.field_label}
                          </span>
                          <div className="rating">
                            {item.vendor_score ? (
                              <button
                                type="button"
                                id="upload"
                                onClick={() => {
                                  ViewReview(item.vendor_score, item.field_label, item.field_order);
                                }}
                              >
                                View
                              </button>
                            ) : (
                              <button
                                type="button"
                                id="upload"
                                onClick={() => {
                                  ScoreReview(item.rfp_field_id, item.field_label, item.field_order);
                                }}
                              >
                                Score
                              </button>
                            )}
                          </div>
                        </label>
                        {item.field_type === "file" ? (
                          <div className="fileType">
                            <a href="#">
                              <img src={doc} alt="docImage" />
                            </a>
                          </div>
                        ) : (
                          <p className="textarea">{item?.vendor_response}</p>
                        )}
                      </div>
                    );
                  })}

                {/* <div className="question-box">
                                    <label className="question-label" htmlFor="">
                                        <span className="rating-header"> Q2: How will training be Coordinated</span>
                                        <div className="rating">
                                            <span>1.0</span>
                                            <button type="button" id="upload" onClick={ViewReview}>
                                                View
                                            </button>
                                        </div>
                                    </label>
                                    <p className="textarea">
                                        catching fire. Sometimes you need to take a site offline for routine maintenance
                                        or upgrades. While a no-downtime update is ideal, it isn’t always possible. The
                                        advantage is, with planned
                                    </p>
                                </div>
                                <div className="question-box">
                                    <label className="question-label" htmlFor="">
                                        <span className="rating-header">Q3: Are You CISA Certified </span>
                                        <div className="rating">
                                            <span>2.0</span>
                                            <button type="button" id="upload" onClick={ViewReview}>
                                                View
                                            </button>
                                        </div>
                                    </label>
                                    <div className="d-flex radio-box ">
                                        <div className="">
                                            <input
                                                type="radio"
                                                name="certified"
                                                id="yes"
                                                className="mr-2"
                                                checked
                                                readOnly
                                            />
                                            <label className="radio-box_label" htmlFor="yes">
                                                Yes
                                            </label>
                                        </div>
                                        <div className="">
                                            <input type="radio" name="certified" id="no" className="mr-2" />
                                            <label className="radio-box_label" htmlFor="no">
                                                No
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                 */}
                {/* <div className="question-box"> */}
                {/* <label className="question-label" htmlFor="">
                                        <span className="rating-header">Q4: Technical Proposal</span>
                                        <div className="rating">
                                            <span>4.0</span>
                                            <button type="button" id="upload" onClick={ViewReview}>
                                                View
                                            </button>
                                        </div>
                                    </label>

                                    <p className="textarea">
                                        catching fire. Sometimes you need to take a site offline for routine maintenance
                                        or upgrades. While a no-downtime update is ideal, it isn’t always possible. The
                                        advantage is, with planned
                                    </p> */}
                {/* <div className="upload-box">
                                            <label className="upload-box_label" htmlFor="">
                                                Attachments:
                                            </label>
                                            <FileUploaderBox boxClasses="fileuploader" />
                                        </div>*/}
                {/* </div> */}

                {/* <div className="question-box">
                                    <label className="question-label" htmlFor="">
                                        <span className="rating-header">Q5: Proposal Presentation</span>
                                        <div className="rating">
                                            <span>2.0</span>
                                            <button type="button" id="upload" onClick={ViewReview}>
                                                View
                                            </button>
                                        </div>
                                    </label>
                                    <p className="textarea">
                                        catching fire. Sometimes you need to take a site offline for routine maintenance
                                        or upgrades. While a no-downtime update is ideal, it isn’t always possible. The
                                        advantage is, with planned
                                    </p>
                                </div>
                             */}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Evaluation_4;

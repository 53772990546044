import axios from "axios";
import { AppThunk, AppDispatch } from "store/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { elementStates, Action } from "./interface";
import { toastr } from "react-redux-toastr";
import API from "utils/httpsRequest";

const initialState: elementStates = {
  loading: false,
  deleting: false,
  isLoading: false,
  elements: null,
  successMessage: "",
  errorMessage: ""
};

const elementSlice = createSlice({
  name: "elements",
  initialState,
  reducers: {
    getAlllElementLoading: (state) => {
      state.loading = true;
    },
    getAlllElementSuccess: (state, action: PayloadAction<Action>) => {
      state.loading = false;
      state.elements = action.payload;
    },
    getAlllElementFail: (state) => {
      state.loading = false;
    },
    createElementLoading: (state) => {
      state.isLoading = true;
    },
    createElementSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoading = false;
      //   state.elements = action.payload;
    },
    createElementFail: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },
    editElementLoading: (state) => {
      state.isLoading = true;
    },
    editElementSuccess: (state, action: PayloadAction<Action>) => {
      state.isLoading = false;
    },
    editElementFail: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },
    deleteElementLoading: (state) => {
      state.deleting = true;
    },
    deleteElementSuccess: (state, action: PayloadAction<Action>) => {
      state.deleting = false;
      state.elements = action.payload;
    },
    deleteElementFail: (state, action: PayloadAction<string>) => {
      state.deleting = false;
      state.errorMessage = action.payload;
    }
  }
});

const { actions, reducer } = elementSlice;
export const {
  getAlllElementLoading,
  getAlllElementSuccess,
  getAlllElementFail,
  createElementLoading,
  createElementSuccess,
  createElementFail,
  editElementLoading,
  editElementSuccess,
  editElementFail,
  deleteElementLoading,
  deleteElementSuccess,
  deleteElementFail
} = actions;

export const getElements =
  (page?: number, length?: number, search?: string, department_status?: string, department_code?: string, company_id?: string, location_id?: string, hod_user_id?: string): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getAlllElementLoading());
    try {
      const response = await API.get("/cost_elements/get", {
        params: {
          page,
          length,
          search,
          department_status,
          department_code,
          company_id,
          location_id,
          hod_user_id
        },
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getAlllElementSuccess(response.data));
      }
    } catch (error) {
      dispatch(getAlllElementFail());
    }
  };

export const createElement =
  (data: any, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(createElementLoading());
    try {
      const response = await API.post(`/cost_elements/create`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(createElementSuccess(response.data.message));
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error) {
      if (error.response) {
        dispatch(createElementFail(error.response.data?.message));
        toastr.error(error.response.data.message);
      } else {
        dispatch(createElementFail("No network connecton"));
        toastr.error("No network connecton");
      }
      setTimeout(() => {
        dispatch(createElementFail(""));
      }, 3000);
    }
  };

export const editElement =
  (data: any, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(editElementLoading());
    try {
      const response = await API.post(`/cost_elements/edit`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(editElementSuccess(response.data.message));
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error) {
      if (error.response) {
        dispatch(editElementFail(error.response.data.message));
        toastr.error(error.response.data.message);
      } else {
        dispatch(editElementFail("No network connecton"));
        toastr.error("No network connecton");
      }
      setTimeout(() => {
        dispatch(editElementFail(""));
      }, 3000);
    }
  };

export const deleteElement =
  (data?: any, cb?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(deleteElementLoading());
    try {
      const response = await API.post(`cost_elements/delete`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(deleteElementSuccess(response.data.message));
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error) {
      dispatch(deleteElementFail(error.response.data.message));
      toastr.error(error.response.data.message);
      setTimeout(() => {
        dispatch(deleteElementFail(""));
      }, 3000);
    }
  };

export default reducer;

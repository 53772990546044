import axios from "axios";
import { AppThunk, AppDispatch } from "store/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { bidsState, Action } from "./interface";
import { toastr } from "react-redux-toastr";
import API from "utils/httpsRequest";

const initialState: bidsState = {
  loading: false,
  bids: null,
  bid: null,
  successMessage: "",
  errorMessage: ""
};

const bidsSlice = createSlice({
  name: "bids",
  initialState,
  reducers: {
    bidsLoading: (state) => {
      state.loading = true;
    },
    bidsSuccess: (state, action: PayloadAction<Action>) => {
      state.loading = false;
      state.bids = action.payload;
    },
    bidsFail: (state) => {
      state.loading = false;
    },
    bidLoading: (state) => {
      state.loading = true;
    },
    bidSuccess: (state, action: PayloadAction<Action>) => {
      state.loading = false;
      state.bid = action.payload;
    },
    bidFail: (state) => {
      state.loading = false;
      // state.errorMessage = action.payload;
    },
    submitRFPLoading: (state) => {
      state.loading = true;
    },
    submitRFPSuccess: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.successMessage = action.payload;
    },
    submitRFPFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.errorMessage = action.payload;
    },
    confirmRFPLoading: (state) => {
      state.loading = true;
    },
    confirmRFPSuccess: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.successMessage = action.payload;
    },
    confirmRFPFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.errorMessage = action.payload;
    },
    declineBidLoading: (state) => {
      state.loading = true;
    },
    declineBidSuccess: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.successMessage = action.payload;
    },
    declineBidFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.errorMessage = action.payload;
    }

    // deleteProductDataLoading: (state) => {
    //   state.deleting = true;
    // },
    // deleteProductDataSuccess: (state, action: PayloadAction<Action>) => {
    //   state.deleting = false;
    //   state.Approvals = action.payload;
    // },
    // deleteProductDataFail: (state, action: PayloadAction<string>) => {
    //   state.deleting = false;
    //   state.errorMessage = action.payload;
    // }
  }
});

const { actions, reducer } = bidsSlice;
export const {
  bidsLoading,
  bidsSuccess,
  bidsFail,
  bidFail,
  bidLoading,
  bidSuccess,
  submitRFPFail,
  submitRFPLoading,
  submitRFPSuccess,
  confirmRFPFail,
  confirmRFPLoading,
  confirmRFPSuccess,
  declineBidFail,
  declineBidLoading,
  declineBidSuccess
} = actions;

export const getBids =
  (page?: number, length?: number, search?: string, requisition_code?: number, rfp_status?: string): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(bidsLoading());
    try {
      const response = await API.get("/rfps/get_vendor_rfps", {
        params: {
          requisition_code,
          length,
          search,
          rfp_status,
          page
        },
        headers: {
          Authorization: `Bearer ${userToken}`
          // Authorization: `Bearer $2y$10$cVC/vaPf/yHubUQ.sOv24uHlNO3EBYgqjWSDCOMh.o3oshbDJTY86`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(bidsSuccess(response.data));
      }
    } catch (error) {
      dispatch(bidsFail());
    }
  };

export const getBid =
  (id: string, cb?: () => void, page?: number, length?: number, search?: string, requisition_code?: number, rfp_status?: string): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(bidLoading());
    try {
      // params: {},
      const response = await API.get(`/rfps/get_vendor_rfps/${id}`, {
        params: {
          requisition_code,
          length,
          search,
          rfp_status,
          page
        },
        headers: {
          // Authorization: `Bearer $2y$10$cVC/vaPf/yHubUQ.sOv24uHlNO3EBYgqjWSDCOMh.o3oshbDJTY86`
          Authorization: `Bearer ${userToken}`
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(bidSuccess(response.data?.data));
        cb && cb();
      }
    } catch (error) {
      dispatch(bidFail());
    }
  };

export const submitBid =
  (payload: object, cb: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    dispatch(submitRFPLoading());
    const { userToken } = getState().user;

    try {
      const response = await API.post(`/rfp_responses/create`, payload, {
        headers: {
          Authorization: `Bearer ${userToken}`
          //   Authorization: `Bearer $2y$10$cVC/vaPf/yHubUQ.sOv24uHlNO3EBYgqjWSDCOMh.o3oshbDJTY86`,
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(submitRFPSuccess(response.data.message));
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error) {
      dispatch(submitRFPFail(error?.response?.data?.error));
      toastr.error(error?.response?.data.message);
    }
  };

export const confirmBid =
  (payload: object, cb: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    dispatch(confirmRFPLoading());
    const { userToken } = getState().user;

    try {
      const response = await API.post(`/rfp_responses/confirm`, payload, {
        headers: {
          Authorization: `Bearer ${userToken}`
          //   Authorization: `Bearer $2y$10$cVC/vaPf/yHubUQ.sOv24uHlNO3EBYgqjWSDCOMh.o3oshbDJTY86`,
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(confirmRFPSuccess(response.data.message));
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error) {
      dispatch(confirmRFPFail(error?.response?.data?.error));
      toastr.error(error?.response?.data.message);
    }
  };

export const declineBid =
  (id, payload: object, cb: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    dispatch(declineBidLoading());
    const { userToken } = getState().user;

    try {
      const response = await API.post(
        `/rfp_vendors/reject_invite
      `,
        payload,
        {
          headers: {
            Authorization: `Bearer ${userToken}`
            //   Authorization: `Bearer $2y$10$cVC/vaPf/yHubUQ.sOv24uHlNO3EBYgqjWSDCOMh.o3oshbDJTY86`,
          }
        }
      );
      if ([200, 201].includes(response.status)) {
        dispatch(declineBidSuccess(response.data.message));
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error) {
      dispatch(declineBidFail(error?.response?.data?.error));
      toastr.error(error?.response?.data.message);
    }
  };

export default reducer;

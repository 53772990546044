import DropdownMenu from "components/DropdownMenu";
import StarRating from "components/Star";
import "./styles.scss";

export const rateColumn = [
  {
    name: "Vendor ID",
    index: "vendor_id",
    id: 1
  },
  {
    name: "Vendor",
    index: "name",
    id: 2
  },
  {
    name: "Company",
    index: "company",
    id: 3
  },
  {
    name: "Rating",
    id: 7,
    render: (item) => <StarRating stars={item.rating} disabled={true} />
  },
  {
    name: "No Of Delivery",
    index: "delivery",
    id: 5
  }
];

export const tableCol = [
  {
    name: "Date",
    index: "goods_received_date",
    id: 0
  },
  {
    name: "Goods Received Id",
    index: "gr_code",
    id: 1
  },
  {
    name: "Timelyness Rating",
    id: 2,
    render: (item) => <StarRating stars={item.timeliness_rating} disabled={true} />
  },
  {
    name: "Delivery Rating",
    id: 3,
    render: (item) => <StarRating stars={item.delivery_rating} disabled={true} />
  },
  {
    name: "Quality Rating",
    id: 4,
    render: (item) => <StarRating stars={item.quality_rating} disabled={true} />
  },
  {
    name: "Actions",
    id: 5,
    render: ({ edit }) => {
      const settings = [
        {
          name: "Edit",
          style: "edit_tag",
          select: edit
        }
      ];
      return (
        <span style={{ position: "relative" }}>
          <DropdownMenu options={settings} boxClasses="dropdown-style">
            <p style={{ fontSize: "2rem" }} className="cursor-pointer">
              ...
            </p>
          </DropdownMenu>
        </span>
      );
    }
  }
];

import { useState } from "react";
import "./styles.scss";
// import PageWrapper from "../../../components/SupplierPageWrapper";

import Stars from "../../../components/Star";
import { tableCol, tableData } from "./data";
import StatCard, { StatsCard } from "components/StatsCard/index";
import Contracts from "assests/icons/catalog.svg";
import Trucks from "assests/icons/catalog2.svg";
import SearchBar from "../../../components/SearchBar";
import Dropdown from "components/Dropdown";
import DataTable from "components/DataTable";
import Icon from "assests/icons/add-circle.svg";
import CreateCatalogue from "components/ModalSupplier/CreateCatalogue";

interface ICatalogueProps {}

const Catalogue: React.FC<ICatalogueProps> = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [pageNum, setPageNumber] = useState(10);
    const [modals, setModals] = useState(false);

    const closeModal = () => {
        setModals(!modals);
    };

    //Create User
    const createUser = () => {
        closeModal();
    };

    return (
        <section className="catalogue">
            <div className="container">
                {modals && <CreateCatalogue closeModal={closeModal} />}

                <div className="catalogue__content">
                    <header>
                        <h4 className="page-title mr-2 ">Stakle’s Ratings</h4>
                        <Stars starClass="rating" stars={3} disabled />
                    </header>
                    <div className="mt-4 row">
                        <StatsCard
                            text="Catalogue Items"
                            classes="stats-icon-orange"
                            image={Contracts}
                            statsNumber={24}
                        />
                        <StatCard text="Pending" classes="stats-icon-orange" image={Trucks} statsNumber={2} />
                        <StatsCard
                            image={Icon}
                            classes="middle-img"
                            centerText="Create Catalogue"
                            onClick={createUser}
                        />
                    </div>
                    <div className="document mt-5">
                        <h3 className="title font-weight-bold">Recent Documents</h3>
                        <div className="d-flex mt-3">
                            <SearchBar placeholder="Search for Product name, ID" />
                            <Dropdown perPage={10} action={(num) => setPageNumber(num)} />
                        </div>
                        <div className="mt-3">
                            <DataTable columns={tableCol} data={tableData} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Catalogue;

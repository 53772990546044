import FileUploaderBox from "components/FileUploaderBox";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRfpFields, getSingleRfp, publishRfp, updateRFISourcing, uploadFile, uploadingImageSuccess } from "store/slice/SourcingSlice/SourcingSlice";
import { RootState } from "store/store";
import silh from "assests/icons/silh.png";
import styled from "styled-components";
import { useForm } from "react-hook-form";

const Wrapper = styled.div`
  p.page-title {
    color: #3d59b7;
    line-height: 36px;
    font-weight: 700;
    font-family: "Averia-bold";
  }

  div.spinner-border {
    display: inline-block;
    width: 5rem;
    height: 5rem;
    vertical-align: text-bottom;
    border: 0.8em solid rgba(47, 128, 237, 0.2);
    border-right-color: transparent;
    border-radius: 50%;
    animation: 1.9s linear infinite spinner-border;
  }

  margin-top: 3rem;

  h5.main {
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 1rem;

    span {
      margin-left: 1.5rem;
      color: #1753ac;
    }
  }

  h5.sub {
    padding-top: 1rem;
    color: #4061cb;
  }

  div.requisition-details {
    margin-top: 3rem;

    div.container {
      border: 1px solid #dfe2e6;
      width: 60%;
      border-radius: 6px;
      padding: 1.5rem 1rem;
      margin-top: 1rem;

      div.flex {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;

        div.flex-item {
          margin: 10px;
          width: 15rem;

          h5 {
            color: #1a3baf;
          }
        }
      }

      table {
        width: 100%;
        margin-top: 3rem;
        margin-bottom: 2rem;

        tbody {
          tr {
            border: 1px solid #dfe2e6;
            border-radius: 6px;
            padding: 1.5rem 1rem !important;
            color: #0062ff;
          }
        }
      }
    }
  }

  div.additional-information {
    margin-top: 3rem;

    span.width-60 {
      display: block;
      width: 60%;
      margin-top: 2rem;

      span.flex {
        margin-top: 1rem;
        display: block;

        label {
          margin-right: 1rem;
        }

        input {
          border: 1px solid #e8e8e8;
          padding: 15px;
          border-radius: 0.5rem;
          min-width: 25rem;
        }
      }
    }
  }
`;

const Overview = React.forwardRef((props: { id; parentCallback }, ref) => {
  const dispatch = useDispatch();

  const { rfp, isLoading, s3url } = useSelector((state: RootState) => state.sourcing);

  const [files, setFiles] = React.useState<any>([]);

  const { register, handleSubmit, control, setValue } = useForm({
    mode: "onChange"
  });

  React.useImperativeHandle(ref, () => ({
    getAlert() {
      handleSubmit(onSubmit)();
      // const data = {
      //   ...ovData,
      //   id: rfp.id,
      // }
      // console.log(data);
      // dispatch(updateRFISourcing(data, () => dispatch(getSingleRfp(props?.id))))
    }
  }));

  const onSubmit = (vals) => {
    if (vals.document_url) vals.document_url = s3url;
    if (vals.rfp_description.length > 0 || vals.in_house_estimate.length > 0 || vals.technical_passing_score.length > 0 || vals.document_url.length > 0) {
      const data = {
        ...vals,
        id: rfp.id
      };
      console.log(data);
      dispatch(updateRFISourcing(data, () => dispatch(getSingleRfp(props?.id))));
    }
  };

  React.useEffect(() => {
    dispatch(getSingleRfp(props?.id));
    dispatch(uploadingImageSuccess(""));
  }, []);

  const handlePublish = () => {
    const data = { rfp_id: props?.["id"] };
    dispatch(publishRfp(data));
  };

  const handleFile = (e, field_name: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(e);
    reader.onloadend = () => {
      // use a regex to remove data url part
      let read: any = reader.result;
      const payload = {
        base64_data: read.replace("data:", "").replace(/^.+,/, ""),
        file_extension: e.name.split(".").pop()
      };
      dispatch(uploadFile(payload));
      setFiles(payload);
    };
  };

  const handleRfpSuc = () => {
    dispatch(getSingleRfp(props?.id));
    dispatch(getRfpFields(props?.id));
  };

  React.useEffect(() => {
    // console.log(rfp);
    if (rfp !== undefined || rfp !== null) {
      setValue("in_house_estimate", rfp?.in_house_estimate);
      setValue("technical_passing_score", rfp?.technical_passing_score);
      setValue("rfp_description", rfp?.rfp_description);
    }
  }, [rfp]);

  return (
    <Wrapper className="route-transition">
      {isLoading ? (
        <div className="justify-content-center w-100 d-flex pb-5 pt-5">
          <div className="spinner-border spinner-border-primary spinner-border-lg justify-content-center mr-2"></div>
        </div>
      ) : (
        <>
          <h5 className="header main">
            Project Detail <span>{rfp?.rfp_code}</span>
          </h5>
          <h5 className="sub">{rfp?.rfp_title}</h5>
          <div className="requisition-details">
            <h5 className="header">Requisition Details</h5>
            <div className="container">
              <div className="row mb-5">
                <div className="col-md-4 col-lg-4 col-xs-12">
                  <div className="mb-3">
                    <p className="page-title">Document Number</p>
                    <p>{rfp?.requisition_code}</p>
                  </div>
                  <div className="mb-3">
                    <p className="page-title">Head Of Department</p>
                    <p>
                      {rfp?.department?.[0]?.hod_lastname || "n/a"} {rfp?.department?.[0]?.hod_othernames}
                    </p>
                  </div>
                </div>
                <div className="col-md-4 col-lg-4 col-xs-12">
                  <div className="mb-3">
                    <p className="page-title">Approved On</p>
                    <p>{rfp?.approved_at || "n/a"}</p>
                  </div>
                  <div className="mb-3">
                    <p className="page-title">Requesting Department</p>
                    <p>{rfp?.department_description}</p>
                  </div>
                </div>
                <div className="col-md-4 col-lg-4 col-xs-12">
                  <div className="mb-3">
                    <p className="page-title">Date Of Request</p>
                    <p>{rfp?.created_at}</p>
                  </div>
                  <div className="mb-3">
                    <p className="page-title">Requester</p>
                    <p>{rfp?.requisition_requester?.lastname || "n/a"}</p>
                  </div>
                </div>
              </div>

              <div className="purchase-table mt-4">
                <table>
                  <tbody>
                    <tr className="table-header">
                      <th style={{ padding: "1rem" }}>Item</th>
                      <th>Mat. Cat.</th>
                      <th>Product Description</th>
                      <th>Quantity</th>
                      <th>UoM</th>
                      <th>Amount</th>
                    </tr>
                    {rfp?.materials?.map((item, i) => (
                      <tr key={i} className="table-data-row">
                        <td style={{ padding: "1rem" }}>{i + 1}</td>
                        <td>{item?.material_category_description}</td>
                        <td>{item?.material_description}</td>
                        <td>{item.quantity_required} </td>
                        <td>{item.uom}</td>
                        <td>{item.estimated_unit_price}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="additional-information">
            <h5 className="header main">Additional Information</h5>
            <span className="width-60">
              <span
                style={{
                  marginBottom: "0.5rem",
                  display: "block",
                  color: "#3d59b7",
                  lineHeight: "36px",
                  fontWeight: "bold",
                  fontFamily: "Averia-bold"
                }}
              >
                Requirements Description
              </span>
              <textarea
                rows={4}
                className="comments text-area mt-4"
                // placeholder="Enter Requirements Description"
                name="rfp_description"
                ref={register({ required: false })}
                style={{
                  resize: "none",
                  border: "1px solid #E8E8E8",
                  borderRadius: "1rem",
                  width: "100%",
                  outline: "none",
                  padding: "1rem"
                }}
                disabled
              />

              <span className="flex">
                <label
                  htmlFor="estimate"
                  style={{
                    color: "#3d59b7",
                    lineHeight: "36px",
                    fontWeight: "bold",
                    fontFamily: "Averia-bold"
                  }}
                >
                  In-house Estimate
                </label>
                <input type="text" placeholder="" name="in_house_estimate" ref={register({ required: false })} disabled style={{ display: "inline-block" }} />
              </span>

              <span className="flex">
                <label
                  htmlFor=""
                  style={{
                    color: "#3d59b7",
                    lineHeight: "36px",
                    fontWeight: "bold",
                    fontFamily: "Averia-bold"
                  }}
                >
                  Qualifying percentage
                </label>
                <input max="100" name="technical_passing_score" disabled type="number" ref={register({ required: false })} placeholder="" style={{ display: "inline-block" }} />
              </span>

              <span className="flex">
                {/* <label
                  htmlFor="upload"
                  style={{
                    color: "#3d59b7",
                    lineHeight: "36px",
                    fontWeight: "bold",
                    fontFamily: "Averia-bold"
                  }}
                >
                  Upload Attachment
                </label> */}
                <span style={{ display: "inline-flex", position: "relative", top: "1.5rem" }}>
                  {/* <FileUploaderBox
                    fileName="document_url"
                    placeholder="Browse your Computer"
                    boxClasses="mt-3 mb-2"
                    handleFile={handleFile}
                    acceptFiles={".jpg, .jpeg, .png"}
                    customRef={register({ required: false })}
                  /> */}
                  {rfp?.document_url && (
                    <a href={`${rfp?.document_url}`} target="_blank" rel="noreferrer">
                      {" "}
                      View Documnet
                    </a>
                  )}
                </span>
              </span>
            </span>
          </div>

          <div className="additional-information">
            <h5 className="header main">Tender Team</h5>
            <span className="width-60" style={{ display: "inline-flex" }}>
              {rfp?.sourcing_team?.[0]?.team_members?.map((item, index) => (
                <div key={index} style={{ margin: "10px", padding: "5px", minWidth: "fit-content" }}>
                  <img src={silh} alt="person img" style={{ height: "15px" }} />{" "}
                  <span style={{ display: "inline-block" }}>
                    {item?.lastname} {item?.othernames}
                  </span>
                </div>
              ))}
            </span>
          </div>
        </>
      )}
    </Wrapper>
  );
});

export default Overview;

import Button from "components/Button";
import React from "react";
import "./styles.scss";

const Communication = () => {
    return (
        <div className="route-transition">
            <div className="bid-communication-page">
                <div className="col-lg-10 col-md-12 pl-0">
                    <div className="d-flex align-items-center send-box mt-3 mb-3">
                        <label htmlFor="">Title</label>
                        <textarea
                            name="additionalComments"
                            id="additionalComments"
                            cols={35}
                            rows={1}
                            className="textarea"
                            placeholder=""
                        />
                    </div>
                    <div className="submit-box mt-4 ">
                        <textarea
                            name="additionalComments"
                            id="additionalComments"
                            cols={35}
                            rows={5}
                            className="textarea"
                            placeholder=""
                        />
                        <div className="submit-btn">
                            <Button
                                type="button"
                                label="Send Comment"
                                btnType="btn-skeleton"
                                xtraClass="btn-padding mt-2"
                                boxClasses=""
                            ></Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Communication;

import React, { useEffect, useState } from "react";
import Modal from "components/Modal";
import ArrowDropdown from "components/ArrowDropdown";
import Textbox from "components/Textbox";
import Button from "components/Button";
import useArrowDropdown from "hooks/useArrowDropdown";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import "./styles.scss";
import { useForm } from "react-hook-form";
import ErrorMesssage from "components/ErrorMessage";
import { createRole, editRole, getPermissions, getRole, getRoles } from "store/slice/Roleslice/RoleSlice";
import SelectDropdown from "components/SelectDropdown";

type Props = {
  closeModal: () => void;
  value?: string;
  roleId?: string;
  checkedPerm?: any;
};
const BuyerCreateRoles = ({ closeModal, value, roleId, checkedPerm }: Props) => {
  //Redux Store
  const dispatch = useDispatch();
  const { permissions, errorMessage, isLoading, role } = useSelector((state: RootState) => state.roles);
  const { register, handleSubmit, errors, setValue, watch } = useForm<FormData>({
    mode: "onBlur"
  });
  const values = watch();
  const [error, setError] = React.useState("");

  const [permission, setPermission] = useState<any>([]);
  const { open, selectedOption, handleDropdownChange, handleAction, handleToggle, ref } = useArrowDropdown(permission, setPermission);

  useEffect(() => {
    if (permissions || role) {
      if (value && value !== "") {
        setValue("user_role_name", role?.data[0]?.user_role_name);
        setValue("role_type", role?.data[0]?.role_type);
        setValue("role_description", role?.data[0]?.role_description);
      }
      const permName = role?.data[0].app_modules.map((perm) => perm.permission_name);
      const permission =
        permissions &&
        permissions?.data.map((data: any) => {
          const perm = value && value !== "" && permName?.includes(data?.permission_name);
          return {
            name: data.permission_name,
            id: data.id,
            label: data.description,
            isChecked: value && value !== "" ? perm : false
          };
        });
      setPermission([...permission]);
    }
  }, [permissions, role]);

  useEffect(() => {
    if (values.role_type) {
      dispatch(getPermissions(values.role_type.toLowerCase()));
      // setValue("role_type", "");
      setPermission([]);
    }
  }, [values.role_type]);

  console.log(values.role_type);

  useEffect(() => {
    if (roleId) {
      dispatch(getRole(roleId));
    }
  }, [roleId]);

  //Clear Error Message
  useEffect(() => {
    const perm = permission.filter((item) => {
      return item.isChecked === true;
    });
    if (perm.length >= 1) setError("");
  }, [permission]);

  type FormData = {
    user_role_name: string;
    role_type: string;
    role_description: string;
    permission: any;
  };

  const onFormSubmit = (vals: FormData) => {
    if (isLoading) return;
    let checkedPerm = [] as any;
    permission.map((perm) => {
      if (perm.isChecked) {
        checkedPerm.push(Number(perm.id));
      }
      return checkedPerm;
    });

    //Validation
    if (checkedPerm.length < 1) {
      setError("Please select atleast one permission");
      return;
    }
    const data = {
      // role_name: vals.role_name,
      ...vals,
      app_modules: checkedPerm,
      user_role_status: 1
    };
    const editData = {
      // user_role_name: vals.user_role_name,
      ...vals,
      app_modules: checkedPerm,
      id: roleId,
      user_role_status: 1
    };
    if (value !== "") {
      dispatch(
        editRole(editData, () => {
          closeModal();
          dispatch(getRoles());
        })
      );
    } else {
      dispatch(
        createRole(data, () => {
          closeModal();
          dispatch(getRoles());
        })
      );
    }
  };

  //  user Types
  const userTypes: Object[] = [
    { value: "Vendor", optionName: "Vendor" },
    { value: "Company", optionName: "Company" }
  ];
  return (
    <Modal modalText={value === "" ? "Create Role" : "Edit Role"} onClose={closeModal} boxClasses="role-modal-top">
      <div className="buyer-role-modal">
        <p className="">Complete this form with accurate data to create a new role on the GZISP Platform.</p>
        <form className="mt-4" onSubmit={handleSubmit(onFormSubmit)}>
          <Textbox
            type="text"
            label="Role Name"
            placeholder="Enter Role Name"
            name="user_role_name"
            error={errors.user_role_name && "This field is required"}
            customRef={register({
              required: true
            })}
          />
          <SelectDropdown
            boxClasses="mt-3"
            name="role_type"
            label="Role Type"
            placeholder="Select Role Type"
            options={userTypes}
            customRef={register({ required: true })}
            error={errors.role_type && "This field is required"}
          />
          <textarea
            placeholder="Role Description"
            name="role_description"
            ref={register({
              required: true
            })}
            rows={2}
            className="textarea mt-4"
          />
          <ArrowDropdown
            text="Select Permissions"
            options={permission}
            handleDropdownChange={handleDropdownChange}
            handleAction={handleAction}
            selectedOption={selectedOption}
            handleToggle={handleToggle}
            open={open}
            boxClasses="mt-4"
            ref={ref}
          />
          <p className={`${error ? "d-block" : "d-none"} error mt-1`}>{error}</p>
          {/* {errorMessage !== "" && <ErrorMesssage message={errorMessage} />} */}
          <Button label={value === "" ? "Create Role" : "Edit Role"} btnType="btn-primary" boxClasses="mt-4 mb-5" btnStyle="100%" loading={isLoading} />
        </form>
        <p className="role-p" onClick={closeModal}>
          Cancel
        </p>
      </div>
    </Modal>
  );
};

export default BuyerCreateRoles;

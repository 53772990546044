import Button from "components/Button";
import DataTable from "components/DataTable";
import VerifyFile from "components/ModalBuyer/VerifyFile";
import Pagination from "components/Pagination";
import SelectDropdown from "components/SelectDropdown";
import Tabs from "components/Tabs";
import Textbox from "components/Textbox";
import { format, isValid, parse } from "date-fns";
import BankInformation from "pages/BuyerDashboard/Approval/ApprovalTabs/BankInformation";
import ContactInformation from "pages/BuyerDashboard/Approval/ApprovalTabs/ContactInformation";
import LegalInformation from "pages/BuyerDashboard/Approval/ApprovalTabs/LegalInformation";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { approveItem, approveItemLoading, rejectItem } from "store/slice/BuyerSlice/ApprovalSlice/ApprovalSlice";
import { doChangeVendorType, getSingleVendor, setvendorCurrency, wtTaxType } from "store/slice/BuyerSlice/VendorSlice/VendorSlice";
import { AppDispatch, RootState } from "store/store";
import { documentsColumns, documents } from "./data";
import Add from "../../../../assests/icons/Remove/Vector (5).svg";
import Remove from "../../../../assests/icons/Remove/Vector (6).svg";
import "./styles.scss";
import ArrowDropdown from "components/ArrowDropdown";
import { doAddVendorBusinessCategory, doRemoveVendorBusinessCategory, getAllBusinessCategory } from "store/slice/BusinessCategorySlice/BusinessCategorySlice";
import useArrowDropdown from "hooks/useArrowDropdown";
import RejectOnboarding from "components/ModalBuyer/RejectOnboarding";
import { Fragment } from "react";
import { enGB } from "date-fns/locale";
import { getAllcurrencies } from "store/slice/CurrencySlice/CurrencySlice";

const ApprovalTabs = () => {
  //State
  const [page, setPage] = useState<number>(1);
  const [length, setLength] = useState<number>(10);
  const [vendor, setVendor] = useState<string>("");
  const [currency, setCurrency] = useState<string>("");
  const [wtTaxTypeState, setWtTaxTypeState] = useState<string>("");
  const [tabNames, setTabNames] = useState([] as any);
  const [modal, setModal] = useState<boolean>(false);
  const [rejectModal, setRejectModal] = useState<boolean>(false);
  const [showCategory, setShowCategory] = useState<boolean>(false);
  const [doc, setDoc] = useState("");
  const [dataId, setDataId] = useState("");
  const [businessList, setbusinessList] = useState<any>([]);
  const [businessFieldError, setBusinessFieldError] = useState("");
  const [placeholder, setPlaceholder] = useState("Select other Business Categories");
  const [filtered, setFiltered] = useState<any>([]);

  const history = useHistory();
  const location = useLocation();

  const menus = ["Contact Information", ...tabNames, "Bank Informtion", "Business Category", "Other Information"];
  const [state, setstate] = useState(menus[0]);
  //Redux Store
  const dispatch = useDispatch<AppDispatch>();
  const { singleVendor } = useSelector((state: RootState) => state.vendorDetails);
  const { loading } = useSelector((state: RootState) => state.approvals);
  const { allBusinessCategory } = useSelector((state: RootState) => state.businessCategory);
  const { currencies } = useSelector((state: RootState) => state.currency);

  //Hooks
  const { register, handleSubmit, errors, setValue, watch } = useForm<FormData>({ mode: "onBlur" });
  const { ...rest } = useArrowDropdown(businessList, setbusinessList, filtered);


  // Currencies
  const currencyOptions = [] as any;
  currencies &&
    currencies.forEach((element: { id: string; currency_symbol: string; currency_description: string }) => {
      currencyOptions.push({
        value: element.id,
        optionName: `${element.currency_symbol} - ${element.currency_description}`
      });
    });

  //Tab Handler
  const selectMenu = useCallback((name) => {
    setstate(name);
  }, []);

  //   Helpers
  let { id }: any = useParams();

  useEffect(() => {
    if (location.state) {
      const state: any = location.state;
    } else {
      history.push("/buyer/approvals");
    }
  }, []);

  // useEffect(() => {
  //   const currentCate = vendorData?.categories?.map((item) => item.business_category_id);
  //   let options: any[] = allBusinessCategory?.reduce((accumulator, current) => {
  //     if (!currentCate.includes(current.id)) {
  //       const payload = {
  //         id: current.id,
  //         label: current.business_category_description,
  //         name: current.business_category_description,
  //         isChecked: false
  //       };
  //       accumulator.push(payload);
  //     }

  //     return accumulator;
  //   }, []);
  //   setFiltered(options);
  //   setbusinessList(options);
  // }, [allBusinessCategory]);

  // //checking multi-select field
  // const checkBusinessList = filtered?.reduce((acc, current) => {
  //   if (current?.isChecked) {
  //     acc.push(current?.id);
  //   }
  //   return acc;
  // }, []);

  // useEffect(() => {
  //   if (checkBusinessList?.length >= 1) {
  //     setBusinessFieldError("");
  //     setPlaceholder(`${checkBusinessList?.length} items selected`);
  //   } else setPlaceholder("Select other Business Categories");
  // }, [businessList, checkBusinessList]);

  // console.log(checkBusinessList);

  type FormData = {
    business_category_id: string;
    witholding_tax: string;
    witholding_tax_type: string;
  };

  //Getting Bussiness Categories
  const businessCategoryOption =
    allBusinessCategory &&
    allBusinessCategory.map((_data: { id: string; business_category_description: string }) => {
      return {
        value: _data.id,
        optionName: _data.business_category_description
      };
    });

  const vendorData = singleVendor?.data[0];
  const vendorDataFiles = singleVendor?.data[0]?.vendor_meta;

  useEffect(() => {
    dispatch(getSingleVendor(id));
    dispatch(getAllBusinessCategory());
    dispatch(getAllcurrencies());
  }, []);
  useEffect(() => {
    if (vendorData?.vendor_type === "Contractor") {
      setVendor("Contractor");
    } else if (vendorData?.vendor_type === "Supplier") {
      setVendor("Supplier");
    } else setVendor("");

    if (vendorData?.more_details?.witholding_tax_type && state === "Other Information") {
      setValue("witholding_tax_type", vendorData?.more_details?.witholding_tax_type);
    }
    if (vendorData?.more_details?.witholding_tax && state === "Other Information") {
      setValue("witholding_tax", vendorData?.more_details?.witholding_tax);
    }
  }, [vendorData, state, setValue]);

  //   console.log(vendorDataFiles);
  const vendorTypeChange = (type) => {
    setVendor(type);
    dispatch(doChangeVendorType({ vendor_id: id, vendor_type: type }));
  };

  const withholdingTaxType = (val) => {
    dispatch(wtTaxType({ witholding_tax_type: val, vendor_id: id }, () => dispatch(getSingleVendor(id))));
  };
  const withholdingTax = (val) => {
    dispatch(wtTaxType({ witholding_tax: val, vendor_id: id }, () => dispatch(getSingleVendor(id))));
  };
  const setVendorCurrency = (val) => {
    dispatch(setvendorCurrency({ vendor_currency_id: val, vendor_id: id }, () => dispatch(getSingleVendor(id))));
  };

  const open = (name, id) => {
    setModal(true);
    setDoc(name);
    setDataId(id);
  };

  const vendorFiles = vendorDataFiles && vendorDataFiles.filter((file) => file.field_type === "File");

  const tableData =
    vendorFiles &&
    vendorFiles.map((file) => {
      let { category, field_label, expires_on, data_status, vendor_data_id, value } = file;
      if (expires_on === "0000-00-00 00:00:00") expires_on = null;
      return {
        category: category,
        field_label,
        expires_on: expires_on === null ? "" : format(new Date(expires_on), "dd.MM.yyyy"),
        data_status: data_status === "-1" ? "In Progress" : "Verified",
        open: () => open(field_label, vendor_data_id),
        link: value
      };
    });

  let test = [] as any;
  let newData = [] as any;
  vendorDataFiles?.forEach((items, i) => {
    if (items.field_type === "Text") {
      test.push(items);
      if (tabNames.includes(items.category)) {
        return;
      } else setTabNames([...tabNames, items.category as any]);
      return items;
    }
  });

  for (let i = 0; i < tabNames.length; i++) {
    let data = {};
    data = { ...data, header: tabNames[i], array: [] };
    test.forEach((item, i) => {
      if (item.category === data["header"]) {
        data["array"] = [
          ...data["array"],

          {
            label: item.field_label,
            value: item.value
          }
        ];
      }
    });
    newData.push(data);
  }

  const closeModal = () => {
    setModal(!modal);
  };

  const closeReject = () => {
    setRejectModal(false);
  };

  const checkFiles =
    vendorFiles &&
    vendorFiles?.map((file) => {
      return file.data_status;
    });

  const approve = () => {
    if (window.confirm("Confirm Approve")) {
      dispatch(
        approveItem(
          {
            approval_queue_id: location.state,
            note: ""
          },
          () => history.push("/buyer/approvals")
        )
      );
    }
  };

  const reject = (vals) => {
    // if (window.confirm("Confirm Approve")) {
    dispatch(
      rejectItem(
        {
          approval_queue_id: location.state,
          note: vals
        },
        () => history.push("/buyer/approvals")
      )
    );
    // }
  };

  //--------------------------------------------
  //Pagination
  //---------------------------------------------
  const handlePageChange = useCallback((page: number, length: number) => {
    setPage(page);
    dispatch(getSingleVendor(id, () => {}, page, length));
  }, []);

  const paginate = useCallback((pageNo: number, length: number) => {
    setPage(pageNo);
    dispatch(getSingleVendor(id, () => {}, page, length));
  }, []);

  //------------------------------------------------------------
  //Show Number for PerPage Function
  //------------------------------------------------------------
  const handleNewPage = useCallback((pageNo: number, page = 1) => {
    setLength(pageNo);
    setPage(1);
    dispatch(getSingleVendor(id, () => {}, page, length));
  }, []);

  const taxType = [
    { value: "W1", optionName: "WHT(Corporate)" },
    { value: "W2", optionName: "WHT(Individual/Ventures)" }
  ];

  const wtTaxList = [
    { value: 10, optionName: "Coy - Interest, Rents & Dividend 10%" },
    { value: 11, optionName: "Royalties 10%" },
    { value: 12, optionName: "Building and Construction 5%" },
    { value: 13, optionName: "Contracts And Agency Arrangement 5%" },
    { value: 14, optionName: "Consultancy and Professional Services 10" },
    { value: 15, optionName: "Management Services 10%" },
    { value: 16, optionName: "Technical Services 10%" },
    { value: 17, optionName: "Commission 10%" },
    { value: 18, optionName: "Directors’ Fees 10%" }
  ];

  return (
    <div className="approval-tabs">
      {modal && <VerifyFile closeModal={closeModal} docName={doc} data_id={dataId} id={id} />}
      {rejectModal && <RejectOnboarding closeModal={closeReject} reject={reject} />}
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <h6 className="page-title m-0">
            {vendorData && vendorData?.vendor_description}
            {/* {vendorData &&
              vendorData?.contact_user.othernames + " " + vendorData?.contact_user.lastname} */}
          </h6>
          <div className="d-flex justify-content-center align-items-center mb-3" style={{ columnGap: "2rem" }}>
            <button
              type="button"
              // disabled={checkFiles?.includes("-1")}
              style={{
                padding: "8px 30px",
                background: " #F85A5A",
                borderRadius: "10px",
                color: "white"
              }}
              onClick={() => setRejectModal(true)}
            >
              {loading ? <div className="spinner-border spinner-border-primary spinner-border-sm mr-2"></div> : "Reject"}
            </button>
            <button
              type="submit"
              disabled={checkFiles?.includes("-1") || vendor === ""}
              style={
                checkFiles?.includes("-1") || vendor === ""
                  ? {
                      padding: "8px 30px",
                      background: "#D8D8D8",
                      borderRadius: "10px",
                      color: "white"
                    }
                  : {
                      padding: "8px 30px",
                      background: "#00B0A5",
                      borderRadius: "10px",
                      color: "white"
                    }
              }
              onClick={approve}
            >
              {loading ? <div className="spinner-border spinner-border-primary spinner-border-sm mr-2"></div> : "Approve"}
            </button>
          </div>
        </div>
        <div className="tab-container">
          <Tabs menus={menus} onSelect={selectMenu} />

          <div className="tabs-page pb-5">
            {state === "Contact Information" && (
              <div className="route-transition">
                <ContactInformation vendorData={vendorData} />
              </div>
            )}

            {state === "Bank Informtion" && (
              <div className="route-transition">
                <BankInformation />
              </div>
            )}
            {newData.map((tab, i) => {
              return (
                state === tab.header && (
                  <div className="route-transition" key={i}>
                    <form>
                      <div className="row">
                        {tab.array.map((textBox, i) => {
                          return (
                            <div className="col-md-12 col-lg-6" key={i}>
                              <Textbox disabled={true} name="farmer_id" value={textBox.value} label={textBox.label} boxClasses="mt-3" />
                            </div>
                          );
                        })}
                      </div>
                    </form>
                  </div>
                )
              );
            })}

            {state === "Business Category" && (
              <div className="route-transition">
                <form>
                  <div className="row">
                    {vendorData?.categories?.map((biz, i) => {
                      return (
                        <div className="col-md-12 col-lg-6 d-flex mt-4" key={i}>
                          <Textbox value={biz.business_category_description} placeholder="Businees Field" boxClasses="w-100" disabled={true} />
                          <img
                            src={Remove}
                            alt=""
                            className="pl-3 cursor-pointer"
                            onClick={() => dispatch(doRemoveVendorBusinessCategory({ vendor_id: id, business_category_id: biz.business_category_id }, () => dispatch(getSingleVendor(id))))}
                          />
                        </div>
                      );
                    })}
                  </div>
                  <div className="d-flex col-md-12 col-lg-6 mt-5 cursor-pointer" onClick={() => setShowCategory(!showCategory)}>
                    <img src={Add} alt="" />
                    <div className="pl-3">{showCategory ? "Hide  Category" : "Add Category"}</div>
                  </div>
                  {showCategory && (
                    <div className="col-6 d-flex">
                      <SelectDropdown
                        boxClasses="mt-3"
                        name="business_category_id"
                        label="Business Category"
                        placeholder="Business Category"
                        options={businessCategoryOption}
                        customRef={register({
                          // required: { value: requiredForm, message: "This field is required" }
                        })}
                        onChange={(e) =>
                          dispatch(
                            doAddVendorBusinessCategory({ vendor_id: id, business_category_id: e.target.value }, () => {
                              dispatch(getSingleVendor(id));
                              setValue("business_category_id", "");
                              setShowCategory(false);
                            })
                          )
                        }
                        // error={errors.business_category_id && errors.business_category_id.message}
                      />
                      {/* <ArrowDropdown text={placeholder} label="Business Categories" error={businessFieldError && businessFieldError} options={businessList} boxClasses="mt-3" {...rest} search={true} /> */}
                      {/* <Button type="button" label="Confirm" btnType="btn-primary" xtraClass="w-100 px-2" boxClasses="pl-3 mt-auto"  />
                      <Button type="button" label="Cancel" btnType="btn-skeleton" xtraClass="w-100 px-2" boxClasses="pl-3 mt-auto"  /> */}
                    </div>
                  )}
                </form>
              </div>
            )}

            {state === "Other Information" && (
              <div className="route-transition">
                <form>
                  <div className="row">
                    <div className="col-md-12 col-lg-6 mt-4">
                      <p className="page-title" style={{ fontSize: "20px" }}>
                        Supplier Selection
                      </p>
                      <div className="d-flex radio-box mt-4" style={{ columnGap: "2rem" }}>
                        <div className="">
                          <input type="radio" name="certified" id="yes" className="mr-2" checked={vendor === "Contractor"} onChange={() => vendorTypeChange("Contractor")} />
                          <label className="radio-box_label" htmlFor="yes">
                            Contractor
                          </label>
                        </div>
                        <div className="">
                          <input type="radio" name="certified" id="no" className="mr-2" checked={vendor === "Supplier"} onChange={() => vendorTypeChange("Supplier")} />
                          <label className="radio-box_label" htmlFor="no">
                            Supplier
                          </label>
                        </div>
                      </div>
                      {vendorData?.country_description === "Nigeria" && (
                        <Fragment>
                          <p className="page-title mt-5" style={{ fontSize: "20px" }}>
                            Witholding Tax Type
                          </p>
                          <div className="d-flex radio-box mt-2">
                            <SelectDropdown
                              boxClasses="mb-1"
                              onChange={(e) => withholdingTaxType(e.target.value)}
                              name="witholding_tax_type"
                              label=""
                              // disabled={true}
                              placeholder=""
                              options={taxType}
                              customRef={register({})}
                              // error={errors.witholding_tax && "This field is required"}
                            />
                          </div>

                          <p className="page-title mt-5" style={{ fontSize: "20px" }}>
                            Witholding Tax %
                          </p>
                          <div className="d-flex radio-box mt-2">
                            <SelectDropdown
                              boxClasses="mb-4"
                              onChange={(e) => withholdingTax(e.target.value)}
                              name="witholding_tax"
                              label=""
                              // disabled={true}
                              placeholder=""
                              options={wtTaxList}
                              customRef={register({})}
                              // error={errors.witholding_tax && "This field is required"}
                            />
                          </div>
                        </Fragment>
                      )}
                      {vendorData?.vendor_classification === "Foreign" && (
                        <Fragment>
                          <p className="page-title mt-5" style={{ fontSize: "20px" }}>
                            Currency
                          </p>
                          <div className="d-flex radio-box mt-2">
                            <SelectDropdown
                              boxClasses="mb-4"
                              onChange={(e) => setVendorCurrency(e.target.value)}
                              name="currency"
                              label=""
                              // disabled={true}
                              placeholder=""
                              options={currencyOptions}
                              customRef={register({})}
                              // error={errors.witholding_tax && "This field is required"}
                            />
                          </div>
                        </Fragment>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>

        <div className="mt-5">
          <DataTable columns={documentsColumns} data={tableData} />
          {/* <Pagination page={0} lastPage={0} paginate={() => {}} total={0} handlePageChange={() => {}} perPage={0} /> */}
          <Pagination page={page} lastPage={singleVendor?.pagination?.pageCount} paginate={paginate} total={singleVendor?.pagination?.total} handlePageChange={handlePageChange} perPage={length} />
        </div>
      </div>
    </div>
  );
};

export default ApprovalTabs;

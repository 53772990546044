import { Link } from "react-router-dom";

import greenCheck from "assests/icons/buyer/green-check.svg";
import rectangle from "assests/icons/buyer/rectangle-buyer.svg";
import Button from "components/Button";

export const tableCol = [
  {
    name: "Created On",
    index: "created_at",
    id: 0
  },
  {
    name: "Purchase No.",
    index: "purchase_no",
    id: 1
  },
  // {
  //   name: "Delivery Location",
  //   index: "delivery_loca",
  //   id: 3
  // },
  {
    name: "Legal Entity",
    index: "company_description",
    id: 4
  },
  {
    name: "Supplier",
    index: "supplier",
    id: 5
  },
  {
    name: "Amount",
    index: "price",
    id: 6
  },
  {
    name: "Currency",
    index: "currency",
    id: 7
  },
  {
    name: "Status",
    // index: "status",
    id: 8,
    render: (item) => (
      <button
        type="button"
        style={{
          padding: "8px 16px",
          background: item.status !== "Approved" ? "rgba(245, 150, 37, 0.15)" : "rgba(0, 176, 7, 0.15)",
          borderRadius: "10px",
          color: item.status !== "Approved" ? "rgba(245, 150, 37, 1)" : "#387C57"
        }}
      >
        {item.status}
      </button>
    )
  },
  // {
  //   name: "Action",
  //   id: 8,
  //   render: (item: { status: string; id: string }) => (
  //     <Link to={`/buyer/purchase-orders/${item?.id}`}>
  //       <button
  //         type="button"
  //         style={{
  //           padding: "8px 16px",
  //           background: item.status === "Pending" ? "rgba(245, 150, 37, 0.15)" : "rgba(0, 70, 176, 0.15)",
  //           borderRadius: "10px",
  //           color: item.status === "Pending" ? "rgba(245, 150, 37, 1)" : "rgba(47, 128, 237, 1)"
  //         }}
  //       >
  //         {/* {item.status} */}
  //         View
  //       </button>
  //     </Link>
  //   )
  // }
];

export const tableData = [
  {
    date: "2nd Sept. 2019",
    vendor_name: "Brent Fawaz",
    vendor_number: "78600090",
    rc_number: "123333",
    specialization: "3000",
    status: "Verified",
    id: 0
  },
  {
    date: "24th May 2019",
    vendor_name: "Chandler Ross",
    vendor_number: "78600091",
    rc_number: "123333",
    specialization: "3I2388",
    status: "Verified",
    id: 1
  },
  {
    date: "5th Jun. 2019",
    vendor_name: "Monica Williams",
    vendor_number: "",
    rc_number: "",
    specialization: "256389",
    status: "Pending",
    id: 2
  },
  {
    date: "7th July 2020",
    vendor_name: "Asa Okoro",
    vendor_number: "8970",
    rc_number: "738932",
    specialization: "6356782",
    status: "Verified",
    id: 3
  },
  {
    date: "19th Aug. 2020",
    vendor_name: "Jamal Abdul",
    vendor_number: "",
    rc_number: "11233123",
    specialization: "24562",
    status: "Pending",
    id: 4
  },
  {
    date: "22nd Feb. 2020",
    vendor_name: "Sani Abubakar",
    vendor_number: "",
    rc_number: "43637.",
    specialization: "78992",
    status: "Pending",
    id: 5
  }
];

import React, { useEffect, useState, useRef, useCallback } from "react";
import "./styles.scss";
import StatsCard from "components/StatsCard";
import DataTable from "components/DataTable";
import { documentsColumns, documents } from "./data";
import Bill from "assests/icons/buyer/bill.svg";
import WarningSign from "assests/icons/buyer/warning.svg";
import Money from "assests/icons/buyer/money.svg";
import Cart from "assests/icons/buyer/cart.svg";
import SearchIcon from "components/SearchBar";
import { useDispatch, useSelector } from "react-redux";
// import { getHouseholdSummary, getAllHousehold } from "store/slice/HouseHoldsSlice/HouseholdSlice";
import { RootState } from "store/store";
import Pagination from "components/Pagination";
import Dropdown from "components/Dropdown";
import useSearch from "hooks/useSearch";
import useArrowDropdown from "hooks/useArrowDropdown";
// import { getCommunity } from "store/slice/CommunitySlice/communitySlice";
import StarRating from "components/Star";
import { getPOSummaries } from "store/slice/SummarySlice/SummarySlice";
import { getDocuments, getSingleDocument } from "store/slice/BuyerSlice/DocumentSlice/DocumentSlice";
import { formatCurrency } from "utils/utils";
import { useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import FilterCard from "components/FilterCard";
import filterIcon from "assests/icons/buyer/filter-icon.svg";

type Props = {};

const SingleSupplierTransaction: React.FunctionComponent<Props> = () => {
  const [page, setPage] = React.useState<number>(1);
  const [length, setlength] = React.useState<number>(10);
  const [perpage, setPerpage] = useState<number>(10);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [selectComboxOption, setSelectComboxOption] = React.useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  let { id }: any = useParams();

  //Redux
  const { documents } = useSelector((state: RootState) => state.document);
  const { POSummaries } = useSelector((state: RootState) => state.summaries) as any;

  //hook
  // const { open, selectedOption, handleDropdownChange, handleAction } = useArrowDropdown();
  const { open: open2, selectedOption: selectedOption2, handleDropdownChange: handleDropdownChange2, handleAction: handleAction2, ref: dropdownRef2 } = useArrowDropdown();

  //

  const { search, inputRef, handleChange, searched } = useSearch(getDocuments, length, undefined, [id]);
  const { register, handleSubmit, reset } = useForm({
    mode: "onChange",
    defaultValues: {
      date_to: "",
      date_from: "",
      purchase_order_code: ""
      // to: '',
    }
  });

  // const data = singleDoc?.data[0];
  console.log(POSummaries);

  const tableData =
    documents &&
    documents?.data?.map((data: any) => {
      const { purchase_order_status } = data;
      let status;
      if (purchase_order_status === "-2") {
        status = "Unpaid";
      } else if (purchase_order_status === "-1") {
        status = "Paid";
      } else {
        status = "Complete";
      }
      return {
        date: data?.purchase_order_date,
        // created_at: data?.created_at,
        ref: data?.purchase_order_code,
        description: data?.vendor_description,
        location: `${data?.street_number} ${data?.street_name}`,
        amount: `${formatCurrency(data?.total_value)} ${data?.currency_description}`,
        // currency: data?.currency_description,
        status,
        id: data?.id
      };
    });

  useEffect(() => {
    dispatch(getPOSummaries(id));
    dispatch(getDocuments(page, length, undefined, id));
  }, []);

  //--------------------------------------------
  //Pagination
  //---------------------------------------------
  const handlePageChange = React.useCallback(
    (page: number, length: number) => {
      setPage(page);
      dispatch(getDocuments(page, length, search, id));
    },
    [search]
  );

  const paginate = React.useCallback(
    (pageNo: number, length: number) => {
      setPage(pageNo);
      dispatch(getDocuments(pageNo, length, undefined, id));
    },
    [search]
  );

  //------------------------------------------------------------
  //Show Number for length Function
  //------------------------------------------------------------
  const handleNewPage = React.useCallback(
    (pageNo: number, page = 1) => {
      setlength(pageNo);
      setPage(1);
      dispatch(getDocuments(page, pageNo, search, id));
    },
    [search]
  );

  // if (documents?.data.length < 1) {
  //   // history.goBack();
  //   history.push('/buyer/suppliers')
  // }

  const onSubmit = (vals) => {
    // dispatch(getGoods(page, length, search, undefined, undefined, undefined, vals.date_from, vals.date_to, vals.purchase_order_code));
    // dispatch(getDocuments(page, length, search, id));
    dispatch(getDocuments(page, length, search, id, vals.date_from, undefined, undefined, vals.date_to, vals.purchase_order_code));
  };

  const handleClear = () => {
    // dispatch(getGoods(page, length, search));
    dispatch(getDocuments(page, length, search, id));
    reset();
  };

  return (
    <div id="buyer-suppliertransactions-page">
      <div className="container">
        <div className="d-flex align-items-center mb-3">
          <h3 className="header">{`${documents?.data[0]?.vendor_description} | ${documents?.data[0]?.vendor_id}`}</h3>
          <StarRating stars={3} disabled={true} />
        </div>
        <div className=" row mb-5">
          <StatsCard statsNumber={POSummaries?.ytd?.total_purchase_order_value} text="Value YTD" classes="stats-icon-orange" image={Money} iconBackgroundColor="rgba(45, 156, 219, 0.1)" />

          <StatsCard statsNumber={POSummaries?.ytd?.total_purchase_orders} text="Purchase Orders" classes="stats-icon-leaf" image={Cart} iconBackgroundColor="rgba(45, 156, 219, 0.1)" />
          <StatsCard statsNumber={0} text="Defect Reports" classes="stats-icon-leaf" image={WarningSign} iconBackgroundColor="rgba(255, 65, 65, 0.1)" />
          <StatsCard statsNumber={0} text="Unpaid Invoices" classes="stats-icon-leaf" image={Bill} iconBackgroundColor="rgba(255, 65, 65, 0.1)" />
        </div>

        <h4 className="page-title mb-2">Supplier Documents</h4>
        {showFilter && (
          <FilterCard handleSubmit={handleSubmit(onSubmit)} clear={handleClear}>
            <div className="row">
              <div className="col col-4">
                <label style={{ display: "block" }}>Start Date</label>
                <input
                  type="date"
                  name="date_from"
                  className="mt-3"
                  placeholder="Start Date"
                  style={{
                    width: "100%",
                    height: "3rem",
                    padding: "5px",
                    borderRadius: "10px",
                    border: "1px solid #dfe2e6"
                  }}
                  ref={register({ required: false })}
                />
              </div>
              <div className="col col-4">
                <label style={{ display: "block" }}>End Date</label>
                <input
                  type="date"
                  name="date_to"
                  className="mt-3"
                  placeholder="End Date"
                  style={{
                    width: "100%",
                    height: "3rem",
                    padding: "5px",
                    borderRadius: "10px",
                    border: "1px solid #dfe2e6"
                  }}
                  ref={register({ required: false })}
                />
              </div>
              <div className="col col-4">
                <label style={{ display: "block" }}>Purchase Order Code</label>
                <input
                  type="text"
                  name="purchase_order_code"
                  className="mt-3"
                  placeholder="Purchase Order Code"
                  style={{
                    width: "100%",
                    height: "3rem",
                    padding: "5px",
                    borderRadius: "10px",
                    border: "1px solid #dfe2e6"
                  }}
                  ref={register({ required: false })}
                />
              </div>
            </div>
          </FilterCard>
        )}
        <div className="search-field">
          <SearchIcon placeholder="Search for Vendor ID or Name." boxClasses=" mb-4" textRef={inputRef} value={search} onChange={handleChange} />
          <Dropdown perPage={perpage} action={handleNewPage} />
          <div>
            <button
              style={{
                padding: "5px 10px",
                borderRadius: "10px",
                background: "#FFFFFF",
                color: "#737A91",
                border: "1px solid #DFE2E6",
                fontSize: "18px",
                marginLeft: "1rem"
              }}
              onClick={() => setShowFilter(!showFilter)}
            >
              <img src={filterIcon} alt="icon" className="icon-image" />
              <span
                style={{
                  paddingLeft: "1rem"
                }}
              >
                {!showFilter ? "Filters" : "Hide Filters"}
              </span>
            </button>
          </div>
        </div>
      </div>

      <DataTable columns={documentsColumns} data={tableData} />
      <Pagination page={page} lastPage={documents?.pagination?.pageCount} paginate={paginate} total={documents?.pagination?.total} handlePageChange={handlePageChange} perPage={length} />
    </div>
    // </div>
  );
};

export default SingleSupplierTransaction;

import StatsCard from "components/StatsCard";
import docImg from "assests/icons/buyer/Purchase Orders/Group (3).svg";
import bill from "assests/icons/buyer/Purchase Orders/bill (1) 2.svg";
import money from "assests/icons/buyer/Purchase Orders/money 1.svg";
import redcircle from "assests/icons/buyer/Purchase Orders/Group (4).svg";
import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { format } from "date-fns";
import "./styles.scss";
import { getSingleDocument } from "store/slice/BuyerSlice/DocumentSlice/DocumentSlice";
import DoughnutChart from "components/Charts/DonutChart";
import { formatCurrency, kFormatter } from "utils/utils";
import { getPOSummaries } from "store/slice/SummarySlice/SummarySlice";

const SingleDocument = () => {
  const [pageNum, setPageNumber] = useState(10);

  //   helpers
  const dispatch = useDispatch();
  let { id }: any = useParams();
  const history = useHistory();

  //   States
  const { singleDoc } = useSelector((state: RootState) => state.document);
  const { POSummaries } = useSelector((state: RootState) => state.summaries) as any;
  const data = singleDoc?.data[0];

  useEffect(() => {
    if (id) {
      dispatch(getSingleDocument(id));
      dispatch(getPOSummaries(undefined, undefined, undefined, id));
    } else history.push("/buyer/purchase-orders");
  }, []);

  const color = ["#A6CEE3", "#1F78B4"];
  let sumQty = data?.materials.reduce((accumulator, current) => {
    return accumulator + Number(current.quantity);
  }, 0);

  let sumQtyDel = data?.materials?.reduce((accumulator, current) => {
    return accumulator + Number(current.quantity_delivered);
  }, 0);
  let notDelSum = sumQty - sumQtyDel;

  const chartData = [
    { value: notDelSum / sumQty, name: `Yet to Delivered ${((notDelSum / sumQty) * 100).toFixed(2)}%` },
    { value: (sumQty - notDelSum) / sumQty, name: `Deliver ${((100 * (sumQty - notDelSum)) / sumQty).toFixed(2)}%` }
  ];

  let status;
  if (data?.purchase_order_status === "-2") {
    status = "In Draft";
  }
  if (data?.purchase_order_status === "-1") {
    status = "Pending";
  }
  if (data?.purchase_order_status === "1") {
    status = "Approved";
  }
  if (data?.purchase_order_status === "2") {
    status = "Accepted";
  }
  if (data?.purchase_order_status === "3") {
    status = "Out for Delivery";
  }
  if (data?.purchase_order_status === "4") {
    status = "Partially Delivered";
  }
  if (data?.purchase_order_status === "5") {
    status = "Delivered";
  }

  const totalLineItemtax =
    data?.materials?.length > 0 &&
    data?.materials?.reduce((acc, current) => {
      return acc + (Number(current.tax_percent) * Number(current.total_amount)) / 100;
    }, 0);

  const totalAmt = Number(POSummaries?.overall?.total_purchase_order_value) + totalLineItemtax;
  // console.log(totalLineItemtax);
  return (
    <div id="document-single">
      <div className="container">
        {/* <h3 className="header">Transaction Stats</h3>
        <div className="stats-card mt-4 mb-5 row">
          <StatsCard text="Value (Amount)" classes="stats-icon-blue" image={money} statsNumber={10} />
          <StatsCard text="Vendor Evaluated" classes="stats-icon-blue" image={docImg} statsNumber={450000} />
          <StatsCard text="Invoice Submitted" classes="stats-icon-blue" image={bill} statsNumber={7287} />
          <StatsCard text="Defects Reported" classes="stats-icon-red" image={redcircle} statsNumber={712} />
        </div> */}
        <div className="d-flex">
          <div className="d-flex">
            <h5 className="page-title">Purchase Order: {data?.purchase_order_code}</h5>
            {/* <span className="button-dupli">Delivered</span> */}
          </div>
          {/* <div className="d-flex justify-content-center align-items-center mb-3" style={{ columnGap: "2rem" }}>
            <button
              type="button"
              disabled={checkFiles?.includes("-1")}
              style={{
                padding: "8px 30px",
                background: " #F85A5A",
                borderRadius: "10px",
                color: "white"
              }}
              onClick={() => setRejectModal(true)}
            >
              {loading ? <div className="spinner-border spinner-border-primary spinner-border-sm mr-2"></div> : "Reject"}
              Save as Draft
            </button>
            <button
              type="submit"
              disabled={checkFiles?.includes("-1") || vendor === ""}
              style={
                checkFiles?.includes("-1")
                  ? {
                      padding: "8px 30px",
                      background: "#D8D8D8",
                      borderRadius: "10px",
                      color: "white"
                    }
                  : {
                      padding: "8px 30px",
                      background: "#00B0A5",
                      borderRadius: "10px",
                      color: "white"
                    }
              }
              onClick={approve}
            >
              {loading ? <div className="spinner-border spinner-border-primary spinner-border-sm mr-2"></div> : "Approve"}
              Publish
            </button>
          </div> */}
        </div>
        <hr className="pb-8" />
        <div className="row mb-5">
          <div className="col-md-4 col-lg-4 col-xs-12">
            <div className="mb-3">
              <p className="page-title">Created On:</p>
              <p>{data?.purchase_order_date}</p>
              {/* <p>{format(new Date(data?.purchase_order_date), "dd.MM.yyyy")}</p> */}
            </div>
            <div className="mb-3">
              <p className="page-title">Legal Entity:</p>
              <p>{data?.department[0]?.company_description}</p>
            </div>
            <div className="mb-3">
              <p className="page-title">Purchase Group:</p>
              <p>Supply Chain</p>
            </div>
          </div>
          <div className="col-md-4 col-lg-4 col-xs-12">
            <div className="mb-3">
              <p className="page-title">Expected Delivery Date:</p>
              <p>{data?.delivery_date}</p>
              {/* <p>{format(new Date(data?.delivery_date), "dd.MM.yyyy")}</p> */}
            </div>
            <div className="mb-3">
              <p className="page-title d-block">Supplier Details</p>
              {/* <p>{data?.requisitions?.location_description}</p> */}
              <p>{data?.vendor_description}</p>
              <p>{data?.street_name}</p>
            </div>
            <div className="mb-3">
              <p className="page-title mb-2">Status</p>
              {/* <p>{data?.requisitions?.location_description}</p> */}
              <span
                style={{
                  padding: "8px 16px",
                  background: status === "Pending" ? "rgba(245, 150, 37, 0.15)" : "rgba(0, 176, 7, 0.15)",
                  borderRadius: "10px",
                  color: status === "Pending" ? "rgba(245, 150, 37, 1)" : "#387C57"
                }}
              >
                {status}
              </span>
            </div>
          </div>
          <div className="col-md-4 col-lg-4 col-xs-12">
            <DoughnutChart data={chartData} color={color} name="" />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md-10">
            <table>
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Location</th>
                  <th>Mat. No</th>
                  <th>Product Description</th>
                  <th>Quantity</th>
                  <th>UoM</th>
                  <th>Net Amount</th>
                </tr>
              </thead>
              <tbody>
                {data?.materials?.map((mat, i) => (
                  <tr key={i} className="border-container mb-3">
                    <td className="first-td">{i + 1}</td>
                    <td>{mat?.location_description}</td>
                    <td>{mat?.material_code}</td>
                    <td>{mat?.material_description}</td>
                    <td>{mat?.quantity}</td>
                    <td>{mat?.uom_description}</td>
                    <td className="last-td">
                      {formatCurrency(mat?.total_amount)} {mat?.currency_description}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="d-flex" style={{ width: "fit-content", float: "right" }}>
              <div className="d-block">
                <p className="page-title">Tax: </p>
                <p className="page-title">Total Amount:</p>
              </div>
              <div className="d-block">
                <p className="page-title">&nbsp; {formatCurrency(totalLineItemtax)} </p>
                <p className="page-title">&nbsp; {formatCurrency(totalAmt)}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="mb-4">
          <p>Transaction History</p>
          <hr />
        </div>

        {data?.requisitions &&
          data?.requisitions?.map((req, i) => {
            return (
              <div className="row mb-3">
                <div className="col-md-10 col-xs-12">
                  <div className="row border-container justify-content-between">
                    <span className="d-flex">
                      <div className="px-3" style={{ width: "fit-content" }}>
                        <p className="">Purchase Requistion</p>
                      </div>
                      <div className="px-3" style={{ width: "fit-content" }}>
                        <p className="">{req.requisition_code ? req?.requisition_code + " - " + req.pr_item_id : "N?A"}</p>
                      </div>
                    </span>

                    <span className="d-flex">
                      <div className="px-3" style={{ width: "fit-content" }}>
                        <p className="">Created By : {`${req?.created_by_othernames} ${req?.created_by_lastname}`}</p>
                      </div>
                      <div className="px-3" style={{ width: "fit-content" }}>
                        <p className="">Creation Date : {req?.created_at ? format(new Date(req?.created_at), "dd.MM.yyyy") : ""}</p>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            );
          })}

        {data?.rfp.length > 0 && (
          <div className="row mb-3">
            <div className="col-md-10 col-xs-12">
              <div className="row border-container justify-content-between">
                <span className="d-flex">
                  <div className="px-3" style={{ width: "fit-content" }}>
                    <p className="">RFP</p>
                  </div>
                  <div className="px-3" style={{ width: "fit-content" }}>
                    <p className="">{data?.rfp[0] ? data?.rfp[0]?.rfp_code : "N?A"}</p>
                  </div>
                </span>

                <span className="d-flex">
                  <div className="px-3" style={{ width: "fit-content" }}>
                    <p className="">Created By : {`${data?.rfp[0]?.created_by_othernames} ${data?.rfp[0]?.created_by_lastname}`}</p>
                  </div>
                  <div className="px-3" style={{ width: "fit-content" }}>
                    <p className="">Creation Date : {data?.rfp[0]?.created_at ? format(new Date(data?.rfp[0]?.created_at), "dd.MM.yyyy") : ""}</p>
                  </div>
                </span>
              </div>
            </div>
          </div>
        )}

        {/* {data?.requisition && (
          <div className="row mb-3">
            <div className="col-md-10 col-xs-12">
              <div className="row border-container justify-content-between">
                <span className="d-flex">
                  <div className="px-3" style={{ width: "fit-content" }}>
                    <p className="">Purchase Approval</p>
                  </div>
                  <div className="px-3" style={{ width: "fit-content" }}>
                    <p className="">{data?.requisitions?.requisition_code ? data?.requisitions?.requisition_code : "N?A"}</p>
                  </div>
                </span>

                <span className="d-flex">
                  <div className="px-3" style={{ width: "fit-content" }}>
                    <p className="">Created By : {`${data?.requisitions?.created_by_othernames} ${data?.requisitions?.created_by_lastname}`}</p>
                  </div>
                  <div className="px-3" style={{ width: "fit-content" }}>
                    <p className="">Creation Date : {data?.requisitions?.created_at ? format(new Date(data?.requisitions?.created_at), "dd.MM.yyyy") : ""}</p>
                  </div>
                </span>
              </div>
            </div>
          </div>
        )} */}

        {data?.goods_received &&
          data?.goods_received?.map((req, i) => {
            return (
              <div className="row mb-3">
                <div className="col-md-10 col-xs-12">
                  <div className="row border-container justify-content-between" style={{ border: "1px solid #dfe2e6", borderRadius: "8px", padding: " 5px" }}>
                    <span className="d-flex">
                      <div className="px-3" style={{ width: "fit-content" }}>
                        <p className="">Goods Received</p>
                      </div>
                      <div className="px-3" style={{ width: "fit-content" }}>
                        <p className="">{req.gr_code && req?.gr_code}</p>
                      </div>
                    </span>

                    <span className="d-flex">
                      <div className="px-3" style={{ width: "fit-content" }}>
                        <p className="">Quantity Received : {`${req?.gr_quantity}`}</p>
                      </div>
                      <div className="px-3" style={{ width: "fit-content" }}>
                        <p className="">Creation Date : {req?.gr_date ? format(new Date(req?.gr_date), "dd.MM.yyyy") : ""}</p>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            );
          })}

        {data?.invoices &&
          data?.invoices?.map((req, i) => {
            return (
              <div className="row mb-3">
                <div className="col-md-10 col-xs-12">
                  <div className="row border-container justify-content-between" style={{ border: "1px solid #dfe2e6", borderRadius: "8px", padding: " 5px" }}>
                    <span className="d-flex">
                      <div className="px-3" style={{ width: "fit-content" }}>
                        <p className="">Invoice</p>
                      </div>
                      <div className="px-3" style={{ width: "fit-content" }}>
                        <p className="">{req.invoice_code && req.id + " - " + req?.invoice_code}</p>
                      </div>
                    </span>

                    <span className="d-flex">
                      <div className="px-3" style={{ width: "fit-content" }}>
                        <p className="">Created By : {`${req?.vendor_description}`}</p>
                      </div>
                      <div className="px-3" style={{ width: "fit-content" }}>
                        <p className="">Creation Date : {req?.created_at ? format(new Date(req?.created_at), "dd.MM.yyyy") : ""}</p>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default SingleDocument;

import React from "react";
import Modal from "components/Modal";
import Button from "components/Button";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import AsyncSelect from "react-select/async";
import API from "utils/httpsRequest";
import { addDays, format } from "date-fns";
import { createRFISourcings, createRFQSourcings } from "store/slice/SourcingSlice/SourcingSlice";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

const Form = styled.form`
  .mySelect__control {
    border-radius: 10px;
    border: 1px solid rgb(223, 226, 230) !important;
  }

  .mySelect__value-container {
    height: 3rem;
  }
`;


type Props = {
  closeModal: () => void;
  item: object;
};

type FormData = {
  team_lead_user_id: string;
  sourcing_team_id: string;
  rfp_title: string;
  days: string;
};

const days = Array.from(Array(30).keys())

const CreateSourcingProject = ({ closeModal, item }: Props) => {
  const [userVal, setUserVal] = React.useState("");
  const [selUserVal, setSelUserVal] = React.useState("");
  const [listVal, setListVal] = React.useState('');
  const [selListVal, setSelListValue] = React.useState('');
  const [sourceType, setSourceType] = React.useState('')
  const [techWeight, setTechWeight] = React.useState('70');
  // const [commWeight, setCommWeight] = React.useState('30');

  const { user } = useSelector((state: RootState) => state.user)
  const { isLoading } = useSelector((state: RootState) => state.sourcing)


  const dispatch = useDispatch();
  const history = useHistory();

  const { token } = user;

  const {
    register,
    handleSubmit,
    errors,
    control,
    formState: { isValid }
  } = useForm<FormData>({
    mode: "onChange"
  });

  const submitForm = (form) => {
    // console.log(item)
    const data = {
      ...form,
      team_lead_user_id: selUserVal?.['value'],
      sourcing_team_id: selListVal?.['value'],
      sourcing_type: sourceType,
      opening_date: format(new Date(), 'yyyy-MM-dd'),
      closing_date: format(addDays(new Date(), form.days), 'yyyy-MM-dd'),
      requisition_id: item?.['id'],
    }
    if (data.sourcing_type === 'rfq') {
      dispatch(createRFQSourcings(data, (res) => handleClose(res)));
    }
    if (data.sourcing_type === 'rfp') {
      const rfiData = {
        ...data,
        rfp_code: Math.floor(100000 + Math.random() * 900000),
        technical_weight: techWeight,
        commercial_weight: 100 - Number(techWeight),
      }
      dispatch(createRFISourcings(rfiData, (res) => handleClose(res)));
    }
  };

  const handleClose = (res) => {
    closeModal()
    history.push({ pathname: `/buyer/sourcing/projects/${res?.data?.rfp_id}` })
  };

  const handleInputChangeUsers = value => {
    setUserVal(value)
  }

  const onChangeUsers = value => {
    setSelUserVal(value);
  }

  const handleInputChangeLists = val => {
    setListVal(val);
  }

  const onChangeList = val => {
    setSelListValue(val)
  }

  const loadUsers = () => {
    return API.get(`/users/list?page=1&length=10&search=${userVal}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      return res.data.data.map((item) => (
        {
          label: item.othernames + ' ' + item.lastname,
          value: item.id
        }
      ))
    })
  }

  const loadLists = () => {
    return API.get(`/sourcing_teams/list?page=1&length=10&search=${listVal}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      return res.data.data.map((item) => ({
        label: item.sourcing_team_description,
        value: item.id
      }))
    })
  }

  return (
    <Modal modalText={"Create Sourcing Project"} onClose={closeModal} modallength={!false ? "modal-length" : ""}>
      <div id="requisition-modal">
        <p className="" style={{ paddingBottom: "1.5rem" }}>
          Create a Sourcing Project for suppliers to send in quotes.
        </p>
        <Form className="mt-2" onSubmit={handleSubmit(submitForm)}>
          <Controller
            control={control}
            defaultValue
            name="team_lead_user_id"
            render={({ ref }) => (
              <AsyncSelect
                cacheOptions
                defaultOptions
                placeholder="Select Team Lead"
                value={selUserVal}
                loadOptions={loadUsers}
                onInputChange={handleInputChangeUsers}
                onChange={onChangeUsers}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                classNamePrefix="mySelect"
              />
            )} />

          <br />

          <Controller
            control={control}
            defaultValue
            name="sourcing_team_id"
            render={({ ref }) => (
              <AsyncSelect
                cacheOption
                defaultOptions
                placeholder="Select Tender Team"
                value={selListVal}
                loadOptions={loadLists}
                onInputChange={handleInputChangeLists}
                onChange={onChangeList}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                classNamePrefix="mySelect"
              />
            )} />

          <textarea rows={4} className="text-area mt-4" placeholder="Describe Sourcing Project" name="rfp_title" ref={register({ required: true })} />
          {errors.rfp_title && <span style={{ color: 'red', fontSize: '14px' }}>This field is required</span>}

          {/* <SelectDropdown
            boxClasses="mt-3"
            name="days"
            label="Tender Validity"
            placeholder="Tender Validity"
            options={days.map((item) => ({ optionName: item + 1, value: item + 1 }))}
            customRef={register({ required: true })}
            error={errors.days && "This field is required"}
          /> */}

          <input
            type="number"
            placeholder="Tender Validity In Days"
            className="mt-3"
            style={{
              display: 'inline-block',
              width: '100%',
              border: '1px solid #e8e8e8',
              padding: '15px',
              borderRadius: '0.5rem',
            }}
            name="days"
            ref={register({
              required: true,
              validate: {
                range: (val) => parseInt(val, 10) < 121,
              }
            })}
          />
          {errors.days?.type === "range" && <span style={{ color: 'red', fontSize: 12, display: 'inline-block' }}>The number must be less than 120</span>}
          {errors.days && <span style={{ color: 'red', fontSize: 12, display: 'inline-block' }}>This field is required</span>}

          {/* <Textbox
            type="number"
            label="Tender Validity"
            placeholder="Tender Validty In Days"
            boxClasses="mt-3"
            name="days"
            customRef={register({
              required: true,
              validate: {
                range: (value) => parseInt(value, 10) < 121,
              }
            })}
            error={errors.days && "This field is required"}
          />
          {errors.days?.type === "range" && <span style={{ color: 'red', fontSize: 12 }}>The number must be less than 120</span>} */}

          <div className="d-flex radio-box mt-4 justify-content-between" style={{ width: '60%', margin: '0 auto' }}>
            <div className="">
              <input type="radio" value="rfq" name="sourcing_type" id="rfq" className="mr-2" onChange={(e) => setSourceType(e.target.value)} />
              <label className="radio-box_label" htmlFor="rfq" style={{ cursor: 'pointer' }}>
                RFQ
              </label>
            </div>
            <div className="">
              <input type="radio" value="rfp" name="sourcing_type" id="rfp" className="mr-2" onChange={(e) => setSourceType(e.target.value)} />
              <label className="radio-box_label" htmlFor="rfp" style={{ cursor: 'pointer' }}>
                RFP
              </label>
            </div>
          </div>

          {sourceType === 'rfp' && (
            <div>
              <label>
                <span style={{ fontSize: '16px' }}>Technical Weight({techWeight})</span>
                &nbsp;
                <input
                  id="technicalWeight"
                  type="range"
                  min="1"
                  max="100"
                  value={techWeight}
                  style={{ width: '100%' }}
                  onChange={(e) => setTechWeight(e.target.value)}
                  step="1" />
                &nbsp;
                <span style={{ fontSize: '16px', float: 'right' }}>Commercial Weight({100 - Number(techWeight)})</span>
              </label>
            </div>
          )}

          <Button disabled={isLoading} label={isLoading ? 'Please wait' : 'Create Details'} btnType="btn-primary" boxClasses="mt-4 mb-3" btnStyle="100%" />
          <p onClick={closeModal} style={{ textAlign: "center", cursor: "pointer" }}>
            Cancel
          </p>
        </Form>
      </div>
    </Modal>
  );
};

export default CreateSourcingProject;

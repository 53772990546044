import React, { useEffect } from "react";
import Modal from "components/Modal";
import ArrowDropdown from "components/ArrowDropdown";
import Textbox from "components/Textbox";
import Button from "components/Button";
import useArrowDropdown from "hooks/useArrowDropdown";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getCompaniesEntityList } from "store/slice/CompaniesSlice/CompaniesSlice";
import { AppDispatch, RootState } from "store/store";
import SelectDropdown from "components/SelectDropdown";
import { Controller } from "react-hook-form";
import API from "utils/httpsRequest";
import AsyncSelect from "react-select/async";
import { createDepartment, editDepartment, getDepartments } from "store/slice/BuyerSlice/DepartmentSlice/DepartmentSlice";
import { addMember, createSource, editSource, getSource } from "store/slice/BuyerSlice/TeamSlice/TeamSlice";
import { getUsers } from "store/slice/UserMgtSlice/UserMgtSlice";
import { useState } from "react";
import { uploadFile } from "store/slice/BuyerSlice/ProductSlice/ProductSlice";
import FileUploaderBox from "components/FileUploaderBox";
import { isNull } from "util";
import { watch } from "node:fs";

type Props = {
  closeModal: () => void;
  singleTeam?: any;
  addMemberToTeam?: any;
  memberList?: any;
};
const CreateOrEditTeam = ({ closeModal, singleTeam = null, addMemberToTeam = null, memberList }: Props) => {
  //State
  const [error, setError] = React.useState("");
  const [files, setFiles] = useState<any>([]);
  const [uploadBool, setUploadBool] = useState(false);
  const [userSel, setUserSel] = React.useState("");
  const [selUserVal, setSelUserVal] = React.useState("");
  const [userList, setUserList] = useState<any>([]);
  const [filtered, setFiltered] = useState<any>([]);
  const { user } = useSelector((state: RootState) => state.user);
  const [placeholder, setPlaceholder] = useState("Select Users");
  const { token } = user;
  const { loading, isLoading } = useSelector((state: RootState) => state.team);
  const [userFieldError, setUserFieldError] = useState("");
  const { users } = useSelector((state: RootState) => state.userMgt);
   //Hooks
 const { register, handleSubmit, errors, control, setValue,watch } = useForm<FormData>({ mode: "onBlur" });
 const { open, selectedOption, handleDropdownChange, handleAction, keyOption, ref } = useArrowDropdown();

  const values = watch();

  // hooks
  const { ...rest } = useArrowDropdown(userList, setUserList, filtered);

console.log((users))

  useEffect(() => {

    let options: any[] = users && users.data?.reduce((accumulator, current) => {
      if (values.team_lead_user_id !== current.id) {
        const payload = {
          id: current.id,
          label: `${current.lastname} ${current.othernames}`,
          name: `${current.lastname} ${current.othernames}`,
          isChecked: false
        };
        accumulator.push(payload);
      }

      return accumulator;
    }, []);
    
    setFiltered(options);
    setUserList(options);
  }, [users, values.team_lead_user_id]);


   //checking multi-select field
   const checkUsersList = filtered?.reduce((acc, current) => {
    if (current?.isChecked) {
      acc.push(current?.id);
    }
    return acc;
  }, []);

   //Clear Error Message
   useEffect(() => {
    if (checkUsersList?.length >= 1) {
      setUserFieldError("");
      setPlaceholder(`${checkUsersList?.length} user selected`);
    } else setPlaceholder("Select users");
  }, [userList, checkUsersList]);


  


  const checkUpload = () => {
    setUploadBool(!uploadBool);
  };
console.log(addMemberToTeam)
  const handleInputChangeUser = (value) => {
    setUserSel(value);
  };

  const onChangeUser = (value) => {
    setSelUserVal(value);
  };

  type FormData = {
    sourcing_team_description: string;
    team_lead_user_id: string;
  };

  //Setting field on edit mode
  useEffect(() => {
    if (singleTeam !== null) {
   
      setValue("sourcing_team_description", singleTeam?.sourcing_team_description);
    }
  }, [singleTeam]);

  //Redux Store
  const dispatch = useDispatch<AppDispatch>();

 
  const newUsers =
    users &&
    users.data.filter((item) => {
      return item.user_type === "Company";
    });

 
  const onFormSubmit = (vals: FormData) => {
    console.log(checkUsersList)
    if (singleTeam) {
      const payload = { ...vals, id: singleTeam.id, team_lead_user_id: selUserVal?.["value"] };
      dispatch(
        editSource(payload, () => {
          closeModal();
          dispatch(getSource(1, 10));
        })
      );
      return;
    } else if (addMemberToTeam!==null) {
     
      // const payload = { user_id: selUserVal?.["value"], sourcing_team_id: addMemberToTeam };
      const payload = { user_id: checkUsersList, sourcing_team_id: addMemberToTeam };
      dispatch(
        addMember(payload, () => {
          closeModal();
          dispatch(getSource(1, 10));
        })
      );
      return;
    }
    const payload = { ...vals, team_lead_user_id: selUserVal?.["value"] };
    dispatch(
      createSource(payload, () => {
        closeModal();
        dispatch(getSource(1, 10));
      })
    );
  };

  useEffect(() => {
    dispatch(getUsers(1, 1000));
  }, []);

  // handle file

  const handleFile = (e, field_name: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(e);
    reader.onloadend = () => {
      // use a regex to remove data url part
      let read: any = reader.result;
      const payload = {
        base64_data: read.replace("data:", "").replace(/^.+,/, ""),
        file_extension: e.name.split(".").pop()
      };
      dispatch(uploadFile(payload));

      setFiles(payload);
    };
  };

  const usersOptions =
    newUsers &&
    newUsers.map((_data: { id: string; lastname: string; othernames: string }) => {
      return {
        value: _data?.id,
        optionName: `${_data?.lastname} ${_data?.othernames}`
      };
    });

  const userOptions2 = () => {
    return API.get(`/users/list?search=${userSel}&length=1000`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    }).then((res) => {
      return res.data.data.map((item) => ({
        label: `${item.lastname} ${item.othernames}`,
        value: item.id
      }));
    });
  };

  return (
    <Modal modalText={singleTeam === null ? "Create" : "Edit"} onClose={closeModal} modallength="modal-length" isUpload={true} checkUpload={checkUpload} uploadBool={uploadBool}>
      {uploadBool ? (
        <div id="buyer-create-location">
          <p className="">Upload sourcing team that will be used to evaluate</p>
      
          <form className="mt-2">
            <p className="template">xxxx Teemplate sourcing team</p>
            <FileUploaderBox
              fileName=""
              boxClasses="mt-3 mb-5"
              handleFile={handleFile}
              acceptFiles={".jpg, .jpeg, .png"}
              customRef={register({ required: true })}
              error={errors["logo"] && "This field is required"}
            />

            <Button label={singleTeam === null ? "Create Sourcing Team" : "Edit Sourcing Team"} btnType="btn-primary" boxClasses="mt-5 mb-5" btnStyle="100%" loading={isLoading} />
          </form>
        </div>
      ) : (
        <div id="buyer-create-location">
         { addMemberToTeam && <p className="black mb-3">{"Team Members"}</p>}
         {
           memberList ? 
           <ul className="colored-ul">
            {memberList &&
              memberList.map((item) => {
                return(
                <li className="mb-2" key={item.user_id}>
                  {item.lastname} {item.othernames}{" "}
                </li>);
              })}
          </ul> : null
         }
           
          {singleTeam && <p className="">{"Edit Sourcing Team"}</p>}
          {!singleTeam && !addMemberToTeam && <p className="">{"Create Sourcing Team"}</p>}
          {addMemberToTeam && <p className="">{"Add Team Member"}</p>}

         

          <form className="mt-2" onSubmit={handleSubmit(onFormSubmit)}>
            {!addMemberToTeam && (
              <Textbox
                type="text"
                name="sourcing_team_description"
                label="Team Description"
                boxClasses="mb-4"
                placeholder="Team Description"
                error={errors.sourcing_team_description && "This field is required"}
                customRef={register({
                  required: true
                })}
              />
            )}

            {/*  
           <SelectDropdown
             boxClasses="mt-3 mb-4"
             name="team_lead_user_id"
             label="User"
             placeholder="Add User"
             options={usersOptions}
             customRef={register({ required: true })}
             error={errors.team_lead_user_id && "This field is required"}
           /> */}


              {
                addMemberToTeam && (
                  <ArrowDropdown
                  text={placeholder}
                  label="User"
                  // error={businessFieldError && "This field is required"}
                  options={userList}
                  boxClasses="mt-3"
                  {...rest}
                  search={true}
                /> 
                )
              }
                   

           {!addMemberToTeam && <Controller
              control={control}
              defaultValue
              boxClasses="mt-3"
              name="user_id"
              render={({ ref }) => (
                <AsyncSelect cacheOptions defaultOptions placeholder="Add User" value={selUserVal} loadOptions={userOptions2} onInputChange={handleInputChangeUser} onChange={onChangeUser} />
              )}
            />}
            
            {singleTeam && <Button label={"Edit Sourcing Team"} btnType="btn-primary" boxClasses="mt-5 mb-5" btnStyle="100%" loading={isLoading} />}
            {!singleTeam && !addMemberToTeam && <Button label={"Create Sourcing Team"} btnType="btn-primary" boxClasses="mt-5 mb-5" btnStyle="100%" loading={isLoading} />}
            {addMemberToTeam && <Button label={"Add Team Member"} btnType="btn-primary" boxClasses="mt-5 mb-5" btnStyle="100%" loading={isLoading} />}
          </form>
        </div>
      )}
    </Modal>
  );
};

export default CreateOrEditTeam;

import StatsCard from "components/StatsCard";
import React, { useState } from "react";
import "../styles.scss";
import Analytics from "assests/icons/buyer/analytics.svg";
import AddIcon from "assests/icons/buyer/add-icon.svg";
import SearchIcon from "components/SearchBar";
import Dropdown from "components/Dropdown";
import DataTable from "components/DataTable";
import { businessDataColumn } from "../data";
import { checkPermission } from "utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { useEffect } from "react";
import { deleteBusinessCat, getBusiness, getBusinessCat } from "store/slice/BuyerSlice/BusinessSlice/BusinessSlice";

import { toastPopUp } from "utils/utils";
import useSearch from "hooks/useSearch";
import Pagination from "components/Pagination";
import CreateOrEditBusinessCategory from "components/ModalBuyer/CreateOrEditBusinessCategory";

const BusinessCategory = () => {
  //State
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [modal, setModal] = useState<boolean>(false);
  const [singleBusinessCategory, setSingleBusinessCategory] = useState<any>(null);
  const { role_access } = useSelector((state: RootState) => state.user?.user);

  //Redux
  const dispatch = useDispatch<AppDispatch>();
  const { businessCat, loadingCat } = useSelector((state: RootState) => state.business);

  //Hook
  const { search, inputRef, handleChange, searched } = useSearch(getBusinessCat, perPage);

  useEffect(() => {
    dispatch(getBusinessCat(page, perPage));
  }, []);

  // setpage to the first page when a searched executed
  useEffect(() => {
    if (searched) setPage(1);
  }, [searched]);

  // new page
  const handleNewPage = (pageNo: number, page: number = 1) => {
    setPerPage(pageNo);
    setPage(1);
    dispatch(getBusinessCat(page, pageNo, search));
  };

  //Modal Handler
  const closeModal = () => {
    setModal((prev) => !prev);
    setSingleBusinessCategory(null);
  };

  //Pagination
  //---------------------------------------------
  const handlePageChange = React.useCallback(
    (page: number, perPage: number) => {
      setPage(page);
      dispatch(getBusinessCat(page, perPage, search));
    },
    [search]
  );

  const paginate = React.useCallback(
    (pageNo: number, perPage: number) => {
      setPage(pageNo);
      dispatch(getBusinessCat(pageNo, perPage, search));
    },
    [search]
  );

  //Function Declaration
  const editBusinessCategory = (_data: any) => {
    setSingleBusinessCategory(_data);
    setModal((prev) => !prev);
  };

  const deleteProduct = (id: string, name: string) => {
    const payload = { business_category_id: id };

    toastPopUp(
      () => {
        dispatch(
          deleteBusinessCat(payload, () => {
            dispatch(getBusinessCat(page, perPage));
          })
        );
      },
      "Delete",
      "Cancel",
      "Delete the entity",
      name,
      "red"
    );
  };

  const businessList =
    businessCat &&
    businessCat?.data?.map((item) => {
      return {
        id: item.id,
        business_category: item.business_category_description,
        deleteProduct: () => checkPermission(role_access, "SETUP_INDEX") && deleteProduct(item.id, item.business_category_description)
      };
    });

  return (
    <div id="settings-material-data">
      {modal && <CreateOrEditBusinessCategory closeModal={closeModal} singleBusinessCategory={singleBusinessCategory} />}
      <div className="container mt-3">
        <div className="row mt-3 mb-5">
          <StatsCard statsNumber={businessCat?.count ?? 0} text="Data" classes="stats-icon" image={Analytics} iconBackgroundColor="rgba(45, 156, 219, 0.1)" />
          <StatsCard image={AddIcon} classes="middle-img" centerText="Create" onClick={closeModal} />
        </div>

        <div className="d-flex  mb-3">
          <SearchIcon placeholder="Search for Business Category" boxClasses="" textRef={inputRef} value={search} onChange={handleChange} />
          <Dropdown perPage={10} action={handleNewPage} />
        </div>
        <DataTable columns={businessDataColumn} data={businessList} loading={loadingCat} />
        {businessCat?.count > 10 ? (
          <Pagination page={page} lastPage={businessCat?.pagination?.lastPage} paginate={paginate} total={businessCat?.pagination?.total} handlePageChange={handlePageChange} perPage={perPage} />
        ) : null}
      </div>
    </div>
  );
};

export default BusinessCategory;

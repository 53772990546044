import axios from "axios";
import { AppThunk, AppDispatch } from "store/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { currenciesState, Action } from "./interface";
import { toastr } from "react-redux-toastr";
import API from "utils/httpsRequest";

const initialState: currenciesState = {
    isLoading: false,
    currencies: null,
    successMessage: "",
    errorMessage: ""
};

const currencySlice = createSlice({
    name: "currency",
    initialState,
    reducers: {
        getAllcurrenciesLoading: (state) => {
            state.isLoading = true;
        },
        getAllcurrenciesSuccess: (state, action: PayloadAction<Action>) => {
            state.isLoading = false;
            state.currencies = action.payload;
        },
        getAllcurrenciesFail: (state) => {
            state.isLoading = false;
        }
    }
});

const { actions, reducer } = currencySlice;
export const { getAllcurrenciesLoading, getAllcurrenciesSuccess, getAllcurrenciesFail } = actions;

export const getAllcurrencies = (): AppThunk => async (dispatch: AppDispatch, getState) => {
    const { userToken } = getState().user;
    dispatch(getAllcurrenciesLoading());

    try {
        const response = await API.get("/currencies/get",{
            headers: {
                Authorization: `Bearer ${userToken}`
              }
        });
        if ([200, 201].includes(response.status)) {
            dispatch(getAllcurrenciesSuccess(response.data.data));
        }
    } catch (error) {
        dispatch(getAllcurrenciesFail());
    }
};

export default reducer;

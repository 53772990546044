/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Modal from "components/Modal";
import ArrowDropdown from "components/ArrowDropdown";
import Textbox from "components/Textbox";
import Button from "components/Button";
import useArrowDropdown from "hooks/useArrowDropdown";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { useEffect } from "react";
import { getAllCompaniesData } from "store/slice/BuyerSlice/CompanyDataSlice/CompanyDataSlice";
import SelectDropdown from "components/SelectDropdown";
import { getAllBusinessCategory } from "store/slice/BusinessCategorySlice/BusinessCategorySlice";
import { getMaterialCategories, uploadFile } from "store/slice/BuyerSlice/ProductSlice/ProductSlice";
import { createField, editField, getFields } from "store/slice/BuyerSlice/onBoardSlice/onBoardSlice";
import { useState } from "react";
import { toastr } from "react-redux-toastr";
import FileUploaderBox from "components/FileUploaderBox";

type Props = {
  closeModal: () => void;
  singleField?: any;
  idOn?: any;
  companyId?: any;
};
const CreateOnBoard = ({ closeModal, singleField = null, idOn, companyId }: Props) => {
  const [error, setError] = React.useState("");
  const { register, handleSubmit, errors, setValue } = useForm<FormData>({
    mode: "onChange"
  });

  const [displayOption, setDisplayOption] = useState(false);
  const [displayExtension, setDisplayExtension] = useState(false);
  const [erp, setErp] = useState("");
  const [ext, setExt] = useState("");

  const [uploadBool, setUploadBool] = useState(false);
  const [files, setFiles] = useState<any>([]);

  const checkUpload = () => {
    setUploadBool(!uploadBool);
  };

  const handleErp = (e) => {
    setErp(e.target.value);
  };

  const handleExt = (e) => {
    setExt(e.target.value);
  };

  // for each option

  type optionType = {
    optionName: string;
    optionValue: string;
  };

  const [eachOption, setEachOption] = useState<optionType>({
    optionName: "",
    optionValue: ""
  });

  //all the options array
  const [options, setOptions] = useState<any>([]);

  //add new option to options array
  const addOption = () => {
    setOptions([...options, { ...eachOption }]);
    toastr.success("", "Option Added");
    setEachOption({
      optionName: "",
      optionValue: ""
    });
  };

  // option input change each handler
  const handleOptionChange = (e) => {
    const { name, value } = e.target;
    setEachOption({ ...eachOption, [name]: value });
  };

  type FormData = {
    allowed_file_extensions: string;
    erp_key: string;
    field_name: string;
    field_label: string;
    required: string;
    field_type: string;
    field_order: number;
    category: string;
  };

  // option handling

  //Redux Store
  const dispatch = useDispatch<AppDispatch>();
  const { companies } = useSelector((state: RootState) => state.companiesData);
  const { loading } = useSelector((state: RootState) => state.field);
  const { materialCategory } = useSelector((state: RootState) => state.product);
  const { allBusinessCategory } = useSelector((state: RootState) => state.businessCategory);
  const [field_types, setFieldTypes] = useState("Text");
  // useEffect(() => {
  //   if (singleField !== null) {
  //     setValue("field_name", singleField?.field_name);
  //     setValue("department_id", singleField?.department_id);
  //     setValue("location_id", singleField?.location_id);
  //     setValue("company_id", singleField?.company_id);

  //   }
  // }, [singleField]);

  const handleFieldChange = (e) => {
    setFieldTypes(e.target.value);
  
    if (e.target.value === "Select" || e.target.value === "Radio" || e.target.value === "Checkbox") {
      setDisplayOption(true);
      setDisplayExtension(false);
    } else if (e.target.value === "File") {
      setDisplayExtension(true);
      setDisplayOption(false);
    } else {
      setDisplayOption(false);
      setDisplayExtension(false);
    }
  };

  useEffect(() => {
    dispatch(getAllCompaniesData());
    dispatch(getMaterialCategories());
    dispatch(getAllBusinessCategory());
  }, []);

  const handleFile = (e, field_name: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(e);
    reader.onloadend = () => {
      // use a regex to remove data url part
      let read: any = reader.result;
      const payload = {
        base64_data: read.replace("data:", "").replace(/^.+,/, ""),
        file_extension: e.name.split(".").pop()
      };
      dispatch(uploadFile(payload));

      setFiles(payload);
    };
  };

  const onFormSubmit = (vals: FormData) => {
    // if (singleField) {
    //   const payload = { ...vals, id: singleField.id };
    //   dispatch(

    //     editField(payload, () => {
    //       closeModal();
    //       dispatch(getFields());
    //     })
    //   );

    //   return;

    // }
    const payload = { ...vals, field_options: options, field_type: field_types, company_id: idOn, erp_key: erp, allowed_file_extensions: ext };

    dispatch(
      createField(payload, () => {
        closeModal();
        dispatch(getFields({ page: 1, length: 10000000000, id: idOn }));
      })
    );
  };
  // getting all companies
  const companiesOptions =
    companies &&
    companies.data.map((_data: { id: string; company_description: string }) => {
      return {
        value: _data?.id,
        optionName: _data?.company_description
      };
    });

  const requiredOption = [
    {
      value: "true",
      optionName: "true"
    },
    {
      value: "false",
      optionName: "false"
    }
  ];

  const fieldTypeOption = [
    {
      value: "Text",
      optionName: "Text"
    },
    {
      value: "File",
      optionName: "File"
    },
    {
      value: "Image",
      optionName: "Image"
    },
    {
      value: "Select",
      optionName: "Select"
    },
    {
      value: "Number",
      optionName: "Number"
    },
    {
      value: "Radio",
      optionName: "Radio"
    },
    {
      value: "Checkbox",
      optionName: "Checkbox"
    },
    {
      value: "Date",
      optionName: "Date"
    }
  ];

  return (
    <Modal modalText="Create Field" isUpload={true} checkUpload={checkUpload} uploadBool={uploadBool} onClose={closeModal}>
      {uploadBool ? (
        <div id="buyer-create-location">
          <p className="">Upload</p>
          <form className="mt-2">
            <p className="template">xxxx Teemplate sourcing team</p>

            <FileUploaderBox
              fileName=""
              boxClasses="mt-3 mb-5"
              handleFile={handleFile}
              acceptFiles={".jpg, .jpeg, .png"}
              customRef={register({ required: true })}
              error={errors["logo"] && "This field is required"}
            />

            <Button label={singleField === null ? "Create Field" : "Edit Field"} btnType="btn-primary" boxClasses="mt-4 mb-5" btnStyle="100%" loading={loading} />
          </form>
        </div>
      ) : (
        <div id="buyer-create-location">
          <p className="">{singleField === null ? "Create a Field Set for On-boarding Suppliers." : "Edit Field Set"}</p>
          <form className="mt-2" onSubmit={handleSubmit(onFormSubmit)}>
            <Textbox
              type="text"
              name="field_name"
              label="Field Name"
              placeholder="Field Name"
              error={errors.field_name && "This field is required"}
              customRef={register({
                required: true
              })}
              boxClasses="mb-3"
            />
            <Textbox
              type="text"
              name="field_label"
              label="Field Label"
              placeholder="Field Label"
              error={errors.field_label && "This field is required"}
              customRef={register({
                required: true
              })}
              boxClasses="mt-3"
            />
            <Textbox
              type="number"
              name="field_order"
              label="Field Order"
              placeholder="Field Order"
              error={errors.field_order && "This field is required"}
              customRef={register({
                required: true
              })}
              boxClasses="mt-3"
            />
            <Textbox
              type="text"
              name="category"
              label="Category"
              placeholder="Category"
              error={errors.category && "This field is required"}
              customRef={register({
                required: true
              })}
              boxClasses="mt-3"
            />
            {/* 
          <Textbox
            type="text"
            name="erp_key"
            label="Erp Key"
            placeholder="Erp Key"
            boxClasses="mt-3"
            error={errors.erp_key && "This field is required"}
          /> */}

            <div className="mt-3 textbox">
              <fieldset>
                <legend id="label-legend" className="pl2 pr2">
                  Erp Key
                </legend>
                <div className="d-flex">
                  <input type="text" name="erp_key" value={erp} onChange={handleErp} placeholder="Erp Key" />
                </div>
              </fieldset>
            </div>

            <SelectDropdown
              boxClasses="mt-3"
              name="required"
              label="Required"
              placeholder="Required"
              options={requiredOption}
              customRef={register({ required: true })}
              error={errors.required && "This field is required"}
            />

            {/* <SelectDropdown
            boxClasses="mt-3"
            name="field_type"
            label="Field Type"
            placeholder="Field Type"
            options={fieldTypeOption}
            customRef={register({ required: true })}
            error={errors.required && "This field is required"}
          /> */}

            <div className="mt-3 select-dropdown">
              <fieldset>
                <legend>Field Type</legend>
                <div className="d-flex">
                  <select
                    autoComplete="off"
                    defaultValue="Field Type"
                    onChange={(e) => {
                      handleFieldChange(e);
                    }}
                    name="field_type"
                    placeholder="Field Type"
                  >
                    <option value="">Field Type</option>
                    {fieldTypeOption &&
                      fieldTypeOption.map((option) => (
                        <option key={option?.value} value={option?.value}>
                          {option?.optionName}
                        </option>
                      ))}
                  </select>
                </div>
              </fieldset>
            </div>

            {displayExtension && (
              <div className="mt-3 textbox">
                <fieldset>
                  <legend id="label-legend" className="pl2 pr2">
                    File Extension
                  </legend>
                  <div className="d-flex">
                    <input type="text" name="allowed_file_extensions" value={ext} onChange={handleExt} placeholder="File Extension" />
                  </div>
                </fieldset>
              </div>
            )}

            {displayOption && (
              <div className="coverOption">
                <p className="addTitle mb-3 mt-3">Add Options (you can add as much as you want)</p>

                <div className="mb-3 textbox">
                  <fieldset>
                    <legend id="label-legend" className="pl2 pr2">
                      Option Name
                    </legend>
                    <div className="d-flex">
                      <input value={eachOption.optionName} name="optionName" onChange={handleOptionChange} type="text" placeholder="Option Name" />
                    </div>
                  </fieldset>
                </div>
                <div className="mb-3 textbox">
                  <fieldset>
                    <legend id="label-legend" className="pl2 pr2">
                      Option Value
                    </legend>
                    <div className="d-flex">
                      <input type="text" name="optionValue" value={eachOption.optionValue} onChange={handleOptionChange} placeholder="Option Value" />
                    </div>
                  </fieldset>
                </div>
                <div className="btnDiv">
                  <button onClick={addOption} type="button">
                    + Add
                  </button>
                </div>
              </div>
            )}

            <Button label={singleField === null ? "Create Field" : "Edit Field"} btnType="btn-primary" boxClasses="mt-4 mb-5" btnStyle="100%" loading={loading} />
          </form>
        </div>
      
      )}
    </Modal>
  );
};

export default CreateOnBoard;

import DataTable from "components/DataTable";
import Dropdown from "components/Dropdown";
import StatsCard from "components/StatsCard";
import Icon from "assests/icons/statsCard/Vector (4).png";
import clock from "assests/icons/buyer/clock.svg";
import interview from "assests/icons/buyer/interview 1.svg";
import React, { useCallback, useEffect, useState } from "react";
import { tableCol } from "./data";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import useSearch from "hooks/useSearch";
import CreateRequisition from "components/ModalBuyer/CreateRequisition";
import { getReqSummary, getRequisitions } from "store/slice/RequisitionSlice/RequisitionSlice";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import SearchIcon from "../../../components/SearchBar";
import filterIcon from "assests/icons/buyer/filter-icon.svg";
import Pagination from "components/Pagination";
import FilterCard from "components/FilterCard";
import { useForm, Controller } from "react-hook-form";
import AsyncSelect from "react-select/async";
import API from "utils/httpsRequest";
import styled from "styled-components";

const FilterContainer = styled.div`
  .mySelect__control {
    border-radius: 10px;
    border: 1px solid rgb(223, 226, 230) !important;
  }

  .mySelect__value-container {
    height: 3rem;
  }
`;

const Requisition = () => {
  const [page, setPage] = useState<number>(1);
  const [perpage, setPerpage] = useState<number>(10);
  const [modals, setModals] = useState(false);
  const [showFilter, setShowFilter] = useState(false)
  const [deptVal, setDeptVal] = useState('');
  const [selDeptVal, setSelDeptVal] = useState<any>('');


  const { search, inputRef, handleChange, searched } = useSearch(getRequisitions, perpage);

  const dispatch = useDispatch<AppDispatch>();

  //Redux
  const { requisitions, isLoading, summary } = useSelector((state: RootState) => state.requisition);
  const { user } = useSelector((state: RootState) => state.user);

  const history = useHistory();

  const { token } = user;

  const {
    register,
    handleSubmit,
    reset,
    control,
  } = useForm({
    mode: "onChange",
    // defaultValues: {
    //   start_date: '',
    //   end_date: '',
    //   from: '',
    //   to: '',
    // }
  })

  const onSubmit = (vals) => {
    const data = {
      ...vals,
      department_id: selDeptVal.value || '',
    }
    dispatch(getRequisitions(page, perpage, search, data.requisition_status, data.date_from, data.date_to, data.department_id))
  }

  const handleClear = () => {
    dispatch(getRequisitions(page, perpage));
    reset();
    setSelDeptVal('')
  };

  const handleInputChangeDept = value => setDeptVal(value);

  const onChangeDept = value => setSelDeptVal(value);

  const loadDept = () => {
    return API.get(`/departments/get?page=1&length=10&search=${deptVal}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      return res.data.data.map((item) => ({
        label: `${item.department_description} - ${item.department_code}`,
        value: item.id,
      }))
    })
  }

  useEffect(() => {
    dispatch(getRequisitions(page, perpage));
    dispatch(getReqSummary());
  }, []);

  const paginate = React.useCallback(
    (pageNo: number, length: number) => {
      setPage(pageNo);
      dispatch(getRequisitions(pageNo, length, search));
    },
    [search]
  );

  React.useEffect(() => {
    if (searched) setPage(1);
  }, [searched]);

  const closeModal = () => {
    setModals(!modals);
  };

  // CreateRequisition
  const createReq = () => {
    closeModal();
  };

  const showRequisition = (e, item) => {
    history.push({ pathname: `/buyer/requisition/${item.id}` });
  };

  const handleNewPage = React.useCallback(
    (pageNo: number, page = 1) => {
      setPerpage(pageNo);
      setPage(1);
      dispatch(getRequisitions(page, pageNo, search));
    },
    [search]
  );

  const handlePageChange = React.useCallback(
    (page: number, length: number) => {
      setPage(page);
      dispatch(getRequisitions(page, length, search));
    },
    [search]
  );

  const requisitionList =
    requisitions &&
    requisitions?.data?.map((item: any) => {
      return {
        ...item,
        created_at: format(new Date(item.created_at), "dd.MM.yyyy"),
        requisition_code: item.requisition_code,
        requisition_title: item.requisition_description,
        location_description: item.location_description,
        requisition_type: item.requisition_type,
        approved_by:
          `${item.approved_by_othernames === null ? '' : item.approved_by_othernames} ${item.approved_by_lastname === null ? '' : item.approved_by_lastname}`,
        id: item.id
      };
    });

  return (
    <div id="requisition">
      <div className="container">
        {modals && <CreateRequisition closeModal={closeModal} />}
        <h3 className="header">Requisition Stats</h3>
        <div className="stats-card mt-4 row">
          <StatsCard
            text="Requisitions"
            classes="stats-icon-blue"
            image={interview}
            statsNumber={requisitions?.count}
          />
          <StatsCard
            text="Pending Approval"
            classes="stats-icon-orange"
            image={clock} statsNumber={summary?.['total_requisitions']}
          />
          <StatsCard
            image={Icon}
            classes="middle-img"
            centerText="Create Requisition"
            onClick={createReq}
          />{" "}
        </div>
        {showFilter && (
          <FilterContainer>
            <FilterCard
              handleSubmit={handleSubmit(onSubmit)}
              clear={handleClear}
            >
              <div className="row">
                <div className="col col-4">
                  <label
                    style={{ display: 'block' }}
                  >Start Date</label>
                  <input
                    type="date"
                    name="date_from"
                    className="mt-3"
                    placeholder="Start Date"
                    style={{
                      width: '100%',
                      height: '3rem',
                      padding: '5px',
                      borderRadius: '10px',
                      border: '1px solid #dfe2e6',
                    }}
                    ref={register({ required: false })}
                  />
                </div>
                <div className="col col-4">
                  <label
                    style={{ display: 'block' }}
                  >End Date</label>
                  <input
                    type="date"
                    name="date_to"
                    className="mt-3"
                    placeholder="End Date"
                    style={{
                      width: '100%',
                      height: '3rem',
                      padding: '5px',
                      borderRadius: '10px',
                      border: '1px solid #dfe2e6',
                    }}
                    ref={register({ required: false })}
                  />
                </div>
              </div>
              <div className="row" style={{
                marginTop: '2rem'
              }}>
                <div className="col col-4">
                  <label
                    style={{ display: 'block' }}
                  >Requisition Status</label>
                  <select
                    style={{
                      width: '100%',
                      height: '3rem',
                      padding: '5px',
                      borderRadius: '10px',
                      border: '1px solid #dfe2e6',
                    }}
                    name="requisition_status"
                    ref={register({
                      required: false
                    })}
                  >
                    <option value=''>Select</option>
                    <option value="1">Approved</option>
                    <option value="-1">Pending</option>
                    <option value="-2">Draft</option>
                    <option value="2">Generated</option>
                    <option value="3">Completed</option>
                  </select>
                </div>
                <div className="col col-4">
                  <label
                    style={{ display: 'block' }}
                  >Department</label>
                  <Controller
                    control={control}
                    defaultValue
                    name="department_id"
                    render={({ ref }) => (
                      <AsyncSelect
                        cacheOption
                        defaultOptions
                        placeholder="Select Department"
                        value={selDeptVal}
                        loadOptions={loadDept}
                        onInputChange={handleInputChangeDept}
                        onChange={onChangeDept}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        classNamePrefix="mySelect"
                      />
                    )} />
                </div>
              </div>
            </FilterCard>
          </FilterContainer>
        )}
        <div className="document mt-5">
          <h3
            className="title font-weight-bold"
            style={{ paddingBottom: '1rem' }}
          >Requisition List</h3>
          <div className="search-field">
            <SearchIcon
              placeholder="Search for Requisition"
              boxClasses="mb-3"
              textRef={inputRef}
              value={search}
              onChange={handleChange}
            />
            <Dropdown
              perPage={perpage}
              action={handleNewPage}
            />
            <div>
              <button style={{
                padding: "5px 10px",
                borderRadius: "10px",
                background: "#FFFFFF",
                color: "#737A91",
                border: '1px solid #DFE2E6',
                fontSize: '18px',
                marginLeft: '1rem',
                width: 'max-content'
              }}
                onClick={() => setShowFilter(!showFilter)}
              >
                <img src={filterIcon} alt="icon" className="icon-image" />
                <span style={{
                  paddingLeft: '1rem',
                }}>
                  {!showFilter ? 'Filters' : 'Hide Filters'}
                </span>
              </button>
            </div>
          </div>

          <div className="mt-3">
            <DataTable
              columns={tableCol}
              data={requisitionList}
              loading={isLoading}
              onClick={showRequisition}
            />
            <Pagination
              page={page}
              lastPage={requisitions?.pagination?.lastPage}
              paginate={paginate}
              total={requisitions?.pagination?.total}
              handlePageChange={handlePageChange}
              perPage={perpage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Requisition;

import { useCallback, useEffect, useState } from "react";
import "./styles.scss";
import StatCard, { StatsCard } from "components/StatsCard/";
import { tableCol } from "./Data";
import people from "../../../assests/icons/supplier/people.svg";
import SearchBar from "../../../components/SearchBar";
import DataTable from "components/DataTable";
import Icon from "assests/icons/add-circle.svg";
import optionIcon from "assests/icons/supplier/option.svg";
import Button from "components/Button";
import CreateUser from "components/ModalSupplier/CreateOrEditUser";
import Contracts from "assests/icons/contract.svg";
import { useDispatch, useSelector } from "react-redux";
import { getSupplierUsers } from "store/slice/SupplierSlices/SupplierUserSlice/SupplierUserSlice";
import { AppDispatch, RootState } from "store/store";
import { checkPermission, toastPopUp } from "utils/utils";
import { deleteUser, getUsers, activateUser, deactivateUser } from "store/slice/UserMgtSlice/UserMgtSlice";
import Pagination from "components/Pagination";
import useSearch from "hooks/useSearch";

interface IUserMenuProps {}

const UserMenu: React.FC<IUserMenuProps> = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  //State
  const [pageNum, setPageNumber] = useState<number>(10);
  const [modals, setModals] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [length, setLength] = useState<number>(10);
  // const [_modalData, set_ModalData] = useState(null);
  const [modalData, setModalData] = useState({
    userId: "",
    viewStatus: false,
    editStatus: false
  });

  const { search, inputRef, handleChange, searched } = useSearch(getSupplierUsers, length, undefined, ["vendor"]);

  //Redux Hook
  const dispatch = useDispatch<AppDispatch>();
  const { users, isLoading } = useSelector((state: RootState) => state.supplierUser);
  const { user } = useSelector((state: RootState) => state.user);
  const { role_access } = useSelector((state: RootState) => state.user?.user);

  useEffect(() => {
    dispatch(getSupplierUsers(page, length, undefined, "vendor"));
  }, []);

  const closeModal = () => {
    setModals(!modals);
  };

  useEffect(() => {
    if (modals === false) {
      setModalData({ ...modalData, userId: "", viewStatus: false, editStatus: false });
    }
  }, [modals]);

  // Methods
  const viewUser = (id: string) => {
    console.log(id);
    closeModal();
    setModalData({ ...modalData, userId: id, viewStatus: true, editStatus: false });
  };

  const editUser = (id: string) => {
    // if (!permissions?.includes("USER_EDIT")) {
    //   toastr.error("", "You don't have the Admin rights to do this");
    //   return;
    // }
    closeModal();
    setModalData({ ...modalData, viewStatus: false, userId: id, editStatus: true });
  };

  const deleteUserId = (id: string, name: string) => {
    // if (!permissions?.includes("USER_DELETE")) {
    //   toastr.error("", "You don't have the Admin rights to do this");
    //   return;
    // }

    toastPopUp(
      () => {
        dispatch(
          deleteUser(id, () => {
            dispatch(getSupplierUsers());
          })
        );
      },
      "Delete",
      "Cancel",
      "Delete the user",
      name,
      "red"
    );
  };

  const activateUserID = (id: string, name: string) => {
    // if (!permissions?.includes("USER_ACTIVATE")) {
    //   toastr.error("", "You don't have the Admin rights to do this");
    //   return;
    // }
    toastPopUp(
      () => {
        dispatch(
          activateUser({ user_id: id }, () => {
            dispatch(getUsers(page, length));
          })
        );
      },
      "Activate",
      "Cancel",
      "Activate",
      name,
      "#00B050"
    );
  };

  const deactivateUserID = (id: string, name: string) => {
    // if (!permissions?.includes("USER_DEACTIVATE")) {
    //   toastr.error("", "You don't have the Admin rights to do this");
    //   return;
    // }
    toastPopUp(
      () => {
        dispatch(
          deactivateUser({ user_id: id }, () => {
            dispatch(getUsers(page, length));
          })
        );
      },
      "Deactivate",
      "Cancel",
      "Deactivate",
      name,
      "#737a91"
    );
  };

  //   const deleteUser = (): void => {};

  const userList =
    users &&
    users?.data?.map((data) => {
      let status;
      if (data.user_status === "1") {
        status = "Active";
      } else if (data.user_status === "0") {
        status = "Inactive";
      } else if (data.user_status === "-1") {
        status = "Unapproved";
      }
      return {
        firstName: data.othernames,
        lastName: data.lastname,
        phoneNo: data.phone_number,
        email: data.username,
        role: data.user_role_name,
        // toggleDropdown: handleTableDropdown,
        userId: data.id,
        status,
        // tableID: tableID,
        view: () => viewUser(data.id),
        edit: () => checkPermission(role_access, "USERS_EDIT") && editUser(data.id),
        deleteUserId: () => checkPermission(role_access, "USERS_DELETE") && deleteUserId(data.id, data.othernames),
        activateUserID: () => checkPermission(role_access, "USERS_ACTIVATE") && activateUserID(data.id, data.othernames),
        deactivateUserID: () => checkPermission(role_access, "USERS_DEACTIVATE") && deactivateUserID(data.id, data.othernames)
      };
    });

  //Create User
  const createUser = () => {
    checkPermission(role_access, "USERS_CREATE") && closeModal();
  };

  // setpage to the first page when a searched executed
  useEffect(() => {
    if (searched) setPage(1);
  }, [searched]);

  //--------------------------------------------
  //Pagination
  //---------------------------------------------
  const handlePageChange = useCallback(
    (page: number, length: number) => {
      setPage(page);
      dispatch(getSupplierUsers(page, length, search, "vendor"));
    },
    [search]
  );

  const paginate = useCallback(
    (pageNo: number, length: number) => {
      setPage(pageNo);
      dispatch(getSupplierUsers(pageNo, length, search, "vendor"));
    },
    [search]
  );

  //------------------------------------------------------------
  //Show Number for length Function
  //------------------------------------------------------------
  const handleNewPage = useCallback(
    (pageNo: number, page = 1) => {
      setLength(pageNo);
      setPage(1);
      dispatch(getSupplierUsers(page, pageNo, search, "vendor"));
    },
    [search]
  );
  return (
    <section className="user-menu">
      <div className="container">
        {modals && <CreateUser closeModal={closeModal} modalData={modalData} />}
        <div className="user-menu__content">
          {/* <header>
            <h3>Users</h3>
            <hr />
          </header> */}
          <h4 className="page-title mr-2 ">
            {user?.vendor_description} | {user?.vendor_code}
          </h4>
          <div className="mt-4 row">
            <StatsCard statsNumber={users?.count} text="Users" classes="stats-icon-orange" image={people} />
            <StatsCard image={Icon} classes="middle-img" centerText="Create User" onClick={createUser} />
          </div>
          <div className="document mt-5">
            <h3 className="title font-weight-bold">User Logs</h3>
            <nav className="mt-3">
              <div className="">
                <SearchBar placeholder="Search for First name, Phone no. or email" textRef={inputRef} value={search} onChange={handleChange} />
              </div>
            </nav>
            <div className="table-data mt-3">
              <DataTable columns={tableCol} data={userList} loading={isLoading} />
              <Pagination page={page} lastPage={users?.pagination?.pageCount} paginate={paginate} total={users?.pagination?.total} handlePageChange={handlePageChange} perPage={length} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UserMenu;

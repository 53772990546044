import { Link } from "react-router-dom";

export const reportCol = [
 
    {
        name: "Country",
        index: "country_description",
        id: 1
    },
    {
        name: "Total Vendors",
        index: "total_vendors",
        id: 2
    },
    {
        name: "Total Companies",
        index: "total_companies",
        id: 3
    }

];


export const reportCategory = [
    {
        name: "ID",
        index: "business_category_id",
        id: 1
    },
    {
        name: "Category",
        index: "business_category_description",
        id: 2
    },
    {
        name: "Total Vendor",
        index: "total_vendors",
        id: 3
    },



];

export const reportDoc = [
    {
        name: "ID",
        index: "id",
        id: 1
    },
    {
        name: "Field Label",
        index: "field_label",
        id: 2
    },
    {
        name: "Total Vendor",
        index: "total_vendors",
        id: 3
    }

];

export const reportData = [
    {
   
        department_code: "Brent Fawaz",
        department_description: "13",
        amount: "N23,345",
        id: 1
    },
    {
   
        location: "Brent Fawaz",
        department: "13",
        amount: "N23,345",
        id: 2
    },
    {
   
        location: "Brent Fawaz",
        department: "13",
        amount: "N23,345",
        id: 4
    }
];

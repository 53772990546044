import React, { useCallback, useState } from "react";
import "./styles.scss";
import TabSmall from "components/TabSmall";
import BusinessGroupCategory from "./components/businessGroupCategory";
import BusinessCategory from "./components/businessCategory";
import CostElement from "./components/CostElement";
import TaxCode from "./components/TaxCode";

const Accounting = () => {
  const menus = [
    { name: "Business Category", id: "business" },
    { name: "Material Group Business", id: "Group" },
    { name: "Cost Element", id: "cat" },
    { name: "Tax Information", id: "cat2" }
  ];
  //State
  const [state, setstate] = useState(menus[0].id);

  const selectMenu = useCallback((name) => {
    setstate(name);
  }, []);

  return (
    <div id="settings-material-data">
      <div className="container">
        <TabSmall menus={menus} onSelect={selectMenu} classes="mb-3" />

        {state === "business" && <BusinessCategory />}
        {state === "Group" && <BusinessGroupCategory />}
        {state === "cat" && <CostElement />}
        {state === "cat2" && <TaxCode />}
      </div>
    </div>
  );
};

export default Accounting;

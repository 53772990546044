import React from "react";
import Document from "assests/icons/sidebar/inventory.svg";
import Button from "components/Button/index";
import "./styles.scss";
import { Link } from "react-router-dom";
import Checkmark from "assests/icons/checkmark-circle.svg";

export const documentsColumns = [
  // {
  //   name: "Date",
  //   index: "date",
  //   id: 1
  // },
  {
    name: "Legal Entity",
    index: "legal",
    id: 1
  },
  {
    name: "Vendor No..",
    index: "no",
    id: 2
  },
  {
    name: "Vendor Name",
    index: "name",
    id: 3
  },
  // {
  //   name: "RC No.",
  //   index: "rc_number",
  //   id: 4
  // },
  {
    name: "Specialization",
    index: "specialization",
    id: 5
  },
  {
    name: "Status",
    id: 6,
    render: (item) => (
      <button
        type="button"
        style={{
          padding: "8px 16px",
          background: item.data_status === "Pending" ? "rgba(245, 150, 37, 0.15)" : "rgba(0, 176, 7, 0.15)",
          borderRadius: "10px",
          color: item.data_status === "Pending" ? "rgba(245, 150, 37, 1)" : "#387C57"
        }}
      >
        {item.data_status}
      </button>
    )
  },
  {
    name: "Action",
    id: 7,
    render: (item) => {
      return (
        <span className="span-container">
          {/* {item.data_status !== "Pending" && ( */}
            <span>
              {item.purchase_order_count > 0 && (
                <p className="edit">
                  <Link to={`/buyer/suppliers/${item.id}`}>Transactions</Link>
                </p>
              )}
              {item.purchase_order_count < 1 && (
                <p className="" style={{ color: "#45454570" }}>
                  Transactions
                </p>
              )}
            </span>
          {/* )} */}
          <p className="delete pl-3">
            <Link to={{ pathname: "/buyer/suppliers/master-data", state: item.id }}>Master Data</Link>
          </p>
        </span>
      );
    }
  }
];

export const documents = [
  {
    date: "2nd Sept. 2019",
    no: "78600090",
    name: "Brent Fawaz",
    rc_number: "123333",
    speciaslization: "Maintenance",
    status: "Verified"
  }
];

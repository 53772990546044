export const sourcingTableCol = [
  {
    name: "Rfx No.",
    index: "rfx_no",
    id: 1
  },
  {
    name: "Title",
    index: "title",
    id: 2
  },
  {
    name: "PR No.",
    index: "pr_no",
    id: 3
  },
  {
    name: "Department",
    index: "department",
    id: 4
  },
  {
    name: "Duration",
    index: "duration",
    id: 5
  },
  {
    name: "Rcvd. Quotes",
    index: "total_submissions",
    id: 6,
  },
  {
    name: "Invited Vendors",
    index: "invited_vendors",
    id: 7
  },
  {
    name: "Status",
    id: 8,
    render: (item) => {
      if (item.rfp_status === '1') {
        return (
          <button style={{
            padding: '8px 16px',
            background: 'rgba(0, 176, 7, 0.15)',
            borderRadius: '10px',
            color: 'rgb(56, 124, 87)',
          }}>Approved</button>
        )
      }
      if (item.rfp_status === '-2') {
        return (
          <button style={{
            padding: "8px 16px",
            background: "rgba(245, 150, 37, 0.15)",
            borderRadius: "10px",
            color: "rgb(245, 150, 37)"
          }}>Draft</button>
        )
      }
      if (item.rfp_status === '-1') {
        return (
          <button style={{
            padding: "8px 16px",
            background: "rgba(245, 150, 37, 0.15)",
            borderRadius: "10px",
            color: "rgb(245, 150, 37)"
          }}>Pending</button>
        )
      }
      if (item.rfp_status === '4') {
        return (
          <button style={{
            padding: '8px 16px',
            background: 'rgba(0, 176, 7, 0.15)',
            borderRadius: '10px',
            color: 'rgb(56, 124, 87)',
          }
          }> Awarded</button >
        )
      }
      if (item.rfp_status === '5') {
        return (
          <button style={{
            padding: '8px 16px',
            background: 'rgba(0, 176, 7, 0.15)',
            borderRadius: '10px',
            color: 'rgb(56, 124, 87)',
          }}>Completed</button>
        )
      }
      if (item.rfp_status === '3') {
        return (
          <button style={{
            padding: "8px 16px",
            background: "rgba(245, 150, 37, 0.15)",
            borderRadius: "10px",
            color: "rgb(245, 150, 37)"
          }}>Selection</button>
        )
      }
      // return (
      //   <button
      //     style={{
      //       color: "#FFF",
      //       background: `${item.status === "published" ? "#0ACC94" : "#737A91"}`,
      //       padding: "5px 15px",
      //       minWidth: "6rem",
      //       borderRadius: "10px"
      //     }}
      //   >
      //     {item.status}
      //   </button>
      // );
    }
  },
  // {
  //   name: "Action",
  //   id: 9,
  //   render: (item) => {
  //     return (
  //       <button
  //         style={{
  //           color: "#2F80ED",
  //           background: "rgba(0, 113, 176, 0.15)",
  //           padding: "5px 15px",
  //           minWidth: "5rem",
  //           borderRadius: "10px"
  //         }}
  //         onClick={() => item.openSourcing(item)}
  //       >
  //         View
  //       </button>
  //     );
  //   }
  // }
];

export const sourcingTableData = [
  {
    rfx_no: "870000090",
    description: "Tender for Generator Maintenance",
    pr_no: "1000008398",
    department: "Engineering",
    duration: "20",
    invited_vendors: "4",
    status: "published"
  },
  {
    rfx_no: "870000090",
    description: "Brand Machine Maintenance Tender",
    pr_no: "1000008398",
    department: "Engineering",
    duration: "20",
    invited_vendors: "6",
    status: "published"
  },
  {
    rfx_no: "870000090",
    description: "Tender for Vendor Software",
    pr_no: "1000008398",
    department: "IT",
    duration: "20",
    invited_vendors: "7",
    status: "draft"
  }
];

export const purchaseReqTableCol = [
  {
    name: "PR No.",
    index: "purchase_req",
    id: 1
  },
  {
    name: "Description",
    index: "description",
    id: 2
  },
  {
    name: "Location",
    index: "location",
    id: 3
  },
  {
    name: "Department",
    index: "department",
    id: 4
  },
  {
    name: "Material Type",
    index: "material_type",
    id: 5
  },
  {
    name: "Approved By",
    index: "approved_by",
    id: 6
  },
  {
    name: "Action",
    // index: "action",
    id: 7,
    render: (item) => {
      return (
        <div style={{ display: 'flex' }}>
          <button
            style={{
              color: "#FFF",
              background: "#0ACC94",
              padding: "5px 15px",
              minWidth: "5rem",
              borderRadius: "10px",
              display: "inline"
            }}
            onClick={() => {
              item.createClicked(item);
            }}
          >
            Create
          </button>
          &nbsp;
          <button
            style={{
              color: "#2F80ED",
              background: "rgba(0, 113, 176, 0.15)",
              padding: "5px 15px",
              minWidth: "5rem",
              borderRadius: "10px",
              display: "inline"
            }}
            onClick={() => {
              item.viewClicked(item);
            }}
          >
            View
          </button>
        </div>
      );
    }
  }
];

export const purchaseReqTableData = [
  {
    purchase_req: "100009029",
    description: "Software development for SCM",
    location: "HQ",
    department: "Procurement",
    material_type: "Service",
    approved_by: "Justice T"
  },
  {
    purchase_req: "17000090",
    description: "Brand Machine Maintenance",
    location: "HQ",
    department: "Engineering",
    material_type: "Service",
    approved_by: "Musa B"
  },
  {
    purchase_req: "17000090",
    description: "Vendor Procurement Software",
    location: "London",
    department: "IT",
    material_type: "Service",
    approved_by: "Musa B"
  }
];

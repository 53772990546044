import axios from "axios";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch, AppThunk } from "store/store";
import { RequisitionAction, RequisitionState } from "./interface";
import { toastr } from "react-redux-toastr";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const initialState: RequisitionState = {
  isLoading: false,
  isCreating: false,
  requisitions: null,
  singleRequisition: {},
  materials: [],
  summary: {},
};

const requisitionSlice = createSlice({
  name: "requisition",
  initialState,
  reducers: {
    getAllRequisitions: (state, action: PayloadAction<RequisitionAction>) => {
      state.requisitions = action.payload;
    },
    getSingleRequisition: (state, action: PayloadAction<RequisitionAction>) => {
      state.singleRequisition = action.payload[0];
    },
    getAllMaterials: (state, action: PayloadAction<RequisitionAction>) => {
      state.materials = action.payload;
    },
    getSummary: (state, action: PayloadAction<RequisitionAction>) => {
      state.summary = action.payload;
    },
    startRequest: (state) => {
      state.isLoading = true;
    },
    endRequest: (state) => {
      state.isLoading = false;
    },
    startCreating: (state) => {
      state.isCreating = true;
    },
    endCreating: (state) => {
      state.isCreating = false;
    }
  }
});

const { actions, reducer } = requisitionSlice;

export const {
  startRequest,
  endRequest,
  getAllRequisitions,
  getSingleRequisition,
  getAllMaterials,
  startCreating,
  endCreating,
  getSummary,
} = actions;

export const createRequisition =
  (data?: object, cb?: (res) => void): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
      dispatch(startCreating());
      const { userToken } = getState().user;
      try {
        const response = await axios.post(`${BASE_URL}/requisitions/create`, data, {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json"
          }
        });
        // console.log(response);
        if ([200, 201].includes(response.status)) {
          dispatch(endCreating());
          toastr.success(response?.data?.message);
          cb && cb(response.data);
        }
      } catch (error) {
        dispatch(endCreating());
        toastr.error(error?.response?.data?.message);
      }
    };

export const addServiceMaterials =
  (data?: object, cb?: () => void): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
      dispatch(startRequest());
      const { userToken } = getState().user;
      try {
        const response = await axios.post(
          `${BASE_URL}/requisitions/add_service_material`,
          { ...data },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              "Content-Type": "application/json"
            }
          }
        );
        if ([200, 201].includes(response.status)) {
          dispatch(endRequest());
          toastr.success(response?.data?.message);
          cb && cb();
        }
      } catch (error) {
        dispatch(endRequest());
        toastr.error(error?.response?.data?.message);
      }
    }

export const addMaterials =
  (data?: object, cb?: (res) => void): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
      dispatch(startRequest());
      const { userToken } = getState().user;
      try {
        const response = await axios.post(
          `${BASE_URL}/requisitions/add_material`,
          { ...data },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              "Content-Type": "application/json"
            }
          }
        );
        // console.log(response);
        if ([200, 201].includes(response.status)) {
          dispatch(endRequest());
          toastr.success(response?.data?.message);
          cb && cb(response.data);
        }
      } catch (error) {
        dispatch(endRequest());
        toastr.error(error?.response?.data?.message);
      }
    };

export const getRequisitions = (
  page?: number,
  length?: number,
  search?: string,
  requisition_status?: string,
  date_from?: string,
  date_to?: string,
  department_id?: string,
): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    dispatch(startRequest());
    const { userToken } = getState().user;
    try {
      const response = await axios.get(`${BASE_URL}/requisitions/get`, {
        params: {
          page,
          length,
          search,
          requisition_status,
          date_from,
          date_to,
          department_id
        },
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json"
        }
      });
      if ([200, 201].includes(response.status)) {
        // console.log(response.data);
        dispatch(getAllRequisitions(response.data));
      }
      dispatch(endRequest());
    } catch (error) {
      dispatch(endRequest());
      toastr.error(error?.response?.data?.message);
    }
  };

export const getRequisition =
  (id: string): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
      dispatch(startRequest());
      const { userToken } = getState().user;
      try {
        const response = await axios.get(`${BASE_URL}/requisitions/get/${id}`, {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json"
          }
        });
        if ([200, 201].includes(response.status)) {
          dispatch(getSingleRequisition(response.data.data));
        }
        dispatch(endRequest());
      } catch (error) {
        dispatch(endRequest());
        toastr.error(error?.response?.data?.message);
      }
    };

export const getReqSummary = (): AppThunk => async (dispatch: AppDispatch, getState) => {
  dispatch(startRequest());
  const { userToken } = getState().user;
  try {
    const response = await axios.get(`${BASE_URL}/requisitions/summary?requisition_status=-1`, {
      headers: {
        Authorization: `Bearer ${userToken}`,
        "Content-Type": "application/json"
      }
    });
    if ([200, 201].includes(response.status)) {
      dispatch(getSummary(response.data.data))
    }
  } catch (err) {
    dispatch(endRequest());
    toastr.error(err?.response?.data?.message);
  }
}

export const getMaterials =
  (page?: number, length?: number, search?: string, material_type?: string, stock_type?: string, catalog?: string): AppThunk =>
    async (dispatch: AppDispatch, getState) => {
      dispatch(startRequest());
      const { userToken } = getState().user;
      try {
        const response = await axios.get(`${BASE_URL}/materials/get`, {
          params: {
            page,
            length,
            search,
            material_type,
            stock_type,
            catalog
          },
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json"
          }
        });
        if ([200, 201].includes(response.status)) {
          dispatch(getAllMaterials(response.data));
        }
        dispatch(endRequest());
      } catch (error) {
        dispatch(endRequest());
        toastr.error(error?.response?.data?.message);
      }
    };

export const delMaterials = (data?: object, cb?: (res) => void): AppThunk => async (dispatch: AppDispatch, getState) => {
  dispatch(startRequest());
  const { userToken } = getState().user;

  try {
    const res = await axios.post(`${BASE_URL}/requisitions/remove_material`, data, {
      headers: {
        Authorization: `Bearer ${userToken}`,
        "Content-Type": "application/json"
      }
    });
    if ([200, 201].includes(res.status)) {
      cb && cb(res);
      dispatch(endRequest());
    }
  } catch (err) {
    dispatch(endRequest());
    toastr.error(err?.response?.data?.message);
  }
}

export const confirmRequisition = (data?: object, cb?: () => void): AppThunk => async (dispatch: AppDispatch, getState) => {
  dispatch(startCreating());
  const { userToken } = getState().user;

  try {
    const res = await axios.post(`${BASE_URL}/requisitions/confirm`, { ...data }, {
      headers: {
        Authorization: `Bearer ${userToken}`,
        "Content-Type": "application/json"
      }
    })
    if ([200, 201].includes(res.status)) {
      toastr.success(res.data.message)
      dispatch(endCreating())
      cb && cb();
    }
  } catch (err) {
    dispatch(endCreating());
    toastr.error(err?.response?.data?.message);
  }
}

export default reducer;

import Document from "assests/icons/sidebar/inventory.svg";
import Button from "components/Button/index";
import "./styles.scss";
import { Link } from "react-router-dom";

export const documentsColumns = [
  // {
  //     name: "Creatd On",
  //     index: "date",
  //     id: 1,
  // },
  // {
  //     name: "Purchase No.",
  //     index: "purchase_no",
  //     id: 2
  // },
  // {
  //     name: "Legal Entity",
  //     index: "legal",
  //     id: 3
  // },
  // {
  //     name: "Amount",
  //     index: "amount",
  //     id: 4
  // },
  // {
  //     name: "Currency",
  //     index: "curr",
  //     id: 5
  // },
  // {
  //     name: "Status",
  //     index: "status",
  //     id: 6
  // },
  // {
  //     name: "Action",
  //     id: 7,
  //     render: (item) => (
  //         <Link to={`/supplier/contracts/${item.id}`}>
  //             <Button
  //                 label="View"
  //                 btnType="btn-secondary"
  //                 boxClasses="button-container"
  //                 xtraClass="button-position"
  //             />
  //         </Link>
  //     )
  // }
  {
    name: "Created On",
    index: "created_at",
    id: 0
  },
  {
    name: "Purchase No.",
    index: "purchase_no",
    id: 1
  },
  {
    name: "Supplier",
    index: "description",
    id: 2
  },
  // {
  //   name: "Delivery Location",
  //   index: "delivery_loca",
  //   id: 3
  // },
  {
    name: "Legal Entity",
    index: "company_description",
    id: 4
  },
  {
    name: "Amount",
    index: "price",
    id: 5
  },
  {
    name: "Currency",
    index: "currency",
    id: 6
  },
  {
    name: "Status",
    // index: "status",
    id: 7,
    render: (item) => (
      <button
        type="button"
        style={{
          padding: "8px 16px",
          background: item.status === "Pending" ? "rgba(245, 150, 37, 0.15)" : "rgba(0, 176, 7, 0.15)",
          borderRadius: "10px",
          color: item.status === "Pending" ? "rgba(245, 150, 37, 1)" : "#387C57"
        }}
      >
        {item.status}
      </button>
    )
  },
  {
    name: "Action",
    id: 8,
    render: (item: { status: string; id: string }) => (
      <Link to={`/supplier/contracts/${item.id}`}>
        <button
          type="button"
          style={{
            padding: "8px 16px",
            background: item.status === "Pending" ? "rgba(245, 150, 37, 0.15)" : "rgba(0, 70, 176, 0.15)",
            borderRadius: "10px",
            color: item.status === "Pending" ? "rgba(245, 150, 37, 1)" : "rgba(47, 128, 237, 1)"
          }}
        >
          {/* {item.status} */}
          View
        </button>
      </Link>
    )
  }
];

export const documents = [
  {
    doc_no: "45000008910",
    description: "Apple Iphone 12 Max, 64GB",
    quantity: "18",
    amount: "N4,800,000",
    status: "Delivered",
    id: 1
  },
  {
    doc_no: "45000008233",
    description: "In-house software development",
    quantity: "1",
    amount: "N34,120,000",
    status: "In Progress",
    id: 2
  },
  {
    doc_no: "45000008738",
    description: "Computer Sales",
    quantity: "20",
    amount: "N5,560,900",
    status: "PO Received",
    id: 3
  },
  {
    doc_no: "45000008910",
    description: "Office Supplies",
    quantity: "5",
    amount: "N78,900",
    status: "Out for delivery",
    id: 4
  }
];

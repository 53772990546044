import StatsCard from "components/StatsCard";
import ApprovalIcon from "assests/icons/sidebar/Mask Group.svg";
import greenCheck from "assests/icons/buyer/green-check.svg";
import SearchBar from "../../../components/SearchBar";
import Dropdown from "components/Dropdown";
import DataTable from "components/DataTable";
import { tableCol, tableData } from "./data";

import "./styles.scss";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { getApprovals } from "store/slice/BuyerSlice/ApprovalSlice/ApprovalSlice";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import useSearch from "hooks/useSearch";
import Pagination from "components/Pagination";
import ArrowDropdown from "components/ArrowDropdown";
import useArrowDropdown from "hooks/useArrowDropdown";
import ApproveRequisition from "./Requisition";
import { titleCase } from "utils/utils";

const Approval = () => {
  const [pageNum, setPageNumber] = useState(10);
  const [page, setPage] = useState<number>(1);
  const [length, setLength] = useState<number>(10);

  const history = useHistory();

  //Redux Store
  const dispatch = useDispatch<AppDispatch>();
  const { approvalItems, loading } = useSelector((state: RootState) => state.approvals);
  const { othernames, lastname } = useSelector((state: RootState) => state.user?.user);

  // Search hook
  const { open, selectedOption, handleDropdownChange, handleAction, keyOption, ref } = useArrowDropdown();
  const { search, inputRef, handleChange, searched } = useSearch(getApprovals, length, undefined, [keyOption]);

  // setpage to the first page when a searched executed
  useEffect(() => {
    if (searched) setPage(1);
  }, [searched]);

  useEffect(() => {
    dispatch(getApprovals(page, length));
  }, []);

  //   Table data
  const approval =
    approvalItems &&
    approvalItems?.data?.map((data: any) => {
      const requester = () => {
        if (data.requester_lastname === null && data.requester_othernames === null) {
          return othernames + " " + lastname;
        } else {
          return data.requester_lastname + "  " + data.requester_othernames;
        }
      };
      let details = "";
      switch (data.activity_description) {
        case "REQUISITION_APPROVAL":
          details = data.meta_data.requisition_description;
          break;
        case "VENDOR_ONBOARDING":
          details = data.meta_data.vendor_description;
          break;
        case "RFP_APPROVAL":
          details = data.meta_data.rfp_description;
          break;
        case "INVOICE_APPROVAL":
          details = data.meta_data.invoice_description;
          break;
        case "PURCHASE_ORDER_APPROVAL":
          details = data.meta_data.po_description;
          break;
        default:
          return details;
      }

      const process = data.activity_description && data.activity_description.replace("_", " ");
      return {
        process: titleCase(process),
        details,
        requester: requester(),
        delivery_date: format(new Date(data.created_at), "dd.MM.yyyy"),
        id: data.record_id,
        // id: data.meta_data && data.meta_data.vendor_id,
        queue_id: data.id
      };
    });

  const showProcess = (e, item) => {
    // console.log(item)
    if (item.process === "Vendor Onboarding") {
      history.push({ pathname: `/buyer/approvals/${item.id}`, state: item.queue_id });
    }
    if (item.process === "Invoice Approval") {
      history.push({ pathname: `/buyer/approvals/invoice/${item.id}`, state: item.queue_id });
    }
    if (item.process === "Rfp Approval") {
      history.push({ pathname: `/buyer/approvals/rfp/${item.id}`, state: item.queue_id });
    }
    if (item.process === "Requisition Approval") {
      history.push({ pathname: `/buyer/approvals/requisition/${item.id}`, state: item.queue_id });
    }
    // else history.push({ pathname: `/buyer/approvals/requisition/${item.id}`, state: item.queue_id });
  };

  //--------------------------------------------
  //Pagination
  //---------------------------------------------
  const handlePageChange = React.useCallback(
    (page: number, length: number) => {
      setPage(page);
      dispatch(getApprovals(page, length, search, keyOption));
    },
    [search]
  );

  const paginate = React.useCallback(
    (pageNo: number, length: number) => {
      setPage(pageNo);
      dispatch(getApprovals(pageNo, length, search, keyOption));
    },
    [search]
  );

  //------------------------------------------------------------
  //Show Number for PerPage Function
  //------------------------------------------------------------
  const handleNewPage = React.useCallback(
    (pageNo: number, page = 1) => {
      setLength(pageNo);
      setPage(1);
      dispatch(getApprovals(page, pageNo, search, keyOption));
    },
    [search]
  );

  const approval_activity = [
    {
      name: "All",
      id: ""
    },
    {
      name: "Vendor Onboarding",
      id: "VENDOR_ONBOARDING"
    },
    {
      name: "Requisition Approval",
      id: "REQUISITION_APPROVAL"
    },
    {
      name: "RFP Approval",
      id: "RFP_APPROVAL"
    },
    {
      name: "Invoice Approval",
      id: "INVOICE_APPROVAL"
    },
    {
      name: "Purchase Order Approval",
      id: "PURCHASE_ORDER_APPROVAL"
    }
  ];

  useEffect(() => {
    dispatch(getApprovals(page, length, search, keyOption));
  }, [keyOption]);

  console.log('test one signal')

  return (
    <div id="approval-main">
      <div className="container">
        <div className="d-flex align-items-center mb-3">
          <h4 className="page-title mr-2 ">Approval Workflow</h4>
        </div>
        <div className="stats-card mt-4 row">
          <StatsCard text="Pending Approvals" classes="stats-icon-green" image={ApprovalIcon} statsNumber={approvalItems?.count} />
        </div>
        <div className="document mt-5">
          <div className="search-field mt-3">
            <div className="search-cont">
              <SearchBar placeholder="Search for Approval Item" inputWidth="80%" textRef={inputRef} value={search} onChange={handleChange} />
            </div>
            <div className="col-md-4 col-xs-12 col-sm-12">
              <ArrowDropdown
                text={"Process"}
                options={approval_activity}
                handleDropdownChange={handleDropdownChange}
                handleAction={handleAction}
                selectedOption={selectedOption}
                open={open}
                ref={ref}
              />
            </div>
            <Dropdown perPage={10} action={handleNewPage} />
          </div>
          <div className="upload mt-4"></div>
          <div className="mt-3">
            <DataTable columns={tableCol} data={approval} onClick={showProcess} loading={loading} />
            <Pagination page={page} lastPage={approvalItems?.pagination?.pageCount} paginate={paginate} total={approvalItems?.pagination?.total} handlePageChange={handlePageChange} perPage={length} />
          </div>
        </div>
      </div>
      {/* <ApproveRequisition /> */}
    </div>
  );
};

export default Approval;

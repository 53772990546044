import StatsCard from "components/StatsCard";
import Icon from "assests/icons/statsCard/Vector (4).png";
import greenCheck from "assests/icons/buyer/green-check.svg";
import rotate from "assests/icons/buyer/rotate 1.svg";
import purpleHands from "assests/icons/buyer/purple-hands.svg";
import SearchBar from "../../../components/SearchBar";
import Dropdown from "components/Dropdown";
import DataTable from "components/DataTable";
import { tableCol, tableData } from "./data";
import { AppDispatch, RootState } from "store/store";
import "./styles.scss";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import useSearch from "hooks/useSearch";
import React, { useEffect } from "react";
import SearchIcon from "../../../components/SearchBar";
import Pagination from "components/Pagination";
import { getAllrfpData, getSummary } from "store/slice/BuyerSlice/EvaluationSlice/EvaluationSlice";

const RFxEvaluation = () => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const dispatch = useDispatch<AppDispatch>();
  const { rfps, summary, loading } = useSelector((state: RootState) => state.rfpData);

  const history = useHistory();

  //Hook
  const { search, inputRef, handleChange, searched } = useSearch(getAllrfpData, perPage, undefined, ["Yes"]);

  //Show Number for PerPage Request
  const handleNewPage = (pageNo: number, page: number = 1) => {
    setPerPage(pageNo);
    setPage(page);
    dispatch(getAllrfpData(page, pageNo, search, undefined, undefined, undefined, undefined, undefined, "Yes"));
  };

  // setpage to the first page when a searched executed
  useEffect(() => {
    if (searched) setPage(1);
  }, [searched]);

  useEffect(() => {
    dispatch(getAllrfpData(page, perPage, undefined, undefined, undefined, undefined, undefined, undefined, "Yes"));
    dispatch(getSummary("3"));
  }, []);

  //Pagination
  //---------------------------------------------
  const handlePageChange = React.useCallback(
    (page: number, perPage: number) => {
      setPage(page);
      dispatch(getAllrfpData(page, perPage, search, undefined, undefined, undefined, undefined, undefined, "Yes"));
    },
    [search]
  );

  const paginate = React.useCallback(
    (pageNo: number, perPage: number) => {
      setPage(pageNo);
      dispatch(getAllrfpData(pageNo, perPage, search, undefined, undefined, undefined, undefined, undefined, "Yes"));
    },
    [search]
  );

  // const view = (id: String,type:String) => {
  //   console.log(type)
  //   if (type==="RFX") {
  //     history.push(`/buyer/rfx-evaluation/evaluation/commercial/?id=${id}&rfpType=${"rfq"}`);
  //   }
  //   else{
  //     history.push(`/buyer/rfx-evaluation/evaluation/technical/?id=${id}&rfpType=${"rfp"}`);
  //   }

  // };

  const view = (e, item) => {
    if (item.rfx_type === "RFX") {
      history.push(`/buyer/rfx-evaluation/evaluation/commercial/?id=${item.project_id}&rfpType=${"rfq"}`);
    } else {
      history.push(`/buyer/rfx-evaluation/evaluation/technical/?id=${item.project_id}&rfpType=${"rfp"}`);
    }
  };

  const rfpList =
    rfps &&
    rfps?.data?.map((item) => {
      return {
        project_id: item.id,
        description: item.rfp_title,
        Submitted_quotes: item.total_submissions,
        invited_vendors: item.total_invited_vendors,
        rfp_status: item.rfp_status,
        rfx_type: item.rfx_type
        // status: () => view(item)
      };
    });

  // const newRfpList =
  //   rfpList &&
  //   rfpList.filter((item) => {
  //     console.log(item.rfp_status);
  //     return item.rfp_status === "3";
  //   });

  return (
    <div id="evaluation">
      <div className="container">
        <h3 className="header">Vendor Selection</h3>
        <div className="stats-card mt-4 row">
          <StatsCard text="In Selection Process" classes="stats-icon-blue" image={rotate} statsNumber={summary?.data && summary?.data?.in_selection?.total_rfps} />
          <StatsCard text="In Negotiations" classes="stats-icon-purple" image={purpleHands} statsNumber={summary?.data && summary?.data?.open_tender?.total_rfps} />
          <StatsCard text="Completed" classes="stats-icon-green" image={greenCheck} statsNumber={summary?.data && summary?.data?.awarded?.total_rfps} />
        </div>

        <div className="d-flex  mb-3 mt-4">
          <SearchIcon placeholder="Search for ID or Discription" boxClasses="" textRef={inputRef} value={search} onChange={handleChange} />
          <Dropdown perPage={perPage} action={handleNewPage} />
        </div>

        <div className="mt-4">
          <DataTable columns={tableCol} data={rfpList} onClick={view} loading={loading} />
        </div>

        {rfps?.count > 10 ? (
          <Pagination page={page} lastPage={rfps?.pagination?.lastPage} paginate={paginate} total={rfps?.pagination?.total} handlePageChange={handlePageChange} perPage={perPage} />
        ) : null}
      </div>
    </div>
  );
};

export default RFxEvaluation;

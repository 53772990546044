import arrowdown from "assests/icons/Vector (2).svg";
import "./styles.scss";
import React from "react";

type Props = {
  text: string;
  options: any[];
  handleDropdownChange?: () => any;
  handleAction?: (e: any, f: any, cb: () => void) => void;
  handleToggle?: (e: any) => void;
  getSearchVal?: (e: any) => void;
  selectedOption?: string;
  searchVal?: string;
  open?: boolean;
  search?: boolean;
  boxClasses?: string;
  disabled?: boolean;
  boxScroll?: string;
  cb?: () => void;
  error?: string;
  label?: string;
};
export type Ref = HTMLElement;

const ArrowDropdown = React.forwardRef(
  ({ text, options, handleDropdownChange, handleAction, selectedOption, getSearchVal, searchVal, search = false, open, boxClasses, handleToggle, disabled = false, boxScroll, cb, error, label }: Props, ref: any) => {
    return (
      <div className={` select-dropdown ${boxClasses}`} ref={ref}>
        <fieldset className={`${error && "error"} ${disabled && "disabled-input"}`}>
          <legend id="label-legend" className={` pl-2 pr-2 ${error && "label-error"}`}>
            {label}
          </legend>
          <div className="select-box cursor-pointer" onClick={handleDropdownChange}>
            <input type="text" name="selectedOption" className="cursor-pointer" placeholder={text} value={selectedOption} disabled />
            {!disabled && <img src={arrowdown} alt="alt" />}
          </div>
          <div className="">
            {open && !disabled && (
              <div className="select-options px-3">
                {search && <input type="text" name="search" className="search mt-2 w-100" placeholder="Search items" value={searchVal} onChange={getSearchVal} />}
                <ul className={`options-dropdown cursor-pointer  ${options && options.length > 5 && "scroll"} `}>
                  {options &&
                    options.map((option: any, i: number) => {
                      if (options && options[0].label) {
                        return (
                          <li key={i}>
                            <input type="checkbox" value={option.id} id={option.id} className="mr-2" name={option.name} checked={option.isChecked} onChange={handleToggle} />
                            <label htmlFor={option.id} className="cursor-pointer">
                              <p className="mb-0">{option.label && option.label}</p>
                            </label>
                          </li>
                        );
                      } else {
                        return (
                          <li className={`mb-2 ${option.style && option.style}`} key={Math.random() * 1000} onClick={() => handleAction && handleAction(option.name, option.id, () => cb && cb())}>
                            <p className="mb-0">{option.name}</p>
                            <p className="mb-0">{option.label && option.label}</p>
                          </li>
                        );
                      }
                    })}
                </ul>
              </div>
            )}
          </div>
        </fieldset>
        <p className={`${error ? "d-block" : "d-none"} input-error mt-1`}>{error}</p>
      </div>
    );
  }
);

export default ArrowDropdown;

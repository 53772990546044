import React from "react";
import styled from "styled-components";
import Icon from "assests/icons/statsCard/Vector (4).png";
import { useDispatch, useSelector } from "react-redux";
import { getVendors, removeVendorFromRfp } from "store/slice/SourcingSlice/SourcingSlice";
import { RootState } from "store/store";
import InviteVendors from "components/ModalBuyer/InviteVendor";
import { format } from "date-fns";
import Pagination from "components/Pagination";

import "styles/variables.scss";
import useSearch from "hooks/useSearch";

const Wrapper = styled.div`
  margin-top: 3rem;

  div.spinner-border {
    display: inline-block;
    width: 5rem;
    height: 5rem;
    vertical-align: text-bottom;
    border: 0.8em solid rgba(47, 128, 237, 0.2);
    border-right-color: transparent;
    border-radius: 50%;
    animation: 1.9s linear infinite spinner-border;
  }
`;

const InvitedVendors = ({ id }) => {
  const dispatch = useDispatch();

  const [showModal, setShowModal] = React.useState(false);
  const [page, setPage] = React.useState<number>(1);
  const [perpage, setPerpage] = React.useState<number>(10);

  const { invitedVendors, rfp, isLoading } = useSelector((state: RootState) => state.sourcing);

  const { search, inputRef, handleChange, searched } = useSearch(getVendors, id, perpage);

  const paginate = React.useCallback(
    (pageNo: number, length: number) => {
      setPage(pageNo);
      dispatch(getVendors(id, pageNo, length, search));
    },
    [search]
  );

  const handlePageChange = React.useCallback(
    (page: number, length: number) => {
      setPage(page);
      dispatch(getVendors(id, page, length, search));
    },
    [search]
  );

  const handleDel = (id) => {
    const data = {
      vendor_id: id,
      rfp_id: rfp.id
    };
    dispatch(removeVendorFromRfp(data, () => handleSuc()));
  };

  const handleSuc = () => {
    dispatch(getVendors(id));
  };

  React.useEffect(() => {
    dispatch(getVendors(id));
  }, [id]);

  React.useEffect(() => {
    if (!showModal) dispatch(getVendors(id));
  }, [showModal]);

  return (
    <Wrapper className="route-transition">
      {/* {showModal && <InviteVendors closeModal={() => setShowModal(false)} />} */}
      {rfp?.rfp_status === "-2" && (
        <div style={{ cursor: "pointer" }} onClick={() => setShowModal(true)}>
          <p style={{ fontWeight: "bold", fontSize: "18px" }}>
            Invite Vendors &nbsp;
            <img src={Icon} alt="Invite Vendors" />
          </p>
        </div>
      )}

      {isLoading ? (
        <div className="justify-content-center w-100 d-flex pb-5 pt-5">
          <div className="spinner-border spinner-border-primary spinner-border-lg justify-content-center mr-2"></div>
        </div>
      ) : (
        <div className="purchase-table mt-4">
          <table>
            <tbody>
              <tr className="table-header">
                <th style={{ color: "#000000" }}>Item</th>
                <th style={{ padding: "1rem", color: "#000000" }}>Vendor ID</th>
                <th style={{ padding: "1rem", color: "#000000" }}>Vendor Description</th>
                <th style={{ padding: "1rem", color: "#000000" }}>Email</th>
                <th style={{ padding: "1rem", color: "#000000" }}>Date Invited</th>
                {rfp?.rfp_status === "-2" && <th style={{ padding: "1rem", color: "#000000" }}>Action</th>}
              </tr>
              {invitedVendors?.map((item, i) => (
                <tr key={i} className="table-data-row">
                  <td style={{ color: "#000000" }}>{i + 1}</td>
                  <td style={{ padding: "1rem", color: "#000000" }}>{item.vendor_id} </td>
                  <td style={{ padding: "1rem", color: "#000000" }}>{item?.vendor_description}</td>
                  <td style={{ padding: "1rem", color: "#000000" }}>{item?.email}</td>
                  <td style={{ padding: "1rem", color: "#000000" }}>{format(new Date(item.created_at), "yyyy-MM-dd")}</td>
                  {rfp?.rfp_status === "-2" && (
                    <td style={{ padding: "1rem", color: "#000000" }}>
                      <button
                        style={{
                          backgroundColor: "rgba(0, 113, 176, 0.15)",
                          color: "#2F80ED",
                          padding: "5px 15px",
                          borderRadius: "10px"
                        }}
                        onClick={() => handleDel(item.vendor_id)}
                        disabled
                      >
                        Remove
                      </button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
          {/* <Pagination page={page} lastPage={invitedVendors?.pagination?.lastPage} paginate={paginate} total={invitedVendors?.pagination?.total} handlePageChange={handlePageChange} perPage={perpage} /> */}
        </div>
      )}
    </Wrapper>
  );
};

export default InvitedVendors;

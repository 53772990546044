import React, { useEffect, useState } from "react";
// import RegistrationLayout from "../RegistrationLayout";
import Textbox from "components/Textbox";
import { useForm } from "react-hook-form";
import Button from "components/Button";
import { useLocation, useParams } from "react-router-dom";
import arrowBack from "assests/icons/arrow-back-circle.svg";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { getAllCountries } from "store/slice/CountriesSlice/CountriesSlice";
import { getAllCities } from "store/slice/CitySlice/CitySlice";
import { getAllListedBanks } from "store/slice/BankSlice/BankSlice";
import SelectDropdown from "components/SelectDropdown";
import { saveOnboardingDraft, vendorOnBoardSubmission } from "store/slice/SupplierSlices/RegistrationSlice/RegistrationSlice";
import TempCode from "components/ModalSupplier/TempOnBoardingCode/TempCodeModal";
import { confirmUpdateVendorData, getSingleVendor, updateVendorData } from "store/slice/BuyerSlice/VendorSlice/VendorSlice";
import { toastPopUp } from "utils/utils";

type Props = {
  showButtons?: boolean;
};
const BankInformation: React.FunctionComponent<Props> = ({ showButtons }) => {
  //State
  const [loading, setState] = useState(false);
  const [requiredForm, setRequiredForm] = useState<boolean>(true);
  const [submitBtn, setSubmitBtn] = useState<boolean>(false);
  const [tempCode_modal, setTempCodeModal] = useState<boolean>(false);
  const [tempCode, setTempCode] = useState<undefined | string>();

  //Helpers
  let history = useHistory();
  let location = useLocation();
  const { state } = location as any;
  let { id }: any = useParams();
  const { register, handleSubmit, errors, setValue, watch } = useForm<FormData>({ mode: "onBlur" });
  const values = watch();

  //Redux Store
  const dispatch = useDispatch<AppDispatch>();
  const { onboarding_data, legal_data_field, complaince_data_fields, forms } = useSelector((state: RootState) => state.vendorRegistration);
  const isLoading = useSelector((state: RootState) => state.vendorDetails.loading);
  const { countries } = useSelector((state: RootState) => state.country);
  const { cities } = useSelector((state: RootState) => state.city);
  const { banks } = useSelector((state: RootState) => state.bank);
  const { singleVendor } = useSelector((state: RootState) => state.vendorDetails);
  const { user } = useSelector((state: RootState) => state.user);

  const vendorData = singleVendor?.data[0];
  // const contactVals = JSON.parse(localStorage.getItem("ContactInfo") || "");

  useEffect(() => {
    dispatch(getAllCountries());
    dispatch(getAllCities());
    // dispatch(getAllListedBanks());
    dispatch(getSingleVendor(user?.vendor_id));
    // if(contactVals)
  }, []);

  useEffect(() => {
    if (vendorData) {
      dispatch(getAllListedBanks(undefined, undefined, undefined, vendorData?.country_id));
    }
  }, [vendorData]);

  //Getting user country
  const getUserCountry = () => {
    const cityID = cities?.find((city: { id: string }) => city?.id == onboarding_data?.city_id);
    const userCountry = countries?.find((country: { country_description: string }) => country?.country_description == cityID?.country_description);
    return userCountry;
  };

  //Getting User banks off user country selected
  let countryBanks = [] as any;
  banks &&
    banks.forEach((element: { country_id: string; bank_description: string }) => {
      // if (element?.country_id === getUserCountry?.()?.id) {
      countryBanks.push({
        value: element?.bank_description,
        optionName: element?.bank_description
      });
      // }
    });

  useEffect(() => {
    if (location.state === undefined) {
      history.push("/supplier/registration/contact-information");
    }
    if (id != onboarding_data?.vendor_id) {
      history.push("/supplier/registration/contact-information");
    }
  }, []);

  //Updating the bank_key Input field
  useEffect(() => {
    if (values.bank) {
      for (const bankey of banks) {
        if (bankey.bank_description === values.bank) {
          setValue("bank_key", bankey.bank_key);
        }
      }
    } else {
      setValue("bank_key", "");
    }
  }, [values.bank]);

  type FormData = {
    bank_account_name: string;
    bank_account_number: string;
    bank_key: string;
    bank: string;
    bank_address: string;
    bank_branch: string;
  };

  useEffect(() => {
    const saved_bankDetails = localStorage.getItem("bank_data");
    if (saved_bankDetails) {
      const field_details = JSON.parse(saved_bankDetails);
      setValue("bank_account_number", field_details.bank_account_number);
      setValue("bank_account_name", field_details.bank_account_name);
      setValue("bank_address", field_details.bank_address);
      setValue("bank_key", field_details.bank_key);
      setValue("bank", field_details.bank);
      setValue("bank_branch", field_details.bank_branch);
    } else if (vendorData) {
      for (const [key, value] of Object.entries(vendorData.more_details) as any) {
        setValue(key, value);
      }
    }
  }, [vendorData]);

  //modal toggler
  const displayTempCodeModal = () => {
    setTempCodeModal(!tempCode_modal);
    history.replace("/");
  };

  //Function declaration: This disables the next button and makes the form inputs not required
  const saveFormDraft = (): void => {
    setRequiredForm(false);
    setSubmitBtn(true);
  };

  const onSubmitForms = (vals) => {
    const { categories, savedValues }: any = location.state;
    const forms = [] as any;
    categories.forEach((item) => {
      if (localStorage.getItem(item) === null) {
        return;
      } else forms.push(JSON.parse(localStorage.getItem(item) || ""));
    });
    // forms.push(JSON.parse(localStorage.getItem("ContactInfo") || ""));
    // const bankFields = Object.entries(vals).map(([key, value]) => {
    //   // const { id } = newData.find((name) => name.field_name === key);
    //   return {
    //     field_name: key,
    //     value: value
    //     // vendor_field_id: id
    //   };
    // });
    var merged;
    if (savedValues && savedValues?.length > 0) {
      merged = [].concat.apply([], [...forms, savedValues]);
    } else merged = [].concat.apply([], [...forms]);
    // const { vendor_fields, ...rest } = onboarding_data;
    const newMerge = merged.map((item) => item.field_name);
    const savedFiles = singleVendor?.data[0].vendor_meta.reduce((acc, curr) => {
      if (!newMerge.includes(curr.field_name)) {
        acc.push({ field_name: curr.field_name, value: curr.value, file_extension: curr.value.split(".").pop() });
      }
      return acc;
    }, []);
    const contactVals = JSON.parse(localStorage.getItem("ContactInfo") || "");
    const payload = {
      // ...rest,
      ...contactVals,
      ...vals,
      meta: [...merged, ...savedFiles],
      vendor_id: singleVendor?.data[0].id,
      po_box: singleVendor?.data[0].po_box,
      registration_number: singleVendor?.data[0].more_details.registration_number,
      iban: singleVendor?.data[0].more_details.iban
    };
    console.log(payload);
    dispatch(
      updateVendorData(payload, () => {
        dispatch(getSingleVendor(user?.vendor_id));
        // history.push("/supplier/company-info");
        // clearing stored data
        // categories.forEach((k) => localStorage.removeItem(k));
        // localStorage.removeItem('ContactInfo')
      })
    );
  };

  const confirmVendor = () => {
    const { categories, savedValues }: any = location.state;
    toastPopUp(
      () => {
        dispatch(
          confirmUpdateVendorData(
            {
              vendor_id: singleVendor?.data[0].id
            },
            () => {
              dispatch(getSingleVendor(user?.vendor_id));
              history.push("/supplier/company-info");
              // clearing stored data
              categories.forEach((k) => localStorage.removeItem(k));
              localStorage.removeItem("ContactInfo");
            }
          )
        );
      },
      "Confirm",
      "Cancel",
      "Confirm Submit. This process is irreversible",
      "",
      "#737a91"
    );
  };

  return (
    <>
      {tempCode_modal && <TempCode closeModal={displayTempCodeModal} code={tempCode} />}
      <form onSubmit={handleSubmit(onSubmitForms)}>
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <SelectDropdown
              boxClasses="mt-4"
              name="bank"
              label="Bank"
              placeholder="Select Bank"
              options={countryBanks}
              customRef={register({ required: requiredForm })}
              error={errors.bank && "This field is required"}
            />
            <Textbox
              label="Account Name"
              name="bank_account_name"
              customRef={register({ required: requiredForm })}
              placeholder="Account Name"
              boxClasses="mt-4"
              error={errors.bank_account_name && "This field is required"}
            />
            <Textbox
              type="number"
              label="Account Number"
              name="bank_account_number"
              customRef={register({ required: requiredForm })}
              placeholder="Account Number"
              boxClasses="mt-4"
              error={errors.bank_account_number && "This field is required"}
            />
            <Textbox readOnly={true} label="Bank Key" name="bank_key" customRef={register({})} placeholder="Bank Key" boxClasses="mt-4" error={errors.bank_key && "This field is required"} />
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <Textbox label="Bank Branch" name="bank_branch" customRef={register({ required: requiredForm })} placeholder="Bank Branch" boxClasses="mt-3" />
            <Textbox label="Bank Address" name="bank_address" customRef={register({ required: requiredForm })} placeholder="Bank Address" boxClasses="mt-3" />
          </div>
        </div>
        {showButtons && (
          // <div className="d-flex justify-content-center align-items-center mt-5">
          //   <Button type="submit" label="Save Draft" btnType="btn-skeleton" xtraClass="w-100" boxClasses="w-7 mr-3" onClick={saveFormDraft} loading={isLoading} />
          //   <Button
          //     type="button"
          //     label="Back"
          //     btnType="btn-skeleton"
          //     xtraClass="w-100 d-flex justify-content-around align-items-center py-2"
          //     boxClasses="w-7 mr-3"
          //     onClick={() => history.goBack()}
          //     icon={arrowBack}
          //   />
          //   <Button type="submit" label="Next" btnType="btn-temporary" xtraClass="w-100" boxClasses="w-7" disabled={submitBtn} loading={loading} />
          // </div>
          <div className="d-flex justify-content-center align-items-center mt-5">
            {/* <Button
                                    type="button"
                                    label="Save Draft"
                                    btnType="btn-skeleton"
                                    xtraClass="w-100"
                                    boxClasses="w-7 mr-3"
                                /> */}
            {state.categories.length >= 1 && (
              <Button
                type="button"
                label="Back"
                btnType="btn-skeleton"
                xtraClass="w-100 d-flex justify-content-around align-items-center py-2"
                boxClasses="w-7 mr-3"
                onClick={() => {
                  history.goBack();
                  localStorage.setItem("bank_data", JSON.stringify(values));
                }}
                icon={arrowBack}
              />
            )}
            <Button type="submit" label="Save" btnType="btn-temporary" xtraClass="w-100" boxClasses="w-7 mr-3" loading={isLoading} />
            {singleVendor?.data[0].sap_sync === "2" && <Button onClick={confirmVendor} type="button" label="Confirm" btnType="btn-temporary" xtraClass="w-100" boxClasses="w-7" loading={isLoading} />}
          </div>
        )}
      </form>
    </>
  );
};

export const AuthBankInformation = () => {
  const { onboarding_data } = useSelector((state: RootState) => state.vendorRegistration);
  return (
    <div>
      <div className="supplier-contact-information">
        {/* <h4 className="text-center">On-Boarding</h4> */}
        {/* <div className="form-container mt-3 pb-5"> */}
        {/* <h4 className="temp_no">Temp ID :{onboarding_data?.onboard_code}</h4> */}
        {/* <h6 className="form-header mt-1">Bank Information</h6> */}
        <BankInformation showButtons={true} />
        {/* </div> */}
      </div>
    </div>
  );
};

export default AuthBankInformation;

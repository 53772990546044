import React, { useEffect, useState } from "react";
import Dropdown from "components/Dropdown";
import DataTable from "components/DataTable";
import { sourcingTableCol } from "../data";
import { useHistory } from "react-router-dom";
import { getSourcings } from "store/slice/SourcingSlice/SourcingSlice";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "components/SearchBar";
import useSearch from "hooks/useSearch";
import { RootState } from "store/store";
import Countdown from "react-countdown";
import Pagination from "components/Pagination";
import { format } from "date-fns";
import FilterCard from "components/FilterCard";
import { useForm, Controller } from "react-hook-form";
import AsyncSelect from "react-select/async";
import API from "utils/httpsRequest";
import filterIcon from "assests/icons/buyer/filter-icon.svg";
import styled from "styled-components";

const FilterContainer = styled.div`
  .mySelect__control {
    border-radius: 10px;
    border: 1px solid rgb(223, 226, 230) !important;
  }

  .mySelect__value-container {
    height: 3rem;
  }
`;

const SourcingProjects = () => {
  const [page, setPage] = useState<number>(1);
  const [perpage, setPerpage] = useState<number>(10);
  const [showFilter, setShowFilter] = useState(false)
  const [deptVal, setDeptVal] = useState('');
  const [selDeptVal, setSelDeptVal] = useState<any>('');

  const history = useHistory();
  const dispatch = useDispatch();

  const { search, inputRef, handleChange } = useSearch(getSourcings, perpage);

  const { sourcings, isLoading } = useSelector((state: RootState) => state.sourcing);

  const { user } = useSelector((state: RootState) => state.user);

  const { token } = user;

  const {
    register,
    handleSubmit,
    reset,
    control,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      start_date: '',
      end_date: '',
      rfp_status: '',
      department_id: '',
    }
  })

  const onSubmit = (vals) => {
    const data = {
      ...vals,
      department_id: selDeptVal.value || '',
    }
    // console.log(data);
    dispatch(getSourcings(page, perpage, search, data.date_from, data.date_to, data.rfp_status, data.department_id))
  }

  const handleClear = () => {
    dispatch(getSourcings(page, perpage));
    reset();
    setSelDeptVal('')
  };

  const handleInputChangeDept = value => setDeptVal(value);

  const onChangeDept = value => setSelDeptVal(value);

  const loadDept = () => {
    return API.get(`/departments/get?page=1&length=10&search=${deptVal}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      return res.data.data.map((item) => ({
        label: `${item.department_description} - ${item.department_code}`,
        value: item.id,
      }))
    })
  }

  useEffect(() => {
    dispatch(getSourcings(page, perpage));
  }, []);

  // useEffect(() => {
  //   console.log(sourcings)
  // }, [sourcings]);

  const showSourcing = (e, item) => {
    history.push({ pathname: `/buyer/sourcing/projects/${item.id}` });
  };

  const paginate = React.useCallback(
    (pageNo: number, length: number) => {
      setPage(pageNo);
      dispatch(getSourcings(pageNo, length, search, '1'));
    },
    [search]
  );

  const handleNewPage = React.useCallback(
    (pageNo: number, page = 1) => {
      setPerpage(pageNo);
      setPage(1);
      dispatch(getSourcings(page, pageNo, search));
    },
    [search]
  );

  const handlePageChange = React.useCallback(
    (page: number, length: number) => {
      setPage(page);
      dispatch(getSourcings(page, length, search));
    },
    [search]
  );

  const sourcingList =
    sourcings &&
    sourcings?.data?.map((item: any) => {
      return {
        ...item,
        rfx_no: item.rfp_code,
        title: item.rfp_title,
        pr_no: item.requisition_code,
        department: item.department_description,
        duration: item?.rfp_status === '1' ? <Countdown date={item?.closing_date} /> : format(new Date(item?.closing_date), 'yyyy-MM-dd'),
        total_submissions: item?.total_submissions,
        invited_vendors: item.total_invited_vendors,
      };
    });

  return (
    <>
      <div className="document mt-5">
        {showFilter && (
          <FilterContainer>
            <FilterCard
              handleSubmit={handleSubmit(onSubmit)}
              clear={handleClear}
            >
              <div className="row">
                <div className="col col-4">
                  <label
                    style={{ display: 'block' }}
                  >Start Date</label>
                  <input
                    type="date"
                    name="date_from"
                    className="mt-3"
                    placeholder="Start Date"
                    style={{
                      width: '100%',
                      height: '3rem',
                      padding: '5px',
                      borderRadius: '10px',
                      border: '1px solid #dfe2e6',
                    }}
                    ref={register({ required: false })}
                  />
                </div>
                <div className="col col-4">
                  <label
                    style={{ display: 'block' }}
                  >End Date</label>
                  <input
                    type="date"
                    name="date_to"
                    className="mt-3"
                    placeholder="End Date"
                    style={{
                      width: '100%',
                      height: '3rem',
                      padding: '5px',
                      borderRadius: '10px',
                      border: '1px solid #dfe2e6',
                    }}
                    ref={register({ required: false })}
                  />
                </div>
              </div>
              <div className="row" style={{
                marginTop: '2rem'
              }}>
                <div className="col col-4">
                  <label
                    style={{ display: 'block' }}
                  >RFP Status</label>
                  <select
                    style={{
                      width: '100%',
                      height: '3rem',
                      padding: '5px',
                      borderRadius: '10px',
                      border: '1px solid #dfe2e6',
                    }}
                    name="rfp_status"
                    ref={register({
                      required: false
                    })}
                  >
                    <option value=''>Select</option>
                    <option value="1">Approved</option>
                    <option value="-2">Draft</option>
                    <option value="-1">Pending</option>
                    <option value="4">Awarded</option>
                    <option value="5">Completed</option>
                    <option value="3">Selection</option>
                  </select>
                </div>
                <div className="col col-4">
                  <label
                    style={{ display: 'block' }}
                  >Department</label>
                  <Controller
                    control={control}
                    defaultValue
                    name="department_id"
                    render={({ ref }) => (
                      <AsyncSelect
                        cacheOption
                        defaultOptions
                        placeholder="Select Department"
                        value={selDeptVal}
                        loadOptions={loadDept}
                        onInputChange={handleInputChangeDept}
                        onChange={onChangeDept}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        classNamePrefix="mySelect"
                      />
                    )} />
                </div>
              </div>
            </FilterCard>
          </FilterContainer>
        )}
        <div className="search-field">
          <SearchIcon placeholder="Search for Sourcing Projects" boxClasses="mb-3" textRef={inputRef} value={search} onChange={handleChange} />
          <Dropdown perPage={perpage} action={handleNewPage} />
          <div>
            <button style={{
              padding: "5px 10px",
              borderRadius: "10px",
              background: "#FFFFFF",
              color: "#737A91",
              border: '1px solid #DFE2E6',
              fontSize: '18px',
              marginLeft: '1rem',
              width: 'max-content'
            }}
              onClick={() => setShowFilter(!showFilter)}
            >
              <img src={filterIcon} alt="icon" className="icon-image" />
              <span style={{
                paddingLeft: '1rem',
              }}>
                {!showFilter ? 'Filters' : 'Hide Filters'}
              </span>
            </button>
          </div>
        </div>

        <div className="mt-3">
          <DataTable
            columns={sourcingTableCol}
            data={sourcingList}
            loading={isLoading}
            onClick={showSourcing}
          />
          <Pagination
            page={page}
            lastPage={sourcings?.pagination?.lastPage}
            paginate={paginate}
            total={sourcings?.pagination?.total}
            handlePageChange={handlePageChange}
            perPage={perpage}
          />
        </div>
      </div>
    </>
  );
};

export default SourcingProjects;

import React, { useState } from "react";
import image from "assests/illustrations/buyer.png";
import Textbox from "components/Textbox";
import Button from "components/Button";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
// import { forgetPassword, forgetPasswordFail } from "store/slice/AuthSlice/AuthSlice";
import ErrorMesssage from "components/ErrorMessage";
import { RootState } from "store/store";
import "./style.scss";
import { useHistory } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { forgetPassword, setPasswordNew, setPasswordRequest } from "store/slice/BuyerSlice/AuthSlice/AuthSlice";
import { toastPopUp } from "utils/utils";
import AuthLayout from "pages/Auth/SupplierAuth/AuthContainer/AuthLayout";

const NewPassword: React.FC = (props) => {
  type FormData = {
    new_pass: string;
    password: string;
  };
  const [password, setPassword] = useState("");
  const [newPass, setNewPass] = useState("");

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const id = urlParams.get("id");
  const token = urlParams.get("token");
  const type = urlParams.get("type");

  const dispatch = useDispatch();
  const history = useHistory();
  const { errorMessage, isLoading } = useSelector((state: RootState) => state.buyerAuth);

  const { register, handleSubmit, errors } = useForm<FormData>({
    mode: "onBlur"
  });

  const onFormSubmit = (vals: FormData) => {
    const payload = { new_password: newPass, current_password: password };
    console.log(payload);
    dispatch(
      setPasswordNew(payload, () => {
        setTimeout(() => {
          history.push("/buyer/dashboard");
        }, 3000);
      })
    );
  };

  React.useEffect(() => {
    // dispatch(forgetPasswordFail(""));
  }, []);

  return (
    <div className="trim">
      {/* {errorMessage !== "" && <ErrorMesssage message={errorMessage} />} */}
      {/* {message !== "" && <ErrorMesssage message={message} success={true} />} */}
      {errorMessage !== "" && <ErrorMesssage message={errorMessage} />}
      <form className="pt-3" onSubmit={handleSubmit(onFormSubmit)}>
        <Textbox
          type="password"
          label="Current Password"
          placeholder="Current Password"
          name="password"
          boxClasses="pb-4"
          error={errors.password && "This field is required"}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          customRef={register({
            required: true
          })}
        />
        <Textbox
          type="password"
          label="New Password"
          placeholder="New Password"
          name="new_pass"
          boxClasses="pb-4"
          error={errors.new_pass && "This field is required"}
          onChange={(e) => {
            setNewPass(e.target.value);
          }}
          customRef={register({
            required: true
          })}
        />
        <Button label="Change Password" btnType="btn-primary" btnStyle="100%" loading={isLoading} />
      </form>
    </div>
  );
};

export default NewPassword;

import axios from "axios";
import { AppThunk, AppDispatch } from "store/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { businessState, Action } from "./interface";
import { toastr } from "react-redux-toastr";
import API from "utils/httpsRequest";

const initialState: businessState = {
  loading: false,
  allBusinessCategory: null,
  successMessage: "",
  errorMessage: ""
};

const businessCategorySlice = createSlice({
  name: "business",
  initialState,
  reducers: {
    getAllBusinessCategoryLoading: (state) => {
      state.loading = true;
    },
    getAllBusinessCategorySuccess: (state, action: PayloadAction<Action>) => {
      state.loading = false;
      state.allBusinessCategory = action.payload;
    },
    getAllBusinessCategoryFail: (state) => {
      state.loading = false;
    },
    getVendorBusinessCategoryLoading: (state) => {
      state.loading = true;
    },
    getVendorBusinessCategorySuccess: (state, action: PayloadAction<Action>) => {
      state.loading = false;
      state.allBusinessCategory = action.payload;
    },
    getVendorBusinessCategoryFail: (state) => {
      state.loading = false;
    },
    addVendorBusinessCategoryLoading: (state) => {
      state.loading = true;
    },
    addVendorBusinessCategorySuccess: (state) => {
      state.loading = false;
      //   state.allBusinessCategory = action.payload;
    },
    addVendorBusinessCategoryFail: (state) => {
      state.loading = false;
    },
    removeVendorBusinessCategoryLoading: (state) => {
      state.loading = true;
    },
    removeVendorBusinessCategorySuccess: (state) => {
      state.loading = false;
      //   state.allBusinessCategory = action.payload;
    },
    removeVendorBusinessCategoryFail: (state) => {
      state.loading = false;
    }
  }
});

const { actions, reducer } = businessCategorySlice;
export const {
  getAllBusinessCategoryLoading,
  getAllBusinessCategorySuccess,
  getAllBusinessCategoryFail,
  getVendorBusinessCategoryFail,
  getVendorBusinessCategoryLoading,
  getVendorBusinessCategorySuccess,
  addVendorBusinessCategoryFail,
  addVendorBusinessCategoryLoading,
  addVendorBusinessCategorySuccess,
  removeVendorBusinessCategoryFail,
  removeVendorBusinessCategoryLoading,
  removeVendorBusinessCategorySuccess
} = actions;

export const getAllBusinessCategory =
  (business_category_type?: string): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    dispatch(getAllBusinessCategoryLoading());
    try {
      const response = await API.get("/business_categories/get", {
        params: {
          length: 1000,
          business_category_type
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(getAllBusinessCategorySuccess(response.data.data));
      }
    } catch (error) {
      dispatch(getAllBusinessCategoryFail());
    }
  };

export const doAddVendorBusinessCategory =
  (payload: object, cb: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    dispatch(addVendorBusinessCategoryLoading());
    const { userToken } = getState().user;
    try {
      const response = await API.post("/vendor_categories/add", payload, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json"
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(addVendorBusinessCategorySuccess());
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error) {
      dispatch(addVendorBusinessCategoryFail());
    }
  };

export const doRemoveVendorBusinessCategory =
  (payload: object, cb: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    dispatch(removeVendorBusinessCategoryLoading());
    const { userToken } = getState().user;
    try {
      const response = await API.post("/vendor_categories/delete", payload, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json"
        }
      });
      if ([200, 201].includes(response.status)) {
        dispatch(removeVendorBusinessCategorySuccess());
        toastr.success("", response.data.message);
        cb && cb();
      }
    } catch (error) {
      dispatch(removeVendorBusinessCategoryFail());
    }
  };

export default reducer;

/* eslint-disable react-hooks/exhaustive-deps */
import StatsCard from "components/StatsCard";
import React, { useEffect, useState } from "react";
import "./styles.scss";
import BusinessTrade from "assests/icons/buyer/business-and-trade.svg";
import AddIcon from "assests/icons/buyer/add-icon.svg";
import SearchIcon from "components/SearchBar";
import Dropdown from "components/Dropdown";
import DataTable from "components/DataTable";
import { useDispatch, useSelector } from "react-redux";
import { companyColumn, companydatas } from "./data";
import CreateOREditLegalEntity from "components/ModalBuyer/CreateOrEditLegalEntity";
import { AppDispatch, RootState } from "store/store";
import { getAllCompaniesData, deleteCompanyData } from "store/slice/BuyerSlice/CompanyDataSlice/CompanyDataSlice";
import { toastPopUp } from "utils/utils";
import useSearch from "hooks/useSearch";
import Pagination from "components/Pagination";
import { checkPermission } from "utils/utils";


const CompanyData = () => {
  //State
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [singleCompanyData, setSingleCompanyData] = useState<any>(null);
  const { role_access } = useSelector((state: RootState) => state.user?.user);


  //Redux
  const dispatch = useDispatch<AppDispatch>();
  const { companies, loading } = useSelector((state: RootState) => state.companiesData);

  //State
  const [modal, setModal] = useState<boolean>(false);
  const closeModal = () => {
    setModal((prev) => !prev);
    setSingleCompanyData(null);
  };

  //Hook
  const { search, inputRef, handleChange, searched } = useSearch(getAllCompaniesData, perPage);

  //Show Number for PerPage Request
  const handleNewPage = (pageNo: number, page: number = 1) => {
    setPerPage(pageNo);
    setPage(page);
    dispatch(getAllCompaniesData(page, pageNo, search));
  };

  // setpage to the first page when a searched executed
  useEffect(() => {
    if (searched) setPage(1);
  }, [searched]);

  useEffect(() => {
    dispatch(getAllCompaniesData(page,perPage));
  }, []);

  //Edit CompanyData
  const editCompanyData = (id: string, desc: string, code: string,country:string) => {
    setSingleCompanyData({ id, desc, code, country });
    setModal((prev) => !prev);
  };

     //Pagination
  //---------------------------------------------
  const handlePageChange = React.useCallback(
    (page: number, perPage: number) => {
      setPage(page);
      dispatch(getAllCompaniesData(page, perPage, search));
    },
    [search]
  );

  const paginate = React.useCallback(
    (pageNo: number, perPage: number) => {
      setPage(pageNo);
      dispatch(getAllCompaniesData(pageNo, perPage, search));
    },
    [search]
  );
  const deleteCompany = (name: string, id: string) => {
    const payload = { id: id };

    toastPopUp(
      () => {
        dispatch(
          deleteCompanyData(payload, () => {
            dispatch(getAllCompaniesData(page,perPage));
          })
        );
      },
      "Delete",
      "Cancel",
      "Delete the entity",
      name,
      "red"
    );
  };

  const companiesList =
    companies &&
    companies?.data?.map((company) => {
      return {
        company_id: company.id,
        company_description: company.company_description,
        company_code: company.company_code,
        currency: company.currency_iso ?company.currency_iso:'N/A',
        edit: () => checkPermission(role_access, "SETUP_INDEX") && editCompanyData(company.id, company.company_description, company.company_code, company.country_id),
        deleteEntity: () => checkPermission(role_access, "SETUP_INDEX") && deleteCompany(company.location_description, company.id)
      };
    });

  return (
    <div id="settings-company">
      {modal && <CreateOREditLegalEntity singleCompanyData={singleCompanyData} closeModal={closeModal} />}
      
      <div className="container">
        <h3 className="page-title">Entity Log</h3>
        <div className="row mt-3 mb-5">
          <StatsCard
            statsNumber={companies?.count ?? 0}
            text="Legal Entity"
            classes="stats-icon"
            image={BusinessTrade}
            iconBackgroundColor="rgba(45, 156, 219, 0.1)"
          />
          <StatsCard image={AddIcon} classes="middle-img" centerText="Create Entity" onClick={closeModal} />
        </div>

        <div className="d-flex  mb-3">
          <SearchIcon
            placeholder="Search for ID or Discription"
            boxClasses=""
            textRef={inputRef}
            value={search}
            onChange={handleChange}
          />
          <Dropdown perPage={perPage} action={handleNewPage} />
        </div>
        <DataTable columns={companyColumn} data={companiesList} loading={loading} />
        {
          companies?.count > 10 ?
          <Pagination
                    page={page}
                    lastPage={companies?.pagination?.lastPage}
                    paginate={paginate}
                    total={companies?.pagination?.total}
                    handlePageChange={handlePageChange}
                    perPage={perPage}
        />
          : null
        }
        
      
        
      </div>
    </div>
  );
};

export default CompanyData;

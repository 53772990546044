/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Modal from "components/Modal";
import ArrowDropdown from "components/ArrowDropdown";
import Textbox from "components/Textbox";
import Button from "components/Button";
import useArrowDropdown from "hooks/useArrowDropdown";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { useEffect } from "react";
import { createWorkFlow_two, editWorkFlow_two, getApprovals_two } from "store/slice/BuyerSlice/ApprovalSlice/ApprovalSlice";
import SelectDropdown from "components/SelectDropdown";
import { getMaterialCategories } from "store/slice/BuyerSlice/ProductSlice/ProductSlice";
import { createApprovalStep, editApprovalStep, getApprovalStep } from "store/slice/BuyerSlice/ApprovalStepSlice/ApprovalStepSlice";
import id from "date-fns/locale/id/index";
import { getUsers } from "store/slice/UserMgtSlice/UserMgtSlice";

type Props = {
  closeModal: () => void;
  singleWorkFlowStep?: any;
  id?:any;
};
const CreateWorkFlowStep = ({ closeModal, singleWorkFlowStep = null,id }: Props) => {
  const [error, setError] = React.useState("");
  const {loading } = useSelector((state: RootState) => state.approvalStep)
  const { users } = useSelector((state: RootState) => state.userMgt);

  const { register, handleSubmit, errors, setValue } = useForm<FormData>({
    mode: "onChange"
  });

  const newUsers = users && users.data.filter((item)=>{
    return item.user_type === "Company"
  })

  type FormData = {
    step_description: string;
    approving_user_id: string;
  };

  //Redux Store
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getUsers(1,10000000));
  }, []);




  useEffect(() => {
    if (singleWorkFlowStep !== null) {
      setValue("approving_user_id", singleWorkFlowStep?.approval_user_id);
      setValue("step_description", singleWorkFlowStep?.step_description);
    }
  }, [singleWorkFlowStep]);


  // getting all companies
  const usersOptions =
    newUsers &&
    newUsers.map((_data: { id: string; lastname: string; othernames:string; }) => {
      return {
        value: _data?.id,
        optionName: `${_data?.lastname} ${_data?.othernames}`
      };
    });

  useEffect(() => {
  }, []);

  const onFormSubmit = (vals: FormData) => {
    if (singleWorkFlowStep) {
      const payload = { ...vals, id: singleWorkFlowStep.id };
      dispatch(
        editApprovalStep(payload, () => {
          closeModal();
          dispatch(getApprovalStep({page:1,length:10,id:id}));
        })
      );
      return;
    }

    const payload = { ...vals, approval_workflow_id: id }

    dispatch(
      createApprovalStep(payload, () => {
        closeModal();
        dispatch(getApprovalStep({page:1,length:10,id:id}));
      })
    );
  };


  const { open, selectedOption, handleDropdownChange, handleAction, keyOption, ref } = useArrowDropdown();

  return (
    <Modal modallength="modal-length" modalText={singleWorkFlowStep===null?"Create Workflow Step":"Edit Workflow Step"} onClose={closeModal}>
      <div id="buyer-create-location">
        <p className="">{singleWorkFlowStep === null ? "Complete this form with accurate data to create a new workflow step." : "Complete this form with accurate data to edit workflow step"}</p>
        <form className="mt-2" onSubmit={handleSubmit(onFormSubmit)}>
          <SelectDropdown
            boxClasses="mt-3"
            name="approving_user_id"
            label="User Id"
            placeholder="User Id"
            options={usersOptions}
            customRef={register({ required: true })}
            error={errors.approving_user_id && "This field is required"}
          />
          <Textbox
            type="text"
            boxClasses="mt-3"
            name="step_description"
            label="Description"
            placeholder="Description"
            error={errors.step_description && "This field is required"}
            customRef={register({
              required: true
            })}
          />
          <Button
            label={singleWorkFlowStep === null ? "Create Workflow Step" : "Edit Workflow Step"}
            btnType="btn-primary"
            boxClasses="mt-4 mb-5"
            btnStyle="100%"
            loading={loading}
          />
        </form>
      </div>
    </Modal>
  );
};

export default CreateWorkFlowStep;
